import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import DialogWrapper from '@/components/shared/DialogWrapper';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'sonner';
import { pathsBase } from '@/routes/paths';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { SONNER_SUCCESS_OPTIONS, SONNER_ERROR_OPTIONS } from '@/common/constants';
import * as Sentry from '@sentry/react';
import { ErrorResponse } from '@/api/types';
import ExpenseReportFundForm from './ExpenseReportFundForm';
import useExpenseReportStore from '@/store/useExpenseReportStore';
import {
  ExpenseReportFundFormType,
  ExpenseReportFundSchema,
  defaultExpenseReportFundFormValues,
} from '../utils/ExpenseReportFundSchema';
import {
  createEmployeeFundMutationFn,
  useEmployeeFunds,
} from '@/api/employeeFund/employeeFund.api';
import { getFullName } from '@/lib/utils';

const ExpenseReportFundCreate: FC = () => {
  const navigate = useNavigate();
  const { expenseReport } = useExpenseReportStore();

  const form = useForm<ExpenseReportFundFormType>({
    defaultValues: defaultExpenseReportFundFormValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(ExpenseReportFundSchema),
  });

  const { refetch } = useEmployeeFunds({ employeeId: expenseReport.id });

  const { mutate: createEmployeeFund, isPending } = useMutation({
    mutationFn: createEmployeeFundMutationFn,
    onSuccess: () => {
      refetch();
      toast(
        <SuccessToastText>
          ¡Anticipo de Dinero para{' '}
          <span className="text-blue-700">
            {getFullName(expenseReport.firstName, expenseReport.lastName)}
          </span>{' '}
          fue creado exitosamente!
        </SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismiss();
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      Sentry.captureException(error);
      toast.error('Ha ocurrido un error durante el Registro del Anticipo.', {
        description: 'Por favor, revisa los datos ingresados e intenta de nuevo.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  const handleCreateEmployeeFund: SubmitHandler<ExpenseReportFundFormType> = (formData) =>
    createEmployeeFund(formData);

  const handleDismiss = () =>
    navigate(
      `/${pathsBase.EXPENSES}/${pathsBase.EXPENSE_REPORTS}/${expenseReport.id}/${pathsBase.EXPENSE_REPORT_DETAIL_FUNDING}`,
    );

  return (
    <DialogWrapper
      title="Registrar Anticipo"
      onDismiss={handleDismiss}
      onOverlayClick={handleDismiss}
      className="min-w-[60rem]"
    >
      <ExpenseReportFundForm
        form={form}
        submitLabel="Registrar Anticipo"
        cancelLabel="Cancelar"
        isLoading={isPending}
        expenseReport={expenseReport}
        submitHandler={handleCreateEmployeeFund}
        dismissHandler={handleDismiss}
      />
    </DialogWrapper>
  );
};

export default ExpenseReportFundCreate;
