import { ColumnDef } from '@tanstack/react-table';
import { ExpenseReport } from '@/api/expenseReport/expenseReport.model';
import { getCurrencyCell, getFullName } from '@/lib/utils';
import { useNavigate } from 'react-router-dom';
import { pathsBase } from '@/routes/paths';
import { SortColumnWithBe } from '@/components/shared/SortColumnWithBe';

const expenseReportsTableColumns: ColumnDef<ExpenseReport>[] = [
  {
    accessorKey: 'name',
    header: () => <p className="text-xs">Nombre</p>,
    cell: ({ row }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const navigate = useNavigate();
      const fullName = getFullName(row.original.firstName, row.original.lastName);

      return (
        <button
          className="underline text-primary-500 hover:text-primary-600"
          onClick={() =>
            navigate(`/${pathsBase.EXPENSES}/${pathsBase.EXPENSE_REPORTS}/${row.original.id}`)
          }
        >
          {fullName}
        </button>
      );
    },
  },
  {
    accessorKey: 'email',
    header: ({ column }) => <SortColumnWithBe title="Email" column={column} />,
  },
  {
    accessorKey: 'balance',
    header: ({ column }) => <SortColumnWithBe title="Balance" column={column} />,
    cell: ({ row }) => {
      const currencyCell = getCurrencyCell<ExpenseReport>(row, 'balance');
      return (
        <p className={parseFloat(row.getValue('balance')) < 0 ? 'text-rose-700' : 'text-green-700'}>
          {currencyCell}
        </p>
      );
    },
  },
];

export { expenseReportsTableColumns };
