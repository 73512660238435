import { AlertCircle } from 'lucide-react';
import { Alert, AlertTitle } from '../ui/alert';
import { FC } from 'react';

interface ValidateAlertMessageProps {
  message: string;
}

const ValidateAlertMessage: FC<ValidateAlertMessageProps> = ({ message }) => (
  <div className="flex flex-row justify-end">
    <Alert variant="destructive" className="w-fit">
      <AlertCircle className="h-4 w-4" />
      <AlertTitle className="m-0 font-normal">{message}</AlertTitle>
    </Alert>
  </div>
);

export { ValidateAlertMessage };
