import { Column } from '@tanstack/react-table';
import { FC, useEffect, useMemo, useState } from 'react';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '../ui/dropdown-menu';
import { CheckIcon, FilterIcon, XIcon } from 'lucide-react';
import { Button } from '../ui/button';
import { cn } from '@/lib/utils';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../ui/command';
import { FilterCell } from '@/common/types';
import { FilterEmpty } from './FilterEmpty';
import { useLocation, useNavigate } from 'react-router-dom';

interface FilterColumnProps {
  title: string;
  column: Column<any, any>;
  filterOptions: Array<FilterCell>;
  entityLabel?: string;
  commandDropdownClassname?: string;
}

const FilterColumnWithBe: FC<FilterColumnProps> = ({
  title,
  column,
  commandDropdownClassname,
  filterOptions,
}: FilterColumnProps) => {
  const [selectedValue, setSelectedValue] = useState<string>('');
  const sortedUniqueValues = useMemo(() => filterOptions, [selectedValue, filterOptions]);

  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const keys = Array.from(params.keys());

  const handleSelectFilter = (value: string) => {
    setSelectedValue(value);
    const currentUrl = new URL(window.location.href);
    const params = new URLSearchParams(currentUrl.search);
    const newFilter = value;
    params.set(column.id, newFilter);
    params.set('pageIndex', '0');
    currentUrl.search = params.toString();
    window.history.pushState({}, '', currentUrl);
    navigate(currentUrl.search);
  };

  const handleClearFiltering = () => {
    setSelectedValue('');
    const currentUrl = new URL(window.location.href);
    const params = new URLSearchParams(currentUrl.search);
    params.delete(column.id);
    currentUrl.search = params.toString();
    window.history.pushState({}, '', currentUrl);
    navigate(currentUrl.search);
  };

  useEffect(() => {
    if (!params.get('pageIndex')) {
      setSelectedValue('');
    }
  }, [params]);

  return (
    <div className="flex items-center space-x-2">
      <DropdownMenu>
        <div className="relative w-fit">
          <DropdownMenuTrigger asChild>
            <Button
              variant="ghost"
              className={cn('p-0 hover:bg-transparent', {
                'text-foreground': keys.includes(column.id),
              })}
            >
              <span className="text-xs">{title}</span>
              <FilterIcon className="w-3 h-3 ml-2" />
            </Button>
          </DropdownMenuTrigger>
          {keys.includes(column.id) ? (
            <Button
              type="button"
              variant="link"
              className="absolute right-[-20px] top-2/4 translate-y-[-50%] z-50 w-fit h-fit p-0 text-destructive"
              onClick={handleClearFiltering}
            >
              <XIcon className="w-3 h-3 color-destructive" />
            </Button>
          ) : null}
        </div>
        <DropdownMenuContent align="end" className={commandDropdownClassname}>
          <Command>
            <CommandInput placeholder={`Filtrar ${title}`} autoFocus={true} className="h-9" />
            <CommandList>
              <CommandEmpty>
                <FilterEmpty
                  title={`No se encontraron resultados que coincidan con la búsqueda ingresada`}
                />
              </CommandEmpty>
              <CommandGroup>
                {sortedUniqueValues.map(({ label, value }) =>
                  value || label ? (
                    <CommandItem
                      key={`${value}-${label}`}
                      className="cursor-pointer"
                      onSelect={() => handleSelectFilter(value)}
                    >
                      {label}
                      <CheckIcon
                        className={cn(
                          'ml-auto h-3 w-3',
                          selectedValue === value ? 'opacity-100' : 'opacity-0',
                        )}
                      />
                    </CommandItem>
                  ) : null,
                )}
              </CommandGroup>
            </CommandList>
          </Command>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export { FilterColumnWithBe };
