import { z } from 'zod';
import moment from 'moment';
import { CURRENCY_OPTIONS, PAYMENT_MAX_VALUE_ERROR_MESSAGE } from '@/common/constants';
import { renderPendingAmount } from './PaymentUtils';
const REQUIRED_OPTION_LABEL = 'Seleccione una opción';
const REQUIRED_AMOUNT_LABEL = 'Ingrese un monto válido';

const SelectSchema = z
  .object({
    id: z.string().min(1, REQUIRED_OPTION_LABEL),
    name: z.string(),
  })
  .required();

const PaymentSchema = z
  .object({
    files: z.array(z.string()).optional(),
    ledgerAccount: SelectSchema,
    paymentNumber: z.string().min(1, REQUIRED_OPTION_LABEL),
    currency: z.string().min(1, REQUIRED_OPTION_LABEL),
    exchangeRate: z.coerce.number().min(0, REQUIRED_AMOUNT_LABEL),
    amount: z.coerce.number().min(1, REQUIRED_AMOUNT_LABEL),
    paymentDate: z.date({
      required_error: 'Seleccione una fecha de pago',
    }),
    pendingAmount: z.number().optional(),
    invoiceCurrency: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    if (
      data.pendingAmount &&
      data.invoiceCurrency &&
      renderPendingAmount(
        data.invoiceCurrency,
        data.currency,
        data.exchangeRate,
        data.amount,
        data.pendingAmount,
      ) < 0
    ) {
      return ctx.addIssue({
        code: 'custom',
        message: PAYMENT_MAX_VALUE_ERROR_MESSAGE,
      });
    }
  });

type PaymentFormType = z.infer<typeof PaymentSchema>;

const defaultPaymentFormValues: PaymentFormType = {
  paymentNumber: 'P-',
  ledgerAccount: { id: '', name: '' },
  files: [],
  currency: CURRENCY_OPTIONS[0].value,
  exchangeRate: 1,
  amount: 0,
  paymentDate: moment().toDate(),
};

export { PaymentSchema, defaultPaymentFormValues };
export type { PaymentFormType };
