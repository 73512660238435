import { forwardRef } from 'react';
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { FormControl, FormField, FormItem } from '../ui/form';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { SearchBarFormType } from '@/common/schemas';
import { Search } from 'lucide-react';
import { cn } from '@/lib/utils';

interface SearchBarProps {
  form: UseFormReturn<SearchBarFormType, any>;
  searchTerm: string;
  isLoading?: boolean;
  className?: string;
  useAbsolutePosition?: boolean;
  submitHandler: SubmitHandler<SearchBarFormType>;
}

const SearchBar = forwardRef<HTMLInputElement, SearchBarProps>(
  (
    { form, searchTerm, isLoading = false, className, useAbsolutePosition = true, submitHandler },
    ref,
  ) => (
    <div className={cn({ 'absolute top-0 right-44': useAbsolutePosition }, className)}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(submitHandler)} className="relative w-fit">
          <FormField
            name="value"
            control={form.control}
            disabled={isLoading}
            render={({ field }) => (
              <FormItem className="w-80 h-10">
                <FormControl>
                  <Input
                    {...field}
                    ref={ref}
                    type="text"
                    placeholder={`Buscar ${searchTerm}...`}
                    autoComplete="false"
                    className="data-error:border-input"
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <div className="absolute top-0 right-0">
            <Button
              type="submit"
              variant="ghost"
              disabled={isLoading || !form.formState.isValid}
              isLoading={isLoading}
              className="relative hover:bg-inherit"
              loaderClassName="w-[20px] h-[20px]"
            >
              <Search size={20} className="text-primary hover:text-primary/90" />
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  ),
);

SearchBar.displayName = 'SearchBar';

export { SearchBar };
