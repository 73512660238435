import { UseFormReturn } from 'react-hook-form';
import { InvoiceFormType } from '../utils/InvoiceSchema';
import { FC } from 'react';
import { LedgerAccountList } from '@/api/ledgerAccount/ledgerAccount.model';
import { Button } from '@/components/ui/button';
import { PlusCircle, TrashIcon } from 'lucide-react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { defaultAccountingEntryItemFormValues } from '@/pages/AccountsMayor/AccountingEntry/AccountingEntryItem/AccountingEntryItemSchema';
import { SelectFilter } from '@/components/shared/SelectFilter';
import { FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { CurrencyInput } from '@/components/ui/currency-input';
import { CurrencyOption, Money } from '@/common/types';
import { DETAIL_DISABLED_BUTTON_CLASS } from '@/common/constants';
import { Separator } from '@radix-ui/react-dropdown-menu';
import { fixAmount } from '@/lib/utils';

interface AccountingTabProps {
  form: UseFormReturn<InvoiceFormType, unknown>;
  ledgerAccounts: LedgerAccountList[] | undefined;
  isLoadingLedgerAccounts: boolean;
  disabled?: boolean;
  onEdit?: boolean;
}

const getDebitInArs = (
  amount: number,
  exchangeRateOfficial: number,
  currency: Money['currency'],
) => {
  if (amount >= 0) {
    if (currency === 'ARS') {
      return amount;
    } else {
      return fixAmount(amount * exchangeRateOfficial, 2);
    }
  }

  return 0;
};

const getCreditInArs = (
  amount: number,
  exchangeRateOfficial: number,
  currency: CurrencyOption['value'],
) => {
  if (amount < 0) {
    if (currency === 'ARS') {
      return -amount;
    }

    return fixAmount(-amount * exchangeRateOfficial, 2);
  }

  return 0;
};

const AccountingTab: FC<AccountingTabProps> = ({
  form,
  ledgerAccounts,
  isLoadingLedgerAccounts,
  disabled,
  onEdit = false,
}) => {
  const accountingEntries = form.watch('accountingEntries');

  const handleAddAccountingEntry = () => {
    form.setValue('accountingEntries', [
      ...accountingEntries,
      {
        ...defaultAccountingEntryItemFormValues,
        currency: form.watch('currency'),
        exchangeRateOfficial: form.watch('exchangeRate'),
      },
    ]);
  };

  const handleDeleteAccountingEntry = (index: number) => {
    const newEntries = accountingEntries.filter((_, i) => i !== index);
    form.setValue('accountingEntries', newEntries);
  };

  return (
    <>
      <Table>
        <TableHeader>
          <TableRow className="hover:bg-inherit">
            {disabled ? null : (
              <TableHead className="h-auto w-[120px] py-3 px-2 font-medium">Tipo</TableHead>
            )}
            <TableHead className="h-auto w-[220px] py-3 px-2 font-medium">Detalle</TableHead>
            <TableHead className="h-auto w-[275px] py-3 px-2 font-medium">
              Cuenta Contable
            </TableHead>
            <TableHead className="h-auto py-3 px-2 font-medium">
              Importe en moneda ({form.watch('currency')})
            </TableHead>
            <TableHead className="h-auto py-3 px-2 font-medium">Debe (ARS)</TableHead>
            <TableHead className="h-auto py-3 px-2 font-medium">Haber (ARS)</TableHead>
            <TableHead className="h-auto min-w-[69px] py-3 px-2"></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {accountingEntries.map((entry, index) => (
            <>
              <TableRow key={index} className="hover:bg-inherit">
                {disabled ? null : (
                  <TableCell className="px-2 align-baseline">
                    <p>{entry.type ?? '-'}</p>
                  </TableCell>
                )}
                <TableCell className="px-2 align-baseline">
                  <FormField
                    disabled={disabled}
                    control={form.control}
                    name={`accountingEntries.${index}.details`}
                    render={({ field }) => (
                      <FormItem className="w-[220px]">
                        <FormControl>
                          <Input
                            placeholder="Detalle del apunte..."
                            className="truncate resize-none"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </TableCell>
                <TableCell className="px-2 align-baseline">
                  <SelectFilter
                    form={form}
                    isLoading={isLoadingLedgerAccounts}
                    formName={`accountingEntries.${index}.ledgerAccount`}
                    formPlaceholder="Cuenta Contable"
                    formFilterPlaceholder="Buscar Cuenta Contable..."
                    options={ledgerAccounts || []}
                    classnames={{
                      button: disabled ? `${DETAIL_DISABLED_BUTTON_CLASS} w-full` : 'w-full',
                      item: 'w-[275px]',
                    }}
                    disabled={disabled}
                  />
                </TableCell>
                <TableCell className="px-2 align-baseline">
                  <FormField
                    control={form.control}
                    name={`accountingEntries.${index}.amount`}
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormControl>
                          <CurrencyInput
                            {...field}
                            type="number"
                            currency={form.watch('currency') as CurrencyOption['value']}
                            placeholder="Ingresar monto"
                            allowNegative={true}
                            disabled={disabled || entry.isSupplierEntry}
                            value={fixAmount(field.value, 2)}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </TableCell>
                <TableCell className="px-2 align-baseline">
                  <FormItem>
                    <FormControl>
                      <CurrencyInput
                        disabled
                        type="number"
                        currency="ARS"
                        placeholder="Ingresar monto"
                        value={getDebitInArs(
                          form.watch(`accountingEntries.${index}.amount`),
                          entry.exchangeRateOfficial || form.watch('exchangeRate'),
                          form.watch('currency') as CurrencyOption['value'],
                        )}
                        onChange={() => {}}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                </TableCell>
                <TableCell className="px-2 align-baseline">
                  <FormItem>
                    <FormControl>
                      <CurrencyInput
                        disabled
                        type="number"
                        currency="ARS"
                        placeholder="Ingresar monto"
                        value={getCreditInArs(
                          form.watch(`accountingEntries.${index}.amount`),
                          entry.exchangeRateOfficial || form.watch('exchangeRate'),
                          form.watch('currency') as CurrencyOption['value'],
                        )}
                        onChange={() => {}}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                </TableCell>
                {disabled ? null : (
                  <TableCell className="w-[69px] px-4 align-baseline">
                    <Button
                      variant="outlineDestructive"
                      type="button"
                      size="icon"
                      className="w-full border-input"
                      disabled={form.watch('accountingEntries').length === 1 || onEdit}
                      onClick={() => handleDeleteAccountingEntry(index)}
                    >
                      <TrashIcon className="w-4 h-4" />
                    </Button>
                  </TableCell>
                )}
              </TableRow>
              <Separator />
            </>
          ))}
        </TableBody>
      </Table>
      {disabled || onEdit ? null : (
        <Button
          variant="outlinePrimary"
          size="sm"
          type="button"
          className="mt-4 text-xs w-fit"
          onClick={handleAddAccountingEntry}
        >
          <PlusCircle className="w-4 h-4 mr-2" />
          Agregar Apunte Contable
        </Button>
      )}
    </>
  );
};

export { AccountingTab };
