import {
  DEFAULT_EMAIL_SCHEMA,
  DEFAULT_NAME_SCHEMA,
  DEFAULT_LAST_NAME_SCHEMA,
} from '@/common/constants';
import { z } from 'zod';

const ExpenseReportSchema = z
  .object({
    firstName: DEFAULT_NAME_SCHEMA,
    lastName: DEFAULT_LAST_NAME_SCHEMA,
    email: DEFAULT_EMAIL_SCHEMA,
  })
  .required();

type ExpenseReportFormType = z.infer<typeof ExpenseReportSchema>;

const defaultExpenseReportFormValues: ExpenseReportFormType = {
  firstName: '',
  lastName: '',
  email: '',
};

export type { ExpenseReportFormType };
export { ExpenseReportSchema, defaultExpenseReportFormValues };
