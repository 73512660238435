import { FC } from 'react';
import { ScreenShareIcon } from 'lucide-react';

const ResponsiveBanner: FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen text-center py-4 px-8 gap-4">
      <ScreenShareIcon className="w-32 h-32" />
      <div className="flex flex-col gap-2">
        <h1 className="text-4xl	text-foreground font-light tracking-tight">Página no disponible</h1>
        <p className="text-2xl	text-foreground font-light tracking-tight">
          Abrí esta página en una pantalla desktop para poder acceder a ella.
        </p>
      </div>
    </div>
  );
};

export default ResponsiveBanner;
