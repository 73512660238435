import httpClient from '../httpClient';
import {
  DEFAULT_DETAIL_OPTION,
  DEFAULT_INVOICE_STATE_OPTION,
  DEFAULT_PAGINATION_DIR,
  DEFAULT_PAGINATION_PAGE_INDEX,
  DEFAULT_PAGINATION_PAGE_SEARCH,
  DEFAULT_PAGINATION_PAGE_SIZE,
  DEFAULT_PAGINATION_SORT,
  DEFAULT_PAYMENT_STATE_OPTION,
  DEfAULT_SUPPLIER_OPTION,
} from '@/common/constants';
import { MutationFunction, keepPreviousData, useQuery } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { InvoiceQueryParams, ResponsePaginated } from '../types';
import { CreateInvoicePayload, InvoiceApi, InvoiceState, PaymentState } from './invoice.model';
import { InvoiceFormType } from '@/pages/Invoices/utils/InvoiceSchema';
import { AccountingEntryPayload } from '../accountingEntry/accountingEntry.model';
import { UUID } from 'crypto';
import { CurrencyOption } from '@/common/types';
import { Expense, ExpenseState } from '../transaction/transaction.model';

const INVOICE_BASE_PATH = '/invoice';
const INVOICE_KEY = 'invoice';
const INVOICE_STALE_TIME = 5000;

const transformInvoiceFormToPayload = (
  data: InvoiceFormType,
  isEdit: boolean = false,
): CreateInvoicePayload => {
  const totalAmount = data.expenses.reduce((acc, entry) => acc + entry.amount, 0);
  // build expenses
  const expenses = data.expenses.map((expense) => {
    const expenseMockup: Expense = {
      id: isEdit ? (expense.id as UUID) : undefined,
      receiptNumber: data.receiptNumber,
      type: 'Expense',
      state: ExpenseState.PENDING,
      dueDate: data.dueDate.toISOString().split('T')[0],
      destination: {
        category: expense.category && expense.category.id ? { id: expense.category.id } : undefined,
        costCenter: { id: expense.costCenter?.id || '' },
      },
      amount: data.currency === 'ARS' ? expense.amount / data.exchangeRateAlt : expense.amount,
      money: {
        currency: data.currency,
        amount: expense.amount,
        exchangeRate: data.exchangeRate,
      },
      supplier: { id: data.supplier.id },
      donation: expense.donation ? { id: expense.donation.id } : undefined,
      ledgerAccount: expense.ledgerAccount ? { id: expense.ledgerAccount.id } : undefined,
      description: expense.description,
      fileURL: data.files && data.files[0],
      receiptDate: data.receiptDate.toISOString().split('T')[0],
      accountingDate: data.accountingDate.toISOString().split('T')[0],
      global: expense.global,
      accountingEntries: data.accountingEntries
        .filter((accountingEntry) => accountingEntry.transaction === expense.id)
        .map((entry) => {
          return {
            id: entry.id as UUID,
            ledgerAccount: { id: entry.ledgerAccount.id as UUID },
            paymentDate: data.accountingDate.toISOString().split('T')[0],
            amount:
              data.currency === 'ARS'
                ? Math.abs(entry.amount)
                : Math.abs(entry.amount) * data.exchangeRate,
            credit: entry.amount < 0,
            details: entry.details,
            moneyOfficial: {
              currency: data.currency,
              amount: Math.abs(entry.amount),
              exchangeRate: data.exchangeRate,
            },
            moneyAlt: {
              currency: data.currency,
              amount: Math.abs((entry.amount * data.exchangeRate) / data.exchangeRateAlt),
              exchangeRate: data.exchangeRateAlt,
            },
            draft: true,
          };
        }),
    };
    if (data.supplier?.accounts[0]?.id || data.supplierAccount?.id) {
      expenseMockup.supplierAccount = {
        id: data.supplierAccount?.id,
      };
    }

    return expenseMockup;
  });
  // Build accounting entries
  const accountingEntries: Array<AccountingEntryPayload> = data.accountingEntries
    .filter((accountingEntry) => accountingEntry.isSupplierEntry)
    .map((entry) => {
      return {
        id: entry.id as UUID,
        ledgerAccount: { id: entry.ledgerAccount.id as UUID },
        paymentDate: data.accountingDate.toISOString().split('T')[0],
        amount:
          data.currency === 'ARS'
            ? Math.abs(entry.amount)
            : Math.abs(entry.amount) * data.exchangeRate,
        credit: entry.amount < 0,
        details: entry.details,
        moneyOfficial: {
          currency: data.currency,
          amount: Math.abs(entry.amount),
          exchangeRate: data.exchangeRate,
        },
        moneyAlt: {
          currency: data.currency,
          amount: Math.abs((entry.amount * data.exchangeRate) / data.exchangeRateAlt),
          exchangeRate: data.exchangeRateAlt,
        },
        draft: true,
      };
    });

  // build final payload
  return {
    receiptNumber: data.receiptNumber,
    accountingDate: data.accountingDate.toISOString().split('T')[0],
    dueDate: data.dueDate.toISOString().split('T')[0],
    receiptDate: data.receiptDate.toISOString().split('T')[0],
    details: data.details || '',
    money: {
      currency: data.currency as CurrencyOption['value'],
      amount: totalAmount,
      exchangeRate: data.exchangeRate,
    },
    moneyAlt: {
      currency: data.currency as CurrencyOption['value'],
      amount: (totalAmount * data.exchangeRate) / data.exchangeRateAlt,
      exchangeRate: data.exchangeRateAlt,
    },
    invoiceState: data.invoiceState || InvoiceState.DRAFT,
    paymentState: data.paymentState || PaymentState.PENDING,
    fileURL: data.files && data.files[0],
    expenses: expenses,
    accountingEntries: accountingEntries,
    currency: data.currency as CurrencyOption['value'],
  };
};

const invoiceApi = {
  getInvoices: async (params?: string) => {
    try {
      const response = await httpClient.get(
        params ? `${INVOICE_BASE_PATH}/list?${params}` : `${INVOICE_BASE_PATH}/list`,
      );
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  getInvoice: async (id: InvoiceApi['id']) => {
    try {
      const response = await httpClient.get(`${INVOICE_BASE_PATH}/${id}`);
      return response.data as InvoiceApi;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },

  getDetails: async (params?: string) => {
    try {
      return await httpClient.get(
        params ? `${INVOICE_BASE_PATH}/details${params}` : `${INVOICE_BASE_PATH}/details`,
      );
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },

  createInvoice: async (data: CreateInvoicePayload): Promise<InvoiceApi> => {
    try {
      const response = await httpClient.post(`${INVOICE_BASE_PATH}`, {
        data,
      });
      return response.data as InvoiceApi;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  publishInvoice: async (id: InvoiceApi['id']) => {
    try {
      const response = await httpClient.get(`${INVOICE_BASE_PATH}/publish/${id}`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  editInvoice: async (id: InvoiceApi['id'], data: CreateInvoicePayload): Promise<InvoiceApi> => {
    try {
      const response = await httpClient.put(`${INVOICE_BASE_PATH}/${id}`, {
        data,
      });
      return response.data as InvoiceApi;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  deleteInvoice: async (id: InvoiceApi['id']) => {
    try {
      const response = await httpClient.delete(`${INVOICE_BASE_PATH}/${id}`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
};

const useInvoices = ({
  page = DEFAULT_PAGINATION_PAGE_INDEX,
  size = DEFAULT_PAGINATION_PAGE_SIZE,
  sort = DEFAULT_PAGINATION_SORT,
  dir = DEFAULT_PAGINATION_DIR,
  search = DEFAULT_PAGINATION_PAGE_SEARCH,
  invoiceState = DEFAULT_INVOICE_STATE_OPTION,
  paymentState = DEFAULT_PAYMENT_STATE_OPTION,
  supplier = DEfAULT_SUPPLIER_OPTION,
  details = DEFAULT_DETAIL_OPTION,
  dueDate = '',
}: InvoiceQueryParams<InvoiceApi> = {}) => {
  const invoiceStateParam = invoiceState ? `&invoiceState=${invoiceState}` : '';
  const paymentStateParam = paymentState ? `&paymentState=${paymentState}` : '';
  const supplierParam = supplier ? `&supplier=${supplier}` : '';
  const detailsParam = details ? `&details=${details}` : '';
  const dueDateParam = dueDate ? `&dueDate=${dueDate}` : '';

  const queryParams = `page=${page}&size=${size}&sort=${sort}&dir=${dir}&search=${search}${invoiceStateParam}${paymentStateParam}${supplierParam}${detailsParam}${dueDateParam}`;
  return useQuery<
    [
      ResponsePaginated<InvoiceApi>,
      {
        dueExpenses: number;
        dueExpensesAlt: number;
      },
    ],
    Error,
    [
      ResponsePaginated<InvoiceApi>,
      {
        dueExpenses: number;
        dueExpensesAlt: number;
      },
    ]
  >({
    queryKey: [
      INVOICE_KEY,
      { page, size, search, sort, dir, invoiceState, paymentState, supplier, details, dueDate },
    ],
    queryFn: async () => {
      const response = (await invoiceApi.getInvoices(queryParams)) as {
        page: ResponsePaginated<InvoiceApi>;
        data: {
          dueExpenses: number;
          dueExpensesAlt: number;
        };
      };
      return [response.page, response.data];
    },
    placeholderData: keepPreviousData,
    staleTime: INVOICE_STALE_TIME,
  });
};

const useInvoice = (id: InvoiceApi['id']) => {
  return useQuery<InvoiceApi, Error, InvoiceApi>({
    queryKey: [INVOICE_KEY, id],
    queryFn: async () => {
      const invoice = await invoiceApi.getInvoice(id);
      if (invoice.fileURL) invoice.files = [invoice.fileURL];
      return invoice;
    },
    placeholderData: keepPreviousData,
    staleTime: INVOICE_STALE_TIME,
  });
};

const useDetails = (params?: URLSearchParams) => {
  const invoiceStateParam = params?.get('invoiceState')
    ? `&invoiceState=${params?.get('invoiceState')}`
    : '';
  const paymentStateParam = params?.get('paymentState')
    ? `&paymentState=${params.get('paymentState')}`
    : '';
  const supplierParam = params?.get('supplier') ? `&supplier=${params?.get('supplier')}` : '';
  const dueDateParam = params?.get('date') ? `&dueDate=${params?.get('date')}` : '';

  const queryParams = `?search=${params?.get('search') || DEFAULT_PAGINATION_PAGE_SEARCH}${invoiceStateParam}${paymentStateParam}${supplierParam}${dueDateParam}`;

  return useQuery<Array<{ id: string; name: string }>, Error>({
    queryKey: [INVOICE_KEY, queryParams],
    queryFn: async () => {
      const details = await invoiceApi.getDetails(queryParams);
      const data = details.data as Array<string>;
      return data.map((detail) => {
        return { id: detail, name: detail };
      });
    },
    placeholderData: keepPreviousData,
    staleTime: INVOICE_STALE_TIME,
  });
};

const createInvoiceMutationFn = async (data: InvoiceFormType): Promise<InvoiceApi> => {
  const payload = transformInvoiceFormToPayload(data);
  return await invoiceApi.createInvoice(payload);
};

const editInvoiceMutationFn: MutationFunction<
  InvoiceApi,
  [InvoiceApi['id'], InvoiceFormType]
> = async (params) => {
  const [id, data] = params;
  const payload = transformInvoiceFormToPayload(data, true);
  return await invoiceApi.editInvoice(id, payload);
};

const deleteInvoiceMutationFn = async (id: InvoiceApi['id']) => {
  return await invoiceApi.deleteInvoice(id);
};

const publishInvoiceMutationFn = async (id: InvoiceApi['id']) => {
  return await invoiceApi.publishInvoice(id);
};

export {
  INVOICE_KEY,
  useInvoices,
  useDetails,
  deleteInvoiceMutationFn,
  createInvoiceMutationFn,
  editInvoiceMutationFn,
  useInvoice,
  publishInvoiceMutationFn,
};
