import { LedgerAccountType, LedgerAccountTypeEnum } from '@/api/ledgerAccount/ledgerAccount.model';
import { RoutedTabsProps } from '@/components/shared/RoutedTabs';
import { paths, pathsBase } from '@/routes/paths';

const getLedgerAccountLabel = (type: string) => {
  if (type.includes(paths.LEDGER_ACCOUNTS_SUBTYPE) || type === LedgerAccountTypeEnum.SUB_TYPE) {
    return 'Subtipo';
  } else if (
    type.includes(paths.LEDGER_ACCOUNTS_ACCOUNT) ||
    type === LedgerAccountTypeEnum.ACCOUNT
  ) {
    return 'Cuenta';
  } else if (
    type.includes(paths.LEDGER_ACCOUNTS_SUBACCOUNT) ||
    type === LedgerAccountTypeEnum.SUB_ACCOUNT
  ) {
    return 'Subcuenta';
  } else {
    return 'Tipo';
  }
};

const getLedgerAccountType = (pathname: string) => {
  if (pathname.includes(paths.LEDGER_ACCOUNTS_SUBTYPE)) {
    return LedgerAccountTypeEnum.SUB_TYPE;
  } else if (pathname.includes(paths.LEDGER_ACCOUNTS_ACCOUNT)) {
    return LedgerAccountTypeEnum.ACCOUNT;
  } else if (pathname.includes(paths.LEDGER_ACCOUNTS_SUBACCOUNT)) {
    return LedgerAccountTypeEnum.SUB_ACCOUNT;
  } else {
    return LedgerAccountTypeEnum.TYPE;
  }
};

const getPreviousLevel = (type: LedgerAccountType): LedgerAccountType => {
  switch (type) {
    case LedgerAccountTypeEnum.SUB_ACCOUNT:
      return LedgerAccountTypeEnum.ACCOUNT;
    case LedgerAccountTypeEnum.ACCOUNT:
      return LedgerAccountTypeEnum.SUB_TYPE;
    case LedgerAccountTypeEnum.SUB_TYPE:
      return LedgerAccountTypeEnum.TYPE;
    default:
      return LedgerAccountTypeEnum.TYPE;
  }
};

const LEDGER_ACCOUNT_TABS: RoutedTabsProps<string>['tabs'] = [
  {
    id: pathsBase.LEDGER_ACCOUNTS_TYPE,
    label: 'Tipos',
    path: paths.LEDGER_ACCOUNTS_TYPE,
  },
  {
    id: pathsBase.LEDGER_ACCOUNTS_SUBTYPE,
    label: 'Subtipos',
    path: paths.LEDGER_ACCOUNTS_SUBTYPE,
  },
  {
    id: pathsBase.LEDGER_ACCOUNTS_ACCOUNT,
    label: 'Cuentas',
    path: paths.LEDGER_ACCOUNTS_ACCOUNT,
  },
  {
    id: pathsBase.LEDGER_ACCOUNTS_SUBACCOUNT,
    label: 'Subcuentas',
    path: paths.LEDGER_ACCOUNTS_SUBACCOUNT,
  },
];

export { LEDGER_ACCOUNT_TABS, getLedgerAccountLabel, getLedgerAccountType, getPreviousLevel };
