import { ReactElement, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table';
import {
  ColumnDef,
  ColumnFiltersState,
  FilterFn,
  OnChangeFn,
  PaginationState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { TablePagination } from '../ui/table-pagination';
import TableSkeleton from './TableSkeleton';
import { TableEmpty, TableEmptyProps } from './TableEmpty';
import { ResponsePaginated } from '@/api/types';
import { Skeleton } from '../ui/skeleton';

const ROWS_LABEL_DEFAULT = 'Páginas';

interface TableWrapperNewProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data?: ResponsePaginated<TData>;
  isLoading?: boolean;
  isFetching?: boolean;
  usePagination?: boolean;
  rowsLabel?: string;
  pageCount?: number;
  pagination?: PaginationState;
  filterFns?: Record<string, FilterFn<any>>;
  switchEmptyEntityGender?: boolean;
  emptyCta?: TableEmptyProps['cta'];
  emptyImgProps?: TableEmptyProps['imageProps'];
  emptyLogo?: ReactElement;
  onPaginationChange?: OnChangeFn<PaginationState>;
}

const TableWrapper = <TData, TValue>({
  data,
  isLoading = false,
  isFetching = false,
  usePagination = true,
  rowsLabel = ROWS_LABEL_DEFAULT,
  columns,
  pageCount,
  pagination,
  filterFns,
  switchEmptyEntityGender = false,
  emptyCta,
  emptyImgProps,
  emptyLogo,
  onPaginationChange,
}: TableWrapperNewProps<TData, TValue>): ReactElement => {
  const { content } = data || { content: [] };

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const table = useReactTable({
    data: content,
    columns,
    manualPagination: true,
    pageCount: pageCount ?? -1,
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    filterFns: filterFns,
    debugTable: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    onPaginationChange: onPaginationChange,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  if (isLoading) {
    return <TableSkeleton />;
  }

  if (table.getRowModel().rows?.length === 0) {
    return (
      <div className="flex justify-center w-full px-8 py-14">
        <TableEmpty
          title={`Aun no hay ${rowsLabel.toLowerCase()} ${switchEmptyEntityGender ? 'creadas' : 'creados'}`}
          description={`En este momento no hay ${rowsLabel.toLowerCase()} ${switchEmptyEntityGender ? 'creadas' : 'creados'}.`}
          cta={emptyCta}
          imageProps={emptyImgProps}
          logo={emptyLogo}
        />
      </div>
    );
  }

  return (
    <>
      <div className="border rounded-lg">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="bg-white hover:bg-white">
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      style={{ width: `${header.getSize()}px`, maxWidth: `${header.getSize()}px` }}
                      className="font-medium"
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.map((row, index) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
                className={
                  index % 2 === 0
                    ? 'bg-[#F4F4F5]/40 hover:bg-[#F4F4F5]/40'
                    : 'bg-white hover:bg-white'
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    className={cell.id.includes('_actions') ? 'text-center' : undefined}
                  >
                    {isFetching && !isLoading ? (
                      <Skeleton className="w-full h-7" />
                    ) : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      {usePagination ? (
        <div className="mt-4">
          <TablePagination table={table} rowsLabel={rowsLabel} />
        </div>
      ) : null}
    </>
  );
};

export { TableWrapper };
