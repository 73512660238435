import { SupplierTableItem } from '@/api/supplier/supplier.model';
import { SortColumnWithBe } from '@/components/shared/SortColumnWithBe';
import { getCurrencyCell } from '@/lib/utils';
import { ColumnDef } from '@tanstack/react-table';

const supplierTableColumns: ColumnDef<SupplierTableItem>[] = [
  {
    accessorKey: 'cuit',
    header: ({ column }) => <SortColumnWithBe title="CUIT/CUIL" column={column} />,
    cell: ({ row }) => {
      const cuit = row.getValue('cuit');
      return cuit || 'N/A';
    },
  },
  {
    accessorKey: 'code',
    header: ({ column }) => <SortColumnWithBe title="Código" column={column} />,
    cell: ({ row }) => {
      const code = row.getValue('code');
      if (code) {
        return code;
      }
      return '-';
    },
  },
  {
    accessorKey: 'name',
    header: () => <p className="text-xs">Nombre</p>,
    cell: ({ row }) => {
      const name = row.getValue('name');

      if (name && name !== '') {
        return name;
      }

      return '-';
    },
    size: 200,
  },
  {
    accessorKey: 'ledgerAccount',
    header: ({ column }) => <SortColumnWithBe title="Cuenta Contable" column={column} />,
    cell: ({ row }) => {
      const ledgerAccount = row.getValue('ledgerAccount');
      return ledgerAccount || 'N/A';
    },
    size: 200,
  },
  {
    accessorKey: 'openInvoices',
    header: () => <p className="text-xs">Facturas Abiertas</p>,
  },
  {
    accessorKey: 'balance',
    header: () => <p className="text-xs">Balance ARS</p>,
    cell: ({ row }) => {
      const currencyCell = getCurrencyCell<SupplierTableItem>(row, 'balance', 'ARS');
      return (
        <p className={parseFloat(row.getValue('balance')) < 0 ? 'text-rose-700' : 'text-green-700'}>
          {currencyCell}
        </p>
      );
    },
  },
  {
    accessorKey: 'balanceAlt',
    header: () => <p className="text-xs">Balance USD</p>,
    cell: ({ row }) => {
      const currencyCell = getCurrencyCell<SupplierTableItem>(row, 'balanceAlt');
      return (
        <p
          className={
            parseFloat(row.getValue('balanceAlt')) < 0 ? 'text-rose-700' : 'text-green-700'
          }
        >
          {currencyCell}
        </p>
      );
    },
  },
];

export { supplierTableColumns };
