import { FC } from 'react';
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { FISCAL_PERSON_TYPE_OPTIONS, AFIP_CONDITION_TYPE_OPTIONS } from '@/common/constants';
import { SupplierFormType, SupplierSchema } from '../utils/SupplierSchema';
import { getRequiredLabel } from '@/lib/utils';
import { useLedgerAccountChildrens } from '@/api/ledgerAccount/ledgerAccount.api';
import { SelectFilter } from '@/components/shared/SelectFilter';
import { Separator } from '@/components/ui/separator';
import { BankAccountForm } from './BankAccountForm';
import { ScrollArea } from '@/components/ui/scroll-area';

interface SupplierFormProps {
  form: UseFormReturn<SupplierFormType, unknown>;
  submitLabel: string;
  cancelLabel: string;
  isLoading?: boolean;
  submitHandler: SubmitHandler<SupplierFormType>;
  dismissHandler: () => void;
}

const SupplierForm: FC<SupplierFormProps> = ({
  form,
  submitLabel,
  cancelLabel,
  isLoading = false,
  submitHandler,
  dismissHandler,
}: SupplierFormProps) => {
  const isLegalEntity = form.watch('type') === 'LEGAL_ENTITY';

  const { data: ledgerAccountsChildren, isLoading: isLoadingLedgerAccounts } =
    useLedgerAccountChildrens();

  return (
    <ScrollArea className="overflow-auto h-[500px]">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(submitHandler)} className="flex flex-col gap-10 px-4">
          <div className="flex gap-4">
            <SelectFilter
              form={form}
              isLoading={isLoadingLedgerAccounts}
              formName="ledgerAccount"
              formLabel={getRequiredLabel(SupplierSchema, 'ledgerAccount', 'Cuenta Contable')}
              formPlaceholder="X.XX.XX.XX - Proveedores"
              formFilterPlaceholder="Buscar Cuenta Contable..."
              options={ledgerAccountsChildren || []}
              classnames={{ button: 'w-1/2', item: 'w-full' }}
            />
          </div>
          <Separator />
          <div className="flex flex-col gap-10">
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-4">
                <div className="flex gap-4">
                  <FormField
                    control={form.control}
                    name="nickName"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>
                          {getRequiredLabel(SupplierSchema, 'nickName', 'Nombre de Fantasía')}
                        </FormLabel>
                        <FormControl>
                          <Input {...field} type="text" placeholder="Ingresar nombre" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="type"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>{getRequiredLabel(SupplierSchema, 'type', 'Tipo')}</FormLabel>
                        <Select onValueChange={field.onChange} value={field.value}>
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Seleccione el tipo" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {FISCAL_PERSON_TYPE_OPTIONS.map((donorType) => (
                              <SelectItem key={donorType.value} value={donorType.value}>
                                {donorType.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex gap-4">
                  {isLegalEntity ? (
                    <FormField
                      control={form.control}
                      name="businessName"
                      render={({ field }) => (
                        <FormItem className="w-full">
                          <FormLabel>
                            {getRequiredLabel(SupplierSchema, 'businessName', 'Razón Social')}
                          </FormLabel>
                          <FormControl>
                            <Input {...field} type="text" placeholder="Ingresar Razón Social" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  ) : (
                    <>
                      <FormField
                        control={form.control}
                        name="firstName"
                        render={({ field }) => (
                          <FormItem className="w-full">
                            <FormLabel>
                              {getRequiredLabel(SupplierSchema, 'firstName', 'Nombre')}
                            </FormLabel>
                            <FormControl>
                              <Input {...field} type="text" placeholder="Ingresar Nombre" />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="lastName"
                        render={({ field }) => (
                          <FormItem className="w-full">
                            <FormLabel>
                              {getRequiredLabel(SupplierSchema, 'lastName', 'Apellido')}
                            </FormLabel>
                            <FormControl>
                              <Input {...field} type="text" placeholder="Ingresar Apellido" />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </>
                  )}
                  <FormField
                    control={form.control}
                    name="cuit"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>
                          {getRequiredLabel(SupplierSchema, 'cuit', 'CUIT/CUIL')}
                        </FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="Ingresar CUIT/CUIL" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex gap-4">
                  <FormField
                    control={form.control}
                    name="activity"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>
                          {getRequiredLabel(SupplierSchema, 'activity', 'Actividad')}
                        </FormLabel>
                        <FormControl>
                          <Input {...field} type="text" placeholder="Ingresar actividad" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="afipCondition"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>
                          {getRequiredLabel(SupplierSchema, 'afipCondition', 'Condición AFIP')}
                        </FormLabel>
                        <Select onValueChange={field.onChange} value={field.value}>
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Seleccione la condición" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {AFIP_CONDITION_TYPE_OPTIONS.map((donorType) => (
                              <SelectItem key={donorType.value} value={donorType.value}>
                                {donorType.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex gap-4">
                  <FormField
                    control={form.control}
                    name="code"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>{getRequiredLabel(SupplierSchema, 'code', 'Código')}</FormLabel>
                        <FormControl>
                          <Input {...field} type="number" placeholder="Ingresar código" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>{getRequiredLabel(SupplierSchema, 'email', 'Email')}</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            value={field.value || ''}
                            type="email"
                            placeholder="Ingresar email"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex gap-4">
                  <FormField
                    control={form.control}
                    name="address"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>
                          {getRequiredLabel(SupplierSchema, 'address', 'Dirección')}
                        </FormLabel>
                        <FormControl>
                          <Input {...field} type="text" placeholder="Ingresar dirección" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-6">
              <h2 className="text-lg font-medium text-foreground leading-none">
                Cuentas Bancarias
              </h2>
              <div className="flex flex-col gap-4">
                <div className="flex gap-4">
                  <BankAccountForm form={form} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-end gap-2">
            <Button type="button" variant="outline" disabled={isLoading} onClick={dismissHandler}>
              {cancelLabel}
            </Button>
            <Button
              type="submit"
              disabled={isLoading || !form.formState.isValid}
              className="relative"
              isLoading={isLoading}
            >
              {submitLabel}
            </Button>
          </div>
        </form>
      </FormProvider>
    </ScrollArea>
  );
};

export { SupplierForm };
