import { FC, ReactElement } from 'react';
import { Skeleton } from '../ui/skeleton';
import { cn } from '@/lib/utils';

interface HeadingClassNames {
  section?: string;
  title?: string;
  subtitle?: string;
}

interface HeadingProps {
  title: string;
  subtitle?: string;
  rightComponent?: ReactElement;
  useBreadcrumb?: boolean;
  breadcrumbComponent?: ReactElement;
  isLoading?: boolean;
  classNames?: HeadingClassNames;
}

const Heading: FC<HeadingProps> = ({
  title,
  subtitle,
  rightComponent,
  useBreadcrumb = false,
  breadcrumbComponent,
  isLoading = false,
  classNames: {
    section: sectionClassname,
    title: titleClassname,
    subtitle: subtitleClassname,
  } = {},
}) => {
  if (isLoading) {
    return (
      <section
        className={cn('flex items-center justify-between px-8 py-10 min-h-44', sectionClassname)}
      >
        <Skeleton className="h-12 w-80 bg-slate-200" />
        <Skeleton className="w-56 h-10 bg-slate-200" />
      </section>
    );
  }

  return (
    <section
      className={cn('flex items-center justify-between px-8 py-10 min-h-44', sectionClassname)}
    >
      <div className="flex flex-col gap-4">
        {useBreadcrumb ? <>{breadcrumbComponent}</> : null}
        <h1
          className={cn(
            'text-5xl font-light leading-none tracking-tight text-foreground',
            titleClassname,
          )}
          dangerouslySetInnerHTML={{ __html: title }}
        ></h1>
        {subtitle ? (
          <h2
            className={cn(
              'text-3xl font-light leading-none tracking-tight text-foreground',
              subtitleClassname,
            )}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          ></h2>
        ) : null}
      </div>
      {rightComponent}
    </section>
  );
};

export { Heading };
