import { FC } from 'react';
import {
  DEFAULT_PAGINATION_PAGE_COUNT,
  SONNER_ERROR_OPTIONS,
  SONNER_SUCCESS_OPTIONS,
} from '@/common/constants';
import { useTablePagination } from '@/hooks/useTablePagination';
import useTableColumnsWithActions from '@/hooks/useTableColumnsWithActions';
import { Outlet, useParams } from 'react-router-dom';
import { AlertDialogWrapper } from '@/components/shared/AlertDialog';
import { useMutation } from '@tanstack/react-query';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { AxiosError } from 'axios';
import { toast } from 'sonner';
import { TableWrapper } from '@/components/shared/TableWrapper';
import * as Sentry from '@sentry/react';
import {
  deleteTransactionMutationFn,
  useEmployeeExpenses,
} from '@/api/transaction/transaction.api';
import { Expense } from '@/api/transaction/transaction.model';
import { ExpenseReportExpensesTableColumns } from '../utils/ExpenseReportExpensesTableColumns';
import { UUID } from 'crypto';
import { useAlertDialog } from '@/hooks/useAlertDialog';

const PAGE_TITLE = 'Rendición de Gastos';

const ExpenseReportExpenses: FC = () => {
  const { id } = useParams() as { id: UUID };

  const { pageIndex, pageSize, paginationState, setPagination } = useTablePagination();

  const { alertDialog, setAlertDialog, handleDismissAlertDialog } = useAlertDialog();

  const { data, isLoading, refetch } = useEmployeeExpenses({
    page: pageIndex,
    size: pageSize,
    employeeId: id,
  });

  const { mutate: deleteExpense, isPending: isDeleteExpensesPending } = useMutation({
    mutationFn: deleteTransactionMutationFn,
    onSuccess: () => {
      refetch();
      toast(
        <SuccessToastText>Gasto eliminado exitosamente</SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismissAlertDialog();
    },
    onError: (error: AxiosError) => {
      Sentry.captureException(error);
      toast.error('Ha ocurrido un error durante la eliminación del Gasto.', {
        description: 'Por favor, intentalo nuevamente.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  const handleDeleteExpense = (expense: Expense) => {
    setAlertDialog({
      title: `¿Estás seguro que queres eliminar este Gasto? `,
      description: `Esta acción no puede ser deshecha y se perderá toda la información asociada a este Gasto.`,
      open: true,
      onCancel: handleDismissAlertDialog,
      onConfirm: () => deleteExpense(expense.id!),
    });
  };

  const ExpenseReportExpensesColumnsWithActions = useTableColumnsWithActions<Expense>(
    ExpenseReportExpensesTableColumns,
    () => {},
    handleDeleteExpense,
    true,
  );

  return (
    <>
      <TableWrapper
        columns={ExpenseReportExpensesColumnsWithActions}
        data={data ? data[0] : data}
        isLoading={isLoading}
        rowsLabel={PAGE_TITLE}
        pageCount={data ? data[0].totalPages : DEFAULT_PAGINATION_PAGE_COUNT}
        pagination={paginationState}
        onPaginationChange={setPagination}
      />
      <AlertDialogWrapper
        {...alertDialog}
        isLoading={isDeleteExpensesPending}
        onDismiss={handleDismissAlertDialog}
      />
      <Outlet />
    </>
  );
};

export default ExpenseReportExpenses;
