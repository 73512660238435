import { CURRENCY_OPTIONS } from '@/common/constants';
import { z } from 'zod';

const REQUIRED_OPTION_LABEL = 'Seleccione una opción';
const REQUIRED_AMOUNT_LABEL = 'Ingrese un monto válido';

const SelectSchema = z
  .object({
    id: z.string().min(1, REQUIRED_OPTION_LABEL),
    name: z.string(),
  })
  .required();

const AccountingEntryItemSchema = z.object({
  id: z.string().optional().nullable(),
  ledgerAccount: SelectSchema,
  currency: z.string().min(1, REQUIRED_OPTION_LABEL),
  amount: z.coerce.number({
    required_error: REQUIRED_AMOUNT_LABEL,
    invalid_type_error: REQUIRED_AMOUNT_LABEL,
  }),
  exchangeRateOfficial: z.coerce.number().min(0, REQUIRED_AMOUNT_LABEL),
  exchangeRateAlt: z.coerce.number().optional(),
  details: z.string().optional(),
  type: z.string().optional(),
  isSupplierEntry: z.boolean().optional(),
  transaction: z.string().optional().nullable(),
});

type AccountingEntryItemFormType = z.infer<typeof AccountingEntryItemSchema>;

const defaultAccountingEntryItemFormValues: AccountingEntryItemFormType = {
  ledgerAccount: {
    id: '',
    name: '',
  },
  currency: CURRENCY_OPTIONS[0].value,
  amount: 0,
  exchangeRateOfficial: 1,
  exchangeRateAlt: 1,
  isSupplierEntry: false,
};

export type { AccountingEntryItemFormType };
export { AccountingEntryItemSchema, defaultAccountingEntryItemFormValues };
