import { DEFAULT_CODE_SCHEMA } from '@/common/constants';
import { UUID } from 'crypto';
import { z } from 'zod';

const REQUIRED_OPTION_LABEL = 'Seleccione una opción';

const SelectSchema = z.object({
  id: z.string().min(1, REQUIRED_OPTION_LABEL),
  name: z.string(),
});

export const CostCenterSchema = z.object({
  project: SelectSchema.required(),
  code: DEFAULT_CODE_SCHEMA,
});

export type CostCenterFormType = {
  code?: string;
  project?: {
    id: UUID;
    name: string;
  };
  territory?: {
    id?: UUID;
    name?: string;
  };
};

export const defaultCostCenterFormValues: CostCenterFormType = {
  code: '',
  project: undefined,
  territory: undefined,
};
