import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { XIcon } from 'lucide-react';
import { FC, ReactNode } from 'react';
import { Button } from '../ui/button';

type MainButtonVariant = 'primary' | 'destructive';

interface AlertDialogWrapperProps {
  title: string;
  description: ReactNode;
  open: boolean;
  isLoading?: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  mainButtonVariant?: MainButtonVariant;
  onDismiss: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const AlertDialogWrapper: FC<AlertDialogWrapperProps> = ({
  title,
  description,
  open,
  isLoading = false,
  confirmLabel = 'Confirmar',
  cancelLabel = 'Cancelar',
  mainButtonVariant = 'destructive',
  onDismiss,
  onConfirm,
  onCancel,
}: AlertDialogWrapperProps) => {
  return (
    <AlertDialog open={open}>
      <AlertDialogContent>
        <button type="button" className="absolute top-4 right-4 !mt-0 !mb-0" onClick={onDismiss}>
          <XIcon className="w-4 h-4 color-foreground" />
        </button>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        {onConfirm || onCancel ? (
          <AlertDialogFooter>
            {onCancel ? (
              <AlertDialogCancel type="button" onClick={onCancel}>
                {cancelLabel}
              </AlertDialogCancel>
            ) : null}
            {onConfirm ? (
              <Button
                type="submit"
                className={
                  mainButtonVariant === 'destructive'
                    ? 'bg-destructive hover:bg-destructive/60'
                    : 'bg-primary hover:bg-primary/60'
                }
                isLoading={isLoading}
                onClick={onConfirm}
              >
                {confirmLabel}
              </Button>
            ) : null}
          </AlertDialogFooter>
        ) : null}
      </AlertDialogContent>
    </AlertDialog>
  );
};

export type { AlertDialogWrapperProps, MainButtonVariant };
export { AlertDialogWrapper };
