import { AccountingEntrySummary } from '@/api/accountingEntry/accountingEntry.model';
import { SortColumnWithBe } from '@/components/shared/SortColumnWithBe';
import { Button } from '@/components/ui/button';
import { formatAmount, getParamsWithAnimation } from '@/lib/utils';
import { pathsBase } from '@/routes/paths';
import { ColumnDef } from '@tanstack/table-core';
import { useLocation, useNavigate } from 'react-router-dom';

const AccountMayorTableColumns = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onDetailClick = (id: string) => {
    navigate(
      `/${pathsBase.ACCOUNTS_MAYOR}/${id}${getParamsWithAnimation(location.search, true).replace('pageIndex', 'amp')}`,
    );
  };

  const accountMayorTableColumns: ColumnDef<AccountingEntrySummary>[] = [
    {
      accessorKey: 'code',
      header: ({ column }) => <SortColumnWithBe title="Código" column={column} />,
    },
    {
      accessorKey: 'ledgerAccount',
      header: () => <p className="text-xs">Cuenta Contable</p>,
    },
    {
      accessorKey: 'debit',
      header: () => <p className="text-xs">Debe</p>,
      cell: ({ row }) => {
        const debit = row.getValue('debit') as number;
        const currencyCell = formatAmount(debit, 'ARS');
        return <p>{currencyCell}</p>;
      },
    },
    {
      accessorKey: 'credit',
      header: () => <p className="text-xs">Haber</p>,
      cell: ({ row }) => {
        const credit = row.getValue('credit') as number;
        const currencyCell = formatAmount(credit, 'ARS');
        return <p>{currencyCell}</p>;
      },
    },
    {
      accessorKey: 'balance',
      header: () => <p className="text-xs">Balance</p>,
      cell: ({ row }) => {
        const balance = row.getValue('balance') as number;
        const currencyCell = formatAmount(balance, 'ARS');
        return (
          <p
            className={
              balance === 0 ? 'text-foreground' : balance < 0 ? 'text-rose-700' : 'text-green-700'
            }
          >
            {currencyCell}
          </p>
        );
      },
    },
    {
      accessorKey: 'balanceAlt',
      header: () => <p className="text-xs">Balance Alternativo</p>,
      cell: ({ row }) => {
        const balance = row.getValue('balanceAlt') as number;
        const currencyCell = formatAmount(balance, 'USD');
        return (
          <p
            className={
              balance === 0 ? 'text-foreground' : balance < 0 ? 'text-rose-700' : 'text-green-700'
            }
          >
            {balance ? currencyCell : '-'}
          </p>
        );
      },
    },
    {
      accessorKey: 'id',
      header: () => <p className="text-xs">Acciones</p>,
      cell: ({ row }) => {
        return (
          <Button
            variant="link"
            className="pl-0 text-foreground hover:text-primary-500"
            onClick={() => {
              onDetailClick(row.getValue('id'));
            }}
          >
            Ver detalle
          </Button>
        );
      },
    },
  ];

  return accountMayorTableColumns;
};

export { AccountMayorTableColumns };
