import { CreditNoteApi } from '@/api/creditNote/creditNote.model';
import { DownloadFileCell } from '@/components/shared/DownloadFileCell';
import { SortColumnWithBe } from '@/components/shared/SortColumnWithBe';
import { formatAmount, genericColumnFilterFn } from '@/lib/utils';
import { ColumnDef } from '@tanstack/react-table';

const creditNotesTableColumns: ColumnDef<CreditNoteApi>[] = [
  {
    accessorKey: 'number',
    header: () => <p className="text-xs">Nro. Nota de Crédito</p>,
  },
  {
    accessorKey: 'reversionDate',
    header: () => <p className="text-xs">Fecha de Reversión</p>,
  },
  {
    accessorKey: 'money.amount',
    header: ({ column }) => (
      <SortColumnWithBe title="Importe Revertido" className="max-w-10" column={column} />
    ),
    cell: ({ row }) => {
      const { amount, currency } = row.original.money;
      return formatAmount(amount, currency);
    },
  },
  {
    accessorKey: 'money.exchangeRate',
    header: () => <p className="text-xs">Tipo de Cambio</p>,
    cell: ({ row }) => {
      const { exchangeRate } = row.original.money;
      return `$${exchangeRate}`;
    },
  },
  {
    accessorKey: 'ledgerAccount.name',
    filterFn: genericColumnFilterFn<CreditNoteApi>('ledgerAccount', 'id'),
    header: () => <p className="text-xs">Cuenta Contable</p>,
  },
  {
    accessorKey: 'details',
    header: () => <p className="text-xs">Motivo</p>,
    cell: ({ row }) => {
      const details = row.getValue('details');
      return details && details !== '' ? details : '-';
    },
  },
  {
    accessorKey: 'fileURL',
    header: () => <p className="text-xs">Comprobante</p>,
    cell: ({ row }) => {
      const fileURL = row.original.fileURL;
      return fileURL ? <DownloadFileCell fileUrl={fileURL} /> : '-';
    },
    size: 100,
  },
];

export { creditNotesTableColumns };
