import { FC, FormEvent, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { InvoiceFormType } from '../../Invoices/utils/InvoiceSchema';
import { TableCell, TableRow } from '@/components/ui/table';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { SelectFilter } from '@/components/shared/SelectFilter';
import { CurrencyOption } from '@/common/types';
import { CurrencyInput } from '@/components/ui/currency-input';
import { Input } from '@/components/ui/input';
import { TrashIcon } from 'lucide-react';
import { Checkbox } from '@/components/ui/checkbox';
import { Button } from '@/components/ui/button';
import { LedgerAccountList } from '@/api/ledgerAccount/ledgerAccount.model';
import {
  useBalanceByCostCenterDonationCategory,
  useCostCenterByCategory,
} from '@/api/costCenter/costCenter.api';
import { useCategoriesList } from '@/api/category/category.api';
import { DonationList } from '@/api/donation/donation.model';
import { CostCenterApi, CostCenterList } from '@/api/costCenter/costCenter.model';
import { CategoryListItem } from '@/api/category/category.model';
import { cn, formatAmount } from '@/lib/utils';
import { InvoiceState, PaymentState } from '@/api/invoice/invoice.model';
import { useDonationsByCostCenter } from '@/api/donation/donation.api';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

interface ExpenseRowProps {
  form: UseFormReturn<InvoiceFormType, unknown>;
  expenseIndex: number;
  ledgerAccounts?: Array<LedgerAccountList>;
  donations?: Array<DonationList>;
  globalCostCenter?: CostCenterApi;
  categories?: Array<CategoryListItem>;
  isLoadingLedgerAccounts: boolean;
  onDeleteExpense: (id: string) => void;
  readonly?: boolean;
  onEdit?: boolean;
}

const ExpenseRow: FC<ExpenseRowProps> = ({
  form,
  expenseIndex,
  isLoadingLedgerAccounts,
  ledgerAccounts,
  globalCostCenter,
  categories,
  onDeleteExpense,
  readonly = false,
  onEdit = false,
}) => {
  const invoiceState = form.watch('invoiceState');
  const expense = form.watch(`expenses.${expenseIndex}`);
  const expenseCostCenterId = form.watch(`expenses.${expenseIndex}.costCenter.id`);
  const expenseCategoryId = form.watch(`expenses.${expenseIndex}.category.id`);
  const expenseDonationId = form.watch(`expenses.${expenseIndex}.donation.id`);

  const [isGlobal, setIsGlobal] = useState(expense.global);

  const { data: allCategories, isLoading: isLoadingAllCategories } = useCategoriesList();
  const { data: costCentersByCategory, isLoading: isLoadingCostCentersByCategory } =
    useCostCenterByCategory(expenseCategoryId);
  const { data: donationsByCostCenter, isLoading: isLoadingDonationByCostCenter } =
    useDonationsByCostCenter(expenseCostCenterId, expenseCategoryId);

  const { data: finalBalance } = useBalanceByCostCenterDonationCategory(
    expenseCostCenterId,
    expenseDonationId,
    expenseCategoryId,
  );

  const categoryBalance = finalBalance ? finalBalance[0].balance : 0;

  const globalCategory = globalCostCenter?.balances
    ? globalCostCenter?.balances[0].category
    : 'Gastos Globales';

  const updateSupplierAccountingEntryAmount = () => {
    const accountingEntries = form.watch('accountingEntries');

    const supplierAccountingEntryIndex = accountingEntries.findIndex(
      (entry) => entry && entry.isSupplierEntry,
    );

    if (supplierAccountingEntryIndex >= 0) {
      const currentAmount = accountingEntries.reduce(
        (acc, entry) => acc + (entry.amount && !entry.isSupplierEntry ? entry.amount : 0),
        0,
      );

      form.setValue(`accountingEntries.${supplierAccountingEntryIndex}.amount`, -currentAmount);
    }
  };

  const handleValueChange = (
    value: string | number | Pick<LedgerAccountList, 'id' | 'name'>,
    key: 'details' | 'ledgerAccount' | 'amount',
  ) => {
    const accountingEntries = form.watch('accountingEntries');

    const accountingEntryIndex = accountingEntries.findIndex(
      (accountingEntry) => accountingEntry && accountingEntry.transaction === expense.id,
    );

    if (accountingEntryIndex >= 0) {
      form.setValue(`accountingEntries.${accountingEntryIndex}.${key}`, value);
      form.setValue(
        `accountingEntries.${accountingEntryIndex}.currency`,
        form.getValues('currency'),
      );
      form.setValue(
        `accountingEntries.${accountingEntryIndex}.exchangeRateOfficial`,
        form.getValues('exchangeRate'),
      );
      form.setValue(`accountingEntries.${accountingEntryIndex}.exchangeRateAlt`, 0);
    } else {
      form.setValue('accountingEntries', [
        ...accountingEntries,
        {
          ledgerAccount: expense.ledgerAccount,
          transaction: expense.id,
          amount: 0,
          details: key === 'details' ? (value as string) : '',
          currency: form.getValues('currency'),
          exchangeRateOfficial: form.getValues('exchangeRate'),
          exchangeRateAlt: 0,
        },
      ]);
    }

    if (key === 'amount') {
      updateSupplierAccountingEntryAmount();
    }
  };

  const handleDescriptionChange = (event?: FormEvent<HTMLInputElement>) => {
    const value = event?.currentTarget.value;

    if (value) {
      handleValueChange(value, 'details');
    }
  };

  const handleCategoryChange = (value: Pick<CategoryListItem, 'id' | 'name'>) => {
    if (value) {
      if (form.watch(`expenses.${expenseIndex}.global`)) {
        form.setValue(`expenses.${expenseIndex}.donation`, null);
        form.setValue(`expenses.${expenseIndex}.category`, {
          id: value.id,
          name: value.name || '',
        });
      } else {
        form.setValue(`expenses.${expenseIndex}.costCenter`, { id: '', name: '' });
        form.setValue(`expenses.${expenseIndex}.donation`, { id: '', name: '' });
        form.setValue(`expenses.${expenseIndex}.category`, {
          id: value.id,
          name: value.name || '',
        });
      }
    }
  };

  const handleCostCenterChange = (value: Pick<CostCenterList, 'id' | 'name'>) => {
    if (value) {
      form.setValue(`expenses.${expenseIndex}.donation`, { id: '', name: '' });
      form.setValue(`expenses.${expenseIndex}.costCenter`, {
        id: value.id,
        name: value.name || '',
      });
    }
  };

  const handleIsGlobalChange = (value: boolean) => {
    form.setValue(`expenses.${expenseIndex}.global`, value);
    setIsGlobal(value);

    if (value) {
      if (globalCostCenter && allCategories) {
        form.setValue(`expenses.${expenseIndex}.costCenter`, {
          id: `${globalCostCenter?.id}`,
          name: globalCostCenter?.code,
        });
        form.setValue(`expenses.${expenseIndex}.category`, {
          id: allCategories[0].id,
          name: allCategories[0].name || '',
        });
      }

      form.setValue(`expenses.${expenseIndex}.donation`, null);
    } else {
      form.setValue(`expenses.${expenseIndex}.category`, { id: '', name: '' });
      form.setValue(`expenses.${expenseIndex}.costCenter`, { id: '', name: '' });
    }
  };

  const handdleLedgerAccountChange = (value: Pick<LedgerAccountList, 'id' | 'name'>) => {
    if (value) {
      form.setValue(`expenses.${expenseIndex}.ledgerAccount`, {
        id: value.id,
        name: value.name,
      });

      handleValueChange(value, 'ledgerAccount');
    }
  };

  const handleAmountChange = (value?: number) => {
    if (value) {
      handleValueChange(value, 'amount');
    }
  };

  const handleDeleteExpense = () => {
    if (expense.id) {
      onDeleteExpense(expense.id);
    }
  };

  return (
    <TableRow className="hover:bg-inherit">
      <TableCell className="px-2 align-baseline">
        <FormField
          control={form.control}
          disabled={readonly}
          name={`expenses.${expenseIndex}.description`}
          render={({ field }) => (
            <FormItem className="w-[220px]">
              <FormControl>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger className="mt-[2px]" formNoValidate disabled>
                      <Input
                        placeholder="Detalle del gasto..."
                        className="truncate resize-none disabled:cursor-default"
                        onChangeCapture={handleDescriptionChange}
                        {...field}
                      />
                    </TooltipTrigger>
                    <TooltipContent
                      side="top"
                      className={cn('w-[200px]', field.value ? '' : 'hidden')}
                    >
                      <p className="text-xs">{field.value}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex gap-2 my-4">
          <FormField
            control={form.control}
            name={`expenses.${expenseIndex}.global`}
            render={({ field }) => (
              <FormItem className="flex flex-row items-start">
                <FormControl>
                  <Checkbox
                    {...field}
                    value=""
                    defaultChecked={expense?.global}
                    onCheckedChange={handleIsGlobalChange}
                    disabled={invoiceState && invoiceState !== InvoiceState.DRAFT}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel
                    className={cn(
                      'font-normal',
                      !invoiceState || invoiceState !== InvoiceState.DRAFT ? 'opacity-50' : '',
                    )}
                  >
                    Gasto Global
                  </FormLabel>
                </div>
              </FormItem>
            )}
          />
        </div>
      </TableCell>
      <TableCell className="px-2 align-baseline">
        <SelectFilter
          form={form}
          disabled={readonly}
          isLoading={isLoadingLedgerAccounts}
          formName={`expenses.${expenseIndex}.ledgerAccount`}
          formPlaceholder="Cuenta Contable"
          formFilterPlaceholder="Buscar Cuenta Contable..."
          options={ledgerAccounts || []}
          onChange={handdleLedgerAccountChange}
          classnames={{ button: 'w-full', item: 'w-[250px]' }}
        />
      </TableCell>
      <TableCell className="px-2 align-baseline">
        <SelectFilter
          form={form}
          formName={`expenses.${expenseIndex}.category`}
          formPlaceholder="Categoría"
          formFilterPlaceholder="Buscar Categoría..."
          options={
            categories?.filter((categories) => categories.name !== globalCategory) ||
            allCategories?.filter((categories) => categories.name !== globalCategory) ||
            []
          }
          classnames={{ button: 'w-full', item: 'w-[180px]' }}
          isLoading={isLoadingAllCategories}
          disabled={readonly || form.watch(`expenses.${expenseIndex}.global`)}
          onChange={handleCategoryChange}
        />
      </TableCell>
      <TableCell className="px-2 align-baseline">
        <SelectFilter
          disabled={isGlobal || readonly}
          form={form}
          formName={`expenses.${expenseIndex}.costCenter`}
          formPlaceholder="Centro de Costos"
          formFilterPlaceholder="Buscar Centro de Costos..."
          options={
            isGlobal && globalCostCenter
              ? [{ id: globalCostCenter.id, name: globalCostCenter.name }]
              : costCentersByCategory || []
          }
          isLoading={isLoadingCostCentersByCategory}
          classnames={{ button: 'w-full', item: 'w-[180px]' }}
          onChange={handleCostCenterChange}
        />
      </TableCell>
      <TableCell className="px-2 align-baseline">
        <SelectFilter
          form={form}
          disabled={isGlobal || readonly}
          isLoading={isLoadingDonationByCostCenter}
          formName={`expenses.${expenseIndex}.donation`}
          formPlaceholder={isGlobal ? '-' : 'Donación'}
          formFilterPlaceholder="Buscar Donación..."
          options={donationsByCostCenter || []}
          classnames={{ button: 'w-full', item: 'w-[180px]' }}
        />
      </TableCell>
      <TableCell className="px-2 align-baseline">
        <FormField
          control={form.control}
          name={`expenses.${expenseIndex}.amount`}
          disabled={
            (readonly && !onEdit) ||
            form.formState.defaultValues?.paymentState === PaymentState.PAYED
          }
          render={({ field }) => (
            <FormItem className="w-full">
              <FormControl>
                <CurrencyInput
                  {...field}
                  type="number"
                  currency={form.watch('currency') as CurrencyOption['value']}
                  placeholder="Ingresar monto"
                  autoEntryCreator={handleAmountChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div
          className={cn(
            'flex gap-1 p-2',
            readonly && onEdit
              ? 'hidden'
              : expenseCategoryId && expenseCostCenterId && expenseDonationId && '',
          )}
        >
          <p>Disponible:</p>
          <p
            className={cn(
              categoryBalance && categoryBalance >= 0 ? 'text-green-600' : 'text-red-600',
            )}
          >
            {formatAmount(categoryBalance)}
          </p>
        </div>
      </TableCell>
      {readonly && onEdit ? null : (
        <TableCell className="w-[69px] px-4 align-baseline">
          <Button
            variant="outlineDestructive"
            type="button"
            size="icon"
            className="w-full border-input"
            disabled={form.watch('expenses').length === 1 || readonly || onEdit}
            onClick={handleDeleteExpense}
          >
            <TrashIcon className="w-4 h-4" />
          </Button>
        </TableCell>
      )}
    </TableRow>
  );
};

export { ExpenseRow };
