import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  BankAccountFormType,
  BankAccountSchema,
  defaultBankAccountFormValues,
} from './utils/BankAccountSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import DialogWrapper from '@/components/shared/DialogWrapper';
import { useMutation } from '@tanstack/react-query';
import {
  editBankAccountMutationFn,
  useBankAccount,
  useBankAccounts,
} from '@/api/bankAccount/bankAccount.api';
import { AxiosError } from 'axios';
import { BankAccountsForm } from './components/BankAccountsForm';
import { toast } from 'sonner';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { SONNER_ERROR_OPTIONS, SONNER_SUCCESS_OPTIONS } from '@/common/constants';
import * as Sentry from '@sentry/react';
import { BankAccountApi } from '@/api/bankAccount/bankAccount.model';
import { UUID } from 'crypto';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ErrorResponse } from '@/api/types';
import { paths } from '@/routes/paths';
import { EditFormSkeleton } from '@/components/shared/EditFormSkeleton';
import { getErrorKeyandValue } from '@/lib/utils';

type BankAccountEditParams = {
  id: BankAccountApi['id'];
};

const BankAccountEdit: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams() as BankAccountEditParams;
  const {
    data: bankAccount,
    isLoading: isBankAccountLoading,
    refetch: refetchBankAccount,
    error,
  } = useBankAccount(id);
  const { refetch: refetchBankAccounts } = useBankAccounts();

  const form = useForm<BankAccountFormType>({
    defaultValues: bankAccount
      ? {
          name: bankAccount.name,
          accountNumber: bankAccount.accountNumber,
          ledgerAccount: bankAccount.ledgerAccount,
          cbu: bankAccount.cbu,
          currency: bankAccount.currency,
        }
      : defaultBankAccountFormValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(BankAccountSchema),
  });

  const { mutate: editBankAccount, isPending } = useMutation<
    BankAccountApi,
    AxiosError<ErrorResponse>,
    [UUID, BankAccountFormType]
  >({
    mutationFn: editBankAccountMutationFn,
    onSuccess: (data: BankAccountApi) => {
      refetchBankAccounts();
      toast(
        <SuccessToastText>
          ¡Cuenta Bancaria <span className="text-blue-700">{data.name}</span> fue editada
          exitosamente!
        </SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismiss();
      refetchBankAccount();
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      Sentry.captureException(error);

      if (
        error.response &&
        error.response.data.type === 'DUPLICATED_ERROR' &&
        error.response.data.errors
      ) {
        const key = getErrorKeyandValue(error.response.data.errors[0]).key;

        if (key === 'account_number') {
          form.setError('accountNumber', {
            type: 'validate',
            message:
              'La cuenta ingresada ya fue utilizada previamente. Intentá nuevamente con una cuenta nueva.',
          });
        } else if (key === 'cbu') {
          form.setError('cbu', {
            type: 'validate',
            message:
              'El cbu ingresado ya fue utilizado previamente. Intentá nuevamente con una cbu nuevo.',
          });
        }
      }
      toast.error('Ha ocurrido un error durante la edición de la nueva Cuenta Bancaria.', {
        description: 'Por favor, revisa los datos ingresados e intenta de nuevo.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  useEffect(() => {
    if (bankAccount) {
      form.reset({
        name: bankAccount.name,
        accountNumber: bankAccount.accountNumber,
        ledgerAccount: bankAccount.ledgerAccount,
        cbu: bankAccount.cbu,
        currency: bankAccount.currency,
      });
    }
  }, [bankAccount]);

  if (error) {
    return <Navigate to="*" replace={true} />;
  }

  const handleDismiss = () => navigate(paths.BANK_ACCOUNTS);

  const handleEditBankAccount: SubmitHandler<BankAccountFormType> = (formData) => {
    formData.accountNumber = formData.accountNumber.trim();
    if (formData.cbu) {
      formData.cbu = formData.cbu.trim();
    }
    if (bankAccount) {
      editBankAccount([bankAccount.id, formData]);
    } else {
      toast.error('Lo sentimos, no tenés ningúna Cuenta Bancaria seleccionada.', {
        description: 'Por favor, seleccioná una Cuenta Bancaria e intentá de nuevo.',
        ...SONNER_ERROR_OPTIONS,
      });
      handleDismiss();
    }
  };

  return (
    <DialogWrapper
      title="Editar Cuenta Bancaria"
      className="w-[425px]"
      onDismiss={handleDismiss}
      onOverlayClick={handleDismiss}
    >
      {isBankAccountLoading || !bankAccount ? (
        <EditFormSkeleton inputRows={2} />
      ) : (
        <BankAccountsForm
          form={form}
          submitLabel="Editar Cuenta Bancaria"
          cancelLabel="Cancelar"
          isLoading={isPending}
          submitHandler={handleEditBankAccount}
          dismissHandler={handleDismiss}
        />
      )}
    </DialogWrapper>
  );
};

export default BankAccountEdit;
