import { UUID } from 'crypto';
import { Expense } from '../transaction/transaction.model';
import { Supplier } from '../supplier/supplier.model';
import { Money } from '@/common/types';
import { AccountingEntryPayload } from '../accountingEntry/accountingEntry.model';
import { CreditNote } from '../creditNote/creditNote.model';
import { BadgeProps } from '@/components/ui/badge';
import { PaymentApi } from '../payment/payment.model';

enum InvoiceState {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

enum PaymentState {
  PENDING = 'PENDING',
  PAYED = 'PAYED',
  CANCELLED = 'CANCELLED',
}

interface ExpenseInInvoiceDTO {
  supplierAccount: { id: string; cbu: string; currency: string };
  id?: UUID;
  description: string;
  global: boolean;
  amount: number;
  ledgerAccount: {
    id: UUID;
    name: string;
    code: string;
  };
  donation: {
    id: UUID;
    name: string;
  };
  costCenter: {
    id: UUID;
    name: string;
  };
  category: {
    id: UUID;
    name: string;
  };
  money?: Money;
}

interface AccountingEntryInInvoiceDTO {
  id: UUID;
  amount: number;
  credit: boolean;
  details: string;
  ledgerAccount: {
    id: UUID;
    name: string;
    code: string;
  };
  type?: string;
  transaction?: UUID;
  moneyOfficial?: Money;
  moneyAlt?: Money;
}

interface Invoice {
  id?: UUID;
  dueDate?: string;
  receiptDate?: string;
  accountingDate?: string;
  receiptNumber?: string;
  money: Money;
  moneyAlt: Money;
  details?: string;
  invoiceState: InvoiceState;
  paymentState: PaymentState;
  fileURL?: string;
  expenses?: Array<ExpenseInInvoiceDTO>;
  accountingEntries?: Array<AccountingEntryInInvoiceDTO>;
  supplier?: Supplier;
  total?: number;
}

interface InvoiceApi extends Invoice {
  files?: Array<string>;
  pendingAmount?: number;
  payments?: Array<PaymentApi>;
  creditNotes?: Array<CreditNote>;
  paidDate?: string;
  createdAt?: string;
  updatedAt?: string;
  onEdit?: () => void;
  onDelete?: () => void;
}

interface CreateInvoicePayload {
  receiptNumber: string;
  details: string;
  dueDate: string;
  receiptDate: string;
  accountingDate: string;
  money: Money;
  moneyAlt: Money;
  invoiceState: InvoiceState;
  paymentState: PaymentState;
  fileURL?: string;
  expenses: Array<Expense>;
  accountingEntries?: Array<AccountingEntryPayload>;
  currency?: string;
}

const invoiceStateMap: { [key in InvoiceState]: string } = {
  PUBLISHED: 'Publicada',
  DRAFT: 'Borrador',
};

const invoicePaymentStateMap: { [key in PaymentState]: string } = {
  PENDING: 'Pendiente',
  PAYED: 'Pago',
  CANCELLED: 'Cancelado',
};

const invoicePaymentBadgeStylesMap: { [key in PaymentState]: BadgeProps['variant'] } = {
  PENDING: 'outlineYellow',
  PAYED: 'outlineGreen',
  CANCELLED: 'outlineRed',
};

export type {
  Invoice,
  InvoiceApi,
  CreateInvoicePayload,
  ExpenseInInvoiceDTO,
  AccountingEntryInInvoiceDTO,
};

export {
  InvoiceState,
  PaymentState,
  invoiceStateMap,
  invoicePaymentStateMap,
  invoicePaymentBadgeStylesMap,
};
