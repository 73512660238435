import { Helmet } from 'react-helmet';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { zodResolver } from '@hookform/resolvers/zod';
import bannerImage from '@/assets/images/banners/banner-01.jpg';
import logoImage from '@/assets/images/logo.png';
import { SubmitHandler, useForm } from 'react-hook-form';
import { LoginFormType, LoginSchema, defaultLoginValues } from './LoginSchema';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { FC } from 'react';
import { useAuth } from '@/hooks/useAuth';

const Login: FC = () => {
  const { loading, mutate } = useAuth();

  const form = useForm<LoginFormType>({
    resolver: zodResolver(LoginSchema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: defaultLoginValues,
  });

  const onSubmitLogin: SubmitHandler<LoginFormType> = (formData) => mutate(formData);

  return (
    <>
      <Helmet>
        <title>PEM - Login</title>
      </Helmet>
      <div className="min-h-screen md:grid md:grid-cols-2">
        <div
          className="hidden bg-center bg-cover md:block"
          style={{
            backgroundImage: `url(${bannerImage})`,
          }}
        ></div>
        <div className="flex flex-col justify-center p-4 sm:p-8 md:min-h-screen">
          <div className="w-full max-w-sm mx-auto space-y-6">
            <div className="space-y-2 text-center">
              <img src={logoImage} alt="logo" width={100} height={100} className="mx-auto mb-6" />
              <h1 className="text-2xl font-semibold">Iniciar Sesión</h1>
            </div>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmitLogin)} className="space-y-6">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input {...field} type="email" autoComplete="email" placeholder="Email" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Contraseña</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          type="password"
                          autoComplete="current-password"
                          placeholder="Password"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="!mt-8">
                  <Button
                    type="submit"
                    className="w-full"
                    disabled={loading || !form.formState.isValid}
                    isLoading={loading}
                  >
                    Iniciar Sesión
                  </Button>
                </div>
              </form>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export { Login };
