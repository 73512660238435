import { Expense } from '@/api/transaction/transaction.model';
import { formatAmount, getConvertedAmount } from '@/lib/utils';
import { ColumnDef } from '@tanstack/react-table';

const ExpenseReportExpensesTableColumns: ColumnDef<Expense>[] = [
  {
    accessorKey: 'reportDate',
    header: () => <p className="text-xs">Fecha</p>,
  },
  {
    accessorKey: 'supplier',
    header: () => <p className="text-xs">Proveedor</p>,
    cell: ({ row }) => {
      const supplier = row.getValue('supplier') as string;
      return supplier || 'N/A';
    },
  },
  {
    accessorKey: 'costCenter',
    header: () => <p className="text-xs">Centro de Costo</p>,
    cell: ({ row }) => {
      const costCenter = row.getValue('costCenter') as string;
      return costCenter || 'N/A';
    },
  },
  {
    accessorKey: 'ledgerAccount',
    header: () => <p className="text-xs">Cuenta Contable</p>,
    cell: ({ row }) => {
      const ledgerAccount = row.getValue('ledgerAccount') as string;
      return ledgerAccount || 'N/A';
    },
  },
  {
    accessorKey: 'category',
    header: () => <p className="text-xs">Categoría</p>,
    cell: ({ row }) => {
      const category = row.getValue('category') as string;
      return category || 'N/A';
    },
  },
  {
    accessorKey: 'amount',
    header: () => <p className="text-xs">Monto</p>,
    cell: ({ row }) => {
      const money = row.getValue('amount') as Expense['amount'];
      const currency = row.getValue('currency') as string;
      return formatAmount(money, currency);
    },
  },
  {
    accessorKey: 'money',
    header: () => <p className="text-xs">Monto en USD</p>,
    cell: ({ row }) => {
      const money = row.getValue('money') as Expense['money'];
      const convertedAmount = getConvertedAmount(money.amount, money.exchangeRate);
      return formatAmount(convertedAmount);
    },
  },
  {
    accessorKey: 'receiptNumber',
    header: () => <p className="text-xs">Recibo #</p>,
  },
];

export { ExpenseReportExpensesTableColumns };
