import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  BankAccountFormType,
  BankAccountSchema,
  defaultBankAccountFormValues,
} from './utils/BankAccountSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import DialogWrapper from '@/components/shared/DialogWrapper';
import { useMutation } from '@tanstack/react-query';
import { createBankAccountMutationFn, useBankAccounts } from '@/api/bankAccount/bankAccount.api';
import { AxiosError } from 'axios';
import { toast } from 'sonner';
import { paths } from '@/routes/paths';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { SONNER_SUCCESS_OPTIONS, SONNER_ERROR_OPTIONS } from '@/common/constants';
import { BankAccountsForm } from './components/BankAccountsForm';
import * as Sentry from '@sentry/react';
import { BankAccountApi } from '@/api/bankAccount/bankAccount.model';
import { ErrorResponse } from '@/api/types';
import { getErrorKeyandValue } from '@/lib/utils';

const BankAccountsCreate: FC = () => {
  const navigate = useNavigate();

  const form = useForm<BankAccountFormType>({
    defaultValues: defaultBankAccountFormValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(BankAccountSchema),
  });

  const { refetch } = useBankAccounts();

  const { mutate: createBankAccount, isPending } = useMutation({
    mutationFn: createBankAccountMutationFn,
    onSuccess: (data: BankAccountApi) => {
      refetch();
      toast(
        <SuccessToastText>
          ¡Cuenta Bancaria <span className="text-blue-700">{data.name}</span> fue creada
          exitosamente!
        </SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismiss();
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      Sentry.captureException(error);

      if (
        error.response &&
        error.response.data.type === 'DUPLICATED_ERROR' &&
        error.response.data.errors
      ) {
        const key = getErrorKeyandValue(error.response.data.errors[0]).key;

        if (key === 'account_number') {
          form.setError('accountNumber', {
            type: 'validate',
            message:
              'La cuenta ingresada ya fue utilizada previamente. Intentá nuevamente con una cuenta nueva.',
          });
        } else if (key === 'cbu') {
          form.setError('cbu', {
            type: 'validate',
            message:
              'El cbu ingresado ya fue utilizado previamente. Intentá nuevamente con una cbu nuevo.',
          });
        }
      }
      toast.error('Ha ocurrido un error durante la creación de la nueva Cuenta Bancaria.', {
        description: 'Por favor, revisa los datos ingresados e intenta de nuevo.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  const handleCreateBankAccount: SubmitHandler<BankAccountFormType> = (formData) => {
    formData.accountNumber = formData.accountNumber.trim();
    if (formData.cbu) {
      formData.cbu = formData.cbu.trim();
    }
    createBankAccount(formData);
  };

  const handleDismiss = () => navigate(paths.BANK_ACCOUNTS);

  return (
    <DialogWrapper
      title="Crear Cuenta Bancaria"
      className="w-[425px]"
      onDismiss={handleDismiss}
      onOverlayClick={handleDismiss}
    >
      <BankAccountsForm
        form={form}
        submitLabel="Crear Cuenta Bancaria"
        cancelLabel="Cancelar"
        isLoading={isPending}
        submitHandler={handleCreateBankAccount}
        dismissHandler={handleDismiss}
      />
    </DialogWrapper>
  );
};

export default BankAccountsCreate;
