import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ProjectFormType, ProjectSchema, defaultProjectFormValues } from './utils/ProjectSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import DialogWrapper from '@/components/shared/DialogWrapper';
import { useMutation } from '@tanstack/react-query';
import { editProjectMutationFn, useProject, useProjects } from '@/api/project/project.api';
import { AxiosError } from 'axios';
import { ProjectsForm } from './components/ProjectsForm';
import { toast } from 'sonner';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { SONNER_ERROR_OPTIONS, SONNER_SUCCESS_OPTIONS } from '@/common/constants';
import * as Sentry from '@sentry/react';
import { ProjectApi } from '@/api/project/project.model';
import { UUID } from 'crypto';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ErrorResponse } from '@/api/types';
import { paths } from '@/routes/paths';
import { EditFormSkeleton } from '@/components/shared/EditFormSkeleton';

type ProjectsEditParams = {
  id: ProjectApi['id'];
};

const ProjectsEdit: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams() as ProjectsEditParams;
  const {
    data: project,
    isLoading: isProjectLoading,
    refetch: refetchProject,
    error,
  } = useProject(id);
  const { refetch: refetchProjects } = useProjects();

  const form = useForm<ProjectFormType>({
    defaultValues: project
      ? {
          name: project.name,
          code: project.code,
        }
      : defaultProjectFormValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(ProjectSchema),
  });

  const { mutate: editProject, isPending } = useMutation<
    ProjectApi,
    AxiosError<ErrorResponse>,
    [UUID, ProjectFormType]
  >({
    mutationFn: editProjectMutationFn,
    onSuccess: (data: ProjectApi) => {
      refetchProjects();
      toast(
        <SuccessToastText>
          ¡Proyecto <span className="text-blue-700">{data.code}</span> fue editado exitosamente!
        </SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismiss();
      refetchProject();
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      Sentry.captureException(error);

      if (error.response && error.response.data.type === 'DUPLICATED_ERROR') {
        form.setError('code', {
          type: 'validate',
          message:
            'El código ingresado ya fue utilizado previamente. Intentá nuevamente con un código personalizado.',
        });
      } else {
        toast.error('Ha ocurrido un error durante la edición del Proyecto.', {
          description: 'Por favor, revisa los datos ingresados e intentá de nuevo.',
          ...SONNER_ERROR_OPTIONS,
        });
      }
    },
  });

  useEffect(() => {
    if (project) {
      form.reset({
        name: project.name,
        code: project.code,
      });
    }
  }, [project]);

  if (error) {
    return <Navigate to="*" replace={true} />;
  }

  const handleDismiss = () => navigate(paths.PROJECTS);

  const handleEditProject: SubmitHandler<ProjectFormType> = (formData) => {
    if (project) {
      editProject([project.id, formData]);
    } else {
      toast.error('Lo sentimos, no tenés ningún Proyecto seleccionado.', {
        description: 'Por favor, seleccioná un Proyecto e intentá de nuevo.',
        ...SONNER_ERROR_OPTIONS,
      });
      handleDismiss();
    }
  };

  return (
    <DialogWrapper
      title="Editar Proyecto"
      className="w-[425px]"
      onDismiss={handleDismiss}
      onOverlayClick={handleDismiss}
    >
      {isProjectLoading || !project ? (
        <EditFormSkeleton inputRows={2} />
      ) : (
        <ProjectsForm
          form={form}
          submitLabel="Editar Proyecto"
          cancelLabel="Cancelar"
          isLoading={isPending}
          submitHandler={handleEditProject}
          dismissHandler={handleDismiss}
        />
      )}
    </DialogWrapper>
  );
};

export default ProjectsEdit;
