import { SheetLayout } from '@/components/layout/SheetLayout';
import { pathsBase } from '@/routes/paths';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import DonationPayForm from './components/DonationPayForm';
import { DonationApi } from '@/api/donation/donation.model';
import { useDonation } from '@/api/donation/donation.api';
import {
  DonationPayFormType,
  DonationPaySchema,
  defaultDonationPayFormValues,
} from './utils/DonationPaySchema';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  InvoiceFormType,
  InvoiceSchema,
  defaultInvoiceFormValues,
  transformDonationToInvoiceFormDefaultValue,
} from '../Invoices/utils/InvoiceSchema';
import { useEffect } from 'react';
import { DonationPayDetailSkeleton } from './components/DonationPayDetailSkeleton';
import { getParamsWithAnimation } from '@/lib/utils';

const PAGE_TITLE = 'Detalle Cobro';

type DonationPayParams = {
  id: DonationApi['id'];
};

const DonationPayDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams() as DonationPayParams;

  const { data: donation, isLoading: isLoadingDonation } = useDonation(id);

  const form = useForm<DonationPayFormType>({
    defaultValues: defaultDonationPayFormValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(DonationPaySchema),
  });

  const accountingForm = useForm<InvoiceFormType>({
    defaultValues: donation
      ? transformDonationToInvoiceFormDefaultValue(donation)
      : defaultInvoiceFormValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(InvoiceSchema),
  });

  const handleDismiss = () =>
    navigate(`/${pathsBase.DONATIONS}/${id}${getParamsWithAnimation(location.search, true)}`);

  useEffect(() => {
    if (donation) {
      accountingForm.reset(transformDonationToInvoiceFormDefaultValue(donation));
    }
  }, [donation]);

  return (
    <>
      <Helmet>
        <title>PEM - {PAGE_TITLE}</title>
      </Helmet>
      <SheetLayout onClose={handleDismiss}>
        <div className="max-w-7xl mx-auto">
          {isLoadingDonation || !donation ? (
            <DonationPayDetailSkeleton />
          ) : (
            <>
              <h1 className="text-4xl font-normal tracking-tighter color-foreground">
                {donation.name}: <span className="font-light">{PAGE_TITLE}</span>
              </h1>
              <div className="mt-12">
                <DonationPayForm
                  donation={donation}
                  form={form}
                  accountingForm={accountingForm}
                  cancelLabel="Cerrar"
                  submitLabel="Confirmar"
                  dismissHandler={handleDismiss}
                  submitHandler={() => {}}
                  disabled
                />
              </div>
            </>
          )}
        </div>
      </SheetLayout>
    </>
  );
};

export default DonationPayDetail;
