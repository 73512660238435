import { MutationFunction, keepPreviousData, useQuery } from '@tanstack/react-query';
import { QueryRequestParams, ResponsePaginated } from '../types';
import {
  DEFAULT_PAGINATION_DIR,
  DEFAULT_PAGINATION_PAGE_INDEX,
  DEFAULT_PAGINATION_PAGE_SIZE,
  DEFAULT_PAGINATION_SORT,
} from '@/common/constants';
import { Donor, DonorApi, DonorList } from './donor.model';
import { DonorFormType } from '@/pages/Donors/utils/DonorSchema';
import { CRUDService } from '../CRUDService.api';
import httpClient from '../httpClient';
import * as Sentry from '@sentry/react';
import { UUID } from 'crypto';

const DONORS_BASE_PATH = '/donor';
const DONORS_KEY = 'donors';
const DONORS_STALE_TIME = 5000;

const transformDonorsList = (response: Array<DonorApi>): Array<DonorList> => {
  return response.map((donor) => {
    return {
      id: donor.id,
      name: donor.name,
      value: donor.name,
    };
  });
};

class DonorService extends CRUDService<Donor, DonorFormType> {
  constructor() {
    super(DONORS_BASE_PATH);
  }

  async getList() {
    try {
      const response = await httpClient.get(`${DONORS_BASE_PATH}/list`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }
}

const donorService = new DonorService();

const useDonors = ({
  page = DEFAULT_PAGINATION_PAGE_INDEX,
  size = DEFAULT_PAGINATION_PAGE_SIZE,
  sort = DEFAULT_PAGINATION_SORT,
  dir = DEFAULT_PAGINATION_DIR,
}: QueryRequestParams<DonorApi> = {}) => {
  const queryParams = `page=${page}&size=${size}&sort=${sort}&dir=${dir}`;

  return useQuery<
    [ResponsePaginated<DonorApi>, { totalDonations: number }],
    Error,
    [ResponsePaginated<DonorApi>, { totalDonations: number }]
  >({
    queryKey: [DONORS_KEY, { page, size }],
    queryFn: async () => {
      const response = (await donorService.getAll(queryParams)) as {
        page: ResponsePaginated<DonorApi>;
        data: { totalDonations: number };
      };
      return [response.page, response.data];
    },
    placeholderData: keepPreviousData,
    staleTime: DONORS_STALE_TIME,
  });
};

const useDonor = (id: UUID) => {
  return useQuery<DonorApi, Error, DonorApi>({
    queryKey: [`${DONORS_KEY}-edit`, { id }],
    queryFn: async () => {
      const response = await donorService.get(id);
      return response as DonorApi;
    },
    placeholderData: keepPreviousData,
    staleTime: DONORS_STALE_TIME,
    retry: 1,
  });
};

const useDonorsList = () => {
  return useQuery<Array<DonorApi>, Error, Array<DonorList>>({
    queryKey: [DONORS_KEY],
    queryFn: async () => {
      const response = await donorService.getList();
      return response as DonorApi[];
    },
    select: transformDonorsList,
    placeholderData: keepPreviousData,
    staleTime: DONORS_STALE_TIME,
  });
};

const createDonorMutationFn = async (data: DonorFormType) => {
  return await donorService.create(data);
};

const editDonorMutationFn: MutationFunction<DonorApi, [DonorApi['id'], DonorFormType]> = async (
  params,
) => {
  const [id, data] = params;
  return (await donorService.edit(id, data)) as DonorApi;
};

const deleteDonorMutationFn = async (id: DonorApi['id']) => {
  return await donorService.delete(id);
};

export {
  DONORS_KEY,
  useDonors,
  useDonor,
  useDonorsList,
  createDonorMutationFn,
  editDonorMutationFn,
  deleteDonorMutationFn,
};
