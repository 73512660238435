import { EmployeeFund } from '@/api/employeeFund/employeeFund.model';
import { formatAmount } from '@/lib/utils';
import { ColumnDef } from '@tanstack/react-table';

const ExpenseReportFundTableColumns: ColumnDef<EmployeeFund>[] = [
  {
    accessorKey: 'createdAt',
    header: () => <p className="text-xs">Fecha</p>,
    cell: ({ row }) => {
      const createdAt = row.getValue('createdAt') as EmployeeFund['createdAt'];
      return createdAt ? new Date(createdAt).toISOString().split('T')[0] : 'N/A';
    },
  },
  {
    accessorKey: 'costCenter',
    header: () => <p className="text-xs">Centro de Costo</p>,
    cell: ({ row }) => {
      const costCenter = row.getValue('costCenter') as string;
      return costCenter || 'N/A';
    },
  },
  {
    accessorKey: 'description',
    header: () => <p className="text-xs">Descripción</p>,
    cell: ({ row }) => {
      const description = row.getValue('description') as EmployeeFund['description'];
      return <div className="truncate">{description || 'N/A'}</div>;
    },
  },
  {
    accessorKey: 'balance',
    header: () => <p className="text-xs">Monto</p>,
    cell: ({ row }) => {
      const amount = row.getValue('balance') as number;
      const money = row.getValue('money') as EmployeeFund['money'];
      return amount ? formatAmount(amount, money?.currency) : 'N/A';
    },
  },
];

export { ExpenseReportFundTableColumns };
