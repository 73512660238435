import { DonationApi } from '@/api/donation/donation.model';
import { FC, useEffect } from 'react';
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { DonationPayFormType } from '../utils/DonationPaySchema';
import { DonationSummaryColumn } from './DonationSummary';
import { Separator } from '@/components/ui/separator';
import { cn, formatAmount } from '@/lib/utils';
import { toast } from 'sonner';
import {
  ACCOUNTING_MAX_VALUE_ERROR_MESSAGE,
  ACCOUNTING_SUM_ERROR_MESSAGE,
  SONNER_ERROR_OPTIONS,
} from '@/common/constants';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { CalendarIcon, PlusCircle } from 'lucide-react';
import { Calendar } from '@/components/ui/calendar';
import AccountingEntryItem from '@/pages/AccountsMayor/AccountingEntry/AccountingEntryItem/AccountingEntryItem';
import { es } from 'date-fns/locale';
import { format } from 'date-fns';
import { AccountingTab } from '@/pages/Invoices/components/AccountingTab';
import { InvoiceFormType } from '@/pages/Invoices/utils/InvoiceSchema';
import { ValidateAlertMessage } from '@/components/shared/ValidateAlertMessage';

interface DonationPayFormProps {
  donation: DonationApi;
  form: UseFormReturn<DonationPayFormType, unknown>;
  accountingForm?: UseFormReturn<InvoiceFormType, unknown>;
  submitLabel: string;
  cancelLabel: string;
  isLoading?: boolean;
  submitHandler: SubmitHandler<DonationPayFormType>;
  dismissHandler: () => void;
  disabled?: boolean;
}

const DonationPayForm: FC<DonationPayFormProps> = ({
  donation,
  form,
  submitLabel,
  cancelLabel,
  isLoading,
  submitHandler,
  dismissHandler,
  disabled,
  accountingForm,
}) => {
  const balance = form
    .watch('accountingEntries')
    .reduce((acc, entry) => acc + entry.amount * entry.exchangeRateOfficial, 0);

  const totalAmount = form.watch('accountingEntries').reduce((acc, entry) => {
    let amount = entry.amount;
    if (entry.currency !== 'USD') {
      amount = entry.amount * entry.exchangeRateOfficial;
    }
    if (amount > 0) {
      return acc + amount;
    }
    return acc;
  }, 0);

  const donationDetailGlobal = donation.donationDetails?.find(
    (detail) => detail.costCenter.global === true,
  );

  useEffect(() => {
    const donationDetails = donation.donationDetails;
    const transactionId = donationDetails && donationDetails[donationDetails.length - 1].id;
    if (transactionId) {
      form.setValue('transactionId', transactionId);
    }
    if (donation.id) {
      form.setValue('donationId', donation.id);
      form.setValue('donationTotalAmount', donation.money?.amount);
    }
  }, []);

  const appendNewAccountingEntry = () => {
    const accountingEntries = form.getValues('accountingEntries');
    const newEmptyAccountingEntry = {
      ledgerAccount: { id: '', name: '' },
      currency: 'ARS',
      exchangeRateOfficial: 0,
      exchangeRateAlt: 0,
      amount: 0,
      description: '',
    };
    const newAccountingEntries = [...accountingEntries, newEmptyAccountingEntry];
    form.setValue('accountingEntries', newAccountingEntries);
  };

  const deleteAccountingEntry = (index: number) => {
    const accountingEntries = form.getValues('accountingEntries');
    const updatedAccountingEntries = [...accountingEntries];
    if (updatedAccountingEntries.length === 2) {
      toast.error('Error al eliminar la partida contable.', {
        description:
          'No se pueden eliminar todas las partidas contables, debe haber al menos dos para poder continuar.',
        ...SONNER_ERROR_OPTIONS,
        duration: 3000,
      });
      return;
    }
    updatedAccountingEntries.splice(index, 1);
    form.setValue('accountingEntries', updatedAccountingEntries);
  };
  return (
    <>
      <div className="flex flex-col gap-4 p-6 my-4 rounded bg-gray-50">
        <h2 className="mb-2 text-lg font-medium text-foreground">Datos Administrativos</h2>
        <div className="flex justify-between gap-4">
          <DonationSummaryColumn title="Nombre" value={donation.name} />
          <DonationSummaryColumn title="Donante" value={donation.donor.name} />
          <DonationSummaryColumn title="Pat. Fiscal" value={donation.fiscalSponsor.name} />
          <DonationSummaryColumn title="Fecha Ingreso" value={donation.entryDate} />
          <DonationSummaryColumn title="Fecha Reporte" value={donation.reportDate} />
        </div>
      </div>
      <Separator className="my-8" />
      <div className="flex flex-col gap-4 p-6 my-4 rounded bg-gray-50">
        <h2 className="mb-2 text-lg font-medium text-foreground">Donación</h2>
        <div className="flex gap-12">
          <DonationSummaryColumn
            title="Total Donación"
            value={formatAmount(donation.money?.amount || 0, donation.money?.currency)}
          />
          <DonationSummaryColumn
            title="Gastos Globales"
            value={formatAmount(donationDetailGlobal?.money?.amount || 0, donation.money?.currency)}
          />
        </div>
      </div>
      <Separator className="my-8" />
      {disabled ? (
        <div>
          <div className="flex flex-col gap-4 p-6 my-4 rounded bg-gray-50">
            <h2 className="mb-2 text-lg font-medium text-foreground">Contabilidad</h2>
            <div className="flex gap-12">
              <DonationSummaryColumn
                title="Fecha de Cobro"
                value={donation?.accountingEntries ? donation.accountingEntries[0].paymentDate : ''}
              />
            </div>
          </div>
          <Separator className="my-8" />

          <h2 className="mb-2 text-lg font-medium text-foreground">Asientos Contables</h2>
          {accountingForm ? (
            <FormProvider {...accountingForm}>
              <AccountingTab
                form={accountingForm}
                ledgerAccounts={[]}
                isLoadingLedgerAccounts={true}
                disabled
              />
            </FormProvider>
          ) : null}
          <div className="flex flex-row justify-end gap-2 mt-6">
            <Button type="button" variant="default" disabled={isLoading} onClick={dismissHandler}>
              {cancelLabel}
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <h2 className="mb-8 text-lg font-medium text-foreground">Contabilidad</h2>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(submitHandler)} className="flex flex-col">
              <div className="flex flex-col gap-6">
                <div className="flex flex-row gap-4">
                  <FormField
                    control={form.control}
                    name="paymentDate"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>Fecha de Cobro</FormLabel>
                        <Popover>
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                variant={'outline'}
                                className="w-1/4 pl-3 hover:bg-transparent"
                                childrenClassName={cn(
                                  'justify-between w-full text-left text-foreground font-normal',
                                  !field.value
                                    ? 'text-muted-foreground hover:text-muted-foreground'
                                    : null,
                                )}
                              >
                                {field.value ? (
                                  format(field.value, 'dd/MM/yyyy')
                                ) : (
                                  <span className="font-light text-muted-foreground">
                                    Selecciona una fecha
                                  </span>
                                )}
                                <CalendarIcon className="w-4 h-4 ml-auto opacity-50" />
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent className="w-auto p-0" align="center">
                            <Calendar
                              mode="single"
                              showOutsideDays
                              fixedWeeks
                              captionLayout="dropdown"
                              fromYear={new Date().getFullYear() - 5}
                              toYear={new Date().getFullYear() + 10}
                              selected={field.value}
                              onSelect={field.onChange}
                              locale={es}
                            />
                          </PopoverContent>
                        </Popover>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
              {form.watch('accountingEntries').length > 0 && (
                <p className="mt-8 text-sm font-medium">Asientos contables</p>
              )}
              {form.watch('accountingEntries').map((_entry, index) => (
                <AccountingEntryItem
                  form={form}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`accountingEntries-${index}`}
                  index={index}
                  onDelete={deleteAccountingEntry}
                />
              ))}
              <Button
                type="button"
                className="mt-6 text-xs w-fit"
                onClick={appendNewAccountingEntry}
              >
                <PlusCircle className="w-4 h-4 mr-2" />
                Agregar Asiento Contable
              </Button>
              <div className="flex flex-col items-end gap-2 w-full mt-6 mb-6">
                {balance !== 0 && <ValidateAlertMessage message={ACCOUNTING_SUM_ERROR_MESSAGE} />}
                {donation.money?.amount && totalAmount !== donation.money?.amount && (
                  <ValidateAlertMessage message={ACCOUNTING_MAX_VALUE_ERROR_MESSAGE} />
                )}
              </div>
              <div className="flex flex-row justify-end gap-2 mt-6">
                <Button
                  type="button"
                  variant="outline"
                  disabled={isLoading}
                  onClick={dismissHandler}
                >
                  {cancelLabel}
                </Button>
                <Button
                  type="submit"
                  disabled={isLoading || !form.formState.isValid}
                  className="relative"
                  isLoading={isLoading}
                >
                  {submitLabel}
                </Button>
              </div>
            </form>
          </FormProvider>
        </div>
      )}
    </>
  );
};

export default DonationPayForm;
