import { Navigate, useNavigate } from 'react-router-dom';
import { paths } from './paths';
import { FC, ReactElement, Suspense, useEffect } from 'react';
import Layout from '@/components/layout/Layout';
import useUserStore from '@/store/useUserStore';
import Sidebar from '@/components/layout/Sidebar';
import ResponsiveLayout from '@/components/layout/ResponsiveLayout';
import httpClient from '@/api/httpClient';

const ProtectedRoute: FC<{ children: ReactElement; useLayout?: boolean }> = ({
  children,
  useLayout = true,
}) => {
  const { token } = useUserStore();
  const navigate = useNavigate();

  useEffect(() => {
    httpClient.setupResponseInterceptor(navigate);
  }, []);

  if (token) {
    if (useLayout) {
      return (
        <ResponsiveLayout>
          <div className="flex min-h-screen max-w-[1920px] mx-auto">
            <Sidebar />
            <Suspense fallback={<Layout isLoading />}>
              <Layout>{children}</Layout>
            </Suspense>
          </div>
        </ResponsiveLayout>
      );
    }
    return children;
  }

  return <Navigate to={paths.LOGIN} replace />;
};

export default ProtectedRoute;
