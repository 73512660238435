import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { CRUDService } from '../CRUDService.api';
import httpClient from '../httpClient';
import {
  AccountingEntry,
  AccountingEntryDetail,
  AccountingEntryGroup,
  AccountingEntryPayload,
  AccountingEntrySummary,
} from './accountingEntry.model';
import * as Sentry from '@sentry/react';
import {
  AccountingEntryByLAQueryParams,
  AccountingEntryQueryParams,
  QueryRequestParams,
  ResponsePaginated,
} from '../types';
import { LedgerAccountApi } from '../ledgerAccount/ledgerAccount.model';
import {
  DEFAULT_PAGINATION_PAGE_INDEX,
  DEFAULT_PAGINATION_PAGE_SIZE,
  DEFAULT_PAGINATION_SORT,
  DEFAULT_PAGINATION_DIR,
  DEFAULT_PAGINATION_PAGE_SEARCH,
} from '@/common/constants';
import { AccountingEntryFormType } from '@/pages/AccountsMayor/AccountingEntry/AccountingEntrySchema';
import { UUID } from 'crypto';
import { getCurrentDate } from '@/lib/utils';

const ACCOUNTING_ENTRY_BASE_PATH = '/accountingEntry';
const ACCOUNTING_ENTRY_KEY = 'accountingEntry';
const ACCOUNTING_ENTRY_STALE_TIME = 5000;

const transformAccountingEntriesPayload = (
  data: AccountingEntryFormType,
): Array<AccountingEntryPayload> => {
  const accountingEntries: Array<AccountingEntryPayload> = [];
  data.accountingEntries.forEach((accountingEntry) => {
    accountingEntries.push({
      ledgerAccount: { id: accountingEntry.ledgerAccount.id as LedgerAccountApi['id'] },
      paymentDate: data.paymentDate.toISOString().split('T')[0],
      amount:
        accountingEntry.currency === 'ARS'
          ? Math.abs(accountingEntry.amount)
          : Math.abs(accountingEntry.amount) * accountingEntry.exchangeRateOfficial,
      credit: accountingEntry.amount < 0 ? true : false,
      details: accountingEntry.details,
      moneyOfficial:
        accountingEntry.currency !== 'ARS'
          ? {
              currency: accountingEntry.currency,
              amount: Math.abs(accountingEntry.amount),
              exchangeRate: accountingEntry.exchangeRateOfficial,
            }
          : undefined,
      moneyAlt:
        accountingEntry.currency !== 'ARS'
          ? {
              currency: accountingEntry.currency,
              amount: Math.abs(accountingEntry.amount),
              exchangeRate: accountingEntry.exchangeRateAlt ? accountingEntry.exchangeRateAlt : 0,
            }
          : undefined,
      transaction: data.transactionId
        ? { id: data.transactionId as UUID, type: 'DonationDetail' }
        : undefined,
      donation: data.donationId ? { id: data.donationId as UUID } : undefined,
    });
  });
  return accountingEntries;
};
class AccountingEntryService extends CRUDService<AccountingEntry, AccountingEntryFormType> {
  constructor() {
    super(ACCOUNTING_ENTRY_BASE_PATH);
  }

  async getList() {
    try {
      const response = await httpClient.get(`${ACCOUNTING_ENTRY_BASE_PATH}/list`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async getByLedgerAccount(ledgerAccountId: LedgerAccountApi['id'], queryParams?: string) {
    try {
      const response = await httpClient.get(
        queryParams
          ? `${ACCOUNTING_ENTRY_BASE_PATH}/ledgerAccount/${ledgerAccountId}?${queryParams}`
          : `${ACCOUNTING_ENTRY_BASE_PATH}/ledgerAccount/${ledgerAccountId}`,
      );
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async getByGroup(groupId: AccountingEntryGroup['id'], queryParams?: string) {
    try {
      const response = await httpClient.get(
        queryParams
          ? `${ACCOUNTING_ENTRY_BASE_PATH}/group/${groupId}?${queryParams}`
          : `${ACCOUNTING_ENTRY_BASE_PATH}/group/${groupId}`,
      );
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async postList(data: Array<AccountingEntryPayload>) {
    try {
      const response = await httpClient.post(`${ACCOUNTING_ENTRY_BASE_PATH}/list`, { data });
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }
}

const accountingEntryService = new AccountingEntryService();

const useAccountingEntries = ({
  page = DEFAULT_PAGINATION_PAGE_INDEX,
  size = DEFAULT_PAGINATION_PAGE_SIZE,
  sort = DEFAULT_PAGINATION_SORT,
  dir = DEFAULT_PAGINATION_DIR,
  search = DEFAULT_PAGINATION_PAGE_SEARCH,
  date = '',
}: AccountingEntryQueryParams<AccountingEntry> = {}) => {
  const dateParam = date ? `&date=${date}` : '';

  const queryParams = `page=${page}&size=${size}&sort=${sort}&dir=${dir}&query=${search}${dateParam}`;

  return useQuery<ResponsePaginated<AccountingEntrySummary>, Error>({
    queryKey: [ACCOUNTING_ENTRY_KEY, { page, size, search, date }],
    queryFn: async () => {
      const response = await accountingEntryService.getAll(queryParams);
      return response as ResponsePaginated<AccountingEntrySummary>;
    },
    placeholderData: keepPreviousData,
    staleTime: ACCOUNTING_ENTRY_STALE_TIME,
  });
};

const useAccountingEntriesByLedgerAccount = ({
  page = DEFAULT_PAGINATION_PAGE_INDEX,
  size = DEFAULT_PAGINATION_PAGE_SIZE,
  sort = DEFAULT_PAGINATION_SORT,
  dir = DEFAULT_PAGINATION_DIR,
  ledgerAccountId,
  startDate = '',
  endDate = '',
}: AccountingEntryByLAQueryParams<LedgerAccountApi> & {
  ledgerAccountId: LedgerAccountApi['id'];
}) => {
  const dateParam = startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : '';

  const queryParams = `page=${page}&size=${size}&sort=${sort}&dir=${dir}${dateParam}`;

  return useQuery<ResponsePaginated<AccountingEntryDetail>, Error>({
    queryKey: [ACCOUNTING_ENTRY_KEY, { page, size, ledgerAccountId, startDate, endDate }],
    queryFn: async () => {
      const response = await accountingEntryService.getByLedgerAccount(
        ledgerAccountId,
        queryParams,
      );
      return response as ResponsePaginated<AccountingEntryDetail>;
    },
    placeholderData: keepPreviousData,
    staleTime: ACCOUNTING_ENTRY_STALE_TIME,
    enabled: !!ledgerAccountId,
  });
};

const useAccountingEntriesByGroup = ({
  page = DEFAULT_PAGINATION_PAGE_INDEX,
  size = DEFAULT_PAGINATION_PAGE_SIZE,
  sort = DEFAULT_PAGINATION_SORT,
  dir = DEFAULT_PAGINATION_DIR,
  groupId,
}: QueryRequestParams<AccountingEntryGroup> & { groupId: AccountingEntryGroup['id'] }) => {
  const queryParams = `page=${page}&size=${size}&sort=${sort}&dir=${dir}`;

  return useQuery<ResponsePaginated<AccountingEntryDetail>, Error>({
    queryKey: [ACCOUNTING_ENTRY_KEY, { page, size, groupId }],
    queryFn: async () => {
      const response = await accountingEntryService.getByGroup(groupId, queryParams);
      return response as ResponsePaginated<AccountingEntryDetail>;
    },
    placeholderData: keepPreviousData,
    staleTime: ACCOUNTING_ENTRY_STALE_TIME,
    enabled: !!groupId,
  });
};

const useGetAccountingReport = (params?: URLSearchParams) => {
  const queryParams = params?.get('date') ? `date=${params.get('date')}` : '';

  return async () => {
    const response = await httpClient.get(`accountingEntry/all/xlsx${queryParams}`, {
      responseType: 'blob',
      timeout: 600000,
    });

    const blob = new Blob([response.data as BlobPart], {
      type: response.headers['content-type'],
    });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', `MayorDeCuentas-PEM_${getCurrentDate()}.xlsx`);
    link.click();
  };
};

const useGetAccountingReportByLedgerAccount = (id: string, params?: URLSearchParams) => {
  const startDate = params?.get('startDate') ? `&startDate=${params.get('startDate')}` : '';
  const endDate = params?.get('endDate') ? `&endDate=${params.get('endDate')}` : '';

  const queryParams = `?${startDate}${endDate}`;

  return async () => {
    const response = await httpClient.get(
      `accountingEntry/ledgerAccount/${id}/xlsx${queryParams}`,
      {
        responseType: 'blob',
        timeout: 600000,
      },
    );

    const blob = new Blob([response.data as BlobPart], {
      type: response.headers['content-type'],
    });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', `-PEM_${getCurrentDate()}.xlsx`);
    link.click();
  };
};

const createAccountingEntryMutationFn = async (data: AccountingEntryFormType) => {
  return await accountingEntryService.create(data);
};

const createAccountingEntriesMutationFn = async (data: AccountingEntryFormType) => {
  const payload = transformAccountingEntriesPayload(data);
  return await accountingEntryService.postList(payload);
};

export {
  useAccountingEntries,
  useAccountingEntriesByLedgerAccount,
  useAccountingEntriesByGroup,
  useGetAccountingReport,
  createAccountingEntryMutationFn,
  createAccountingEntriesMutationFn,
  useGetAccountingReportByLedgerAccount,
};
