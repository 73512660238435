import React, { useState, useEffect, useMemo } from 'react';
import { Input, InputProps } from './input'; // Adjust the import path as necessary
import { CurrencyOption } from '@/common/types';
import { cn, getCurrencyOptionByValue } from '@/lib/utils';

interface CurrencyInputProps extends Omit<InputProps, 'onChange' | 'value'> {
  value?: number;
  allowNegative?: boolean;
  currency?: CurrencyOption['value'];
  defaultValue?: number;
  onChange: (value?: number) => void;
  autoEntryCreator?: (value?: number) => void;
}

const CurrencyInput = React.forwardRef<HTMLInputElement, CurrencyInputProps>(
  (
    {
      value,
      allowNegative = false,
      currency = 'USD',
      defaultValue,
      onChange,
      autoEntryCreator,
      ...props
    },
    ref,
  ) => {
    // Local state to handle raw and formatted input value
    const [inputValue, setInputValue] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [useDefaultValue, setUseDefaultValue] = useState(true);

    useEffect(() => {
      if (defaultValue && !isFocused && useDefaultValue) {
        // Format value when not focused
        setInputValue(formatNumber(defaultValue));
        onChange(defaultValue);
      }
    }, [defaultValue, isFocused]);

    useEffect(() => {
      // Format value when not focused
      setInputValue(isFocused ? value?.toString().replace('.', ',') : formatNumber(value));
    }, [value, isFocused]);

    // Function to format numbers with thousand separators
    const formatNumber = (num: any) => {
      if (num === null || num === undefined) return '';
      const parts = num.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      return parts.join(',');
    };

    const handleFocus = () => {
      setIsFocused(true);
      if (defaultValue) {
        setUseDefaultValue(false);
        setInputValue('0');
        onChange(0);
      } else {
        setInputValue(value?.toString().replace('.', ',') || '');
      }
    };

    const handleBlur = () => {
      setIsFocused(false);
      setInputValue(formatNumber(value));
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let { value } = event.target;
      setInputValue(value); // Update with raw input during typing

      if (value === '') {
        onChange(0);
        return;
      }

      value = value.replace(',', '.'); // Replace comma with dot for decimal parsing

      const numericValue = parseFloat(value.replace(/[^\d.-]/g, ''));
      if (!isNaN(numericValue)) {
        if (numericValue < 0 && !allowNegative) {
          return;
        }
        onChange(numericValue);
        if (autoEntryCreator) {
          autoEntryCreator(numericValue);
        }
      }
    };

    const currencyOption = useMemo(() => getCurrencyOptionByValue(currency), [currency]);

    return (
      <div className="relative flex items-center">
        <span className="absolute ml-3 text-sm">{currencyOption?.symbol}</span>
        <Input
          {...props}
          ref={ref}
          type="text"
          value={inputValue}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={(event) => {
            if (
              (!/[0-9.,-]/.test(event.key) &&
                !event.ctrlKey &&
                !event.metaKey &&
                event.key !== 'Backspace' &&
                event.key !== 'Delete' &&
                event.key !== 'ArrowLeft' &&
                event.key !== 'ArrowRight' &&
                event.key !== 'Enter' &&
                event.key !== 'Tab') ||
              (event.key === '-' && !allowNegative)
            ) {
              event.preventDefault();
            }
          }}
          className={cn('pl-12 pr-3 py-2')}
        />
      </div>
    );
  },
);

CurrencyInput.displayName = 'CurrencyInput';

export { CurrencyInput };
