import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ProjectFormType, ProjectSchema, defaultProjectFormValues } from './utils/ProjectSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import DialogWrapper from '@/components/shared/DialogWrapper';
import { useMutation } from '@tanstack/react-query';
import { createProjectMutationFn, useProjects } from '@/api/project/project.api';
import { AxiosError } from 'axios';
import { toast } from 'sonner';
import { paths } from '@/routes/paths';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { SONNER_SUCCESS_OPTIONS, SONNER_ERROR_OPTIONS } from '@/common/constants';
import { ProjectsForm } from './components/ProjectsForm';
import * as Sentry from '@sentry/react';
import { ProjectApi } from '@/api/project/project.model';
import { ErrorResponse } from '@/api/types';

const ProjectsCreate: FC = () => {
  const navigate = useNavigate();

  const form = useForm<ProjectFormType>({
    defaultValues: defaultProjectFormValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(ProjectSchema),
  });

  const { refetch } = useProjects();

  const { mutate: createProject, isPending } = useMutation({
    mutationFn: createProjectMutationFn,
    onSuccess: (data: ProjectApi) => {
      refetch();
      toast(
        <SuccessToastText>
          ¡Proyecto <span className="text-blue-700">{data.code}</span> fue creado exitosamente!
        </SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismiss();
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      Sentry.captureException(error);

      if (error.response && error.response.data.type === 'DUPLICATED_ERROR') {
        form.setError('code', {
          type: 'validate',
          message:
            'El código ingresado ya fue utilizado previamente. Intentá nuevamente con un código nuevo.',
        });
      }
      toast.error('Ha ocurrido un error durante la creación del nuevo Proyecto.', {
        description: 'Por favor, revisa los datos ingresados e intenta de nuevo.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  const handleCreateProject: SubmitHandler<ProjectFormType> = (formData) => createProject(formData);

  const handleDismiss = () => navigate(paths.PROJECTS);

  return (
    <DialogWrapper
      title="Crear Proyecto"
      className="w-[425px]"
      onDismiss={handleDismiss}
      onOverlayClick={handleDismiss}
    >
      <ProjectsForm
        form={form}
        submitLabel="Crear Proyecto"
        cancelLabel="Cancelar"
        isLoading={isPending}
        submitHandler={handleCreateProject}
        dismissHandler={handleDismiss}
      />
    </DialogWrapper>
  );
};

export default ProjectsCreate;
