import useUserStore from '@/store/useUserStore';
import axios, {
  AxiosInstance,
  AxiosResponse,
  Method,
  InternalAxiosRequestConfig,
  AxiosPromise,
  AxiosError,
  AxiosRequestHeaders,
} from 'axios';
import { Request } from './types';
import { NavigateFunction } from 'react-router-dom';

export class HttpClient {
  private httpClient: AxiosInstance;
  private isResponseInterceptorSetup: boolean = false;

  constructor() {
    this.httpClient = axios.create({
      baseURL: import.meta.env.VITE_API_URL,
      timeout: 60000,
      headers: {},
    });

    this.httpClient.interceptors.request.use(this.handleRequestUse.bind(this));
    this.httpClient.interceptors.response.use(this.handleResponseUse.bind(this));
  }

  private handleRequestUse(config: InternalAxiosRequestConfig) {
    const token = useUserStore.getState().token;
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      } as AxiosRequestHeaders;
    }
    return config;
  }

  private handleResponseUse(response: AxiosResponse) {
    // handle response interceptor logic here
    return response;
  }

  private async handleRequestWithoutAuth<T>(
    url: string,
    method: Method,
    config: Request = {},
  ): Promise<AxiosResponse<T>> {
    const { headers = { 'Content-Type': 'application/json' }, data, timeout } = config;
    return this.httpClient.request({
      url,
      method,
      data,
      headers,
      timeout,
    });
  }

  private async handleRequest<T>(
    url: string,
    method: Method,
    config: Request = {},
  ): Promise<AxiosResponse<T>> {
    const { data, params, responseType, timeout } = config;
    const headers = {
      'Content-Type': 'application/json',
      ...config.headers,
    };
    return this.httpClient.request({
      url,
      method,
      data,
      params,
      headers,
      responseType,
      timeout,
    });
  }

  public setupResponseInterceptor(navigate: NavigateFunction) {
    if (this.isResponseInterceptorSetup) {
      return;
    }

    this.httpClient.interceptors.response.use(
      (response: AxiosResponse) => {
        return this.handleResponseUse(response);
      },
      (error: AxiosError<any>) => {
        if (error.request?.status === 403 || error.response?.status === 403) {
          useUserStore.getState().setToken('');
          navigate('/login');
        } else {
          return Promise.reject(error);
        }
      },
    );

    this.isResponseInterceptorSetup = true;
  }

  public get<T>(url: string, config: Request = {}): AxiosPromise<T> {
    return this.handleRequest(url, 'get', config);
  }

  public post<T>(url: string, config: Request = {}): AxiosPromise<T> {
    return this.handleRequest(url, 'post', config);
  }

  public put<T>(url: string, config: Request = {}): AxiosPromise<T> {
    return this.handleRequest(url, 'put', config);
  }

  public delete<T>(url: string, config: Request = {}): AxiosPromise<T> {
    return this.handleRequest(url, 'delete', config);
  }

  public patch<T>(url: string, config: Request = {}): AxiosPromise<T> {
    return this.handleRequest(url, 'patch', config);
  }

  public login<T>(config: Request = {}): AxiosPromise<T> {
    return this.handleRequestWithoutAuth('/auth/login', 'post', config);
  }

  public setAuthToken(token: string) {
    useUserStore.getState().setToken(token); // Use Zustand store to set token
  }
}

export default new HttpClient();
