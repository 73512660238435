import TableSkeleton from '@/components/shared/TableSkeleton';
import { Separator } from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';

const InvoiceFormSkeleton = () => (
  <div className="w-full max-w-screen-2xl mx-auto px-14">
    <div className="flex flex-col">
      <div className="flex justify-between">
        <Skeleton className="w-96 h-10" />
        <div className="flex items-center gap-6">
          <Skeleton className="w-32 h-10" />
          <Skeleton className="w-32 h-10" />
        </div>
      </div>
      <Separator className="mt-8" />
    </div>
    <div className={'flex flex-col mt-16 mb-[60px]'}>
      <div className="flex flex-col gap-[50px]">
        <div className="flex gap-20">
          <div className="flex flex-col w-full gap-10">
            <div className="flex flex-row gap-2">
              <div className="flex flex-row gap-6 w-full border-r-slate-200  border-r-[1px] pr-6">
                <Skeleton className="w-1/2 h-10" />
                <Skeleton className="w-full h-10" />
              </div>
              <div className="flex flex-row gap-6 w-full pl-4">
                <Skeleton className="w-full h-10" />
                <Skeleton className="w-full h-10" />
                <Skeleton className="w-full h-10" />
              </div>
            </div>
            <div className="flex flex-row">
              <Skeleton className="w-[245px] h-10" />
            </div>
            <Skeleton className="w-full h-20" />
          </div>
          <Separator orientation="vertical" className="h-auto" />
          <div className="flex flex-col w-1/4 gap-6">
            <Skeleton className="w-full h-10" />
            <Skeleton className="w-full h-10" />
            <Skeleton className="w-full h-10" />
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <Skeleton className="w-[788px] h-10" />
          <TableSkeleton rows={2} />
        </div>
      </div>
    </div>
  </div>
);

export { InvoiceFormSkeleton };
