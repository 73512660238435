import { FC } from 'react';
import { DonationFormType } from '../utils/DonationSchema';
import { Separator } from '@/components/ui/separator';
import { cn, formatAmount } from '@/lib/utils';
import { Button } from '@/components/ui/button';

interface DonationSummaryProps {
  donation: DonationFormType;
  cancelLabel: string;
  submitLabel: string;
  isLoading?: boolean;
  onDismiss: () => void;
  onSubmit: () => void;
}

const DonationSummary: FC<DonationSummaryProps> = ({
  donation,
  cancelLabel,
  submitLabel,
  isLoading = false,
  onDismiss,
  onSubmit,
}) => {
  return (
    <div className="flex flex-col gap-6">
      <div>
        <h2 className="mb-2 text-lg font-medium text-foreground">Datos Administrativos</h2>
        <div className="flex justify-between gap-4">
          <DonationSummaryColumn title="Nombre" value={donation.name} />
          <DonationSummaryColumn title="Donante" value={donation.donor.name} />
          <DonationSummaryColumn title="Pat. Fiscal" value={donation.fiscalSponsor.name} />
          <DonationSummaryColumn
            title="Fecha Ingreso"
            value={donation.entryDate.toLocaleString('es-AR').split(', ')[0]}
          />
          <DonationSummaryColumn
            title="Fecha Reporte"
            value={donation.reportDate.toLocaleString('es-AR').split(', ')[0]}
          />
        </div>
        <Separator className="my-6" />
        <h2 className="my-2 text-lg font-medium text-foreground">Donación</h2>
        <div className="flex gap-12">
          <DonationSummaryColumn
            title="Total Donación"
            value={formatAmount(donation.amount, donation.currency)}
          />
          <DonationSummaryColumn
            title="Gastos Globales"
            value={formatAmount(donation.globalCostCenter.amount, donation.currency)}
          />
        </div>
        <Separator className="my-6" />
        <h2 className=" text-lg font-medium text-foreground">Centros de Costos</h2>
        <div className="w-fit max-h-52 overflow-y-scroll pr-3">
          {donation.costCenters.map((costCenter) => (
            <DonationSummaryCostCenter
              key={costCenter.id}
              name={costCenter.name}
              amount={costCenter.categories.reduce((acc, category) => acc + category.amount, 0)}
              currency={donation.currency}
            />
          ))}
        </div>
      </div>
      <div className="flex flex-row justify-end gap-2">
        <Button type="button" variant="outline" onClick={onDismiss}>
          {cancelLabel}
        </Button>
        <Button className="relative" isLoading={isLoading} disabled={isLoading} onClick={onSubmit}>
          {submitLabel}
        </Button>
      </div>
    </div>
  );
};

interface DonationSummaryColumnProps {
  title: string;
  value: string;
  className?: string;
}

export const DonationSummaryColumn: FC<DonationSummaryColumnProps> = ({
  title,
  value,
  className,
}) => {
  return (
    <div className={cn('flex flex-col gap-2', className)}>
      <span className="font-light text-muted-foreground">{title}</span>
      <span className="truncate max-w-48">{value}</span>
    </div>
  );
};

const DonationSummaryCostCenter: FC<{ name: string; amount: number; currency: string }> = ({
  name,
  amount,
  currency,
}) => {
  return (
    <div className="flex justify-between gap-12 w-fit p-4 my-2 rounded bg-gray-50">
      <DonationSummaryColumn title="Nombre" value={name} className="min-w-48" />
      <DonationSummaryColumn title="Monto Asignado" value={formatAmount(amount, currency)} />
    </div>
  );
};

export default DonationSummary;
