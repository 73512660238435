import { LedgerAccountList } from '@/api/ledgerAccount/ledgerAccount.model';
import { Button } from '@/components/ui/button';
import { Table, TableBody, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { PlusCircle } from 'lucide-react';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { InvoiceFormType } from '../utils/InvoiceSchema';
import { defaultExpenseFormValues } from '../../Expenses/utils/ExpenseSchema';
import { ExpenseRow } from '../../Expenses/components/ExpenseRow';
import { SupplierList } from '@/api/supplier/supplier.model';
import { useDonationsList } from '@/api/donation/donation.api';
import { useGetGlobalCostCenter } from '@/api/costCenter/costCenter.api';
import { useCategoriesList } from '@/api/category/category.api';

interface ExpensesTabProps {
  form: UseFormReturn<InvoiceFormType, unknown>;
  ledgerAccounts?: Array<LedgerAccountList>;
  suppliers?: Array<SupplierList>;
  isLoadingLedgerAccounts: boolean;
  readonly?: boolean;
  onEdit?: boolean;
}

const ExpensesTab: FC<ExpensesTabProps> = ({
  form,
  ledgerAccounts,
  isLoadingLedgerAccounts,
  readonly = false,
  onEdit = false,
}) => {
  const formExpenses = form.watch('expenses');
  const formAccountingEntries = form.watch('accountingEntries');

  const { data: donations } = useDonationsList();

  const { data: globalCostCenter } = useGetGlobalCostCenter();

  const { data: categories } = useCategoriesList();

  const handleAddExpense = () => {
    form.setValue('expenses', [
      ...formExpenses,
      { ...defaultExpenseFormValues, id: self.crypto.randomUUID() },
    ]);
  };

  const handleDeleteExpense = (id: string) => {
    const newExpenses = formExpenses.filter((expense) => expense.id !== id);
    formAccountingEntries.splice(
      formAccountingEntries.findIndex((accountingEntry) => accountingEntry.transaction === id),
      1,
    );

    form.setValue('expenses', newExpenses);

    const supplierAccountingEntryIndex = formAccountingEntries.findIndex(
      (entry) => entry && entry.isSupplierEntry,
    );

    if (supplierAccountingEntryIndex >= 0) {
      const currentAmount = formAccountingEntries.reduce(
        (acc, entry) => acc + (entry.amount && !entry.isSupplierEntry ? entry.amount : 0),
        0,
      );
      formAccountingEntries[supplierAccountingEntryIndex].amount = -currentAmount;
    }
    form.setValue('accountingEntries', formAccountingEntries);
  };

  return (
    <div>
      <Table>
        <TableHeader>
          <TableRow className="hover:bg-inherit">
            <TableHead className="w-[220px] h-auto py-3 px-2 font-medium">Descripción</TableHead>
            <TableHead className="w-[250px] h-auto py-3 px-2 font-medium">
              Cuenta Contable
            </TableHead>
            <TableHead className="w-[180px] h-auto py-3 px-2 font-medium">Categoría</TableHead>
            <TableHead className="w-[180px] h-auto py-3 px-2 font-medium">
              Centro de Costos
            </TableHead>
            <TableHead className="w-[180px] h-auto py-3 px-2 font-medium">Donación</TableHead>
            <TableHead className="h-auto min-w-[150px] py-3 px-2 font-medium">Importe</TableHead>
            <TableHead className="h-auto min-w-[69px] py-3 px-2"></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {formExpenses.map((_expense, index) => (
            <ExpenseRow
              key={_expense.id}
              form={form}
              expenseIndex={index}
              ledgerAccounts={ledgerAccounts}
              donations={donations}
              globalCostCenter={globalCostCenter}
              categories={categories}
              isLoadingLedgerAccounts={isLoadingLedgerAccounts}
              onDeleteExpense={handleDeleteExpense}
              readonly={readonly}
              onEdit={onEdit}
            />
          ))}
        </TableBody>
      </Table>
      {!readonly && !onEdit && (
        <Button
          variant="outlinePrimary"
          size="sm"
          type="button"
          className="text-xs w-fit"
          onClick={handleAddExpense}
        >
          <PlusCircle className="w-4 h-4 mr-2" />
          Agregar Gasto
        </Button>
      )}
    </div>
  );
};

export { ExpensesTab };
