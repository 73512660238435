import React, { FC } from 'react';
import ResponsiveBanner from './ResponsiveBanner';
import { useScreenDetector } from '@/hooks/useScreenDetector';

interface ResponsiveLayoutProps {
  children?: React.ReactNode;
}

const ResponsiveLayout: FC<ResponsiveLayoutProps> = ({ children }) => {
  const { isMobile } = useScreenDetector();

  if (isMobile) {
    return <ResponsiveBanner />;
  }

  return children;
};

export default ResponsiveLayout;
