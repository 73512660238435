import { FC, useEffect, useMemo } from 'react';
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { cn, formatAmount } from '@/lib/utils';
import { PaymentFormType } from '../utils/PaymentSchema';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { CalendarIcon } from 'lucide-react';
import { format } from 'date-fns';
import { Calendar } from '@/components/ui/calendar';
import { es } from 'date-fns/locale';
import { SelectFilter } from '@/components/shared/SelectFilter';
import { useLedgerAccountChildrens } from '@/api/ledgerAccount/ledgerAccount.api';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { CURRENCY_OPTIONS, PAYMENT_MAX_VALUE_ERROR_MESSAGE } from '@/common/constants';
import { CurrencyInput } from '@/components/ui/currency-input';
import { CurrencyOption } from '@/common/types';
import { Input } from '@/components/ui/input';
import { FileUploader } from '@/components/shared/FileUploader';
import { Separator } from '@/components/ui/separator';
import { InvoiceApi } from '@/api/invoice/invoice.model';
import { renderPendingAmount } from '../utils/PaymentUtils';
import { ValidateAlertMessage } from '@/components/shared/ValidateAlertMessage';

interface PaymentFormProps {
  form: UseFormReturn<PaymentFormType, any>;
  submitLabel: string;
  cancelLabel: string;
  isLoading?: boolean;
  submitHandler: SubmitHandler<PaymentFormType>;
  dismissHandler: () => void;
  invoice: InvoiceApi | null;
}

const PaymentForm: FC<PaymentFormProps> = ({
  form,
  submitLabel,
  cancelLabel,
  isLoading = false,
  submitHandler,
  dismissHandler,
  invoice,
}: PaymentFormProps) => {
  const { data: ledgerAccounts, isLoading: isLoadingLedgerAccounts } = useLedgerAccountChildrens();

  const handleChangeCurrency = (value: string) => {
    form.setValue('currency', value);
    form.setValue('amount', 0);
    if (value === 'ARS' && invoice?.money.currency === 'ARS') {
      form.setValue('exchangeRate', 1);
    }
  };

  const renderFormatedAmount = () => {
    const currencyInvoice = invoice?.money.currency;
    const currencyPayment = form.watch('currency');
    const exchangeRate = Number(form.watch('exchangeRate')) || 1;
    const amount = form.watch('amount');

    if (currencyInvoice === 'USD' && currencyPayment === 'ARS') {
      return amount / exchangeRate;
    }
    if (currencyInvoice === 'USD' && currencyPayment === 'USD') {
      return amount;
    }
    if (currencyInvoice === 'ARS' && currencyPayment === 'USD') {
      return amount * exchangeRate;
    }
    if (currencyInvoice === 'ARS' && currencyPayment === 'ARS') {
      return amount;
    }
    return amount;
  };

  const pendingAmount = useMemo(() => {
    const exchangeRate = Number(form.watch('exchangeRate')) || 1;
    let amount;
    if (invoice && invoice.pendingAmount) {
      amount = renderPendingAmount(
        invoice.money.currency,
        form.watch('currency'),
        exchangeRate,
        form.watch('amount'),
        invoice.pendingAmount,
      );
    }
    return amount;
  }, [form.watch('amount'), form.watch('exchangeRate')]);

  useEffect(() => {
    if (invoice?.pendingAmount) {
      form.setValue('pendingAmount', invoice.pendingAmount);
    }
    if (invoice?.money.currency) {
      form.setValue('invoiceCurrency', invoice.money.currency);
    }
  }, []);

  const handleExchangeRateBlur = () => {
    const exchangeRate = Number(form.watch('exchangeRate'));
    if (exchangeRate === 0 || !exchangeRate) {
      form.setValue('exchangeRate', 1);
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(submitHandler)} className="flex flex-col gap-12">
        <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-5">
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-1">
                <FormField
                  control={form.control}
                  name="paymentNumber"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel># Pago</FormLabel>
                      <FormControl>
                        <Input {...field} autoFocus type="text" placeholder="Ingresar # Pago" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-1">
                <FormField
                  control={form.control}
                  name="paymentDate"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Fecha de Pago</FormLabel>
                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant={'outline'}
                              className={cn(
                                'w-full font-normal justify-start',
                                !field.value && 'text-muted-foreground',
                              )}
                            >
                              <div
                                className={cn(
                                  'w-full flex items-center justify-start',
                                  field.value ? 'text-sm font-normal' : 'text-slate-500',
                                )}
                              >
                                <CalendarIcon className="w-4 h-4 mr-2 opacity-50" />
                                {field.value ? (
                                  format(field.value, 'dd/MM/yyyy')
                                ) : (
                                  <span>Selecciona una fecha</span>
                                )}
                              </div>
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                          <Calendar
                            mode="single"
                            showOutsideDays
                            fixedWeeks
                            captionLayout="dropdown"
                            fromYear={new Date().getFullYear() - 5}
                            toYear={new Date().getFullYear() + 10}
                            selected={field.value}
                            onSelect={field.onChange}
                            locale={es}
                          />
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-1">
                <FormItem>
                  <SelectFilter
                    formLabel="Medio de Pago"
                    form={form}
                    isLoading={isLoadingLedgerAccounts}
                    formName={`ledgerAccount`}
                    formPlaceholder="Cuenta Contable"
                    formFilterPlaceholder="Buscar Cuenta Contable..."
                    options={ledgerAccounts || []}
                    classnames={{ button: 'w-full', item: 'w-full' }}
                  />
                </FormItem>
              </div>
              <div className="col-span-1">
                <FormItem>
                  <FormLabel>Comprobante</FormLabel>
                  <FileUploader
                    form={form}
                    fieldName="files"
                    label="Seleccionar Archivo"
                    fileLimit={1}
                    initialFiles={form.watch('files')}
                    buttonClassname="justify-start w-full"
                    trashClassname="h-10"
                  />
                </FormItem>
              </div>
            </div>
          </div>
          <Separator />
          <div className="grid grid-cols-3 gap-4">
            <div className="w-full">
              <FormField
                control={form.control}
                name="currency"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Moneda</FormLabel>
                    <Select
                      onValueChange={(value) => handleChangeCurrency(value)}
                      value={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Seleccione la Moneda" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {CURRENCY_OPTIONS.map((currency) => (
                          <SelectItem key={currency.value} value={currency.value}>
                            {currency.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="w-full">
              <FormField
                control={form.control}
                name="exchangeRate"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Tipo de Cambio del día</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        type="number"
                        placeholder="Ingresar Conversión"
                        disabled={
                          invoice?.money.currency === 'ARS' && form.watch('currency') === 'ARS'
                        }
                        onBlur={handleExchangeRateBlur}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="w-full">
              <FormField
                control={form.control}
                name="amount"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Monto en {form.watch('currency')}</FormLabel>
                    <FormControl>
                      <CurrencyInput
                        {...field}
                        type="number"
                        currency={form.watch('currency') as CurrencyOption['value']}
                        placeholder="Ingresar monto"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-12">
          <div className="flex flex-col gap-8">
            <div className="flex flex-col items-end justify-between gap-[10px] py-[10px] px-4 rounded-lg bg-slate-100 border border-1">
              <p className="text-base text-foreground font-medium">
                Total a pagar: {formatAmount(form.watch('amount'), form.watch('currency'))}
              </p>
              <Separator className="bg-slate-200" />
              <div className="flex flex-col gap-1 text-right">
                <p className="text-sm text-foreground font-medium">
                  {`Total a pagar en moneda factura: ${formatAmount(renderFormatedAmount() || 0, invoice?.money.currency)}`}
                </p>
                <p className="text-sm leading-6">
                  Importe adeudado:{' '}
                  {(pendingAmount || pendingAmount === 0) &&
                    formatAmount(pendingAmount, invoice?.money.currency)}
                </p>
              </div>
            </div>
            {(pendingAmount || pendingAmount === 0) && pendingAmount < 0 && (
              <ValidateAlertMessage message={PAYMENT_MAX_VALUE_ERROR_MESSAGE} />
            )}
          </div>
          <div className="flex flex-row justify-end gap-2">
            <Button type="button" variant="outline" disabled={isLoading} onClick={dismissHandler}>
              {cancelLabel}
            </Button>
            <Button
              type="submit"
              disabled={isLoading || !form.formState.isValid}
              className="relative"
              isLoading={isLoading}
            >
              {submitLabel}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export { PaymentForm };
