import { FC } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { cn, formatAmount } from '@/lib/utils';

interface InfoCardProps {
  items: Array<{
    title: string;
    value?: number | string;
    values?: Array<{ value: number; currency?: string }>;
  }>;
  className?: { card?: string; header?: string; content?: string };
}

const InfoCard: FC<InfoCardProps> = ({ items, className }: InfoCardProps) => (
  <Card
    className={cn(
      `py-6 px-5 ${items.length > 1 ? 'flex items-start gap-5' : null}`,
      className?.card,
    )}
  >
    {items.map(({ title, value, values }) => (
      <div key={`item-${title}`}>
        <CardHeader
          className={cn(
            'flex flex-row items-center justify-between p-0 pb-2 space-y-0',
            className?.header,
          )}
        >
          <CardTitle className="text-[10px] text-muted-foreground font-medium uppercase tracking-wide">
            {title}
          </CardTitle>
        </CardHeader>
        <CardContent className={cn('p-0', className?.content)}>
          {values
            ? values.map((value, index) => (
                <h2 key={index} className="text-2xl font-light leading-none text-card-foreground">
                  {formatAmount(value.value, value.currency ? value.currency : 'USD')}
                </h2>
              ))
            : null}
          {value && typeof value === 'number' ? (
            <h2 className="text-2xl font-light leading-none text-card-foreground">
              {formatAmount(value)}
            </h2>
          ) : (
            <h2 className="text-2xl font-light leading-none text-card-foreground">{value}</h2>
          )}
        </CardContent>
      </div>
    ))}
  </Card>
);

export { InfoCard };
