/* eslint-disable sonarjs/no-duplicate-string */
import { FC, useEffect } from 'react';
import { FormProvider, SubmitHandler, UseFormReturn, useForm } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { CreditNoteFormType } from '../utils/CreditNoteSchema';
import { SelectFilter } from '@/components/shared/SelectFilter';
import { useLedgerAccountChildrens } from '@/api/ledgerAccount/ledgerAccount.api';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { FileUploader } from '@/components/shared/FileUploader';
import { Separator } from '@/components/ui/separator';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { CalendarIcon } from 'lucide-react';
import { format } from 'date-fns';
import { Calendar } from '@/components/ui/calendar';
import { cn } from '@/lib/utils';
import { es } from 'date-fns/locale';
import { Textarea } from '@/components/ui/textarea';
import { InvoiceApi } from '@/api/invoice/invoice.model';
import { CreditNoteExpensesTable } from './CreditNoteExpensesTable';
import { TotalsCard } from '@/components/shared/TotalsCard';
import { CURRENCY_OPTIONS } from '@/common/constants';
import {
  InvoiceFormType,
  InvoiceSchema,
  defaultInvoiceFormValues,
} from '@/pages/Invoices/utils/InvoiceSchema';
import { generateInvoiceForm } from '@/pages/Invoices/utils/InvoicesUtils';
import { zodResolver } from '@hookform/resolvers/zod';
import { ValidateAlertMessage } from '@/components/shared/ValidateAlertMessage';

interface CreditNoteFormProps {
  form: UseFormReturn<CreditNoteFormType, unknown>;
  invoice: InvoiceApi;
  isLoading?: boolean;
  submitLabel: string;
  cancelLabel: string;
  submitHandler: SubmitHandler<CreditNoteFormType>;
  dismissHandler: () => void;
}

const CreditNoteForm: FC<CreditNoteFormProps> = ({
  form,
  invoice,
  isLoading,
  submitLabel,
  cancelLabel,
  submitHandler,
  dismissHandler,
}) => {
  const { data: ledgerAccountsChildren, isLoading: isLoadingLedgerAccounts } =
    useLedgerAccountChildrens();

  const invoiceForm = useForm<InvoiceFormType>({
    defaultValues: invoice ? generateInvoiceForm(invoice) : defaultInvoiceFormValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(InvoiceSchema),
  });

  const revertedAmount = invoiceForm.watch('expenses').reduce((accumulator, item) => {
    return accumulator + item.amount;
  }, 0);

  const handleExchangeRateBlur = () => {
    const exchangeRate = Number(form.watch('exchangeRate'));
    if (exchangeRate === 0 || !exchangeRate) {
      form.setValue('exchangeRate', 1);
    }
  };

  useEffect(() => {
    form.setValue('revertedAmount', revertedAmount, { shouldValidate: true });
  }, [revertedAmount]);

  useEffect(() => {
    if (invoice.pendingAmount)
      form.setValue('invoicePendingAmount', invoice.pendingAmount, { shouldValidate: true });
  }, [invoice]);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(submitHandler)} className="flex flex-col gap-5">
        <div className="flex flex-col gap-10 w-full">
          <div className="flex justify-between gap-20 w-full">
            <div className="flex flex-col gap-6 w-full">
              <div className="flex gap-6">
                <FormField
                  control={form.control}
                  name="number"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel># Nota de Crédito</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          autoFocus
                          type="text"
                          placeholder="Ingrese N° de nota de crédito"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <SelectFilter
                  form={form}
                  isLoading={isLoadingLedgerAccounts}
                  formName="ledgerAccount"
                  formLabel="Cuenta Contable"
                  formPlaceholder="X.XX.XX.XX - Proveedores"
                  formFilterPlaceholder="Buscar Cuenta Contable..."
                  options={ledgerAccountsChildren || []}
                  classnames={{ button: 'w-full', item: 'w-full' }}
                  disabled
                />
                <div className="flex flex-col gap-2 w-full">
                  <FormItem>
                    <FormLabel>Archivo Adjunto</FormLabel>
                    <FileUploader
                      form={form}
                      fieldName="files"
                      label="Seleccionar Archivo"
                      fileLimit={1}
                      buttonClassname="justify-start w-full"
                      initialFiles={form.watch('files')}
                    />
                  </FormItem>
                </div>
              </div>
              <FormField
                control={form.control}
                name="details"
                render={({ field }) => (
                  <FormItem className="w-full h-full">
                    <FormLabel>Detalle</FormLabel>
                    <FormControl>
                      <Textarea
                        placeholder="Ingrese aquí el detalle del la nota de crédito..."
                        className="h-full resize-none"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <Separator orientation="vertical" className="h-auto" />
            <div className="flex flex-col gap-6 w-full max-w-[250px]">
              <FormField
                control={form.control}
                name="reversionDate"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Fecha de Reversión</FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant={'outline'}
                            className={cn(
                              'w-full font-normal justify-start',
                              !field.value && 'text-muted-foreground',
                            )}
                          >
                            <div
                              className={cn(
                                'w-60 flex items-center justify-start',
                                field.value ? 'text-sm font-normal' : 'text-slate-500',
                              )}
                            >
                              <CalendarIcon className="w-4 h-4 mr-2 opacity-50" />
                              {field.value ? (
                                format(field.value, 'dd/MM/yyyy')
                              ) : (
                                <span>Seleccioné una fecha</span>
                              )}
                            </div>
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          showOutsideDays
                          fixedWeeks
                          captionLayout="dropdown"
                          fromYear={new Date().getFullYear()}
                          toYear={new Date().getFullYear() + 10}
                          selected={field.value}
                          onSelect={field.onChange}
                          locale={es}
                        />
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="receiptDate"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Fecha de Comprobante</FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant={'outline'}
                            className={cn(
                              'w-full font-normal justify-start',
                              !field.value && 'text-muted-foreground',
                            )}
                          >
                            <div
                              className={cn(
                                'w-60 flex items-center justify-start',
                                field.value ? 'text-sm font-normal' : 'text-slate-500',
                              )}
                            >
                              <CalendarIcon className="w-4 h-4 mr-2 opacity-50" />
                              {field.value ? (
                                format(field.value, 'dd/MM/yyyy')
                              ) : (
                                <span>Seleccioné una fecha</span>
                              )}
                            </div>
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          showOutsideDays
                          fixedWeeks
                          captionLayout="dropdown"
                          fromYear={new Date().getFullYear()}
                          toYear={new Date().getFullYear() + 10}
                          selected={field.value}
                          onSelect={field.onChange}
                          locale={es}
                        />
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="exchangeRate"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Tipo de Cambio (ARS/US$)</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        type="number"
                        placeholder="Ingresar Conversión"
                        onBlur={handleExchangeRateBlur}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="flex">
            <CreditNoteExpensesTable
              form={form}
              invoiceForm={invoiceForm}
              expenses={invoice.expenses}
              ledgerAccounts={ledgerAccountsChildren}
              isLoadingLedgerAccounts={isLoadingLedgerAccounts}
            />
          </div>
        </div>
        <div className="flex justify-end">
          <TotalsCard
            total={{
              label: 'Importe a revertir',
              amount: revertedAmount,
              currency: invoice?.money.currency || CURRENCY_OPTIONS[0],
            }}
            pending={{
              label: 'Importe adeudado',
              amount: invoice.pendingAmount || 0,
              currency: invoice?.money.currency || CURRENCY_OPTIONS[0],
            }}
          />
        </div>
        <div className="flex flex-col gap-2">
          {!revertedAmount && <ValidateAlertMessage message="Debe existir un importe a revertir" />}
          {invoice.pendingAmount && revertedAmount > invoice.pendingAmount && (
            <ValidateAlertMessage message="El importe a revertir debe ser menor que el adeudado" />
          )}
          {!form.watch('number') && (
            <ValidateAlertMessage message="Especifique el # de Nota de Crédito" />
          )}
        </div>
        <div className="flex flex-row justify-end gap-2">
          <Button type="button" variant="outline" disabled={isLoading} onClick={dismissHandler}>
            {cancelLabel}
          </Button>
          <Button
            type="submit"
            disabled={!form.formState.isValid}
            className="relative"
            isLoading={isLoading}
          >
            {submitLabel}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default CreditNoteForm;
