import { Separator } from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';

const DonationPayFormSkeleton = () => (
  <div className="mx-auto px-14 max-w-[1000px]">
    <div className="flex flex-col">
      <div className="flex justify-start">
        <Skeleton className="w-2/3 h-10" />
      </div>
    </div>
    <div className={'flex flex-col mt-14 mb-[60px]'}>
      <div className="flex flex-col gap-[50px]">
        <div className="flex flex-col w-full gap-10">
          <Skeleton className="w-full h-36" />
          <Separator />
        </div>
        <div className="flex flex-col w-full gap-10">
          <Skeleton className="w-full h-36" />
          <Separator />
        </div>
        <div className="flex flex-row gap-6 w-full">
          <Skeleton className="w-1/4 h-10" />
        </div>
        <div className="flex flex-col gap-6 w-full">
          <Skeleton className="w-full bg-transparent border">
            <div className="flex flex-col my-10 gap-[50px]">
              <div className="flex items-center">
                <Skeleton className="w-1/2 h-10 ml-6" />
              </div>
              <div className="flex items-center justify-between mx-6">
                <Skeleton className="w-1/5 h-10" />
                <Skeleton className="w-1/5 h-10" />
                <Skeleton className="w-1/5 h-10" />
                <Skeleton className="w-1/5 h-10" />
              </div>
              <div className="flex items-center justify-between mx-6">
                <Skeleton className="w-[390px] h-10" />
                <Skeleton className="w-[390px] h-10" />
              </div>
              <div className="flex items-center mx-6">
                <Skeleton className="w-full h-20" />
              </div>
            </div>
          </Skeleton>
          <Skeleton className="w-full bg-transparent border">
            <div className="flex flex-col my-10 gap-[50px]">
              <div className="flex items-center">
                <Skeleton className="w-1/2 h-10 ml-6" />
              </div>
              <div className="flex items-center justify-between mx-6">
                <Skeleton className="w-1/5 h-10" />
                <Skeleton className="w-1/5 h-10" />
                <Skeleton className="w-1/5 h-10" />
                <Skeleton className="w-1/5 h-10" />
              </div>
              <div className="flex items-center justify-between mx-6">
                <Skeleton className="w-[390px] h-10" />
                <Skeleton className="w-[390px] h-10" />
              </div>
              <div className="flex items-center mx-6">
                <Skeleton className="w-full h-20" />
              </div>
            </div>
          </Skeleton>
        </div>
      </div>
    </div>
  </div>
);

export { DonationPayFormSkeleton };
