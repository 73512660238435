import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { AccountingEntryFormType } from './AccountingEntrySchema';
import { FC } from 'react';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Button } from '@/components/ui/button';
import { cn, fixAmount } from '@/lib/utils';
import { format } from 'date-fns';
import { CalendarIcon, PlusCircle } from 'lucide-react';
import { Calendar } from '@/components/ui/calendar';
import { es } from 'date-fns/locale';
import AccountingEntryItem from './AccountingEntryItem/AccountingEntryItem';
import { toast } from 'sonner';
import { ACCOUNTING_SUM_ERROR_MESSAGE, SONNER_ERROR_OPTIONS } from '@/common/constants';

interface AccountingEntryFormProps {
  form: UseFormReturn<AccountingEntryFormType, unknown>;
  submitLabel: string;
  cancelLabel: string;
  isLoading?: boolean;
  dismissHandler: () => void;
  submitHandler: SubmitHandler<AccountingEntryFormType>;
  usePrepopulatedAccount?: boolean;
}

const AccountingEntryForm: FC<AccountingEntryFormProps> = ({
  form,
  cancelLabel,
  submitLabel,
  isLoading,
  submitHandler,
  dismissHandler,
  usePrepopulatedAccount,
}) => {
  const totalAmount = fixAmount(
    form
      .watch('accountingEntries')
      .reduce((acc, entry) => acc + entry.amount * entry.exchangeRateOfficial, 0),
    2,
  );

  const appendNewAccountingEntry = () => {
    const accountingEntries = form.getValues('accountingEntries');
    const newEmptyAccountingEntry = {
      ledgerAccount: { id: '', name: '' },
      currency: 'ARS',
      exchangeRateOfficial: 1,
      exchangeRateAlt: 1,
      amount: 0,
      details: '',
    };
    const newAccountingEntries = [...accountingEntries, newEmptyAccountingEntry];
    form.setValue('accountingEntries', newAccountingEntries);
  };

  const deleteAccountingEntry = (index: number) => {
    const accountingEntries = form.getValues('accountingEntries');
    const updatedAccountingEntries = [...accountingEntries];
    if (updatedAccountingEntries.length === 2) {
      toast.error('Error al eliminar la partida contable.', {
        description:
          'No se pueden eliminar todas las partidas contables, debe haber al menos dos para poder continuar.',
        ...SONNER_ERROR_OPTIONS,
        duration: 3000,
      });
      return;
    }
    updatedAccountingEntries.splice(index, 1);
    form.setValue('accountingEntries', updatedAccountingEntries);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(submitHandler)} className="flex flex-col">
        <div className="flex flex-col gap-6">
          <div className="flex flex-row gap-4">
            <FormField
              control={form.control}
              name="paymentDate"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Fecha Contable</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={'outline'}
                          className="w-1/4 pl-3 hover:bg-transparent"
                          childrenClassName={cn(
                            'justify-between w-full text-left text-foreground font-normal',
                            !field.value
                              ? 'text-muted-foreground hover:text-muted-foreground'
                              : null,
                          )}
                        >
                          {field.value ? (
                            format(field.value, 'dd/MM/yyyy')
                          ) : (
                            <span className="font-light text-muted-foreground">
                              Selecciona una fecha
                            </span>
                          )}
                          <CalendarIcon className="w-4 h-4 ml-auto opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="center">
                      <Calendar
                        defaultMonth={form.getValues('paymentDate')}
                        mode="single"
                        showOutsideDays
                        fixedWeeks
                        captionLayout="dropdown"
                        fromYear={new Date().getFullYear() - 5}
                        toYear={new Date().getFullYear() + 10}
                        selected={field.value}
                        onSelect={field.onChange}
                        locale={es}
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        {form.watch('accountingEntries').length > 0 && (
          <p className="mt-8 text-sm font-medium">Asientos contables</p>
        )}
        {form.watch('accountingEntries').map((_entry, index) => (
          <AccountingEntryItem
            key={`accountingEntries-${index}-${_entry.ledgerAccount?.id}`}
            form={form}
            index={index}
            onDelete={deleteAccountingEntry}
            usePrepopulatedAccount={usePrepopulatedAccount && index === 0}
          />
        ))}
        <Button type="button" className="mt-6 text-xs w-fit" onClick={appendNewAccountingEntry}>
          <PlusCircle className="w-4 h-4 mr-2" />
          Agregar Asiento Contable
        </Button>
        {totalAmount !== 0 && (
          <div className="flex flex-row justify-end my-2">
            <p className="text-sm font-medium text-rose-700">{ACCOUNTING_SUM_ERROR_MESSAGE}</p>
          </div>
        )}
        <div className="flex flex-row justify-end gap-2 mt-6">
          <Button type="button" variant="outline" disabled={isLoading} onClick={dismissHandler}>
            {cancelLabel}
          </Button>
          <Button
            type="submit"
            disabled={isLoading || !form.formState.isValid}
            className="relative"
            isLoading={isLoading}
          >
            {submitLabel}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default AccountingEntryForm;
