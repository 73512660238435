import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { cn, formatAmount } from '@/lib/utils';
import { FC, useMemo } from 'react';

interface DonationRemainAmountProps {
  totalAmount: number;
  assignedAmount: number;
  currency?: string;
}

const DonationRemainAmount: FC<DonationRemainAmountProps> = ({
  totalAmount,
  assignedAmount,
  currency,
}) => {
  const assignedPercentage = useMemo(() => {
    const amount = (assignedAmount / totalAmount) * 100;

    if (isNaN(amount)) {
      return 0;
    } else {
      return ((assignedAmount / totalAmount) * 100).toFixed(2);
    }
  }, [assignedAmount, totalAmount]);

  return (
    <Card>
      <CardHeader className="pt-3 pb-2">
        <CardDescription>Monto por Asignar</CardDescription>
        <CardTitle
          className={cn('text-2xl truncate', totalAmount - assignedAmount < 0 && 'text-rose-700')}
        >
          {formatAmount(totalAmount - assignedAmount || 0, currency)}
        </CardTitle>
      </CardHeader>
      <CardContent className="px-6 pb-4">
        <div className="text-xs truncate text-muted-foreground">
          {assignedPercentage}% Asignado ({formatAmount(assignedAmount || 0, currency)})
        </div>
      </CardContent>
    </Card>
  );
};

export default DonationRemainAmount;
