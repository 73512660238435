import { SheetLayout } from '@/components/layout/SheetLayout';
import { paths, pathsBase } from '@/routes/paths';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DonationPayForm from './components/DonationPayForm';
import { DonationApi } from '@/api/donation/donation.model';
import { payDonationMutationFn, useDonation, useDonations } from '@/api/donation/donation.api';
import {
  DonationPayFormType,
  DonationPaySchema,
  defaultDonationPayFormValues,
} from './utils/DonationPaySchema';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'sonner';
import { SONNER_ERROR_OPTIONS, SONNER_SUCCESS_OPTIONS } from '@/common/constants';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import { DonationPayFormSkeleton } from './components/DonationPaySkeleton';
import { getParamsWithAnimation, getQueryParamByName } from '@/lib/utils';

const PAGE_TITLE = 'Registrar Cobro de Donación';

type DonationPayParams = {
  id: DonationApi['id'];
};

const DonationPay = () => {
  const navigate = useNavigate();
  const { id } = useParams() as DonationPayParams;
  const location = useLocation();
  const detailParam = getQueryParamByName('detail', location) === 'true';

  const { data: donation, isLoading: isLoadingDonation } = useDonation(id);
  const { refetch } = useDonations();

  const form = useForm<DonationPayFormType>({
    defaultValues: defaultDonationPayFormValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(DonationPaySchema),
  });

  const { mutate: payDonation, isPending } = useMutation({
    mutationFn: payDonationMutationFn,
    onSuccess: () => {
      refetch();
      toast(
        <SuccessToastText>
          ¡Donación <span className="text-blue-700">{donation?.name}</span> cobrada exitosamente!
        </SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismiss(detailParam);
    },
    onError: (error: AxiosError) => {
      Sentry.captureException(error);
      toast.error('Ha ocurrido un error durante el Registro del Cobro de la Donación.', {
        description: 'Por favor, revisa los datos ingresados e intenta de nuevo.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  const handlePayDonation: SubmitHandler<DonationPayFormType> = (formData) => payDonation(formData);

  const handleDismiss = (detail: boolean) => {
    if (detail) {
      navigate(
        `/${pathsBase.DONATIONS}/${id}${getParamsWithAnimation(location.search.replace('&detail=true', ''))}`,
      );
    } else {
      navigate(`${paths.DONATIONS}${getParamsWithAnimation(location.search, true)}`);
    }
  };

  return (
    <>
      <Helmet>
        <title>PEM - {PAGE_TITLE}</title>
      </Helmet>
      <SheetLayout onClose={() => handleDismiss(detailParam)}>
        {isLoadingDonation || !donation ? (
          <DonationPayFormSkeleton />
        ) : (
          <div className="max-w-4xl mx-auto">
            <>
              <h1 className="text-4xl font-normal tracking-tighter text-foreground">
                {donation.name} <span className="font-light">: Registrar Cobro</span>
              </h1>
              <p className="mt-4 text-sm text-muted-foreground">
                En esta sección podes ver el registro y confirmación de Cobros relacionados con la
                donación especificada. Complete los detalles administrativos y contables a
                continuación para asegurar la correcta asignación y manejo de los fondos recibidos.
              </p>
              <div className="mt-12">
                <DonationPayForm
                  donation={donation}
                  form={form}
                  cancelLabel="Cancelar"
                  submitLabel="Confirmar"
                  dismissHandler={() => handleDismiss(detailParam)}
                  isLoading={isLoadingDonation || isPending}
                  submitHandler={handlePayDonation}
                />
              </div>
            </>
          </div>
        )}
      </SheetLayout>
    </>
  );
};

export default DonationPay;
