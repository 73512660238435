import * as React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ChevronDown } from 'lucide-react';

import { cn } from '@/lib/utils';

interface AccordionTriggerProps
  extends React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> {
  chevronClassname?: string;
  onChevronClick?: () => void;
}

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item ref={ref} className={cn('border-b', className)} {...props} />
));
AccordionItem.displayName = 'AccordionItem';

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  AccordionTriggerProps
>(({ className, children, chevronClassname, onChevronClick, ...props }, ref) => {
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (onChevronClick) e.preventDefault();
  };

  return (
    <AccordionPrimitive.Header className="flex flex-1 items-center justify-between">
      <AccordionPrimitive.Trigger
        ref={ref}
        className={cn(
          'flex flex-1 space-between py-4 font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180',
          className,
        )}
        onClick={handleClick}
        {...props}
      >
        {children}
        {!onChevronClick ? (
          <ChevronDown className="w-4 h-4 transition-transform duration-200 shrink-0" />
        ) : null}
      </AccordionPrimitive.Trigger>
      {onChevronClick ? (
        <ChevronDown
          strokeWidth={0.7}
          className={cn(
            'w-4 h-4 text-muted-foreground transition-transform duration-200 shrink-0 cursor-pointer',
            chevronClassname,
          )}
          onClick={onChevronClick}
        />
      ) : null}
    </AccordionPrimitive.Header>
  );
});
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className="overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
    {...props}
  >
    <div className={cn('pb-4 pt-0', className)}>{children}</div>
  </AccordionPrimitive.Content>
));

AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
