import { z } from 'zod';
import { ExpenseSchema } from '../../Expenses/utils/ExpenseSchema';
import moment from 'moment';
import { CURRENCY_OPTIONS, ENTRY_WITHOUT_AMOUNT_ERROR_MESSAGE } from '@/common/constants';
import { AccountingEntryItemSchema } from '@/pages/AccountsMayor/AccountingEntry/AccountingEntryItem/AccountingEntryItemSchema';
import { DonationApi } from '@/api/donation/donation.model';
import { InvoiceState, PaymentState } from '@/api/invoice/invoice.model.ts';
import { fixAmount } from '@/lib/utils';

const REQUIRED_OPTION_LABEL = 'Seleccione una opción';
const REQUIRED_AMOUNT_LABEL = 'Ingrese un monto válido';

const Accounts = z
  .object({
    id: z.string().optional(),
    cbu: z.string().optional(),
    currency: z.string().optional(),
    createdAt: z.string().optional(),
    updatedAt: z.string().optional(),
    expenses: z.array(z.object({ id: z.string() })).optional(),
  })
  .array()
  .optional();

const SelectSchema = z
  .object(
    {
      id: z.string().min(1, REQUIRED_OPTION_LABEL),
      name: z.string(),
      accounts: Accounts,
    },
    { invalid_type_error: 'jnj', required_error: 'kjn' },
  )
  .required();

const InvoiceSchema = z
  .object({
    receiptNumber: z.string().min(1, 'Ingrese un número de Factura'),
    supplier: SelectSchema,
    supplierAccount: z
      .object({ id: z.string(), cbu: z.string().optional(), name: z.string().optional() })
      .optional(),
    files: z.array(z.string()).optional(),
    details: z.string().optional(),
    currency: z.string().min(1, REQUIRED_OPTION_LABEL),
    exchangeRate: z.coerce.number().min(0, REQUIRED_AMOUNT_LABEL),
    exchangeRateAlt: z.coerce.number().min(0, REQUIRED_AMOUNT_LABEL),
    invoiceState: z.nativeEnum(InvoiceState),
    paymentState: z.nativeEnum(PaymentState),
    dueDate: z.date({
      required_error: 'Seleccione una fecha de vencimiento',
    }),
    receiptDate: z.date({
      required_error: 'Seleccione una fecha de comprobante',
    }),
    accountingDate: z.date({
      required_error: 'Seleccione una fecha contable',
    }),
    expenses: z.array(ExpenseSchema).min(1, 'Ingrese al menos un gasto'),
    accountingEntries: z
      .array(AccountingEntryItemSchema)
      .min(2, 'Ingrese al menos dos asientos contables'),
  })
  .superRefine((data: { [key: string]: any }, ctx) => {
    const totalAmount = fixAmount(
      data.accountingEntries.reduce((acc: any, entry: any) => {
        return acc + entry.amount;
      }, 0),
      2,
    );

    const entryWithoutAmount = data.accountingEntries.some((entry: any) => entry.amount === 0);

    const expenseWithoutAmount = data.expenses.some((expense: any) => expense.amount === 0);

    if (expenseWithoutAmount || entryWithoutAmount) {
      return ctx.addIssue({
        code: 'custom',
        message: ENTRY_WITHOUT_AMOUNT_ERROR_MESSAGE,
      });
    }

    if (totalAmount !== 0) {
      return ctx.addIssue({
        code: 'custom',
        message: 'La suma de los montos de las partidas contables debe ser igual a 0',
      });
    }

    const requiredFields = ['supplier'];
    requiredFields.forEach((field) => {
      if (!data[field] || data[field].id === '') {
        ctx.addIssue({
          path: [field],
          message: 'Este campo es obligatorio',
          code: 'custom',
        });
      }
    });
  });

type InvoiceFormType = z.infer<typeof InvoiceSchema>;

const defaultInvoiceFormValues: InvoiceFormType = {
  receiptNumber: '',
  supplier: { id: '', name: '', accounts: [] },
  supplierAccount: { id: '', cbu: '', name: '' },
  files: [],
  details: '',
  currency: CURRENCY_OPTIONS[0].value,
  exchangeRate: 1,
  exchangeRateAlt: 1,
  invoiceState: InvoiceState.DRAFT,
  paymentState: PaymentState.PENDING,
  dueDate: moment().toDate(),
  receiptDate: moment().toDate(),
  accountingDate: moment().toDate(),
  expenses: [
    {
      id: self.crypto.randomUUID(),
      description: '',
      global: false,
      ledgerAccount: { id: '', name: '' },
      donation: { id: '', name: '' },
      costCenter: { id: '', name: '' },
      category: { id: '', name: '' },
      amount: 0,
    },
  ],
  accountingEntries: [],
};

const transformDonationToInvoiceFormDefaultValue = (data: DonationApi): InvoiceFormType => {
  const firstEntry = data.accountingEntries?.[0];
  const defaultCurrency = firstEntry?.moneyOfficial?.currency || data.money?.currency || '';
  const defaultExchangeRate = firstEntry?.moneyOfficial?.exchangeRate || 1;

  return {
    receiptNumber: '',
    supplier: { id: '', name: '', accounts: [] },
    supplierAccount: { id: '', cbu: '', name: '' },
    files: [],
    details: '',
    currency: defaultCurrency,
    exchangeRate: defaultExchangeRate,
    exchangeRateAlt: 1,
    paymentState: PaymentState.PENDING,
    invoiceState: InvoiceState.DRAFT,
    dueDate: moment().toDate(),
    receiptDate: moment().toDate(),
    accountingDate: moment().toDate(),
    expenses: [
      {
        id: crypto.randomUUID(),
        description: '',
        global: false,
        ledgerAccount: { id: '', name: '' },
        donation: { id: '', name: '' },
        costCenter: { id: '', name: '' },
        category: { id: '', name: '' },
        amount: 0,
      },
    ],
    accountingEntries:
      data.accountingEntries?.map((entry) => {
        const moneyOfficial = entry.moneyOfficial;
        const currency = moneyOfficial?.currency || '';
        const exchangeRateOfficial = moneyOfficial?.exchangeRate || 1;
        const amount = moneyOfficial
          ? entry.credit
            ? -(moneyOfficial.amount || 0)
            : moneyOfficial.amount || 0
          : entry.credit
            ? -(entry.amount || 0)
            : entry.amount || 0;

        return {
          id: entry.id as string,
          ledgerAccount: {
            id: '',
            name: `${entry.ledgerAccountCode} - ${entry.ledgerAccount}`,
          },
          currency,
          amount,
          exchangeRateOfficial,
          exchangeRateAlt: entry.moneyAlt?.exchangeRate || 1,
          details: entry.details,
          type: '',
          isSupplierEntry: undefined,
          transaction: null,
        };
      }) || [],
  };
};

export { InvoiceSchema, defaultInvoiceFormValues, transformDonationToInvoiceFormDefaultValue };

export type { InvoiceFormType };
