import { DEFAULT_NAME_SCHEMA } from '@/common/constants';
import { z } from 'zod';

export const CategorySchema = z
  .object({
    name: DEFAULT_NAME_SCHEMA,
  })
  .required();

export type CategoryFormType = z.infer<typeof CategorySchema>;

export const defaultCategoryFormValues: CategoryFormType = { name: '' };
