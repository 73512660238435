import { DEFAULT_CODE_SCHEMA, DEFAULT_NAME_SCHEMA } from '@/common/constants';
import { z } from 'zod';

export const TerritorySchema = z.object({
  name: DEFAULT_NAME_SCHEMA,
  code: DEFAULT_CODE_SCHEMA,
});

export type TerritoryFormType = {
  name: string;
  code: string;
  projects?: string;
};

export const defaultTerritoryFormValues: TerritoryFormType = { name: '', code: '', projects: '' };
