import {
  deleteLedgerAccountMutationFn,
  useLedgerAccountByType,
} from '@/api/ledgerAccount/ledgerAccount.api';
import { useTablePagination } from '@/hooks/useTablePagination';
import { getLedgerAccountTableColumnsByType } from '../utils/LedgerAccountColumnsUtils';
import { Outlet, useNavigate } from 'react-router-dom';
import { TableWrapper } from '@/components/shared/TableWrapper';
import {
  DEFAULT_PAGINATION_PAGE_COUNT,
  SONNER_ERROR_OPTIONS,
  SONNER_SUCCESS_OPTIONS,
} from '@/common/constants';
import { LedgerAccountApi, LedgerAccountTypeEnum } from '@/api/ledgerAccount/ledgerAccount.model';
import { AlertDialogWrapper } from '@/components/shared/AlertDialog';
import SuccessToastText from '@/components/shared/SuccessToastText';
import useTableColumnsWithActions from '@/hooks/useTableColumnsWithActions';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'sonner';
import * as Sentry from '@sentry/react';
import { paths, pathsBase } from '@/routes/paths';
import { SearchBar } from '@/components/shared/SearchBar';
import { useSearchBar } from '@/hooks/useSearchBar';
import { useAlertDialog } from '@/hooks/useAlertDialog';
import useFilterAndSortByBe from '@/hooks/useFilterAndSortByBe';
import { getParamsWithAnimation } from '@/lib/utils';

const PAGE_TITLE = 'SubSubcuentas';

const LedgerAccountSubAccount = () => {
  const navigate = useNavigate();

  const { pageIndex, pageSize, paginationState, dir, sort, setPagination, setDir, setSort } =
    useTablePagination<LedgerAccountApi>();

  const { searchValue, form: searchBarForm, handleSubmit: handleSearchSubmit } = useSearchBar();

  const { data, isLoading, isFetching, refetch } = useLedgerAccountByType({
    page: pageIndex,
    size: pageSize,
    dir: dir,
    sort: sort,
    type: LedgerAccountTypeEnum.SUB_ACCOUNT,
    search: searchValue,
  });

  const { alertDialog, setAlertDialog, handleDismissAlertDialog } = useAlertDialog();

  const { mutate: deleteSubAccount, isPending: isDeleteSubAccountPending } = useMutation({
    mutationFn: deleteLedgerAccountMutationFn,
    onSuccess: () => {
      refetch();
      toast(
        <SuccessToastText>Subcuenta eliminada exitosamente</SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismissAlertDialog();
    },
    onError: (error: AxiosError) => {
      Sentry.captureException(error);
      toast.error('Ha ocurrido un error durante la eliminación de la Subcuenta.', {
        description: 'Por favor, intentalo nuevamente.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  useFilterAndSortByBe<LedgerAccountApi>(
    refetch,
    setDir,
    setSort,
    undefined,
    undefined,
    'code',
    'asc',
  );

  const handleDeleteSubAccount = (ledgerAccount: LedgerAccountApi) => {
    setAlertDialog({
      title: `¿Estás seguro que queres eliminar "${ledgerAccount.code}. ${ledgerAccount.name}"? `,
      description: `Esta acción no puede ser deshecha y se perderá toda la información asociada a esta Subcuenta: "${ledgerAccount.code}. ${ledgerAccount.name}". `,
      open: true,
      onCancel: handleDismissAlertDialog,
      onConfirm: () => deleteSubAccount(ledgerAccount.id),
    });
  };

  const handleEditSubAccount = (ledgerAccount: LedgerAccountApi) =>
    navigate(
      `${paths.LEDGER_ACCOUNTS_SUBACCOUNT}/${pathsBase.EDIT}/${ledgerAccount.id}${getParamsWithAnimation(location.search)}`,
    );

  const LedgerAccountTypeColumns = getLedgerAccountTableColumnsByType();

  const LedgerAccountColumnsWithActions = useTableColumnsWithActions<LedgerAccountApi>(
    LedgerAccountTypeColumns,
    handleEditSubAccount,
    handleDeleteSubAccount,
  );

  return (
    <>
      <SearchBar
        form={searchBarForm}
        searchTerm="subcuenta"
        isLoading={isLoading || isFetching}
        useAbsolutePosition={false}
        submitHandler={handleSearchSubmit}
      />
      <TableWrapper
        columns={LedgerAccountColumnsWithActions}
        data={data}
        isLoading={isLoading}
        isFetching={isFetching}
        rowsLabel={PAGE_TITLE}
        pageCount={data ? data.totalPages : DEFAULT_PAGINATION_PAGE_COUNT}
        pagination={paginationState}
        onPaginationChange={setPagination}
      />
      <AlertDialogWrapper
        {...alertDialog}
        isLoading={isDeleteSubAccountPending}
        onDismiss={handleDismissAlertDialog}
      />
      <Outlet />
    </>
  );
};

export default LedgerAccountSubAccount;
