import { LedgerAccountApi } from '@/api/ledgerAccount/ledgerAccount.model';
import { PaymentApi } from '@/api/payment/payment.model';
import { DownloadFileCell } from '@/components/shared/DownloadFileCell';
import { formatAmount } from '@/lib/utils';
import { ColumnDef } from '@tanstack/react-table';

const paymentsTableColumns: ColumnDef<PaymentApi>[] = [
  {
    accessorKey: 'paymentNumber',
    header: 'Nro. Pago',
  },
  {
    accessorKey: 'paymentDate',
    header: 'Fecha de Pago',
  },
  {
    accessorKey: 'money.currency',
    header: 'Moneda',
  },
  {
    accessorKey: 'money.exchangeRate',
    header: 'Tipo de Cambio',
    cell: ({ row }) => {
      const exchangeRate = row.original.money.exchangeRate.toString();
      return exchangeRate.replace('.', ',');
    },
  },
  {
    accessorKey: 'money.amount',
    header: 'Importe',
    cell: ({ row }) => {
      const amount = row.original.money.amount;
      const currency = row.original.money.currency;
      return formatAmount(amount, currency);
    },
  },
  {
    accessorKey: 'ledgerAccount',
    header: 'Cuenta Contable',
    cell: ({ row }) => {
      const code = row.getValue('ledgerAccount') as LedgerAccountApi;
      return code?.code + ' - ' + code.name || 'N/A';
    },
  },
  {
    accessorKey: 'fileURL',
    header: 'Comprobante',
    cell: ({ row }) => {
      const fileURL = row.original.fileURL;
      return fileURL ? <DownloadFileCell fileUrl={fileURL} /> : '-';
    },
  },
];

export { paymentsTableColumns };
