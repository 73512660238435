import { FC, ReactElement, createContext, useContext } from 'react';
import { S3Client } from '@aws-sdk/client-s3';

interface S3ClientContextProps {
  s3Client: S3Client;
  children?: ReactElement;
}

const S3ClientContext = createContext<S3ClientContextProps | null>(null);

const S3ClientProvider: FC<S3ClientContextProps> = ({ s3Client, children }) => {
  return <S3ClientContext.Provider value={{ s3Client }}>{children}</S3ClientContext.Provider>;
};

const useS3Client = () => {
  const context = useContext(S3ClientContext);
  if (!context) {
    throw new Error('useS3Client must be used within a S3ClientProvider');
  }
  return context.s3Client;
};

export { S3ClientProvider, useS3Client };
