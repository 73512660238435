import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { paths } from '@/routes/paths';
import { SheetLayout } from '@/components/layout/SheetLayout';
import { SubmitHandler, useForm } from 'react-hook-form';
import { SONNER_ERROR_OPTIONS, SONNER_SUCCESS_OPTIONS } from '@/common/constants';
import { toast } from 'sonner';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { useMutation } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import { InvoiceForm } from './components/InvoiceForm';
import { InvoiceFormType, InvoiceSchema, defaultInvoiceFormValues } from './utils/InvoiceSchema';
import { createInvoiceMutationFn, useInvoices } from '@/api/invoice/invoice.api';
import { InvoiceApi } from '@/api/invoice/invoice.model';
import { AxiosError } from 'axios';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorResponse } from '@/api/types';

const PAGE_TITLE = 'Registrar Nueva Factura';

const InvoiceCreate: FC = () => {
  const navigate = useNavigate();

  const form = useForm<InvoiceFormType>({
    defaultValues: defaultInvoiceFormValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(InvoiceSchema),
  });

  const { refetch } = useInvoices();

  const { mutate: createInvoice, isPending: isCreateInvoicePending } = useMutation({
    mutationFn: createInvoiceMutationFn,
    onSuccess: (data: InvoiceApi) => {
      refetch();
      toast(
        <SuccessToastText>
          ¡Factura <span className="text-blue-700">#{data.receiptNumber}</span> creada exitosamente!
        </SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismiss();
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      if (error.response && error.response.data.type === 'DUPLICATED_ERROR') {
        form.setError('receiptNumber', {
          type: 'validate',
          message:
            'El número de factura ingresado ya fue utilizado previamente para el proveedor seleccionado.',
        });
      }
      toast.error('Ha ocurrido un error durante la creación de la Factura.', {
        description: 'Por favor, revisa los datos ingresados e intenta de nuevo.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  const handleCreateInvoice: SubmitHandler<InvoiceFormType> = (formData) => createInvoice(formData);

  const handleDismiss = () => navigate(paths.INVOICES);

  return (
    <>
      <Helmet>
        <title>PEM - {PAGE_TITLE}</title>
      </Helmet>
      <SheetLayout
        contentClassname="p-0 pt-6"
        childrenClassname="flex flex-col flex-1 p-0 pt-8"
        closeContentClassname="px-6"
        onClose={handleDismiss}
      >
        <div className="flex flex-col flex-1 w-full gap-12 mx-auto">
          <div className="w-full max-w-screen-2xl mx-auto px-14">
            <h1 className="text-[40px] font-extralight leading-none tracking-tighter color-foreground">
              {PAGE_TITLE}
            </h1>
            <p className="mt-4 text-base text-muted-foreground font-extralight">
              Utilice este formulario para ingresar los detalles de una nueva factura. Asegúrese de
              completar todos los campos requeridos para facilitar el proceso de contabilidad.
            </p>
          </div>
          <div className="flex flex-1 justify-center mt-4">
            <InvoiceForm
              form={form}
              isLoading={isCreateInvoicePending}
              submitLabel={PAGE_TITLE}
              cancelLabel="Cancelar"
              submitHandler={handleCreateInvoice}
              dismissHandler={handleDismiss}
            />
          </div>
        </div>
      </SheetLayout>
    </>
  );
};

export { InvoiceCreate };
