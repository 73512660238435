import { SheetLayout } from '@/components/layout/SheetLayout';
import { paths } from '@/routes/paths';
import { Helmet } from 'react-helmet';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AccountingEntryFormType,
  AccountingEntrySchema,
  defaultAccountingEntryFormValues,
} from './AccountingEntrySchema';
import { zodResolver } from '@hookform/resolvers/zod';
import AccountingEntryForm from './AccountingEntryForm';
import {
  createAccountingEntriesMutationFn,
  useAccountingEntries,
  useAccountingEntriesByLedgerAccount,
} from '@/api/accountingEntry/accountingEntry.api';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { toast } from 'sonner';
import { SONNER_ERROR_OPTIONS, SONNER_SUCCESS_OPTIONS } from '@/common/constants';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import { AccountingEntry } from '@/api/accountingEntry/accountingEntry.model';
import { useLedgerAccount } from '@/api/ledgerAccount/ledgerAccount.api';
import { useEffect } from 'react';

const PAGE_TITLE = 'Crear Asientos Contables';

type AccountingEntryCreateParams = {
  id: AccountingEntry['id'];
};

const AccountingEntryCreate = () => {
  const { id } = useParams() as AccountingEntryCreateParams;
  const navigate = useNavigate();

  const { data: ledgerAccount } = useLedgerAccount(id);

  const handleDismiss = () => {
    if (id) {
      navigate(`${paths.ACCOUNTS_MAYOR}/${id}`);
    } else {
      navigate(paths.ACCOUNTS_MAYOR);
    }
  };

  const { refetch: refetchAccountingEntries } = useAccountingEntries();
  const { refetch: refetchAccountingEntriesByLedgerAccount } = useAccountingEntriesByLedgerAccount({
    ledgerAccountId: id,
  });

  const refetch = () => {
    if (id) {
      refetchAccountingEntriesByLedgerAccount();
    } else {
      refetchAccountingEntries();
    }
  };

  const { mutate: createAccountingEntries, isPending } = useMutation({
    mutationFn: createAccountingEntriesMutationFn,
    onSuccess: () => {
      refetch();
      toast(
        <SuccessToastText>¡Asientos contables creados exitosamente!</SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismiss();
    },
    onError: (error: AxiosError) => {
      Sentry.captureException(error);
      toast.error('Ha ocurrido un error durante la creación del asiento contable.', {
        description: 'Por favor, revisa los datos ingresados e intenta de nuevo.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  const handleCreateAccountingEntries: SubmitHandler<AccountingEntryFormType> = (formData) =>
    createAccountingEntries(formData);

  const form = useForm<AccountingEntryFormType>({
    defaultValues: defaultAccountingEntryFormValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(AccountingEntrySchema),
  });

  useEffect(() => {
    if (ledgerAccount) {
      form.setValue(`accountingEntries.${0}.ledgerAccount`, ledgerAccount);
    }
  }, [ledgerAccount]);

  return (
    <>
      <Helmet>
        <title>PEM - {PAGE_TITLE}</title>
      </Helmet>
      <SheetLayout onClose={handleDismiss}>
        <div className="max-w-4xl mx-auto">
          <h1 className="mb-4 text-3xl font-semibold tracking-tighter color-foreground">
            {PAGE_TITLE}
          </h1>
          <p className="text-sm text-muted-foreground">
            Completá el siguiente formulario para crear nuevos asientos contables. Asegurate de
            proporcionar información precisa para cada transacción para mantener la integridad de
            los registros financieros.
          </p>
          <div className="mt-12">
            <AccountingEntryForm
              form={form}
              cancelLabel="Cancelar"
              submitLabel={PAGE_TITLE}
              isLoading={isPending}
              dismissHandler={handleDismiss}
              submitHandler={handleCreateAccountingEntries}
              usePrepopulatedAccount={Boolean(id)}
            />
          </div>
        </div>
      </SheetLayout>
    </>
  );
};

export default AccountingEntryCreate;
