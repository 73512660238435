import { FC } from 'react';
import { Skeleton } from '../ui/skeleton';

const DEFAULT_ROWS_COUNT = 5;

interface TableSkeletonProps {
  rows?: number;
  usePagination?: boolean;
}

const TableSkeleton: FC<TableSkeletonProps> = ({
  usePagination = true,
  rows = DEFAULT_ROWS_COUNT,
}) => {
  return (
    <>
      <div className="rounded-md border">
        <Skeleton className="h-[64px] w-full rounded-t-md rounded-b-none bg-slate-100" />
        {Array.from(Array(rows)).map((_, index) => (
          <Skeleton
            key={`skeleton-${index + 1}`}
            className={`h-[64px] w-full ${index === rows - 1 ? 'rounded-md' : 'rounded-none'} ${index % 2 === 0 ? 'bg-slate-100/50' : 'bg-slate-50/50'}`}
          />
        ))}
      </div>
      {usePagination ? (
        <div className="mt-4 flex justify-end">
          <Skeleton className="h-[32px] w-[365px] rounded-md bg-slate-100" />
        </div>
      ) : null}
    </>
  );
};

export default TableSkeleton;
