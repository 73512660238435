import { FC } from 'react';
import {
  DEFAULT_PAGINATION_PAGE_COUNT,
  SONNER_ERROR_OPTIONS,
  SONNER_SUCCESS_OPTIONS,
} from '@/common/constants';
import { useTablePagination } from '@/hooks/useTablePagination';
import useTableColumnsWithActions from '@/hooks/useTableColumnsWithActions';
import { Outlet, useParams } from 'react-router-dom';
import { AlertDialogWrapper } from '@/components/shared/AlertDialog';
import { useMutation } from '@tanstack/react-query';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { AxiosError } from 'axios';
import { toast } from 'sonner';
import { TableWrapper } from '@/components/shared/TableWrapper';
import * as Sentry from '@sentry/react';
import { UUID } from 'crypto';
import {
  deleteEmployeeFundMutationFn,
  useEmployeeFunds,
} from '@/api/employeeFund/employeeFund.api';
import { EmployeeFund } from '@/api/employeeFund/employeeFund.model';
import { ExpenseReportFundTableColumns } from '../utils/ExpenseReportFundTableColumns';
import { useAlertDialog } from '@/hooks/useAlertDialog';

const PAGE_TITLE = 'Anticipos';

const ExpenseReportFund: FC = () => {
  const { id } = useParams() as { id: UUID };

  const { pageIndex, pageSize, paginationState, setPagination } = useTablePagination();

  const { alertDialog, setAlertDialog, handleDismissAlertDialog } = useAlertDialog();

  const { data, isLoading, refetch } = useEmployeeFunds({
    page: pageIndex,
    size: pageSize,
    employeeId: id,
  });

  const { mutate: deleteEmployeeFund, isPending: isDeleteEmployeeFundsPending } = useMutation({
    mutationFn: deleteEmployeeFundMutationFn,
    onSuccess: () => {
      refetch();
      toast(
        <SuccessToastText>Anticipo eliminado exitosamente</SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismissAlertDialog();
    },
    onError: (error: AxiosError) => {
      Sentry.captureException(error);
      toast.error('Ha ocurrido un error durante la eliminación del Anticipo.', {
        description: 'Por favor, intentalo nuevamente.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  const handleDeleteEmployeeFund = (employeeFund: EmployeeFund) => {
    setAlertDialog({
      title: `¿Estás seguro que queres eliminar este Anticipo? `,
      description: `Esta acción no puede ser deshecha y se perderá toda la información asociada a este Anticipo.`,
      open: true,
      onCancel: handleDismissAlertDialog,
      onConfirm: () => deleteEmployeeFund(employeeFund.id!),
    });
  };

  const ExpenseReportFundColumnsWithActions = useTableColumnsWithActions<EmployeeFund>(
    ExpenseReportFundTableColumns,
    () => {},
    handleDeleteEmployeeFund,
    true,
  );

  return (
    <>
      <TableWrapper
        columns={ExpenseReportFundColumnsWithActions}
        data={data ? data[0] : data}
        isLoading={isLoading}
        rowsLabel={PAGE_TITLE}
        pageCount={data ? data[0].totalPages : DEFAULT_PAGINATION_PAGE_COUNT}
        pagination={paginationState}
        onPaginationChange={setPagination}
      />
      <AlertDialogWrapper
        {...alertDialog}
        isLoading={isDeleteEmployeeFundsPending}
        onDismiss={handleDismissAlertDialog}
      />
      <Outlet />
    </>
  );
};

export default ExpenseReportFund;
