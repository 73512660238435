import { Separator } from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';

const DonationFormSkeleton = () => (
  <div className="mx-auto px-14 max-w-[1000px]">
    <div className="flex flex-col">
      <div className="flex justify-between">
        <div className="flex items-center gap-6">
          <Skeleton className="w-80 h-10" />
          <Skeleton className="w-32 h-10" />
        </div>
        <div className="flex items-center gap-6">
          <Skeleton className="w-32 h-10" />
          <Skeleton className="w-32 h-10" />
        </div>
      </div>
      <Separator className="mt-8" />
    </div>
    <div className={'flex flex-col mt-16 mb-[60px]'}>
      <div className="flex flex-col gap-[50px]">
        <div className="flex gap-20">
          <div className="flex flex-col w-full gap-10">
            <div className="flex flex-row gap-6 w-full">
              <Skeleton className="w-full h-10" />
              <Skeleton className="w-full h-10" />
              <Skeleton className="w-full h-10" />
            </div>
            <div className="flex flex-row gap-6 w-full">
              <Skeleton className="w-full h-10" />
              <Skeleton className="w-full h-10" />
              <Skeleton className="w-full h-10" />
            </div>
            <Skeleton className="w-full h-20" />
            <Separator />
          </div>
        </div>
        <div className="flex flex-row gap-6 w-full">
          <Skeleton className="w-full h-10" />
          <Skeleton className="w-full h-10" />
          <Skeleton className="w-full h-10" />
          <Skeleton className="w-full h-10" />
        </div>
        <Separator />
        <div className="flex flex-row gap-6 w-full">
          <Skeleton className="w-1/4 h-10" />
        </div>
        <Separator />
        <div className="flex flex-row gap-6 w-full">
          <Skeleton className="w-1/4 h-10" />
        </div>
        <Separator />
        <div className="flex flex-row gap-6 w-full">
          <Skeleton className="w-full h-28 bg-transparent border">
            <div className="flex h-full items-center justify-between">
              <Skeleton className="w-1/4 h-10 ml-6" />
              <Skeleton className="w-1/6 h-10 mr-6" />
            </div>
          </Skeleton>
        </div>
      </div>
    </div>
  </div>
);

export { DonationFormSkeleton };
