import { MutationFunction, keepPreviousData, useQuery } from '@tanstack/react-query';
import { QueryRequestParams, ResponsePaginated } from '../types';
import {
  DEFAULT_PAGINATION_DIR,
  DEFAULT_PAGINATION_PAGE_INDEX,
  DEFAULT_PAGINATION_PAGE_SEARCH,
  DEFAULT_PAGINATION_PAGE_SIZE,
  DEFAULT_PAGINATION_SORT,
} from '@/common/constants';
import { CRUDService } from '../CRUDService.api';
import {
  LedgerAccount,
  LedgerAccountApi,
  LedgerAccountList,
  LedgerAccountTransformResponse,
  LedgerAccountType,
} from './ledgerAccount.model';
import { LedgerAccountFormType } from '@/pages/LedgerAccounts/utils/LedgerAccountSchema';
import httpClient from '../httpClient';
import * as Sentry from '@sentry/react';
import { UUID } from 'crypto';
import { getPreviousLevel } from '@/pages/LedgerAccounts/utils/LedgerAccountUtils';

const LEDGER_ACCOUNT_BASE_PATH = '/ledgerAccount';
const LEDGER_ACCOUNT_KEY = 'ledgerAccount';
const LEDGER_ACCOUNT_STALE_TIME = 5000;

const transformLedgerAccounts = (
  response: ResponsePaginated<LedgerAccountApi>,
): LedgerAccountTransformResponse => {
  return {
    ...response,
    content: response.content.map((category) => {
      return {
        ...category,
        concepts: category.children,
      };
    }),
  };
};

const transformLedgerAccountList = (
  response: Array<LedgerAccountApi>,
): Array<LedgerAccountList> => {
  return response.map((ledgerAccount) => {
    return {
      id: ledgerAccount.id,
      name: `${ledgerAccount.code}. ${ledgerAccount.name}`,
      code: ledgerAccount.code,
      value: ledgerAccount.name,
      children: ledgerAccount.children,
    };
  });
};

const transformCreateLedgerAccount = (data: LedgerAccountFormType) => {
  return {
    name: data.parent.name.replace(`${data.parent.code}. `, ''),
    code: data.parent.code,
    type: getPreviousLevel(data.type as LedgerAccountType),
    concepts: [
      ...data.parent.children,
      {
        name: data.name,
        code: data.code,
        type: data.type,
        concepts: [],
      },
    ],
  };
};

const transformEditLedgerAccount = (
  data: LedgerAccountFormType,
  concepts: LedgerAccount['children'],
) => {
  return {
    name: data.name,
    code: data.code,
    type: data.type,
    concepts: concepts,
  };
};

class LedgerAccountService extends CRUDService<LedgerAccountApi, unknown> {
  constructor() {
    super(LEDGER_ACCOUNT_BASE_PATH);
  }

  // Add here the methods that are specific to this service
  async getParents(params?: string) {
    try {
      const response = await httpClient.get(
        params
          ? `${LEDGER_ACCOUNT_BASE_PATH}/parents?${params}`
          : `${LEDGER_ACCOUNT_BASE_PATH}/parents`,
      );
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async getlist() {
    try {
      const response = await httpClient.get(`${LEDGER_ACCOUNT_BASE_PATH}/list`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async getChildrens() {
    try {
      const response = await httpClient.get(`${LEDGER_ACCOUNT_BASE_PATH}/children`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async getPaginatedListByType(type: LedgerAccountType, params?: string) {
    try {
      const response = await httpClient.get(
        params
          ? `${LEDGER_ACCOUNT_BASE_PATH}/page/type/${type}?${params}`
          : `${LEDGER_ACCOUNT_BASE_PATH}/page/type/${type}`,
      );
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async getByType(type: LedgerAccountType) {
    try {
      const response = await httpClient.get(`${LEDGER_ACCOUNT_BASE_PATH}/type/${type}`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }
}

const ledgerAccountService = new LedgerAccountService();

const useLedgerAccounts = ({
  page = DEFAULT_PAGINATION_PAGE_INDEX,
  size = DEFAULT_PAGINATION_PAGE_SIZE,
  sort = DEFAULT_PAGINATION_SORT,
  dir = DEFAULT_PAGINATION_DIR,
}: QueryRequestParams<LedgerAccountApi> = {}) => {
  const queryParams = `page=${page}&size=${size}&sort=${sort}&dir=${dir}`;

  return useQuery<ResponsePaginated<LedgerAccountApi>, Error, LedgerAccountTransformResponse>({
    queryKey: [LEDGER_ACCOUNT_KEY, { page, size }],
    queryFn: async () => {
      const response = await ledgerAccountService.getParents(queryParams);
      return response as LedgerAccountTransformResponse;
    },
    select: transformLedgerAccounts,
    placeholderData: keepPreviousData,
    staleTime: LEDGER_ACCOUNT_STALE_TIME,
  });
};

const useLedgerAccount = (id: UUID) => {
  return useQuery<LedgerAccountApi, Error, LedgerAccount>({
    queryKey: [`${LEDGER_ACCOUNT_KEY}-edit`, { id }],
    queryFn: async () => {
      const response = await ledgerAccountService.get(id);
      return response as LedgerAccount;
    },
    enabled: Boolean(id),
    placeholderData: keepPreviousData,
    staleTime: LEDGER_ACCOUNT_STALE_TIME,
    retry: 1,
  });
};

const useLedgerAccountsList = () => {
  return useQuery<Array<LedgerAccountApi>, Error, Array<LedgerAccountList>>({
    queryKey: [LEDGER_ACCOUNT_KEY],
    queryFn: async () => {
      const response = await ledgerAccountService.getlist();
      return response as Array<LedgerAccountApi>;
    },
    select: transformLedgerAccountList,
    placeholderData: keepPreviousData,
    staleTime: LEDGER_ACCOUNT_STALE_TIME,
  });
};

const useLedgerAccountChildrens = () => {
  return useQuery<Array<LedgerAccountApi>, Error, Array<LedgerAccountList>>({
    queryKey: [LEDGER_ACCOUNT_KEY, 'children'],
    queryFn: async () => {
      const response = await ledgerAccountService.getChildrens();
      return response as Array<LedgerAccountApi>;
    },
    select: transformLedgerAccountList,
    placeholderData: keepPreviousData,
    staleTime: LEDGER_ACCOUNT_STALE_TIME,
  });
};

const useLedgerAccountByType = ({
  page = DEFAULT_PAGINATION_PAGE_INDEX,
  size = DEFAULT_PAGINATION_PAGE_SIZE,
  sort = DEFAULT_PAGINATION_SORT,
  dir = DEFAULT_PAGINATION_DIR,
  search = DEFAULT_PAGINATION_PAGE_SEARCH,
  type,
}: QueryRequestParams<LedgerAccountApi> & { type: LedgerAccountType }) => {
  const queryParams = `page=${page}&size=${size}&sort=${sort}&dir=${dir}&query=${search}`;

  return useQuery<ResponsePaginated<LedgerAccountApi>, Error, LedgerAccountTransformResponse>({
    queryKey: [`${LEDGER_ACCOUNT_KEY}-type`, { page, size, type, search }],
    queryFn: async () => {
      const response = await ledgerAccountService.getPaginatedListByType(type, queryParams);
      return response as LedgerAccountTransformResponse;
    },
    select: transformLedgerAccounts,
    placeholderData: keepPreviousData,
    staleTime: LEDGER_ACCOUNT_STALE_TIME,
  });
};

const useLedgerAccountByTypeList = (type: LedgerAccountType) => {
  return useQuery<Array<LedgerAccountApi>, Error, Array<LedgerAccountList>>({
    queryKey: [`${LEDGER_ACCOUNT_KEY}-type`, { type }],
    queryFn: async () => {
      const response = await ledgerAccountService.getByType(type);
      return response as Array<LedgerAccountApi>;
    },
    select: transformLedgerAccountList,
    placeholderData: keepPreviousData,
    staleTime: LEDGER_ACCOUNT_STALE_TIME,
  });
};

const createLedgerAccountMutationFn = async (data: LedgerAccountFormType) => {
  const transformedData = transformCreateLedgerAccount(data);
  return (await ledgerAccountService.edit(data.parent.id, transformedData)) as LedgerAccountApi;
};

const editLedgerAccountMutationFn: MutationFunction<
  LedgerAccountApi,
  [LedgerAccount, LedgerAccountFormType]
> = async (params) => {
  const [ledgerAccount, data] = params;
  const transformedData = transformEditLedgerAccount(data, ledgerAccount.children);
  return (await ledgerAccountService.edit(ledgerAccount.id, transformedData)) as LedgerAccountApi;
};

const deleteLedgerAccountMutationFn = async (id: LedgerAccountApi['id']) => {
  return await ledgerAccountService.delete(id);
};

export {
  LEDGER_ACCOUNT_KEY,
  useLedgerAccounts,
  useLedgerAccount,
  useLedgerAccountsList,
  useLedgerAccountChildrens,
  useLedgerAccountByType,
  useLedgerAccountByTypeList,
  createLedgerAccountMutationFn,
  editLedgerAccountMutationFn,
  deleteLedgerAccountMutationFn,
};
