import React from 'react';
import Navbar from './Navbar';
import ResponsiveBanner from './ResponsiveBanner';
import TableSkeleton from '../shared/TableSkeleton';
import { PageContent } from '../shared/PageContent';
import { Heading } from '../shared/Heading';
import { useScreenDetector } from '@/hooks/useScreenDetector';

interface LayoutProps {
  children?: React.ReactNode;
  isLoading?: boolean;
}

const LayoutContentSkeleton = () => (
  <>
    <Heading title="Loading" isLoading />
    <PageContent>
      <TableSkeleton />
    </PageContent>
  </>
);

const Layout: React.FC<LayoutProps> = ({ children, isLoading }) => {
  const { isMobile } = useScreenDetector();

  if (isMobile) {
    return <ResponsiveBanner />;
  }

  return (
    <div className="flex flex-col flex-1">
      <Navbar isLoading={isLoading} />
      <main className="flex-1 overflow-y-auto">
        {isLoading ? <LayoutContentSkeleton /> : (children as React.ReactElement)}
      </main>
    </div>
  );
};

export default Layout;
