import {
  deleteInvoiceMutationFn,
  editInvoiceMutationFn,
  publishInvoiceMutationFn,
  useInvoice,
  useInvoices,
} from '@/api/invoice/invoice.api';
import { Expense } from '@/api/transaction/transaction.model';
import { SheetLayout } from '@/components/layout/SheetLayout';
import { InvoiceForm } from './components/InvoiceForm';
import {
  defaultInvoiceFormValues,
  InvoiceFormType,
  InvoiceSchema,
} from '@/pages/Invoices/utils/InvoiceSchema';
import { paths, pathsBase } from '@/routes/paths';
import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { generateInvoiceForm } from './utils/InvoicesUtils';
import { Separator } from '@/components/ui/separator';
import { Invoice, InvoiceApi, InvoiceState, PaymentState } from '@/api/invoice/invoice.model';
import { Button } from '@/components/ui/button';
import { useMutation } from '@tanstack/react-query';
import {
  SONNER_ERROR_OPTIONS,
  SONNER_SUCCESS_OPTIONS,
  UNSAVED_CHANGES_ALERT_DIALOG_CONFIG,
} from '@/common/constants';
import { toast } from 'sonner';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { useTablePagination } from '@/hooks/useTablePagination';
import { AlertDialogWrapper } from '@/components/shared/AlertDialog';
import { AxiosError } from 'axios';
import { ErrorResponse } from '@/api/types';
import useUserStore from '@/store/useUserStore.ts';
import { UserRole } from '@/api/user/user.model.ts';
import { InvoiceBadge } from '@/pages/Invoices/components/InvoiceBadge.tsx';
import { InvoiceFormSkeleton } from './components/InvoiceFormSkeleton';
import { useAlertDialog } from '@/hooks/useAlertDialog';
import { getParamsWithAnimation } from '@/lib/utils';

type InvoiceEditParams = {
  id: Expense['id'];
};

const PAGE_TITLE = 'Editar Factura';

const InvoiceDetails: FC = () => {
  const { id } = useParams() as InvoiceEditParams;

  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useUserStore();

  const { pageIndex, pageSize } = useTablePagination();

  const { alertDialog, setAlertDialog, handleDismissAlertDialog } = useAlertDialog();

  const {
    data: invoice,
    isLoading: isLoadingInvoice,
    error,
    refetch: refetchInvoice,
  } = useInvoice(id);

  const { refetch: refetchInvoices } = useInvoices({
    page: pageIndex,
    size: pageSize,
  });

  const form = useForm<InvoiceFormType>({
    defaultValues: defaultInvoiceFormValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(InvoiceSchema),
  });

  const dirty = useMemo(() => {
    return invoice?.paymentState === PaymentState.PAYED ? false : form.formState.isDirty;
  }, [form.formState.isDirty]);

  useEffect(() => {
    if (invoice) {
      form.reset(generateInvoiceForm(invoice));
    }
  }, [invoice]);

  const { mutate: deleteInvoice, isPending: isDeleteInvoicePending } = useMutation({
    mutationFn: deleteInvoiceMutationFn,
    onSuccess: () => {
      refetchInvoices();
      toast(
        <SuccessToastText>
          ¡Factura{' '}
          {invoice ? <span className="text-blue-700">#{invoice.receiptNumber}</span> : null}{' '}
          eliminada exitosamente!
        </SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismissAlertDialog();
      handleDismiss();
    },
    onError: () => {
      toast.error('Ha ocurrido un error durante la eliminación de la Factura.', {
        description: 'Por favor, intentalo nuevamente.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  const { mutate: publishInvoice, isPending: isPublishInvoicePending } = useMutation({
    mutationFn: publishInvoiceMutationFn,
    onSuccess: () => {
      refetchInvoice();
      refetchInvoices();
      toast(
        <SuccessToastText>
          ¡Factura <span className="text-blue-700">#{form.watch('receiptNumber')}</span> publicada
          exitosamente!
        </SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismissAlertDialog();
    },
    onError: () => {
      toast.error('Ha ocurrido un error durante la publicación de la Factura.', {
        description: 'Por favor, intentalo nuevamente.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  const { mutate: editInvoice, isPending: isEditInvoicePending } = useMutation({
    mutationFn: editInvoiceMutationFn,
    onSuccess: (data: InvoiceApi) => {
      refetchInvoice();
      refetchInvoices();
      toast(
        <SuccessToastText>
          ¡Factura <span className="text-blue-700">#{data.receiptNumber}</span> editada
          exitosamente!
        </SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      console.log('error', error);
      if (error.response && error.response.data.type === 'DUPLICATED_ERROR') {
        toast.error('Ha ocurrido un error durante la edición de la Factura.', {
          description:
            'El número ingresado ya fue utilizada previamente. Intentá nuevamente con uno número',
          ...SONNER_ERROR_OPTIONS,
        });
        form.setError('receiptNumber', {
          type: 'validate',
          message: 'Número ya utilizado',
        });
      } else {
        toast.error('Ha ocurrido un error durante la edición de la Factura.', {
          description: 'Por favor, revisa los datos ingresados e intenta de nuevo.',
          ...SONNER_ERROR_OPTIONS,
        });
      }
    },
  });

  const handleEditInvoice: SubmitHandler<InvoiceFormType> = (formData) => {
    if (invoice) {
      editInvoice([invoice.id, formData]);
    }
  };

  const handleDeleteInvoice = (invoice: InvoiceApi) => {
    setAlertDialog({
      title: `¿Estás seguro que queres eliminar la factura "#${invoice.receiptNumber}"? `,
      description: `Esta acción no puede ser deshecha y se perderá toda la información asociada a esta Factura: ("#${invoice.receiptNumber}"). `,
      open: true,
      confirmLabel: 'Eliminar Factura',
      onCancel: handleDismissAlertDialog,
      onConfirm: () => (invoice.id ? deleteInvoice(invoice.id) : {}),
    });
  };

  const handlePublishInvoice = (invoice: InvoiceApi) => {
    setAlertDialog({
      title: `¿Estás seguro que queres publicar la factura #${invoice.receiptNumber}"? `,
      description: `Esta acción no puede ser deshecha y ya no será posible la edición de la información asociada a esta Factura (“#${invoice.receiptNumber}”). `,
      open: true,
      confirmLabel: 'Publicar Factura',
      mainButtonVariant: 'primary',
      onCancel: handleDismissAlertDialog,
      onConfirm: () => publishInvoice(`${invoice.id}` as Invoice['id']),
    });
  };

  const handleDismiss = () => {
    if (dirty) {
      setAlertDialog({
        ...UNSAVED_CHANGES_ALERT_DIALOG_CONFIG,
        confirmLabel: 'Continuar sin guardar',
        onCancel: handleDismissAlertDialog,
        onConfirm: handleInvoiceNavigate,
      });
    } else {
      handleInvoiceNavigate();
    }
  };
  const handleCreateCreditNote = () => {
    if (dirty) {
      setAlertDialog({
        ...UNSAVED_CHANGES_ALERT_DIALOG_CONFIG,
        confirmLabel: 'Crear Nota de Crédito',
        onCancel: handleDismissAlertDialog,
        onConfirm: handleCreditNoteNavigate,
      });
    } else {
      handleCreditNoteNavigate();
    }
  };

  const handleCreatePayment = () => {
    if (dirty) {
      setAlertDialog({
        ...UNSAVED_CHANGES_ALERT_DIALOG_CONFIG,
        confirmLabel: 'Registrar Pago',
        onCancel: handleDismissAlertDialog,
        onConfirm: handlePaymentNavigate,
      });
    } else {
      handlePaymentNavigate();
    }
  };

  const handleInvoiceNavigate = () => {
    navigate(
      `${paths.INVOICES}${getParamsWithAnimation(location.search, true).replace('invoicesPageIndex', 'pageIndex')}`,
    );
  };

  const handleCreditNoteNavigate = () => {
    if (invoice) {
      navigate(
        `${paths.INVOICES}/${invoice.id}/${pathsBase.CREDIT_NOTE}${getParamsWithAnimation(location.search)}`,
      );
    }
  };

  const handlePaymentNavigate = () => {
    if (invoice) {
      navigate(
        `/${pathsBase.EXPENSES}/${pathsBase.INVOICES}/${invoice.id}/${pathsBase.PAY}${getParamsWithAnimation(location.search)}`,
      );
    }
  };

  if (error) {
    return <Navigate to="*" replace={true} />;
  }

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
      </Helmet>
      <SheetLayout
        contentClassname="p-0 pt-6"
        childrenClassname="flex flex-col flex-1 p-0 pt-8"
        closeContentClassname="px-6"
        onClose={handleInvoiceNavigate}
      >
        {isLoadingInvoice || !invoice ? (
          <InvoiceFormSkeleton />
        ) : (
          <>
            <div className="flex flex-col w-full max-w-screen-2xl mx-auto px-14">
              <div className="flex justify-between">
                <div className="flex gap-6">
                  <h1 className="text-4xl font-light tracking-tighter color-foreground">
                    Factura: <span className="font-medium">#{invoice.receiptNumber}</span>
                  </h1>
                  <InvoiceBadge invoice={invoice} />
                </div>
                {invoice.invoiceState === InvoiceState.DRAFT && user?.role == UserRole.ADMIN ? (
                  <div>
                    <Button
                      variant="outlineDestructive"
                      className="mr-4"
                      onClick={() => handleDeleteInvoice(invoice)}
                    >
                      Eliminar Factura
                    </Button>
                    <Button variant="default" onClick={() => handlePublishInvoice(invoice)}>
                      Publicar Factura
                    </Button>
                  </div>
                ) : invoice.invoiceState !== InvoiceState.DRAFT &&
                  invoice.paymentState === PaymentState.PENDING ? (
                  <div>
                    <Button variant="default" className="mr-4" onClick={handleCreatePayment}>
                      Registrar Pago
                    </Button>
                    <Button variant="outlinePrimary" onClick={handleCreateCreditNote}>
                      Nota de Crédito
                    </Button>
                  </div>
                ) : null}
              </div>
              <Separator className="mt-8" />
            </div>
            <div className="flex flex-1 justify-center mt-16">
              <InvoiceForm
                form={form}
                invoice={invoice}
                submitLabel="Guardar Cambios"
                cancelLabel={dirty ? 'Cancelar' : 'Salir'}
                isLoading={isLoadingInvoice || isEditInvoicePending}
                submitHandler={handleEditInvoice}
                dismissHandler={handleDismiss}
                allowPayments={invoice.invoiceState !== InvoiceState.DRAFT}
                readonly={
                  (invoice.invoiceState !== InvoiceState.DRAFT && user?.role !== UserRole.ADMIN) ||
                  invoice.paymentState !== PaymentState.PENDING
                }
                onEdit
              />
            </div>
            <AlertDialogWrapper
              {...alertDialog}
              isLoading={isDeleteInvoicePending || isPublishInvoicePending}
              onDismiss={handleDismissAlertDialog}
            />
          </>
        )}
      </SheetLayout>
    </>
  );
};

export default InvoiceDetails;
export type { InvoiceEditParams };
