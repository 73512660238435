import { MutationFunction, keepPreviousData, useQuery } from '@tanstack/react-query';
import {
  DEFAULT_PAGINATION_DIR,
  DEFAULT_PAGINATION_PAGE_INDEX,
  DEFAULT_PAGINATION_PAGE_SIZE,
  DEFAULT_PAGINATION_SORT,
  DEFAULT_PROJECT_OPTION,
} from '@/common/constants';
import { ResponsePaginated, TerritoryQueryParams } from '../types';
import { Territory, TerritoryApi, TerritoryListItem } from './territory.model';
import { TerritoryFormType } from '@/pages/Territories/utils/TerritorySchema';
import { CRUDService } from '../CRUDService.api';
import httpClient from '../httpClient';
import * as Sentry from '@sentry/react';
import { UUID } from 'crypto';

const TERRITORY_BASE_PATH = '/territory';
const TERRITORY_KEY = 'territory';
const TERRITORY_STALE_TIME = 5000;

const transformTerritoriesList = (response: Array<TerritoryApi>): Array<TerritoryListItem> => {
  return response.map((project) => {
    return {
      id: project.id,
      name: project.name,
      value: project.id,
      code: project.code,
    };
  });
};

class TerritoryService extends CRUDService<TerritoryApi, TerritoryFormType> {
  constructor() {
    super(TERRITORY_BASE_PATH);
  }

  async getList() {
    try {
      const response = await httpClient.get(`${TERRITORY_BASE_PATH}/list`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }
}

const territoryService = new TerritoryService();

const useTerritories = ({
  page = DEFAULT_PAGINATION_PAGE_INDEX,
  size = DEFAULT_PAGINATION_PAGE_SIZE,
  sort = DEFAULT_PAGINATION_SORT,
  dir = DEFAULT_PAGINATION_DIR,
  projects = DEFAULT_PROJECT_OPTION,
}: TerritoryQueryParams<Territory> = {}) => {
  const queryParams =
    `page=${page}&size=${size}&sort=${sort}&dir=${dir}` + (projects ? `&projects=${projects}` : '');

  return useQuery<ResponsePaginated<Territory>, Error>({
    queryKey: [TERRITORY_KEY, { page, size, dir, sort, projects }],
    queryFn: async () => {
      const response = await territoryService.getAll(queryParams);
      return response as ResponsePaginated<TerritoryApi>;
    },
    placeholderData: keepPreviousData,
    staleTime: TERRITORY_STALE_TIME,
  });
};

const useTerritory = (id: UUID) => {
  return useQuery<TerritoryApi, Error, TerritoryApi>({
    queryKey: [`${TERRITORY_KEY}-edit`, { id }],
    queryFn: async () => {
      const response = await territoryService.get(id);
      return response as TerritoryApi;
    },
    placeholderData: keepPreviousData,
    staleTime: TERRITORY_STALE_TIME,
    retry: 1,
  });
};

const useTerritoriesList = () => {
  return useQuery<Array<TerritoryApi>, Error, Array<TerritoryListItem>>({
    queryKey: [`${TERRITORY_KEY}-list`],
    queryFn: async () => {
      const response = await territoryService.getList();
      return response as Array<TerritoryApi>;
    },
    select: transformTerritoriesList,
    placeholderData: keepPreviousData,
    staleTime: TERRITORY_STALE_TIME,
  });
};

const createTerritoryMutationFn = async (data: TerritoryFormType) => {
  return await territoryService.create(data);
};

const editTerritoryMutationFn: MutationFunction<
  TerritoryApi,
  [TerritoryApi['id'], TerritoryFormType]
> = async (params) => {
  const [id, data] = params;
  return (await territoryService.edit(id, data)) as TerritoryApi;
};

const deleteTerritoryMutationFn = async (id: TerritoryApi['id']) => {
  return await territoryService.delete(id);
};

export {
  TERRITORY_KEY,
  useTerritories,
  useTerritory,
  createTerritoryMutationFn,
  editTerritoryMutationFn,
  deleteTerritoryMutationFn,
  useTerritoriesList,
};
