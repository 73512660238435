import { FC } from 'react';
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { FiscalSponsorFormType, FiscalSponsorSchema } from '../utils/FiscalSponsorSchema';
import { getRequiredLabel } from '@/lib/utils';

interface FiscalSponsorFormProps {
  form: UseFormReturn<FiscalSponsorFormType, unknown>;
  submitLabel: string;
  cancelLabel: string;
  isLoading?: boolean;
  submitHandler: SubmitHandler<FiscalSponsorFormType>;
  dismissHandler: () => void;
}

const FiscalSponsorForm: FC<FiscalSponsorFormProps> = ({
  form,
  submitLabel,
  cancelLabel,
  isLoading = false,
  dismissHandler,
  submitHandler,
}) => {
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(submitHandler)} className="flex flex-col gap-8">
        <div className="flex flex-col gap-4">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{getRequiredLabel(FiscalSponsorSchema, 'name', 'Nombre')}</FormLabel>
                <FormControl>
                  <Input {...field} type="name" placeholder="Ingresar nombre" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{getRequiredLabel(FiscalSponsorSchema, 'email', 'Email')}</FormLabel>
                <FormControl>
                  <Input {...field} type="email" placeholder="Ingresar email" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex flex-row justify-end gap-2">
          <Button type="button" variant="outline" disabled={isLoading} onClick={dismissHandler}>
            {cancelLabel}
          </Button>
          <Button
            type="submit"
            disabled={isLoading || !form.formState.isValid}
            className="relative"
            isLoading={isLoading}
          >
            {submitLabel}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default FiscalSponsorForm;
