import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { CostCenterFormType, CostCenterSchema } from './utils/CostCenterSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import DialogWrapper from '@/components/shared/DialogWrapper';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'sonner';
import { paths } from '@/routes/paths';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { SONNER_SUCCESS_OPTIONS, SONNER_ERROR_OPTIONS } from '@/common/constants';
import * as Sentry from '@sentry/react';
import {
  editCostCenterMutationFn,
  useCostCenter,
  useCostCenters,
} from '@/api/costCenter/costCenter.api';
import { CostCenterApi } from '@/api/costCenter/costCenter.model';
import { CostCentersForm } from './components/CostCentersForm';
import { EditFormSkeleton } from '@/components/shared/EditFormSkeleton';
import { ErrorResponse } from '@/api/types';
import { UUID } from 'crypto';
import { getParamsWithAnimation } from '@/lib/utils';

type CostCentersEditParams = {
  id: CostCenterApi['id'];
};

const CostCentersEdit: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams() as CostCentersEditParams;
  const {
    data: costCenter,
    isLoading: isCostCenterLoading,
    refetch: refetchCostCenter,
    error,
  } = useCostCenter(id);
  const { refetch: refetchCostCenters } = useCostCenters();
  const editDefaultValues = {
    code: costCenter?.code,
    project: costCenter?.project,
    territory: costCenter?.territory,
  };

  const form = useForm<CostCenterFormType>({
    defaultValues: editDefaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(CostCenterSchema),
  });

  const { mutate: editCostCenter, isPending } = useMutation<
    CostCenterApi,
    AxiosError<ErrorResponse>,
    [UUID, CostCenterFormType]
  >({
    mutationFn: editCostCenterMutationFn,
    onSuccess: (data: CostCenterApi) => {
      refetchCostCenters();
      toast(
        <SuccessToastText>
          ¡Centro de Costos <span className="text-blue-700">{data.code}</span> fue editado
          exitosamente!
        </SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismiss();
      refetchCostCenter();
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      Sentry.captureException(error);

      if (error.response && error.response.data.type === 'DUPLICATED_ERROR') {
        form.setError('code', {
          type: 'validate',
          message:
            'El código ingresado ya fue utilizado previamente. Intentá nuevamente con un código personalizado.',
        });
      } else {
        toast.error('Ha ocurrido un error durante la edición del Proyecto.', {
          description: 'Por favor, revisa los datos ingresados e intentá de nuevo.',
          ...SONNER_ERROR_OPTIONS,
        });
      }
    },
  });

  useEffect(() => {
    form.reset(editDefaultValues);
  }, [costCenter]);

  if (error) {
    return <Navigate to="*" replace={true} />;
  }

  const handleDismiss = () =>
    navigate(`${paths.COST_CENTERS}${getParamsWithAnimation(location.search, true)}`);

  const handleEditCostCenter: SubmitHandler<CostCenterFormType> = (formData) => {
    if (costCenter) {
      editCostCenter([costCenter.id, formData]);
    } else {
      toast.error('Lo sentimos, no tenés ningún Centro de Costos seleccionado.', {
        description: 'Por favor, seleccioná un Centro de Costos e intentá de nuevo.',
        ...SONNER_ERROR_OPTIONS,
      });
      handleDismiss();
    }
  };

  return (
    <DialogWrapper
      title="Editar Centro de Costos"
      subtitle="Un Centro de Costos es una combinación entre un Proyecto y un Territorio. Una vez creado, podrá ser asociado a una donación."
      className="w-[425px]"
      onDismiss={handleDismiss}
      onOverlayClick={handleDismiss}
    >
      {isCostCenterLoading || !costCenter ? (
        <EditFormSkeleton inputRows={4} />
      ) : (
        <CostCentersForm
          form={form}
          submitLabel="Editar Centro de Costos"
          cancelLabel="Cancelar"
          isLoading={isPending}
          submitHandler={handleEditCostCenter}
          dismissHandler={handleDismiss}
          isModify
        />
      )}
    </DialogWrapper>
  );
};

export { CostCentersEdit };
