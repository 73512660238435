import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { pathsBase } from '@/routes/paths';
import { SheetLayout } from '@/components/layout/SheetLayout';
import { Helmet } from 'react-helmet';
import useExpenseReportStore from '@/store/useExpenseReportStore';

const PAGE_TITLE = 'Rendir Gasto';

const ExpenseReportExpensesCreate: FC = () => {
  const navigate = useNavigate();
  const { expenseReport } = useExpenseReportStore();

  /* 
  const form = useForm<ExpenseFormType>({
    defaultValues: defaultExpenseFormValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(ExpenseSchema),
  });

  const { refetch } = useEmployeeExpenses({ employeeId: expenseReport.id });

  const { mutate: createExpense, isPending } = useMutation({
    mutationFn: createExpenseMutationFn,
    onSuccess: () => {
      refetch();
      toast(
        <SuccessToastText>¡Gasto creado exitosamente!</SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismiss();
    },
    onError: () => {
      toast.error('Ha ocurrido un error durante la creación del Gasto.', {
        description: 'Por favor, revisa los datos ingresados e intenta de nuevo.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  }); */

  //const handleCreateExpense: SubmitHandler<ExpenseFormType> = (formData) => createExpense(formData);

  const handleDismiss = () =>
    navigate(`/${pathsBase.EXPENSES}/${pathsBase.EXPENSE_REPORTS}/${expenseReport.id}`);

  return (
    <>
      <Helmet>
        <title>PEM - {PAGE_TITLE}</title>
      </Helmet>
      <SheetLayout onClose={handleDismiss}>
        <div className="mx-auto max-w-screen-2xl">
          <h1 className="text-4xl font-light tracking-tighter color-foreground">{PAGE_TITLE}</h1>
          <div className="mt-4">
            {/*
             * Form removed for invoice rework
             */}
          </div>
        </div>
      </SheetLayout>
    </>
  );
};

export { ExpenseReportExpensesCreate };
