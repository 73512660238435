import { MutationFunction, keepPreviousData, useQuery } from '@tanstack/react-query';
import { QueryRequestParams, ResponsePaginated } from '../types';
import {
  DEFAULT_PAGINATION_DIR,
  DEFAULT_PAGINATION_PAGE_INDEX,
  DEFAULT_PAGINATION_PAGE_SIZE,
  DEFAULT_PAGINATION_SORT,
} from '@/common/constants';
import { CRUDService } from '../CRUDService.api';
import { ExpenseReport, ExpenseReportApi } from './expenseReport.model';
import { ExpenseReportFormType } from '@/pages/ExpenseReports/utils/ExpenseReportSchema';
import { UUID } from 'crypto';

const EXPENSE_REPORTS_BASE_PATH = '/employee';
const EXPENSE_REPORTS_KEY = 'employee';
const EXPENSE_REPORTS_STALE_TIME = 5000;

class ExpenseReportService extends CRUDService<ExpenseReport, ExpenseReportFormType> {
  constructor() {
    super(EXPENSE_REPORTS_BASE_PATH);
  }
}

const expenseReportService = new ExpenseReportService();

const useExpenseReports = ({
  page = DEFAULT_PAGINATION_PAGE_INDEX,
  size = DEFAULT_PAGINATION_PAGE_SIZE,
  sort = DEFAULT_PAGINATION_SORT,
  dir = DEFAULT_PAGINATION_DIR,
}: QueryRequestParams<ExpenseReportApi> = {}) => {
  const queryParams = `page=${page}&size=${size}&sort=${sort}&dir=${dir}`;

  return useQuery<
    [ResponsePaginated<ExpenseReportApi>, { totalExpenses: number }],
    Error,
    [ResponsePaginated<ExpenseReportApi>, { totalExpenses: number }]
  >({
    queryKey: [EXPENSE_REPORTS_KEY, { page, size }],
    queryFn: async () => {
      const response = (await expenseReportService.getAll(queryParams)) as {
        page: ResponsePaginated<ExpenseReportApi>;
        data: { totalExpenses: number };
      };
      return [response.page, response.data];
    },
    placeholderData: keepPreviousData,
    staleTime: EXPENSE_REPORTS_STALE_TIME,
  });
};

const useExpenseReport = (id: UUID) => {
  return useQuery<ExpenseReportApi, Error, ExpenseReportApi>({
    queryKey: [`${EXPENSE_REPORTS_KEY}-edit`, { id }],
    queryFn: async () => {
      const response = await expenseReportService.get(id);
      return response as ExpenseReportApi;
    },
    placeholderData: keepPreviousData,
    staleTime: EXPENSE_REPORTS_STALE_TIME,
    retry: 1,
    enabled: !!id,
  });
};

const createExpenseReportMutationFn = async (data: ExpenseReportFormType) => {
  return await expenseReportService.create(data);
};

const editExpenseReportMutationFn: MutationFunction<
  ExpenseReportApi,
  [ExpenseReportApi['id'], ExpenseReportFormType]
> = async (params) => {
  const [id, data] = params;
  return (await expenseReportService.edit(id, data)) as ExpenseReportApi;
};

const deleteExpenseReportMutationFn = async (id: ExpenseReportApi['id']) => {
  return await expenseReportService.delete(id);
};

export {
  EXPENSE_REPORTS_KEY,
  useExpenseReports,
  useExpenseReport,
  createExpenseReportMutationFn,
  editExpenseReportMutationFn,
  deleteExpenseReportMutationFn,
};
