import { FC } from 'react';
import notFoundImage from '@/assets/images/illustrations/not-found.png';

interface CommandEmptyProps {
  title: string;
}

const FilterEmpty: FC<CommandEmptyProps> = ({ title }: CommandEmptyProps) => {
  return (
    <div className="flex flex-col justify-center">
      <img src={notFoundImage} alt="logo" width={100} height="auto" className="mx-auto" />
      <p className="text-xs font-light text-muted-foreground mt-3 text-center">{title}</p>
    </div>
  );
};

export { FilterEmpty };
