import { FC } from 'react';
import { Skeleton } from '../ui/skeleton';

const DEFAULT_SKELETON_INPUT_ROWS = 3;

interface EditFormSkeleton {
  inputRows?: number;
}

const SkeletonInputRow = () => (
  <div className="flex flex-col mt-4">
    <Skeleton className="w-20 h-4" />
    <Skeleton className="w-full h-10 mt-2" />
  </div>
);

const EditFormSkeleton: FC<EditFormSkeleton> = ({
  inputRows = DEFAULT_SKELETON_INPUT_ROWS,
}: EditFormSkeleton) => {
  const skeletonInputRows = Array.from({ length: inputRows }, (_, index) => (
    <SkeletonInputRow key={index} />
  ));

  return (
    <div className="flex flex-col">
      {skeletonInputRows}
      <div className="flex justify-end gap-2 mt-8">
        <Skeleton className="w-24 h-10" />
        <Skeleton className="w-24 h-10" />
      </div>
    </div>
  );
};

export { EditFormSkeleton };
