import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';
import { Loader2 } from 'lucide-react';

const buttonVariants = cva(
  'relative inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-500 focus-visible:ring-offset-1',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost:
          'hover:bg-accent hover:text-accent-foreground ring-0 focus-visible:ring-0 focus-visible:ring-offset-0',
        link: 'text-primary underline-offset-4 hover:underline ring-0 focus-visible:ring-0 focus-visible:ring-offset-0',
        outlinePrimary: 'border border-primary/60 text-primary/85 hover:bg-primary/5',
        outlineDestructive:
          'border border-destructive/60 text-destructive/85 hover:bg-destructive/5',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
  fullWidth?: boolean;
  childrenClassName?: string;
  loaderClassName?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      children,
      isLoading = false,
      fullWidth = false,
      childrenClassName,
      loaderClassName,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(
          'disabled:pointer-events-none disabled:opacity-50',
          buttonVariants({ variant, size, className }),
        )}
        ref={ref}
        {...props}
      >
        <div
          className={cn(
            'flex items-center',
            isLoading ? 'opacity-0 invisible' : 'opacity-1 visible',
            fullWidth ? 'w-full' : null,
            childrenClassName,
          )}
        >
          {children}
        </div>
        {isLoading ? (
          <div
            className={`absolute top-2/4 left-2/4 translate-y-[-50%] translate-x-[-50%]`}
            aria-hidden="true"
          >
            <Loader2 className={cn('animate-spin', loaderClassName)} />
          </div>
        ) : null}
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
