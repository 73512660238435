import { z } from 'zod';
import moment from 'moment';
import { CURRENCY_OPTIONS } from '@/common/constants';

const REQUIRED_OPTION_LABEL = 'Seleccione una opción';
const REQUIRED_AMOUNT_LABEL = 'Ingrese un monto válido';

const SelectSchema = z
  .object({
    id: z.string().min(1, REQUIRED_OPTION_LABEL),
    name: z.string(),
  })
  .required();

const CreditNoteSchema = z
  .object({
    number: z.string().min(1, 'Ingrese un número de Nota de Crédito'),
    ledgerAccount: SelectSchema,
    details: z.string().optional(),
    reversionDate: z.date({
      required_error: 'Seleccione una fecha de reversión',
    }),
    receiptDate: z.date({
      required_error: 'Seleccione una fecha de comprobante',
    }),
    currency: z.string().min(1, REQUIRED_OPTION_LABEL),
    exchangeRate: z.coerce.number().min(0, REQUIRED_AMOUNT_LABEL),
    files: z.array(z.string()).optional(),
    creditNoteDetails: z
      .array(
        z.object({
          expense: z.object({
            id: z.string(),
            type: z.string(),
          }),
          amount: z.coerce
            .number({ invalid_type_error: 'Ingrese un monto válido' })
            .min(0, REQUIRED_AMOUNT_LABEL),
        }),
      )
      .min(1, 'Ingrese al menos un gasto'),
    revertedAmount: z.coerce.number(),
    invoicePendingAmount: z.coerce.number(),
  })
  .refine((data) => {
    return (
      data.revertedAmount &&
      data.invoicePendingAmount &&
      data.revertedAmount <= data.invoicePendingAmount
    );
  });

type CreditNoteFormType = z.infer<typeof CreditNoteSchema>;

const defaultCreditNoteFormValues: CreditNoteFormType = {
  number: '',
  ledgerAccount: { id: '', name: '' },
  receiptDate: moment().toDate(),
  reversionDate: moment().toDate(),
  currency: CURRENCY_OPTIONS[0].value,
  exchangeRate: 1,
  files: [],
  creditNoteDetails: [
    {
      expense: {
        id: '',
        type: '',
      },
      amount: 0,
    },
  ],
  revertedAmount: 0,
  invoicePendingAmount: 0,
};

export { SelectSchema, defaultCreditNoteFormValues, CreditNoteSchema };
export type { CreditNoteFormType };
