import { Accordion } from '@/components/ui/accordion';
import { FC, useState } from 'react';
import { Balances, CostCenterApi } from '@/api/costCenter/costCenter.model';
import { CategoriesBox } from './CategoriesBox';
import { ScrollArea } from '@/components/ui/scroll-area';

interface CostCenterWithCategory {
  category: string;
  donations: { name: string; amount: number; balance: number; expenses: number }[];
  id: string;
  amount: number;
  balance: number;
  expenses: number;
}

interface CostCenterDetailParams {
  costCenter: CostCenterApi;
}

const CostCenterDetailForm: FC<CostCenterDetailParams> = ({
  costCenter,
}: CostCenterDetailParams) => {
  const [activeCostCenter, setActiveCostCenter] = useState<string>('-0');

  const handleActiveCostCenterChange = (newActive: string) => {
    if (newActive === activeCostCenter) {
      setActiveCostCenter('');
    } else {
      setActiveCostCenter(newActive);
    }
  };

  const mergeDonationsByCategory = (array: Array<Balances>) => {
    const result: { [key: string]: CostCenterWithCategory } = {};

    array.forEach((item) => {
      if (!result[item.category]) {
        result[item.category] = {
          category: item.category,
          donations: [],
          id: item.id,
          amount: 0,
          balance: 0,
          expenses: 0,
        };
      }
      result[item.category].donations.push({
        name: item.donation.name,
        amount: item.amount,
        balance: item.balance,
        expenses: item.amount - item.balance,
      });
      result[item.category].amount += item.amount;
      result[item.category].balance += item.balance;
      result[item.category].expenses += item.amount - item.balance;
    });

    return Object.values(result);
  };

  const mergedBalances = mergeDonationsByCategory(costCenter.balances!);

  return (
    <div className="flex flex-col gap-5">
      <p className="text-xl font-medium text-foreground leading-none">
        Centro de costo: {costCenter.code}
      </p>
      <Accordion
        type="single"
        value={activeCostCenter}
        collapsible
        className="flex flex-col w-full gap-4"
      >
        <ScrollArea className={`overflow-auto ${mergedBalances.length > 3 ? 'h-96' : 'max-h-96'}`}>
          {mergedBalances ? (
            mergedBalances.map((field, index) => (
              <fieldset key={field.id} className="flex w-full my-2">
                <CategoriesBox
                  field={field}
                  index={index}
                  value={`${field.id}-${index}`}
                  isOpen={activeCostCenter === `${field.id}-${index}`}
                  onChevronClick={handleActiveCostCenterChange}
                />
              </fieldset>
            ))
          ) : (
            <p>No existe Categorias</p>
          )}
        </ScrollArea>
      </Accordion>
    </div>
  );
};

export { CostCenterDetailForm };
