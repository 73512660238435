import { UUID } from 'crypto';
import { ResponsePaginated } from '../types';

type LedgerAccountType = 'TYPE' | 'SUB_TYPE' | 'ACCOUNT' | 'SUB_ACCOUNT';

interface LedgerAccount extends LedgerAccountApi {
  concepts: Array<Concept>;
  onEdit?: () => void;
  onDelete?: () => void;
}

interface Concept {
  id: UUID;
  name: string;
  code: string;
}

interface LedgerAccountApi {
  id: UUID;
  name: string;
  code: string;
  type: LedgerAccountType;
  parent: {
    id: UUID;
    name: string;
    code: string;
    type: LedgerAccountType;
  };
  children: Array<Concept>;
  createdAt: string;
  updatedAt: string;
}

interface LedgerAccountList {
  id: UUID;
  name: string;
  code: string;
  value: string;
  children: Array<Concept>;
}

type LedgerAccountTransformResponse = Omit<ResponsePaginated<LedgerAccountApi>, 'content'> & {
  content: Array<LedgerAccount>;
};

const ledgerAccountMap: { [key in LedgerAccountType]: string } = {
  TYPE: 'Tipo',
  SUB_TYPE: 'Subtipo',
  ACCOUNT: 'Cuenta',
  SUB_ACCOUNT: 'Subcuenta',
};

enum LedgerAccountTypeEnum {
  TYPE = 'TYPE',
  SUB_TYPE = 'SUB_TYPE',
  ACCOUNT = 'ACCOUNT',
  SUB_ACCOUNT = 'SUB_ACCOUNT',
}

export type {
  LedgerAccountApi,
  LedgerAccount,
  LedgerAccountList,
  LedgerAccountTransformResponse,
  LedgerAccountType,
};
export { ledgerAccountMap, LedgerAccountTypeEnum };
