import { ProjectFormType } from '@/pages/Projects/utils/ProjectSchema';
import { MutationFunction, keepPreviousData, useQuery } from '@tanstack/react-query';
import { ProjectQueryParams, ResponsePaginated } from '../types';
import {
  DEFAULT_PAGINATION_DIR,
  DEFAULT_PAGINATION_PAGE_INDEX,
  DEFAULT_PAGINATION_PAGE_SIZE,
  DEFAULT_PAGINATION_SORT,
  DEFAULT_TERRITORY_OPTION,
} from '@/common/constants';
import { ProjectApi, ProjectListItem, ProjectsTransformResponse } from './project.model';
import { CRUDService } from '../CRUDService.api';
import httpClient from '../httpClient';
import * as Sentry from '@sentry/react';
import { UUID } from 'crypto';

const PROJECTS_BASE_PATH = '/project';
const PROJECTS_KEY = 'projects';
const PROJECTS_STALE_TIME = 5000;

const transformProjectsList = (response: Array<ProjectApi>): Array<ProjectListItem> => {
  return response.map((project) => {
    return {
      id: project.id,
      name: project.name,
      value: project.id,
      code: project.code,
    };
  });
};

class ProjectService extends CRUDService<ProjectApi, ProjectFormType> {
  constructor() {
    super(PROJECTS_BASE_PATH);
  }

  async getList() {
    try {
      const response = await httpClient.get(`${PROJECTS_BASE_PATH}/list`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }
}

const projectService = new ProjectService();

const useProjects = ({
  page = DEFAULT_PAGINATION_PAGE_INDEX,
  size = DEFAULT_PAGINATION_PAGE_SIZE,
  sort = DEFAULT_PAGINATION_SORT,
  dir = DEFAULT_PAGINATION_DIR,
  territories = DEFAULT_TERRITORY_OPTION,
}: ProjectQueryParams<ProjectApi> = {}) => {
  const queryParams =
    `page=${page}&size=${size}&sort=${sort}&dir=${dir}` +
    (territories ? `&territories=${territories}` : '');

  return useQuery<ResponsePaginated<ProjectApi>, Error, ProjectsTransformResponse>({
    queryKey: [PROJECTS_KEY, { page, size, sort, dir, territories }],
    queryFn: async () => {
      const response = await projectService.getAll(queryParams);
      return response as ResponsePaginated<ProjectApi>;
    },
    placeholderData: keepPreviousData,
    staleTime: PROJECTS_STALE_TIME,
  });
};

const useProject = (id: UUID) => {
  return useQuery<ProjectApi, Error, ProjectApi>({
    queryKey: [`${PROJECTS_KEY}-edit`, { id }],
    queryFn: async () => {
      const response = await projectService.get(id);
      return response as ProjectApi;
    },
    placeholderData: keepPreviousData,
    staleTime: PROJECTS_STALE_TIME,
    retry: 1,
  });
};

const useProjectsList = () => {
  return useQuery<Array<ProjectApi>, Error, Array<ProjectListItem>>({
    queryKey: [`${PROJECTS_KEY}-list`],
    queryFn: async () => {
      const response = await projectService.getList();
      return response as Array<ProjectApi>;
    },
    select: transformProjectsList,
    placeholderData: keepPreviousData,
    staleTime: PROJECTS_STALE_TIME,
  });
};

const createProjectMutationFn = async (data: ProjectFormType) => {
  return await projectService.create(data);
};

const editProjectMutationFn: MutationFunction<
  ProjectApi,
  [ProjectApi['id'], ProjectFormType]
> = async (params) => {
  const [id, data] = params;
  return (await projectService.edit(id, data)) as ProjectApi;
};

const deleteProjectMutationFn = async (id: ProjectApi['id']) => {
  return await projectService.delete(id);
};

export {
  PROJECTS_KEY,
  useProjects,
  useProject,
  useProjectsList,
  createProjectMutationFn,
  editProjectMutationFn,
  deleteProjectMutationFn,
};
