import { DonationApi, DonationDetail } from '@/api/donation/donation.model';
import {
  ACCOUNTING_MAX_VALUE_ERROR_MESSAGE,
  ACCOUNTING_SUM_ERROR_MESSAGE,
  CURRENCY_OPTIONS,
} from '@/common/constants';
import { AccountingEntryItemSchema } from '@/pages/AccountsMayor/AccountingEntry/AccountingEntryItem/AccountingEntryItemSchema';
import { z } from 'zod';
import { DonationFormType } from './DonationSchema';

export const DonationPaySchema = z
  .object({
    paymentDate: z.date({
      required_error: 'Seleccione una fecha de cobro',
    }),
    accountingEntries: z
      .array(AccountingEntryItemSchema)
      .min(2, 'Ingrese al menos dos partidas contables'),
    transactionId: z.string().optional(),
    donationId: z.string().optional(),
    donationTotalAmount: z.number().optional(),
  })
  .superRefine((data, ctx) => {
    const balance = data.accountingEntries.reduce((acc, entry) => {
      return acc + entry.amount * entry.exchangeRateOfficial;
    }, 0);

    if (balance !== 0) {
      return ctx.addIssue({
        code: 'custom',
        message: ACCOUNTING_SUM_ERROR_MESSAGE,
      });
    }

    const totalAmount = data.accountingEntries.reduce((acc, entry) => {
      let amount = entry.amount;
      if (entry.currency !== 'USD') {
        amount = entry.amount * entry.exchangeRateOfficial;
      }
      if (amount > 0) {
        return acc + amount;
      }
      return acc;
    }, 0);

    if (data.donationTotalAmount && totalAmount !== data.donationTotalAmount) {
      return ctx.addIssue({
        code: 'custom',
        message: ACCOUNTING_MAX_VALUE_ERROR_MESSAGE,
      });
    }
  });

export type DonationPayFormType = z.infer<typeof DonationPaySchema>;

export const defaultDonationPayFormValues: DonationPayFormType = {
  paymentDate: new Date(),
  accountingEntries: [
    {
      ledgerAccount: {
        id: '',
        name: '',
      },
      currency: CURRENCY_OPTIONS[0].value,
      amount: 0,
      exchangeRateOfficial: 1,
      exchangeRateAlt: 1,
      details: '',
    },
    {
      ledgerAccount: {
        id: '',
        name: '',
      },
      currency: CURRENCY_OPTIONS[0].value,
      amount: 0,
      exchangeRateOfficial: 1,
      exchangeRateAlt: 1,
      details: '',
    },
  ],
};

export const setDonationDetailDefaultValue = (donation: DonationApi) => {
  if (donation && donation?.donationDetails) {
    const costCentersWithoutGlobal: Array<DonationDetail> = donation?.donationDetails?.filter(
      (centers) => centers?.costCenter?.global !== true,
    );
    const globalCostCenter: DonationDetail = donation?.donationDetails?.find(
      (centers) => centers?.costCenter?.global === true,
    ) as DonationDetail;
    const detailDonationValues: DonationFormType = {
      name: donation?.name,
      fiscalSponsor: {
        id: donation?.fiscalSponsor.id,
        name: donation?.fiscalSponsor.name,
      },
      donor: {
        id: donation?.donor.id,
        name: donation?.donor.name,
      },
      exchangeRate: donation?.money?.exchangeRate || 1,
      amount: donation?.money?.amount || 0,
      description: donation.description || '',
      amountConverted: Number(donation?.amount),
      currency: donation?.money?.currency || 'USD',
      year: donation.year.toString(),
      entryDate: new Date(donation?.entryDate),
      reportDate: new Date(donation?.reportDate),
      files: donation.files,
      globalCostCenter: {
        id: globalCostCenter?.id,
        amount: globalCostCenter?.amount * (donation?.money?.exchangeRate || 1),
      },
      costCenters: costCentersWithoutGlobal?.map((element) => {
        return {
          id: element?.costCenter?.id,
          name: element?.costCenter?.name,
          balance: element?.costCenter.balance,
          categories: element?.costCenter?.balances
            ? element?.costCenter?.balances?.map((category) => {
                return {
                  id: category?.id,
                  name: category?.category,
                  amount: donation.money?.exchangeRate
                    ? category?.amount * donation.money?.exchangeRate
                    : category?.amount,
                };
              })
            : [
                {
                  id: '',
                  amount: 0,
                },
              ],
        };
      }),
    };
    return detailDonationValues;
  }
};
