import { CurrencyOption } from '@/common/types';
import { cn, formatAmount } from '@/lib/utils';

type TotalsCardItem = {
  label: string;
  amount: number;
  currency: CurrencyOption['value'];
};

interface TotalsCardProps {
  total: TotalsCardItem;
  pending?: TotalsCardItem;
  className?: string;
}

const TotalsCard = ({ total, pending, className }: TotalsCardProps) => (
  <div className={cn('p-6 text-right bg-muted rounded-lg border border-1', className)}>
    <p className="text-sm text-foreground leading-6	font-medium">
      {`${total.label}: ${formatAmount(total.amount, total.currency)}`}
    </p>
    {pending ? (
      <p className="mt-2 text-xs text-foreground leading-6font-medium">
        {`${pending.label}: ${formatAmount(pending.amount, pending.currency)}`}
      </p>
    ) : null}
  </div>
);

export { TotalsCard };
