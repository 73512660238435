import {
  useAccountingEntries,
  useGetAccountingReport,
} from '@/api/accountingEntry/accountingEntry.api';
import { ANIMATION_QUERY_PARAM, DEFAULT_PAGINATION_PAGE_COUNT } from '@/common/constants';
import { Heading } from '@/components/shared/Heading';
import { PageContent } from '@/components/shared/PageContent';
import { TableWrapper } from '@/components/shared/TableWrapper';
import { Button } from '@/components/ui/button';
import { useTablePagination } from '@/hooks/useTablePagination';
import { pathsBase } from '@/routes/paths';
import { FileDownIcon, FilePlusIcon, Trash2 } from 'lucide-react';
import { Helmet } from 'react-helmet';
import { Outlet, useNavigate } from 'react-router-dom';
import { AccountMayorTableColumns } from './utils/AccountMayorTableColumns';
import { SearchBar } from '@/components/shared/SearchBar';
import { useSearchBar } from '@/hooks/useSearchBar';
import { AccountingEntry } from '@/api/accountingEntry/accountingEntry.model';
import useCustomLoader from '@/hooks/useCustomLoader';
import useFilterAndSortByBe from '@/hooks/useFilterAndSortByBe';
import { DateFilter } from '@/components/shared/DateFilter';
import { getParams, handleClearQueryParams } from '@/lib/utils';

const PAGE_TITLE = 'Mayor de Cuentas';

const AccountsMayor = () => {
  const navigate = useNavigate();

  const {
    pageIndex,
    pageSize,
    paginationState,
    setPagination,
    dir,
    setDir,
    sort,
    setSort,
    selectedDate,
    setSelectedDate,
  } = useTablePagination<AccountingEntry>();

  const { searchValue, form: searchBarForm, handleSubmit: handleSearchSubmit } = useSearchBar();
  const { response: handleDownloadAccountingReport, isLoading: isLoadingAccountingReport } =
    useCustomLoader(useGetAccountingReport(getParams()));

  const { data, isLoading, isFetching, refetch } = useAccountingEntries({
    page: pageIndex,
    size: pageSize,
    search: searchValue,
    dir: dir,
    sort: sort,
    date: selectedDate,
  });

  useFilterAndSortByBe<AccountingEntry>(refetch, setDir, setSort);

  const handleCreateAccountingEntry = () =>
    navigate(`/${pathsBase.ACCOUNTS_MAYOR}/${pathsBase.CREATE}?${ANIMATION_QUERY_PARAM}=true`);

  const columns = AccountMayorTableColumns();

  return (
    <>
      <Helmet>
        <title>PEM - {PAGE_TITLE}</title>
      </Helmet>
      <Heading
        title={PAGE_TITLE}
        rightComponent={
          <div className="flex gap-4">
            <SearchBar
              form={searchBarForm}
              searchTerm="apunte"
              isLoading={isLoading || isFetching}
              useAbsolutePosition={false}
              submitHandler={handleSearchSubmit}
            />
            <DateFilter setSelectedDate={setSelectedDate} selectedDate={selectedDate} />
            <Button
              variant={'secondary'}
              onClick={() => handleDownloadAccountingReport()}
              isLoading={isLoadingAccountingReport}
              disabled={isLoadingAccountingReport}
            >
              <FileDownIcon className="w-4 h-4 mr-2" />
              Reporte
            </Button>
            <Button onClick={handleCreateAccountingEntry}>
              <FilePlusIcon className="w-4 h-4 mr-2" />
              Crear Asientos
            </Button>
          </div>
        }
      />
      <PageContent>
        <TableWrapper
          columns={columns}
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
          rowsLabel={PAGE_TITLE}
          pageCount={data ? data.totalPages : DEFAULT_PAGINATION_PAGE_COUNT}
          pagination={paginationState}
          onPaginationChange={setPagination}
          emptyCta={
            location.search
              ? {
                  label: 'Limpiar Filtros',
                  onClick: () => {
                    handleClearQueryParams(navigate, true);
                    setSelectedDate('');
                  },
                }
              : undefined
          }
          emptyLogo={<Trash2 className="mr-2 h-4 w-4" />}
        />
      </PageContent>
      <Outlet />
    </>
  );
};

export default AccountsMayor;
