import { AccountingEntryDetail } from '@/api/accountingEntry/accountingEntry.model';
import { Money } from '@/api/transaction/transaction.model';
import { SortColumnWithBe } from '@/components/shared/SortColumnWithBe';
import { cn, formatAmount } from '@/lib/utils';
import { ColumnDef } from '@tanstack/table-core';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

const AccountingEntryDetailTableColumns = () => {
  const accountingEntryDetailTableColumns: ColumnDef<AccountingEntryDetail>[] = [
    {
      accessorKey: 'code',
      header: ({ column }) => <SortColumnWithBe title="Código" column={column} />,
    },
    {
      accessorKey: 'ledgerAccount',
      header: ({ column }) => <SortColumnWithBe title="Cuenta Contable" column={column} />,
      cell: ({ row }) => {
        return row.original.ledgerAccountCode + row.getValue('ledgerAccount');
      },
    },
    {
      accessorKey: 'paymentDate',
      header: ({ column }) => <SortColumnWithBe title="Fecha" column={column} />,
      cell: ({ row }) => {
        return row.getValue('paymentDate');
      },
    },
    {
      accessorKey: 'moneyOfficial.amount',
      header: ({ column }) => <SortColumnWithBe title="Monto Original" column={column} />,
      cell: ({ row }) => {
        const money = row.original['moneyOfficial'] as Money;
        const amount = row.original.amount;
        const originalAmount = money ? money.amount : amount;
        const originalAmountCreditOrDebit = row.original.credit ? -originalAmount : originalAmount;
        return formatAmount(originalAmountCreditOrDebit, money?.currency || 'ARS');
      },
    },
    {
      accessorKey: 'amount',
      header: () => <p className="text-xs">Debe</p>,
      cell: ({ row }) => {
        const credit = row.original.credit;
        const amount = row.getValue('amount') as number;
        return credit ? formatAmount(0, 'ARS') : formatAmount(amount, 'ARS');
      },
    },
    {
      accessorKey: 'credit',
      header: () => <p className="text-xs">Haber</p>,
      cell: ({ row }) => {
        const credit = row.getValue('credit') as boolean;
        const amount = row.original.amount;
        return credit ? formatAmount(amount, 'ARS') : formatAmount(0, 'ARS');
      },
    },
    {
      accessorKey: 'details',
      header: () => <p className="text-xs">Detalles</p>,
      cell: ({ row }) => {
        const details = row.getValue('details') as string;
        return (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="mt-[2px]" formNoValidate disabled>
                <p className="py-2 text-sm truncate max-w-40 text-foreground">{details || '-'}</p>
              </TooltipTrigger>
              <TooltipContent side="top" className={cn('w-[200px]', details ? '' : 'hidden')}>
                <p className="text-xs">{details || '-'}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        );
      },
    },
  ];

  return accountingEntryDetailTableColumns;
};

export { AccountingEntryDetailTableColumns };
