import { TableRowActions } from '@/components/shared/TableRowActions';
import { Button } from '@/components/ui/button';
import { handleClearQueryParams } from '@/lib/utils';
import { ColumnDef } from '@tanstack/react-table';
import { Trash2 } from 'lucide-react';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useTableColumnsWithActions = <T,>(
  columns: ColumnDef<T>[],
  handleEdit?: (item: T) => void,
  handleDelete?: (item: T) => void,
  disabledEdit?: boolean,
  handleOpen?: (item: T) => void,
  disabledDelete?: boolean,
) => {
  const location = useLocation();
  const navigate = useNavigate();

  const hasOtherParams = (search: string) => {
    const params = new URLSearchParams(search);
    let paramCount = 0;
    let hasUseAnimation = false;
    let hasPageIndex = false;
    let hasDate = false;
    let hasSearch = false;

    params.forEach((value, key) => {
      if (key === 'useAnimation' && value === 'true') {
        hasUseAnimation = true;
      } else if (key === 'pageIndex') {
        hasPageIndex = true;
      } else if (key === 'date') {
        hasDate = true;
      } else if (key === 'search') {
        hasSearch = true;
      } else {
        paramCount++;
      }
    });

    return !(paramCount === 0 && (hasUseAnimation || hasPageIndex || hasDate || hasSearch));
  };

  return useMemo(
    () => [
      ...columns,
      {
        header: () => {
          return (
            <div className="flex items-center">
              {location.search && hasOtherParams(location.search) ? (
                <Button
                  type="button"
                  variant="link"
                  className="absolute right-[35px] w-fit h-fit p-0 text-destructive"
                  onClick={() => handleClearQueryParams(navigate)}
                >
                  <Trash2 className="w-3 h-3 text-s color-destructive" />
                </Button>
              ) : null}
            </div>
          );
        },
        id: 'actions',
        maxSize: 75,
        cell: ({ row }) => {
          const item = row.original;
          return (
            <TableRowActions
              onEdit={handleEdit ? () => handleEdit(item) : undefined}
              onDelete={handleDelete ? () => handleDelete(item) : undefined}
              disabledEdit={disabledEdit}
              disabledDelete={disabledDelete}
              onOpen={handleOpen ? () => handleOpen(item) : undefined}
            />
          );
        },
      },
    ],
    [columns, handleEdit, handleDelete],
  );
};

export default useTableColumnsWithActions;
