import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import DialogWrapper from '@/components/shared/DialogWrapper';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'sonner';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { SONNER_ERROR_OPTIONS, SONNER_SUCCESS_OPTIONS } from '@/common/constants';
import { DonorApi } from '@/api/donor/donor.model';
import { DonorFormType, DonorSchema, defaultDonorFormValues } from './utils/DonorSchema';
import { editDonorMutationFn, useDonor, useDonors } from '@/api/donor/donor.api';
import { DonorsForm } from './components/DonorsForm';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { UUID } from 'crypto';
import { ErrorResponse } from '@/api/types';
import { paths } from '@/routes/paths';
import { EditFormSkeleton } from '@/components/shared/EditFormSkeleton';
import * as Sentry from '@sentry/react';

type DonorsEditParams = {
  id: DonorApi['id'];
};

const DonorsEdit: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams() as DonorsEditParams;
  const { data: donor, isLoading: isDonorLoading, refetch: refetchDonor, error } = useDonor(id);
  const { refetch: refetchDonors } = useDonors();

  const form = useForm<DonorFormType>({
    defaultValues: donor
      ? {
          name: donor.name,
          email: donor.email,
          type: donor.type,
        }
      : defaultDonorFormValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(DonorSchema),
  });

  const { mutate: editDonor, isPending } = useMutation<
    DonorApi,
    AxiosError<ErrorResponse>,
    [UUID, DonorFormType]
  >({
    mutationFn: editDonorMutationFn,
    onSuccess: (data: DonorApi) => {
      refetchDonors();
      toast(
        <SuccessToastText>
          ¡Donante <span className="text-blue-700">{data.name}</span> fue editado exitosamente!
        </SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismiss();
      refetchDonor();
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      Sentry.captureException(error);

      if (error.response && error.response.data.type === 'DUPLICATED_ERROR') {
        form.setError('email', {
          type: 'validate',
          message:
            'El email ingresado ya fue utilizado previamente para otro Donante. Intentá nuevamente ingresado un nuevo email.',
        });
      } else {
        toast.error('Ha ocurrido un error durante la edición del Donante.', {
          description: 'Por favor, revisa los datos ingresados e intentá de nuevo.',
          ...SONNER_ERROR_OPTIONS,
        });
      }
    },
  });

  useEffect(() => {
    if (donor) {
      form.reset({
        name: donor.name,
        email: donor.email,
        type: donor.type,
      });
    }
  }, [donor]);

  if (error) {
    return <Navigate to="*" replace={true} />;
  }

  const handleDismiss = () => navigate(paths.DONORS);

  const handleEditDonor: SubmitHandler<DonorFormType> = (formData) => {
    if (donor) {
      editDonor([donor.id, formData]);
    } else {
      toast.error('Lo sentimos, no tenés ningún Donante seleccionado.', {
        description: 'Por favor, seleccioná un Donante e intentá de nuevo.',
        ...SONNER_ERROR_OPTIONS,
      });
      handleDismiss();
    }
  };

  return (
    <DialogWrapper
      title="Editar Donante"
      className="w-[425px]"
      onDismiss={handleDismiss}
      onOverlayClick={handleDismiss}
    >
      {isDonorLoading || !donor ? (
        <EditFormSkeleton inputRows={2} />
      ) : (
        <DonorsForm
          form={form}
          submitLabel="Editar Donante"
          cancelLabel="Cancelar"
          isLoading={isPending}
          submitHandler={handleEditDonor}
          dismissHandler={handleDismiss}
        />
      )}
    </DialogWrapper>
  );
};

export default DonorsEdit;
