import { CostCenterList } from '@/api/costCenter/costCenter.model';
import { SelectFilter } from '@/components/shared/SelectFilter';
import { AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Card } from '@/components/ui/card';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { CategoryDonationFormType, DonationFormType } from '../utils/DonationSchema';
import { PlusCircle, Trash } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn, formatAmount } from '@/lib/utils';
import { FormControl, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { CurrencyInput } from '@/components/ui/currency-input';
import { CurrencyOption } from '@/common/types';
import { useCategoriesList } from '@/api/category/category.api';
import { DETAIL_DISABLED_BUTTON_CLASS } from '@/common/constants';
import { CostCenterBoxItem } from '@/api/types';

// TODO: revisar estas props
interface CostCenterBoxProps {
  field: any;
  index: number;
  value: string;
  form: UseFormReturn<DonationFormType, unknown>;
  costCentersList: Array<CostCenterList>;
  costCenterOptions: Array<CostCenterList>;
  isOpen?: boolean;
  onChevronClick: (value: string) => void;
  onRemove: () => void;
  setActiveCostCenter?: React.Dispatch<React.SetStateAction<string>>;
  disabled?: boolean;
}

const CostCenterBox: FC<CostCenterBoxProps> = ({
  field,
  index,
  value,
  form,
  costCentersList,
  costCenterOptions,
  isOpen = false,
  onChevronClick,
  onRemove,
  disabled,
  setActiveCostCenter,
}: CostCenterBoxProps) => {
  const { data: categories, isLoading: isLoadingCategories } = useCategoriesList();
  const selectedCostCenter: CostCenterBoxItem = form.getValues(`costCenters.${index}`);

  const appendToCategoryFieldArray = (
    costCenterIndex: number,
    newCategory: CategoryDonationFormType,
  ) => {
    const formValues = form.getValues();
    const updatedCostCenters = [...formValues.costCenters];

    // Check if the cost center already has categories, if not, initialize it
    if (!updatedCostCenters[costCenterIndex].categories) {
      updatedCostCenters[costCenterIndex].categories = [];
    }

    updatedCostCenters[costCenterIndex].categories.push(newCategory);
    form.setValue('costCenters', updatedCostCenters);
  };

  const removeCategory = (costCenterIndex: number, categoryIndex: number) => {
    const formValues = form.getValues();
    const updatedCostCenters = [...formValues.costCenters];
    updatedCostCenters[costCenterIndex].categories.splice(categoryIndex, 1);
    form.setValue('costCenters', updatedCostCenters);
  };

  const onChangeCategoryAmount = (
    costCenterIndex: number,
    categoryIndex: number,
    amount: number | undefined,
  ) => {
    const formValues = form.getValues();
    const updatedCostCenters = [...formValues.costCenters];
    updatedCostCenters[costCenterIndex].categories[categoryIndex].amount = amount || 0;
    form.setValue('costCenters', updatedCostCenters);
  };

  return (
    <Card className="w-full px-5 py-[22px] shadow-sm">
      <AccordionItem value={value} className="border-none">
        <AccordionTrigger
          className="flex justify-between py-0 pr-6 hover:no-underline"
          chevronClassname={cn('w-9 h-9', isOpen ? 'rotate-180' : null)}
          onChevronClick={() => onChevronClick(value)}
        >
          <div className="flex items-end gap-2">
            <SelectFilter
              form={form}
              isLoading={false}
              formName={`costCenters.${index}.id`}
              formLabel="Centro de Costos"
              formPlaceholder="Seleccionar Centro de Costos"
              formFilterPlaceholder="Buscar Centro de Costos..."
              options={costCenterOptions || []}
              classnames={{
                item: 'items-start w-[250px]',
                button: disabled ? `${DETAIL_DISABLED_BUTTON_CLASS} w-[250px]` : 'w-[250px]',
              }}
              onChange={(value) => {
                form.setValue(`costCenters.${index}.id`, value.id, {
                  shouldValidate: true,
                });
                form.setValue(`costCenters.${index}.name`, value.name || '', {
                  shouldValidate: true,
                });
                setActiveCostCenter!(`${value.id}-${index}`);
              }}
              selectedOption={
                costCentersList?.find(
                  (costCenter) => costCenter.id === form.watch(`costCenters.${index}.id`),
                )?.name
              }
              disabled={disabled}
            />
            {disabled ? null : (
              <Button
                type="button"
                variant="outlineDestructive"
                className="p-3"
                disabled={form.getValues().costCenters.length === 1}
                onClick={onRemove}
              >
                <Trash className="w-4 h-4" />
              </Button>
            )}
          </div>
          <div className="flex gap-5">
            <div className={cn('flex flex-col items-start gap-1.5', !disabled ? 'hidden' : '')}>
              <p className="text-sm font-medium uppercase text-muted-foreground">DISPONIBLE:</p>
              <p className="text-xl font-normal text-foreground">
                {formatAmount(selectedCostCenter.balance, form.watch('currency'))}
              </p>
            </div>
            <div className="flex flex-col items-start gap-1.5">
              <p className="text-sm font-medium uppercase text-muted-foreground">TOTAL:</p>
              <p className="text-xl font-normal text-foreground">
                {formatAmount(
                  field.categories.reduce(
                    (acc: number, category: { amount: number }) => acc + category.amount,
                    0,
                  ),
                  form.watch('currency'),
                )}
              </p>
            </div>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <div className="flex flex-col gap-2.5 mt-6">
            <p className="text-sm font-medium text-foreground">Categorías</p>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2.5">
                {field.categories.map(
                  (category: { id: string; name: string }, categoryIndex: number) => (
                    <div
                      key={`${category.id}-${categoryIndex}`}
                      className={cn(
                        'flex items-end justify-between gap-5 p-5 rounded-lg bg-slate-100',
                        { 'bg-slate-50': disabled },
                      )}
                    >
                      <div className="flex gap-4">
                        <SelectFilter
                          form={form}
                          isLoading={isLoadingCategories}
                          formName={`costCenters.${index}.categories.${categoryIndex}.id`}
                          formLabel="Categoría"
                          formPlaceholder="Categoría"
                          formFilterPlaceholder="Buscar Categoría..."
                          options={categories || []}
                          classnames={{
                            button: disabled ? `${DETAIL_DISABLED_BUTTON_CLASS} w-full` : 'w-full',
                            item: 'w-[300px]',
                          }}
                          onChange={(value) => {
                            form.setValue(
                              `costCenters.${index}.categories.${categoryIndex}.id`,
                              value.id,
                              {
                                shouldValidate: true,
                              },
                            );
                          }}
                          selectedOption={
                            disabled
                              ? category.name
                              : categories?.find(
                                  (category) =>
                                    category.id ===
                                    form.watch(
                                      `costCenters.${index}.categories.${categoryIndex}.id`,
                                    ),
                                )?.name
                          }
                          disabled={disabled}
                        />

                        <FormItem className="w-[200px]">
                          <FormLabel>Monto en {form.watch('currency')}</FormLabel>
                          <FormControl>
                            <CurrencyInput
                              disabled={disabled}
                              type="number"
                              value={field.categories[categoryIndex].amount}
                              currency={form.watch('currency') as CurrencyOption['value']}
                              placeholder="Ingresar monto"
                              onChange={(value) =>
                                onChangeCategoryAmount(index, categoryIndex, value)
                              }
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      </div>
                      {disabled ? null : (
                        <Button
                          type="button"
                          variant="outlineDestructive"
                          className="p-3"
                          disabled={field.categories.length === 1}
                          onClick={() => removeCategory(index, categoryIndex)}
                        >
                          <Trash className="w-4 h-4" />
                        </Button>
                      )}
                    </div>
                  ),
                )}
              </div>
              {disabled ? null : (
                <Button
                  type="button"
                  variant="outlinePrimary"
                  className="text-xs w-fit"
                  onClick={() => appendToCategoryFieldArray(index, { id: '', amount: 0 })}
                >
                  <PlusCircle className="w-4 h-4 mr-2" />
                  Agregar Categoría
                </Button>
              )}
            </div>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Card>
  );
};

export { CostCenterBox };
