import { FC } from 'react';
import bannerImage from '@/assets/images/banners/banner-02.jpg';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { paths } from '@/routes/paths';

const NotFound: FC = () => {
  const navigate = useNavigate();

  const handleGoBack = () => navigate(-1);

  const handleNavigateHome = () => navigate(paths.DASHBOARD);

  return (
    <div className="min-h-screen md:grid md:grid-cols-2">
      <div className="flex flex-col justify-center gap-10 pt-16 px-8">
        <div className="flex flex-col gap-4 text-left">
          <div className="flex flex-col gap-1">
            <p className="text-xl text-destructive font-light tracking-tight uppercase">
              404 Error
            </p>
            <h1 className="text-5xl text-foreground font-light tracking-tight">
              Página no encontrada
            </h1>
          </div>
          <p className="text-2xl text-muted-foreground font-light tracking-tight">
            Lo sentimos, la página buscada no existe o ha sido movida.
          </p>
        </div>
        <div className="flex gap-4">
          <Button variant="outline" onClick={handleGoBack}>
            Volver a la página anterior
          </Button>
          <Button onClick={handleNavigateHome}>Ir al Inicio</Button>
        </div>
      </div>
      <div
        className="hidden bg-center bg-cover md:block"
        style={{
          backgroundImage: `url(${bannerImage})`,
        }}
      ></div>
    </div>
  );
};

export { NotFound };
