import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { Dialog, DialogClose, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { XIcon } from 'lucide-react';
import { cn } from '@/lib/utils';

interface DialogWrapperProps {
  title?: string;
  subtitle?: string;
  children: ReactElement;
  open?: boolean;
  useCloseIcon?: boolean;
  className?: string;
  contentClassName?: string;
  titleClassName?: string;
  subtitleClassName?: string;
  onDismiss: () => void;
  onOverlayClick?: () => void;
}

const DialogWrapper: FC<DialogWrapperProps> = ({
  title,
  subtitle,
  children,
  open = true,
  useCloseIcon = true,
  className,
  contentClassName,
  titleClassName,
  subtitleClassName,
  onDismiss,
  onOverlayClick,
}) => {
  const [isShow, setIsShow] = useState(false);
  const dialogRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent | Event) => {
    if (
      dialogRef.current &&
      !dialogRef.current.contains(event.target as Node) &&
      isShow &&
      onOverlayClick &&
      event.target instanceof Element &&
      event.target.classList.contains('dialog-overlay')
    ) {
      onOverlayClick();
    }
  };

  useEffect(() => {
    if (isShow && onOverlayClick) {
      document.addEventListener('click', handleClickOutside);

      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }
  }, [isShow]);

  useEffect(() => {
    if (!isShow) setIsShow(true);
  }, []);

  return (
    <Dialog open={open}>
      <DialogContent ref={dialogRef} className={`w-fit min-w-[425px] ${className}`}>
        {useCloseIcon ? (
          <DialogClose
            type="button"
            autoFocus={false}
            className="absolute top-4 right-4 !mt-0 !mb-0"
            onClick={onDismiss}
          >
            <XIcon className="w-4 h-4 color-foreground" />
          </DialogClose>
        ) : null}
        <div className={cn('flex flex-col gap-6', contentClassName)}>
          {title ? (
            <DialogHeader>
              <DialogTitle className={titleClassName}>{title}</DialogTitle>
              {subtitle ? (
                <p className={cn('text-xs text-muted-foreground', subtitleClassName)}>{subtitle}</p>
              ) : null}
            </DialogHeader>
          ) : null}
          <div>{children}</div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DialogWrapper;
