import { UUID } from 'crypto';
import { BankAccount } from '../bankAccount/bankAccount.model';
import { CostCenterApi } from '../costCenter/costCenter.model';
import { DonorApi } from '../donor/donor.model';
import { FiscalSponsorApi } from '../fiscalSponsor/fiscalSponsor.model';
import { LedgerAccountApi } from '../ledgerAccount/ledgerAccount.model';
import { Money } from '../transaction/transaction.model';
import { AccountingEntryPaymentDetail } from '../accountingEntry/accountingEntry.model';

enum DonationState {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

interface DonationCostCenterDetail {
  id: string;
  name: string;
  categories: Array<{
    id: string;
    amount: number;
  }>;
}

interface DonationDetail {
  id: UUID;
  amount: number;
  costCenter: CostCenterApi;
  money: Money;
}

interface DonationApi {
  name: string;
  id: string;
  createdAt: string | null;
  updatedAt: string | null;
  amount: number;
  year: number;
  fiscalSponsor: FiscalSponsorApi;
  donor: DonorApi;
  ledgerAccount: LedgerAccountApi;
  entryDate: string;
  reportDate: string;
  bankAccount: BankAccount;
  costCenters: Array<DonationCostCenterDetail>;
  state: DonationState;
  description?: string;
  donationDetails?: Array<DonationDetail>;
  money?: Money;
  files?: Array<string>;
  accountingEntries?: Array<AccountingEntryPaymentDetail>;
  balance?: number;
  totalExpenses?: number;
}

interface DonationDetailsPayload {
  type: 'DonationDetail';
  amount: number;
  destination: {
    costCenter: { id: string };
    category?: { id: string };
  };
  money: Money;
  description?: string;
}

interface DonationCreatePayload {
  fiscalSponsor: { id: string };
  donor: { id: string };
  amount: number;
  year: number;
  name: string;
  description?: string;
  reportDate: string;
  entryDate: string;
  donationDetails: Array<DonationDetailsPayload>;
  files?: Array<string>;
  money?: Money;
}

interface DonationList {
  id: string;
  name: string;
  value: string;
}

const donationStateMap: { [key in DonationState]: string } = {
  PENDING: 'Pendiente',
  COMPLETED: 'Completo',
};

export type {
  DonationApi,
  DonationDetailsPayload,
  DonationCreatePayload,
  DonationList,
  DonationDetail,
};
export { DonationState, donationStateMap };
