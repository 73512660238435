import { BankAccountFormType } from '@/pages/BankAccounts/utils/BankAccountSchema';
import { MutationFunction, keepPreviousData, useQuery } from '@tanstack/react-query';
import { QueryRequestParams, ResponsePaginated } from '../types';
import {
  DEFAULT_PAGINATION_DIR,
  DEFAULT_PAGINATION_PAGE_INDEX,
  DEFAULT_PAGINATION_PAGE_SIZE,
  DEFAULT_PAGINATION_SORT,
} from '@/common/constants';
import {
  BankAccountApi,
  BankAccountListItem,
  BankAccountTransformResponse,
} from './bankAccount.model';
import { CRUDService } from '../CRUDService.api';
import httpClient from '../httpClient';
import * as Sentry from '@sentry/react';
import { UUID } from 'crypto';

const BANK_ACCOUNT_BASE_PATH = '/bankAccount';
const BANK_ACCOUNT_KEY = 'bankAccount';
const BANK_ACCOUNT_STALE_TIME = 5000;

const transformBankAccountList = (response: Array<BankAccountApi>): Array<BankAccountListItem> => {
  return response.map((bankAccount) => {
    return {
      id: bankAccount.id,
      name: bankAccount.name,
      value: bankAccount.id,
      accountNumber: bankAccount.accountNumber,
    };
  });
};

class BankAccountService extends CRUDService<BankAccountApi, BankAccountFormType> {
  constructor() {
    super(BANK_ACCOUNT_BASE_PATH);
  }

  async getList() {
    try {
      const response = await httpClient.get(`${BANK_ACCOUNT_BASE_PATH}/list`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }
}

const bankAccountService = new BankAccountService();

const useBankAccounts = ({
  page = DEFAULT_PAGINATION_PAGE_INDEX,
  size = DEFAULT_PAGINATION_PAGE_SIZE,
  sort = DEFAULT_PAGINATION_SORT,
  dir = DEFAULT_PAGINATION_DIR,
}: QueryRequestParams<BankAccountApi> = {}) => {
  const queryParams = `page=${page}&size=${size}&sort=${sort}&dir=${dir}`;

  return useQuery<ResponsePaginated<BankAccountApi>, Error, BankAccountTransformResponse>({
    queryKey: [BANK_ACCOUNT_KEY, { page, size }],
    queryFn: async () => {
      const response = await bankAccountService.getAll(queryParams);
      return response as ResponsePaginated<BankAccountApi>;
    },
    placeholderData: keepPreviousData,
    staleTime: BANK_ACCOUNT_STALE_TIME,
  });
};

const useBankAccount = (id: UUID) => {
  return useQuery<BankAccountApi, Error, BankAccountApi>({
    queryKey: [`${BANK_ACCOUNT_KEY}-edit`, { id }],
    queryFn: async () => {
      const response = await bankAccountService.get(id);
      return response as BankAccountApi;
    },
    placeholderData: keepPreviousData,
    staleTime: BANK_ACCOUNT_STALE_TIME,
    retry: 1,
  });
};

const useBankAccountList = () => {
  return useQuery<Array<BankAccountApi>, Error, Array<BankAccountListItem>>({
    queryKey: [`${BANK_ACCOUNT_KEY}-list`],
    queryFn: async () => {
      const response = await bankAccountService.getList();
      return response as Array<BankAccountApi>;
    },
    select: transformBankAccountList,
    placeholderData: keepPreviousData,
    staleTime: BANK_ACCOUNT_STALE_TIME,
  });
};

const createBankAccountMutationFn = async (data: BankAccountFormType) => {
  return await bankAccountService.create(data);
};

const editBankAccountMutationFn: MutationFunction<
  BankAccountApi,
  [BankAccountApi['id'], BankAccountFormType]
> = async (params) => {
  const [id, data] = params;
  return (await bankAccountService.edit(id, data)) as BankAccountApi;
};

const deleteBankAccountMutationFn = async (id: BankAccountApi['id']) => {
  return await bankAccountService.delete(id);
};

export {
  BANK_ACCOUNT_KEY,
  useBankAccounts,
  useBankAccount,
  useBankAccountList,
  createBankAccountMutationFn,
  editBankAccountMutationFn,
  deleteBankAccountMutationFn,
};
