import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { GetObjectCommand, PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { ExternalLink, Loader2, Trash, Upload } from 'lucide-react';
import { FC, useState, ChangeEvent, useRef, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { toast } from 'sonner';

type FileItem = {
  file: File;
  isLoading: boolean;
  s3Url?: string;
  presignedUrl?: string;
};

interface FileUploaderProps {
  form: UseFormReturn<any, unknown, any>;
  fieldName: string;
  label?: string;
  fileLimit?: number;
  initialFiles?: string[]; // Array of URLs for initial files
  buttonClassname?: string;
  trashClassname?: string;
  disabled?: boolean;
}

// Extract the key from the S3 URL
const extractKeyFromUrl = (url: string) => {
  const parsedUrl = new URL(url);
  return parsedUrl.pathname.substring(1);
};

const FileUploader: FC<FileUploaderProps> = ({
  form,
  fieldName,
  label,
  fileLimit,
  initialFiles,
  buttonClassname,
  trashClassname,
  disabled,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [files, setFiles] = useState<FileItem[]>(
    initialFiles?.map((url) => ({ isLoading: false, s3Url: url })) ||
      form.getValues()[fieldName] ||
      [],
  );
  const [isLoading, setIsLoading] = useState(false);

  const s3Client = new S3Client({
    region: import.meta.env.VITE_S3_REGION,
    credentials: {
      accessKeyId: import.meta.env.VITE_S3_ACCESS_KEY_ID,
      secretAccessKey: import.meta.env.VITE_S3_SECRET_ACCESS_KEY,
    },
  });

  const generatePresignedUrl = async (key: string) => {
    const command = new GetObjectCommand({
      Bucket: import.meta.env.VITE_S3_BUCKET_NAME,
      Key: key,
    });

    // Generate a presigned URL for the uploaded file valid for 24 hours
    return await getSignedUrl(s3Client, command, { expiresIn: 86400 });
  };

  const normalizeFileName: (filename: string) => string = (filename) => {
    return filename
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, '_')
      .replace(/[^a-zA-Z0-9_.-]/g, '');
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      if (fileLimit && files.length + event.target.files.length > fileLimit) {
        toast.error(`Solo puedes cargar hasta ${fileLimit} archivo/s.`, {
          closeButton: true,
        });
        return;
      }
      const file = event.target.files[0];
      const normalizedFileName = normalizeFileName(file.name);

      const fileItem: FileItem = {
        file,
        isLoading: true,
      };

      setIsLoading(true);
      setFiles([...files, fileItem]);

      const today = new Date().getTime();
      const key = `donations/documents/${import.meta.env.VITE_ENVIRONMENT}/${today}${normalizedFileName}`;

      const params = {
        Bucket: import.meta.env.VITE_S3_BUCKET_NAME,
        Key: key,
        Body: file,
        ContentType: file.type,
      };

      try {
        await s3Client.send(new PutObjectCommand(params));

        const s3Url = `https://${import.meta.env.VITE_S3_BUCKET_NAME}.s3.amazonaws.com/${key}`;
        // Generate a presigned URL for the uploaded file
        const presignedUrl = await generatePresignedUrl(key);

        // Update the file item with the S3 URL and loading status
        setFiles((prevFiles) =>
          prevFiles.map((f) =>
            f.file?.name === file.name ? { ...f, isLoading: false, s3Url, presignedUrl } : f,
          ),
        );

        form.setValue(fieldName, [...form.getValues()[fieldName], s3Url], { shouldDirty: true });
      } catch (error) {
        console.error('Upload failed:', error);
        toast.error('Ocurrió un error al cargar el documento. Por favor, intenta de nuevo.', {
          closeButton: true,
        });
        // Remove the failed upload from state
        setFiles((prevFiles) => prevFiles.filter((f) => f.file.name !== file.name));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleInputFileClick = () => fileInputRef.current?.click();

  const handleRemoveFile = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    form.setValue(
      fieldName,
      newFiles.map((f) => f.s3Url),
      { shouldDirty: true },
    );
  };

  const handleDownloadFile = (presignedUrl: string) => {
    const link = document.createElement('a');
    link.href = presignedUrl;
    link.target = '_blank'; // Open in a new tab
    link.download = presignedUrl.split('/').pop() || 'downloaded_file';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (initialFiles && initialFiles.length > 0) {
      form.setValue(fieldName, initialFiles);
    }
  }, []);

  useEffect(() => {
    if (form.getValues()[fieldName].length > 0) {
      const newFiles = form.getValues()[fieldName].map((url: string) => ({
        file: new File([], url.split('/').pop() ?? ''),
        isLoading: false,
        s3Url: url,
      }));
      setFiles(newFiles);
    }
  }, [form.getValues()[fieldName]]);

  useEffect(() => {
    const generatePresignedUrls = async () => {
      const urls = await Promise.all(
        (initialFiles || []).map(async (url) => {
          const key = extractKeyFromUrl(url);
          return await generatePresignedUrl(key);
        }),
      );

      setFiles((prevFiles) => prevFiles.map((f, index) => ({ ...f, presignedUrl: urls[index] })));
    };

    generatePresignedUrls();
  }, [initialFiles]);

  return (
    <>
      <input
        ref={fileInputRef}
        id="files"
        name="files"
        type="file"
        className="hidden"
        aria-hidden="true"
        onChange={handleFileChange}
      />
      <div className="flex flex-col">
        <ul className="flex flex-col space-y-3">
          {files.map((file, index) => (
            <li key={file.s3Url} className="flex items-center gap-3">
              <Card
                className={cn('h-10 w-full px-3 py-2 shadow-none max-w-80', {
                  'bg-gray-100': file.isLoading,
                })}
              >
                {file.isLoading ? (
                  <div className="flex items-center gap-2">
                    <Loader2 className="w-4 h-4 animate-spin" />
                    <p className="text-sm font-normal truncate text-foreground">
                      Uploading {file.file.name}...
                    </p>
                  </div>
                ) : (
                  <div className="flex items-center justify-between gap-5">
                    <p className="text-sm font-normal truncate text-foreground">
                      {file.file?.name || file.s3Url?.split('/').pop()}
                    </p>
                    <ExternalLink
                      className="w-4 h-4 hover:cursor-pointer"
                      onClick={() => handleDownloadFile(file.presignedUrl!)}
                    />
                  </div>
                )}
              </Card>
              {!file.isLoading && !disabled ? (
                <Button
                  type="button"
                  variant="outlineDestructive"
                  className={cn('p-3 border-input', { trashClassname })}
                  onClick={() => handleRemoveFile(index)}
                >
                  <Trash className="w-4 h-4" />
                </Button>
              ) : null}
            </li>
          ))}
        </ul>
        {files.length === 0 || (fileLimit && files.length < fileLimit) ? (
          <Button
            disabled={disabled}
            type="button"
            variant="outline"
            className={cn(
              'w-fit font-normal',
              { 'mt-5': files.length > 0 || isLoading },
              buttonClassname,
            )}
            childrenClassName="justify-between w-full"
            onClick={handleInputFileClick}
          >
            {label || 'Cargar Nuevo Documento'}
            <Upload className="w-4 h-4 ml-2 text-foreground" />
          </Button>
        ) : null}
      </div>
    </>
  );
};

export { FileUploader };
