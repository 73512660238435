import { ChevronLeftIcon, ChevronRightIcon, ArrowLeftIcon, ArrowRightIcon } from 'lucide-react';
import { Table } from '@tanstack/react-table';

import { Button } from '@/components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { DEFAULT_PAGINATION_SIZE_OPTIONS } from '@/common/constants';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface DataTablePaginationProps<TData> {
  table: Table<TData>;
  rowsLabel?: string;
}

export function TablePagination<TData>({
  table,
  rowsLabel = 'Filas',
}: DataTablePaginationProps<TData>) {
  const navigate = useNavigate();
  const location = useLocation();

  const paramsPageIndex = useMemo(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    return Number(params.get('pageIndex')) || 0;
  }, [location.search]);

  const updatePageIndex = (pageIndex: number) => {
    const params = new URLSearchParams(location.search);
    params.set('pageIndex', pageIndex.toString());
    const newSearch = params.toString();
    window.history.pushState({}, '', `?${newSearch}`);
    navigate(`?${newSearch}`);
  };

  const handleNextPage = () => {
    updatePageIndex(table.getState().pagination.pageIndex + 1);
  };

  const handlePreviousPage = () => {
    updatePageIndex(table.getState().pagination.pageIndex - 1);
  };

  const handleSetPage = (pageNum: number) => {
    updatePageIndex(pageNum);
  };

  useEffect(() => {
    table.setPageIndex(paramsPageIndex);
  }, [paramsPageIndex]);

  return (
    <div className="flex items-center justify-end">
      <div className="flex items-center space-x-6 lg:space-x-8">
        <div className="flex items-center space-x-2">
          <p className="text-sm font-medium">{rowsLabel} por página</p>
          <Select
            value={`${table.getState().pagination.pageSize}`}
            onValueChange={(value) => {
              table.setPageSize(Number(value));
            }}
          >
            <SelectTrigger className="h-8 w-[70px]">
              <SelectValue placeholder={table.getState().pagination.pageSize} />
            </SelectTrigger>
            <SelectContent side="top">
              {DEFAULT_PAGINATION_SIZE_OPTIONS.map((pageSize) => (
                <SelectItem key={pageSize} value={`${pageSize}`}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex w-[100px] items-center justify-center text-sm font-medium">
          Página {table.getState().pagination.pageIndex + 1} de {table.getPageCount()}
        </div>
        <div className="flex items-center space-x-2">
          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 lg:flex"
            onClick={() => handleSetPage(0)}
            disabled={!table.getCanPreviousPage()}
          >
            <span className="sr-only">Ir a primer página</span>
            <ArrowLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={() => handlePreviousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <span className="sr-only">Ir a página anterior</span>
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={() => handleNextPage()}
            disabled={!table.getCanNextPage()}
          >
            <span className="sr-only">Ir a página siguiente</span>
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 lg:flex"
            onClick={() => handleSetPage(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            <span className="sr-only">Ir a última página</span>
            <ArrowRightIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </div>
  );
}
