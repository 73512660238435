import { DEFAULT_CODE_SCHEMA, DEFAULT_NAME_SCHEMA } from '@/common/constants';
import { z } from 'zod';

export const ProjectSchema = z
  .object({
    name: DEFAULT_NAME_SCHEMA,
    code: DEFAULT_CODE_SCHEMA,
  })
  .required();

export type ProjectFormType = {
  name: string;
  code: string;
  territories: Array<string>;
};

export const defaultProjectFormValues: ProjectFormType = { name: '', code: '', territories: [] };
