import { MutationFunction, keepPreviousData, useQuery } from '@tanstack/react-query';
import { CostCenterQueryParams, ResponsePaginated } from '../types';
import {
  DEFAULT_PAGINATION_DIR,
  DEFAULT_PAGINATION_PAGE_INDEX,
  DEFAULT_PAGINATION_PAGE_SIZE,
  DEFAULT_PAGINATION_SORT,
  DEFAULT_PROJECT_OPTION,
  DEFAULT_TERRITORY_OPTION,
} from '@/common/constants';
import { CostCenterApi, CostCenterFormTypeApi, CostCenterList } from './costCenter.model';
import { CRUDService } from '../CRUDService.api';
import httpClient from '../httpClient';
import * as Sentry from '@sentry/react';
import { CostCenterFormType } from '@/pages/CostCenter/utils/CostCenterSchema';
import { UUID } from 'crypto';

const COST_CENTERS_BASE_PATH = '/costCenter';
const COST_CENTERS_KEY = 'costCenter';
const COST_CENTERS_STALE_TIME = 5000;

const transformCostCenterList = (response: Array<CostCenterApi>): Array<CostCenterList> => {
  // return costcenter list but filter the ones that are global
  return response.map((costCenter) => {
    return {
      id: costCenter.id,
      name: costCenter.name || costCenter.code,
      value: costCenter.name || costCenter.code,
      global: costCenter.global,
    };
  });
};

class CostCenterService extends CRUDService<CostCenterApi, CostCenterFormTypeApi> {
  constructor() {
    super(COST_CENTERS_BASE_PATH);
  }

  // Add here the methods that are specific to this service
  async getList() {
    try {
      const response = await httpClient.get(`${COST_CENTERS_BASE_PATH}/list`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async getCostCentersByDonation(donationId: string) {
    try {
      const response = await httpClient.get(`${COST_CENTERS_BASE_PATH}/donation/${donationId}`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async getCostCentersByCategory(categoryId: string) {
    try {
      const response = await httpClient.get(`${COST_CENTERS_BASE_PATH}/category/${categoryId}`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async getGlobalCostCenter() {
    try {
      const response = await httpClient.get(`${COST_CENTERS_BASE_PATH}/global`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async getBalanceByCostCenterDonationCategory(
    costCenterId: string,
    donationId: string,
    categoryId: string,
  ) {
    try {
      const response = await httpClient.get(
        `${COST_CENTERS_BASE_PATH}/balance/${categoryId}/${costCenterId}/${donationId}`,
      );
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }
}

const costCenterService = new CostCenterService();

const useCostCenters = ({
  page = DEFAULT_PAGINATION_PAGE_INDEX,
  size = DEFAULT_PAGINATION_PAGE_SIZE,
  sort = DEFAULT_PAGINATION_SORT,
  dir = DEFAULT_PAGINATION_DIR,
  project = DEFAULT_PROJECT_OPTION,
  territory = DEFAULT_TERRITORY_OPTION,
}: CostCenterQueryParams<CostCenterApi> = {}) => {
  const queryParams =
    `page=${page}&size=${size}&sort=${sort}&dir=${dir}` +
    (project ? `&project=${project}` : '') +
    (territory ? `&territory=${territory}` : '');

  return useQuery<ResponsePaginated<CostCenterApi>, Error, ResponsePaginated<CostCenterApi>>({
    queryKey: [COST_CENTERS_KEY, { page, size, sort, dir, project, territory }],
    queryFn: async () => {
      const response = await costCenterService.getAll(queryParams);
      return response as ResponsePaginated<CostCenterApi>;
    },
    placeholderData: keepPreviousData,
    staleTime: COST_CENTERS_STALE_TIME,
  });
};

const useCostCentersList = () => {
  return useQuery<Array<CostCenterApi>, Error, Array<CostCenterList>>({
    queryKey: [`${COST_CENTERS_KEY}-list`],
    queryFn: async () => {
      const response = await costCenterService.getList();
      return response as Array<CostCenterApi>;
    },
    select: transformCostCenterList,
    placeholderData: keepPreviousData,
    staleTime: COST_CENTERS_STALE_TIME,
  });
};

const useCostCenterByDonation = (donationId: string) => {
  return useQuery<Array<CostCenterApi>, Error, Array<CostCenterList>>({
    queryKey: [`${COST_CENTERS_KEY}-donation`, { donationId }],
    queryFn: async () => {
      const response = await costCenterService.getCostCentersByDonation(donationId);
      return response as Array<CostCenterApi>;
    },
    select: transformCostCenterList,
    placeholderData: keepPreviousData,
    staleTime: COST_CENTERS_STALE_TIME,
    enabled: !!donationId,
  });
};

const useCostCenterByCategory = (categoryId: string) => {
  return useQuery<Array<CostCenterApi>, Error, Array<CostCenterList>>({
    queryKey: [`${COST_CENTERS_KEY}-category`, { categoryId }],
    queryFn: async () => {
      const response = await costCenterService.getCostCentersByCategory(categoryId);
      return response as Array<CostCenterApi>;
    },
    select: transformCostCenterList,
    placeholderData: keepPreviousData,
    staleTime: COST_CENTERS_STALE_TIME,
    enabled: !!categoryId,
  });
};

const useGetGlobalCostCenter = () => {
  return useQuery<CostCenterApi, Error, CostCenterApi>({
    queryKey: [`${COST_CENTERS_KEY}-global`],
    queryFn: async () => {
      const response = await costCenterService.getGlobalCostCenter();
      return response as CostCenterApi;
    },
    placeholderData: keepPreviousData,
    staleTime: COST_CENTERS_STALE_TIME,
  });
};

const useCostCenter = (id: UUID) => {
  return useQuery<CostCenterApi, Error, CostCenterApi>({
    queryKey: [`${COST_CENTERS_KEY}-edit`, { id }],
    queryFn: async () => {
      const response = await costCenterService.get(id);
      return response as CostCenterApi;
    },
    placeholderData: keepPreviousData,
    staleTime: COST_CENTERS_STALE_TIME,
    retry: 1,
  });
};

const useBalanceByCostCenterDonationCategory = (
  costCenterId: string,
  donationId: string,
  categoryId: string,
) => {
  return useQuery<Array<{ balance: number }>, Error>({
    queryKey: [COST_CENTERS_KEY, categoryId, donationId, costCenterId],
    queryFn: async () => {
      const response: Array<{ balance: number }> =
        (await costCenterService.getBalanceByCostCenterDonationCategory(
          costCenterId,
          donationId,
          categoryId,
        )) as Array<{ balance: number }>;
      return response;
    },
    placeholderData: keepPreviousData,
    staleTime: COST_CENTERS_STALE_TIME,
    enabled: !!costCenterId && !!donationId && !!categoryId,
  });
};

const createCostCenterMutationFn = async (data: CostCenterFormTypeApi) => {
  return await costCenterService.create(data);
};

const editCostCenterMutationFn: MutationFunction<
  CostCenterApi,
  [CostCenterApi['id'], CostCenterFormType]
> = async (params) => {
  const [id, data] = params;

  let parsedData;

  if (data.territory) {
    parsedData = {
      territory: {
        id: data.territory,
      },
    };
  }

  if (data.project && data.code) {
    parsedData = {
      code: data.code,
      project: data.project,
      territory: data.territory,
    };

    return (await costCenterService.edit(id, parsedData)) as CostCenterApi;
  } else {
    throw new Error('Cost Center project or code were not selected.');
  }
};

const deleteCostCenterMutationFn = async (id: CostCenterApi['id']) => {
  return await costCenterService.delete(id);
};

export {
  COST_CENTERS_KEY,
  useCostCenters,
  useCostCenter,
  useCostCentersList,
  useCostCenterByDonation,
  useCostCenterByCategory,
  useGetGlobalCostCenter,
  createCostCenterMutationFn,
  editCostCenterMutationFn,
  deleteCostCenterMutationFn,
  useBalanceByCostCenterDonationCategory,
};
