import { CURRENCY_OPTIONS, YEAR_OPTIONS, DEFAULT_NAME_SCHEMA } from '@/common/constants';
import { z } from 'zod';
import moment from 'moment';

const REQUIRED_OPTION_LABEL = 'Seleccione una opción';
const REQUIRED_AMOUNT_LABEL = 'Ingrese un monto válido';

const SelectSchema = z
  .object({
    id: z.string().min(1, REQUIRED_OPTION_LABEL),
    name: z.string(),
  })
  .required();

const CategoryDonationSchema = z.object({
  id: z.string().min(1, REQUIRED_OPTION_LABEL),
  amount: z.coerce.number().min(0, REQUIRED_AMOUNT_LABEL),
});

const DonationSchema = z
  .object({
    name: DEFAULT_NAME_SCHEMA,
    fiscalSponsor: SelectSchema,
    donor: SelectSchema,
    amount: z.coerce.number().min(0, REQUIRED_AMOUNT_LABEL),
    currency: z.string().min(1, REQUIRED_OPTION_LABEL),
    exchangeRate: z.coerce.number().min(0, REQUIRED_AMOUNT_LABEL),
    amountConverted: z.coerce.number().min(0, REQUIRED_AMOUNT_LABEL),
    year: z.string().min(1, REQUIRED_OPTION_LABEL),
    state: z.string().optional(),
    entryDate: z.date({
      required_error: 'Seleccione una fecha de ingreso',
    }),
    reportDate: z.date({
      required_error: 'Seleccione una fecha de reporte',
    }),
    files: z.array(z.string()).optional(),
    globalCostCenter: CategoryDonationSchema,
    costCenters: z
      .array(
        z.object({
          id: z.string().min(1, REQUIRED_OPTION_LABEL),
          name: z.string(),
          categories: z.array(CategoryDonationSchema),
        }),
      )
      .min(1, 'Seleccione al menos un centro de costo'),
    description: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    const totalAmount = data.costCenters.reduce((acc, costCenter) => {
      const costCenterSum = costCenter.categories.reduce((acc, category) => {
        return Number(acc) + Number(category.amount);
      }, 0);
      return Number(acc) + costCenterSum;
    }, 0);

    if (totalAmount !== Number(data.amount - data.globalCostCenter.amount)) {
      return ctx.addIssue({
        code: 'custom',
        message: 'La suma de los montos de las categorías no coincide con el monto total',
        path: ['costCenters'],
      });
    }
  });

type DonationFormType = z.infer<typeof DonationSchema>;

type CategoryDonationFormType = z.infer<typeof CategoryDonationSchema>;

const defaultDonationFormValues: DonationFormType = {
  name: '',
  fiscalSponsor: {
    id: '',
    name: '',
  },
  donor: {
    id: '',
    name: '',
  },
  exchangeRate: 1,
  amount: 0,
  amountConverted: 0,
  currency: CURRENCY_OPTIONS[0].value,
  year: YEAR_OPTIONS[0].value,
  entryDate: moment().toDate(),
  reportDate: moment().add(1, 'years').toDate(),
  files: [],
  state: '',
  globalCostCenter: {
    id: '',
    amount: 0,
  },
  costCenters: [
    {
      id: '',
      name: '',
      categories: [
        {
          id: '',
          amount: 0,
        },
      ],
    },
  ],
};

export type { DonationFormType, CategoryDonationFormType };
export { DonationSchema, defaultDonationFormValues };
