import { LedgerAccountApi } from '@/api/ledgerAccount/ledgerAccount.model';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type LedgerAccountStore = {
  ledgerAccount: LedgerAccountApi;
  setLedgerAccount: (ledgerAccount: LedgerAccountApi) => void;
};

const useLedgerAccountStore = create<LedgerAccountStore>()(
  persist(
    (set) => ({
      ledgerAccount: {} as LedgerAccountApi,
      setLedgerAccount: (ledgerAccount) => set({ ledgerAccount }),
    }),
    {
      name: 'ledger-account-storage',
    },
  ),
);

export default useLedgerAccountStore;
