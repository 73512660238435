import { FC, useState } from 'react';
import { RoutedTabs } from '@/components/shared/RoutedTabs';
import { UseFormReturn } from 'react-hook-form';
import { InvoiceFormType } from '../utils/InvoiceSchema';
import { ExpensesTab } from './ExpensesTab';
import { PaymentsTab } from './PaymentsTab';
import { CreditNotesTab } from './CreditNotesTab';
import { AccountingTab } from './AccountingTab';
import {
  ACCOUNTING_SUM_ERROR_MESSAGE,
  ENTRY_WITHOUT_AMOUNT_ERROR_MESSAGE,
  EXPENSE_WITHOUT_AMOUNT_ERROR_MESSAGE,
} from '@/common/constants';
import { LedgerAccountList } from '@/api/ledgerAccount/ledgerAccount.model';
import { SupplierList } from '@/api/supplier/supplier.model';
import { PaymentState } from '@/api/invoice/invoice.model.ts';
import { ValidateAlertMessage } from '@/components/shared/ValidateAlertMessage';

interface Tab {
  id: string;
  label: string;
  path: string;
}

const INVOICE_FORM_TABS_CREATE: Array<Tab> = [
  { id: 'expenses', label: 'Gastos', path: 'expenses' },
  { id: 'accounting', label: 'Apuntes Contables', path: 'accounting' },
];

const INVOICE_FORM_TABS_PAYMENT: Array<Tab> = [
  ...INVOICE_FORM_TABS_CREATE,
  { id: 'payments', label: 'Pagos', path: 'payments' },
  { id: 'creditNotes', label: 'Notas de Crédito', path: 'creditNotes' },
];

interface InvoiceTabsProps {
  totalAmount: number;
  form: UseFormReturn<InvoiceFormType, unknown>;
  allowPayments: boolean;
  ledgerAccounts?: Array<LedgerAccountList>;
  isLoadingLedgerAccountsChildren: boolean;
  suppliers?: Array<SupplierList>;
  invoicePaymentState?: PaymentState;
  readonly?: boolean;
  onEdit?: boolean;
}

const InvoiceTabs: FC<InvoiceTabsProps> = ({
  totalAmount,
  form,
  allowPayments,
  ledgerAccounts,
  isLoadingLedgerAccountsChildren = false,
  suppliers,
  invoicePaymentState = PaymentState.PENDING,
  readonly = false,
  onEdit = false,
}) => {
  const [activeTab, setActiveTab] = useState<string>(INVOICE_FORM_TABS_CREATE[0].id);

  const expenseWithoutAmount = form.watch('expenses').some((expense) => expense.amount === 0);

  const entryWithoutAmount = form
    .watch('accountingEntries')
    .some((entry: { amount: number }) => entry.amount === 0);

  return (
    <div className="flex flex-col gap-4">
      <RoutedTabs
        tabs={allowPayments ? INVOICE_FORM_TABS_PAYMENT : INVOICE_FORM_TABS_CREATE}
        value={activeTab}
        onValueChange={setActiveTab}
      />
      {activeTab === INVOICE_FORM_TABS_CREATE[0].id ? (
        <>
          <ExpensesTab
            form={form}
            ledgerAccounts={ledgerAccounts}
            suppliers={suppliers}
            isLoadingLedgerAccounts={isLoadingLedgerAccountsChildren}
            readonly={readonly}
            onEdit={onEdit}
          />
          {expenseWithoutAmount && (
            <ValidateAlertMessage message={EXPENSE_WITHOUT_AMOUNT_ERROR_MESSAGE} />
          )}
        </>
      ) : null}
      {activeTab === INVOICE_FORM_TABS_CREATE[1].id ? (
        <>
          <AccountingTab
            form={form}
            ledgerAccounts={ledgerAccounts}
            isLoadingLedgerAccounts={isLoadingLedgerAccountsChildren}
            disabled={readonly}
            onEdit={onEdit}
          />
          {totalAmount !== 0 && <ValidateAlertMessage message={ACCOUNTING_SUM_ERROR_MESSAGE} />}
          {entryWithoutAmount && (
            <ValidateAlertMessage message={ENTRY_WITHOUT_AMOUNT_ERROR_MESSAGE} />
          )}
        </>
      ) : null}
      {activeTab === INVOICE_FORM_TABS_PAYMENT[2].id ? (
        <PaymentsTab showCTA={invoicePaymentState == PaymentState.PENDING} />
      ) : null}
      {activeTab === INVOICE_FORM_TABS_PAYMENT[3].id ? (
        <CreditNotesTab showCTA={invoicePaymentState == PaymentState.PENDING} />
      ) : null}
    </div>
  );
};

export { InvoiceTabs };
