import { CURRENCY_OPTIONS } from '@/common/constants';
import { z } from 'zod';

const REQUIRED_OPTION_LABEL = 'Seleccione una opción';
const REQUIRED_AMOUNT_LABEL = 'Ingrese un monto válido';

const SelectSchema = z
  .object({
    id: z.string().min(1, REQUIRED_OPTION_LABEL),
    name: z.string(),
  })
  .required();

const ExpenseReportFundSchema = z.object({
  employee: SelectSchema,
  ledgerAccount: SelectSchema,
  donation: SelectSchema,
  costCenter: SelectSchema,
  category: SelectSchema,
  amount: z.coerce
    .number({ invalid_type_error: REQUIRED_AMOUNT_LABEL })
    .min(1, REQUIRED_AMOUNT_LABEL),
  currency: z.string().min(1, REQUIRED_OPTION_LABEL),
  exchangeRate: z.coerce.number().min(0, REQUIRED_AMOUNT_LABEL),
  amountConverted: z.coerce.number().min(0, REQUIRED_AMOUNT_LABEL),
  description: z.string().min(1, 'Ingrese una descripción'),
  files: z.array(z.string()).optional(),
});

const defaultExpenseReportFundFormValues = {
  employee: { id: '', name: '' },
  ledgerAccount: { id: '', name: '' },
  donation: { id: '', name: '' },
  costCenter: { id: '', name: '' },
  category: { id: '', name: '' },
  amount: 0,
  currency: CURRENCY_OPTIONS[0].value,
  exchangeRate: 1,
  amountConverted: 0,
  description: '',
  files: [],
};

type ExpenseReportFundFormType = z.infer<typeof ExpenseReportFundSchema>;

export { ExpenseReportFundSchema, defaultExpenseReportFundFormValues };
export type { ExpenseReportFundFormType };
