import {
  AccountingEntryInInvoiceDTO,
  ExpenseInInvoiceDTO,
  InvoiceApi,
} from '@/api/invoice/invoice.model';
import { AccountingEntryItemFormType } from '@/pages/AccountsMayor/AccountingEntry/AccountingEntryItem/AccountingEntryItemSchema';
import { ExpenseFormType } from '@/pages/Expenses/utils/ExpenseSchema';
import { InvoiceFormType } from '@/pages/Invoices/utils/InvoiceSchema';
import moment from 'moment';
import { Money } from '@/common/types.ts';

const getAccountEntryAmount = (
  invoiceCurrency: string,
  amount: number,
  money: Money,
  credit: boolean,
): number => {
  if (invoiceCurrency === 'ARS') {
    return credit ? -amount : amount;
  } else {
    return money.currency === 'ARS'
      ? credit
        ? -money.amount / money.exchangeRate
        : money.amount / money.exchangeRate
      : credit
        ? -money.amount
        : money.amount;
  }
};

const generateExpenses = (expenses: Array<ExpenseInInvoiceDTO>): Array<ExpenseFormType> => {
  return expenses.map((expense) => {
    return {
      id: expense.id,
      description: expense.description || '',
      global: expense.global,
      amount: expense.amount,
      ledgerAccount: {
        id: expense.ledgerAccount?.id || '',
        name: `${expense.ledgerAccount.code}. ${expense.ledgerAccount?.name}` || '',
      },
      donation: expense.donation
        ? {
            id: expense.donation.id,
            name: expense.donation.name,
          }
        : null,
      costCenter: {
        id: expense.costCenter?.id || '',
        name: expense.costCenter?.name || '',
      },
      category: {
        id: expense.category?.id || '',
        name: expense.category?.name || '',
      },
      money: expense.money,
    };
  });
};

const generateAccountingEntries = (
  accountingEntries: Array<AccountingEntryInInvoiceDTO>,
  currency: string,
): Array<AccountingEntryItemFormType> => {
  return accountingEntries.map((entry) => {
    return {
      id: entry.id,
      currency: currency,
      exchangeRateOfficial: entry.moneyOfficial?.exchangeRate || 1,
      exchangeRateAlt: 0,
      ledgerAccount: {
        id: entry.ledgerAccount.id,
        name: `${entry.ledgerAccount?.code}. ${entry.ledgerAccount?.name}`,
      },
      details: entry.details,
      amount: entry.moneyOfficial
        ? getAccountEntryAmount(currency, entry.amount, entry.moneyOfficial, entry.credit)
        : entry.amount,
      type: entry.type,
      isSupplierEntry: entry.type === 'INVOICE',
      transaction: entry.transaction,
    };
  });
};

const generateInvoiceForm = (invoice: InvoiceApi): InvoiceFormType => {
  const supplier = invoice.supplier
    ? {
        id: invoice.supplier.id,
        name: invoice.supplier.name,
        value: invoice.supplier.name,
        ledgerAccount: invoice.supplier.ledgerAccount,
        accounts: invoice.supplier.accounts || [],
      }
    : { id: '', name: '', accounts: [] };
  const expenses = generateExpenses(invoice.expenses || []);

  const accountingEntries = generateAccountingEntries(
    invoice.accountingEntries || [],
    invoice.money.currency,
  );

  return {
    receiptNumber: invoice.receiptNumber || '',
    supplier: supplier,
    supplierAccount: invoice.expenses
      ? {
          id: invoice.expenses[0].supplierAccount?.id || '',
          cbu: invoice.expenses[0].supplierAccount?.cbu || '',
          name: invoice.expenses[0].supplierAccount?.id
            ? `${invoice.expenses[0].supplierAccount?.currency} - ${invoice.expenses[0].supplierAccount?.cbu}`
            : '',
        }
      : { id: '' },
    files: invoice.fileURL ? [invoice.fileURL] : [],
    details: invoice.details || '',
    currency: invoice.money?.currency,
    exchangeRate: invoice.money?.exchangeRate,
    exchangeRateAlt: invoice.moneyAlt?.exchangeRate,
    invoiceState: invoice.invoiceState,
    paymentState: invoice.paymentState,
    dueDate: moment(invoice.dueDate).toDate(),
    receiptDate: moment(invoice.receiptDate).toDate(),
    accountingDate: moment(invoice.accountingDate).toDate(),
    expenses: expenses,
    accountingEntries: accountingEntries,
  };
};

export { generateExpenses, generateInvoiceForm };
