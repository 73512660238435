import { DEFAULT_EMAIL_SCHEMA } from '@/common/constants';
import { z } from 'zod';

export const LoginSchema = z
  .object({
    email: DEFAULT_EMAIL_SCHEMA,
    password: z.string().min(8, { message: 'La contraseña debe contener al menos 8 caracteres' }),
  })
  .required();

export type LoginFormType = z.infer<typeof LoginSchema>;

export const defaultLoginValues: LoginFormType = { email: '', password: '' };
