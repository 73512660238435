import { z } from 'zod';
import { AccountingEntryItemSchema } from './AccountingEntryItem/AccountingEntryItemSchema';
import { CURRENCY_OPTIONS } from '@/common/constants';
import { fixAmount } from '@/lib/utils';

const AccountingEntrySchema = z
  .object({
    paymentDate: z.date({
      required_error: 'Seleccione una fecha de cobro',
    }),
    accountingEntries: z
      .array(AccountingEntryItemSchema)
      .min(2, 'Ingrese al menos dos partidas contables'),
    transactionId: z.string().optional(),
    donationId: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    const balance = data.accountingEntries.reduce(
      (acc, entry) => acc + entry.amount * entry.exchangeRateOfficial,
      0,
    );

    if (fixAmount(balance, 2) !== 0) {
      return ctx.addIssue({
        code: 'custom',
      });
    }
  });

type AccountingEntryFormType = z.infer<typeof AccountingEntrySchema>;

const defaultAccountingEntryFormValues: AccountingEntryFormType = {
  paymentDate: new Date(),
  accountingEntries: [
    {
      ledgerAccount: {
        id: '',
        name: '',
      },
      currency: CURRENCY_OPTIONS[0].value,
      amount: 0,
      exchangeRateOfficial: 1,
      exchangeRateAlt: 1,
      details: '',
    },
    {
      ledgerAccount: {
        id: '',
        name: '',
      },
      currency: CURRENCY_OPTIONS[0].value,
      amount: 0,
      exchangeRateOfficial: 1,
      exchangeRateAlt: 1,
      details: '',
    },
  ],
};

export type { AccountingEntryFormType };
export { AccountingEntrySchema, defaultAccountingEntryFormValues };
