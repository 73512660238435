import { QueryKey } from '@tanstack/react-query';
import { HttpStatusCode, ResponseType } from 'axios';
import { DonationState } from './donation/donation.model';

interface Pageable {
  pageNumber: number;
  pageSize: number;
  sort: Sort;
  offset: number;
  paged: boolean;
  unpaged: boolean;
}

interface Sort {
  sorted: boolean;
  empty: boolean;
  unsorted: boolean;
}

interface ResponsePaginated<T> {
  content: T[];
  pageable: Pageable;
  last: boolean;
  totalPages: number;
  totalElements: number;
  size: number;
  number: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

interface Request {
  headers?: Record<string, string>;
  data?: any;
  params?: any;
  responseType?: ResponseType;
  timeout?: number;
}

type QuerySortParamOptions = 'asc' | 'desc';
type InvoiceStateParamOptions = 'DRAFT' | 'PUBLISHED';
type PaymentStateParamOptions = 'PAYED' | 'PENDING' | 'CANCELED';

interface QueryRequestParams<T> {
  queryKey?: QueryKey;
  page?: number;
  size?: number;
  sort?: keyof T;
  dir?: QuerySortParamOptions;
  search?: string;
  staleTime?: number;
}

interface InvoiceQueryParams<T> extends QueryRequestParams<T> {
  invoiceState?: InvoiceStateParamOptions | '';
  paymentState?: PaymentStateParamOptions | '';
  supplier?: string;
  details?: string;
  dueDate?: string;
}

interface ProjectQueryParams<T> extends QueryRequestParams<T> {
  territories?: string;
}

interface CostCenterQueryParams<T> extends QueryRequestParams<T> {
  project?: string;
  territory?: string;
}

interface DonationQueryParams<T> extends QueryRequestParams<T> {
  costCenters?: string;
  state?: DonationState | '';
}

interface FiscalSponsorQueryParams<T> extends QueryRequestParams<T> {
  projects?: string;
}

interface TerritoryQueryParams<T> extends QueryRequestParams<T> {
  projects?: string;
}

interface AccountingEntryQueryParams<T> extends QueryRequestParams<T> {
  date?: string;
}

interface AccountingEntryByLAQueryParams<T> extends QueryRequestParams<T> {
  startDate?: string;
  endDate?: string;
}

type ErrorOptions = 'DUPLICATED_ERROR' | 'PARAM_NOT_FOUND' | 'ENTITY_RELATION';

type FiscalPersonType = 'INDIVIDUAL' | 'LEGAL_ENTITY';

const fiscalPersonTypeMap: { [key in FiscalPersonType]: string } = {
  INDIVIDUAL: 'Persona Física',
  LEGAL_ENTITY: 'Persona Jurídica',
};

type AfipConditionType =
  | 'RESPONSABLE_INSCRIPTO'
  | 'AUTONOMO'
  | 'EXENTO'
  | 'CONSUMIDOR_FINAL'
  | 'NO_RESPONSABLE'
  | 'NO_CATEGORIZADO'
  | 'RESPONSABLE_MONOTRIBUTO';

const afipConditionTypeMap: { [key in AfipConditionType]: string } = {
  RESPONSABLE_INSCRIPTO: 'Responsable Inscripto',
  AUTONOMO: 'Autónomo',
  EXENTO: 'Exento',
  CONSUMIDOR_FINAL: 'Consumidor Final',
  NO_RESPONSABLE: 'No Responsable',
  NO_CATEGORIZADO: 'No Categorizado',
  RESPONSABLE_MONOTRIBUTO: 'Responsable Monotributo',
};

interface ErrorResponse {
  status: HttpStatusCode;
  type?: ErrorOptions;
  message?: string;
  errors?: Array<string>;
}

type CostCenterBoxItem = {
  name: string;
  id: string;
  balance?: number;
  categories: {
    id: string;
    amount: number;
  }[];
};

export type {
  ResponsePaginated,
  Request,
  QueryRequestParams,
  InvoiceQueryParams,
  QuerySortParamOptions,
  ErrorResponse,
  FiscalPersonType,
  AfipConditionType,
  ProjectQueryParams,
  CostCenterQueryParams,
  DonationQueryParams,
  FiscalSponsorQueryParams,
  TerritoryQueryParams,
  CostCenterBoxItem,
  AccountingEntryQueryParams,
  AccountingEntryByLAQueryParams,
};
export { fiscalPersonTypeMap, afipConditionTypeMap };
