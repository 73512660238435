import { FC, ReactNode } from 'react';

interface LayoutProps {
  children: ReactNode;
}

const PageContent: FC<LayoutProps> = ({ children }) => {
  return <section className="pt-4 pb-8 px-8">{children}</section>;
};

export { PageContent };
