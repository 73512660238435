import { Card } from '@/components/ui/card';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { SupplierFormType } from '../utils/SupplierSchema';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Trash } from 'lucide-react';
import { FormField, FormItem } from '@/components/ui/form';

interface BankAccountBoxParams {
  form: UseFormReturn<SupplierFormType, unknown>;
  id: string | undefined;
  index: number;
}

const BankAccountBox: FC<BankAccountBoxParams> = ({ form, id, index }) => {
  const deleteAccountHandler = () => {
    const accounts = form.getValues('accounts');
    const newAccounts = accounts?.filter((account) => account.id !== id);
    form.setValue('accounts', newAccounts);
  };

  return (
    <div className="w-full my-1">
      <Card className="shadow-sm bg-slate-50">
        <div className="flex justify-between w-full items-center">
          <div className="w-3/5 p-3">
            <FormField
              name={`accounts.${index}.cbu`}
              render={({ field }) => (
                <FormItem>
                  <Label>CBU</Label>
                  <Input
                    type="text"
                    placeholder="Ingrese # de CBU"
                    className="p-3"
                    onChange={field.onChange}
                    value={field.value}
                  />
                </FormItem>
              )}
            />
          </div>
          <div className="w-1/3 p-3">
            <FormField
              name={`accounts.${index}.currency`}
              render={({ field }) => (
                <FormItem>
                  <Label>Moneda</Label>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <SelectTrigger>
                      <SelectValue placeholder="Seleccione una moneda" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="ARS">ARS</SelectItem>
                      <SelectItem value="USD">USD</SelectItem>
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />
          </div>
          <div className="pt-6 pr-3">
            <Button
              type="button"
              variant="outlineDestructive"
              className="p-3"
              onClick={deleteAccountHandler}
              disabled={form.getValues('accounts')?.length === 1}
            >
              <Trash className="w-4 h-4" />
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export { BankAccountBox };
