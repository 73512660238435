import { Accordion } from '@/components/ui/accordion';
import { FC, useEffect, useState } from 'react';
import { CostCenterBox } from './CostCenterBox';
import { UseFormReturn, useFieldArray } from 'react-hook-form';
import { DonationFormType } from '../utils/DonationSchema';
import { Button } from '@/components/ui/button';
import { PlusCircle } from 'lucide-react';
import { useCostCentersList } from '@/api/costCenter/costCenter.api';

interface CostCenterFormProps {
  form: UseFormReturn<DonationFormType, unknown>;
  disabled?: boolean;
}

const CostCenterForm: FC<CostCenterFormProps> = ({ form, disabled }: CostCenterFormProps) => {
  const [activeCostCenter, setActiveCostCenter] = useState<string>('-0');
  const watchedCostCenters = form.watch('costCenters');

  const { data: costCenters, isLoading: isLoadingCostCenters } = useCostCentersList();

  useEffect(() => {
    if (costCenters) {
      const globalCostCenter = costCenters.find((costCenter) => costCenter.global === true);
      if (globalCostCenter) {
        form.setValue('globalCostCenter.id', globalCostCenter.id);
      }
    }
  }, [costCenters]);

  const costCentersWithoutGlobal = costCenters?.filter((costCenter) => costCenter.global === false);

  const costCentersWithoutSelected = costCentersWithoutGlobal?.filter((costCenter) => {
    return !watchedCostCenters.find((watchedCostCenter) => watchedCostCenter.id === costCenter.id);
  });

  const {
    // fields,
    append: appendCostCenter,
    remove: removeCostCenter,
  } = useFieldArray<DonationFormType>({
    control: form.control,
    name: 'costCenters',
  });

  const handleActiveCostCenterChange = (newActive: string) => {
    if (newActive === activeCostCenter) {
      setActiveCostCenter('');
    } else {
      setActiveCostCenter(newActive);
    }
  };

  const handleAddCostCenter = () => {
    appendCostCenter({
      id: '',
      name: '',
      categories: [{ id: '', amount: 0 }],
    });
    setActiveCostCenter((-watchedCostCenters.length).toString());
  };

  return (
    <div className="flex flex-col gap-5">
      <Accordion
        type="single"
        value={activeCostCenter}
        collapsible
        className="flex flex-col w-full gap-4"
      >
        {isLoadingCostCenters ? (
          <p>Loading</p>
        ) : costCenters ? (
          watchedCostCenters.map((field, index) => (
            <fieldset key={field.id} className="flex w-full">
              <CostCenterBox
                field={field}
                index={index}
                value={`${field.id}-${index}`}
                form={form}
                costCentersList={costCentersWithoutGlobal || []}
                costCenterOptions={costCentersWithoutSelected || []}
                isOpen={activeCostCenter === `${field.id}-${index}`}
                onChevronClick={handleActiveCostCenterChange}
                onRemove={() => removeCostCenter(index)}
                setActiveCostCenter={setActiveCostCenter}
                disabled={disabled}
              />
            </fieldset>
          ))
        ) : (
          <p>No existen Centros de Costos</p>
        )}
      </Accordion>
      {disabled ? null : (
        <Button type="button" className="text-xs w-fit" onClick={handleAddCostCenter}>
          <PlusCircle className="w-4 h-4 mr-2" />
          Agregar Centro de Costos
        </Button>
      )}
    </div>
  );
};

export { CostCenterForm };
