import { ColumnDef } from '@tanstack/react-table';
import { Category } from '@/api/category/category.model';
import { SortColumnWithBe } from '@/components/shared/SortColumnWithBe';
import { formatAmount } from '@/lib/utils.ts';

const categoriesTableColumns: ColumnDef<Category>[] = [
  {
    accessorKey: 'name',
    header: ({ column }) => <SortColumnWithBe title="Nombre" column={column} />,
  },
  {
    accessorKey: 'total',
    header: () => <p className="text-xs">Total</p>,
    cell: ({ row }) => {
      const amount = formatAmount(row.original.total);
      return (
        <p className={Number.parseFloat(amount) < 0 ? 'text-rose-700' : 'text-green-700'}>
          {amount}
        </p>
      );
    },
  },
  {
    accessorKey: 'expenses',
    header: () => <p className="text-xs">Gastos</p>,
    cell: ({ row }) => {
      const amount = formatAmount(row.original.expenses);
      return <p className="text-rose-700">{amount}</p>;
    },
  },
  {
    accessorKey: 'balances',
    header: () => <p className="text-xs">Balance</p>,
    cell: ({ row }) => {
      const amount = formatAmount(row.original.balance);
      return <p className="text-green-700">{amount}</p>;
    },
  },
];

export { categoriesTableColumns };
