/* eslint-disable sonarjs/no-duplicate-string */
import { FC, useMemo } from 'react';
import { InvoiceFormType } from '../utils/InvoiceSchema';
import { FieldValues, FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { FileUploader } from '@/components/shared/FileUploader';
import { Button } from '@/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { format } from 'date-fns';
import { Calendar } from '@/components/ui/calendar';
import { es } from 'date-fns/locale';
import { cn, fixAmount } from '@/lib/utils';
import { CalendarIcon } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { useSupplierList } from '@/api/supplier/supplier.api';
import { SelectFilter } from '@/components/shared/SelectFilter';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { CURRENCY_OPTIONS } from '@/common/constants';
import { useLedgerAccountChildrens } from '@/api/ledgerAccount/ledgerAccount.api';
import { Supplier } from '@/api/supplier/supplier.model';
import { InvoiceTabs } from './InvoiceTabs';
import { InvoiceApi, InvoiceState } from '@/api/invoice/invoice.model';
import { Separator } from '@/components/ui/separator';
import { TotalsCard } from '@/components/shared/TotalsCard';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

interface InvoiceFormProps {
  form: UseFormReturn<InvoiceFormType, unknown>;
  submitLabel?: string;
  cancelLabel: string;
  invoice?: InvoiceApi;
  isLoading?: boolean;
  allowPayments?: boolean;
  submitHandler?: SubmitHandler<InvoiceFormType>;
  dismissHandler: () => void;
  readonly?: boolean;
  onEdit?: boolean;
}

const InvoiceForm: FC<InvoiceFormProps> = ({
  form,
  isLoading,
  submitLabel,
  cancelLabel,
  invoice,
  allowPayments = false,
  submitHandler,
  dismissHandler,
  readonly = false,
  onEdit = false,
}) => {
  const { data: suppliers, isLoading: isLoadingSuppliers } = useSupplierList();
  const { data: ledgerAccountsChildren, isLoading: isLoadingLedgerAccountsChildren } =
    useLedgerAccountChildrens();

  const totalAmount = fixAmount(
    form.watch('accountingEntries').reduce((acc, entry) => {
      return acc + (entry ? entry.amount : 0);
    }, 0),
    2,
  );

  const totalExpensesAmount = form.watch('expenses').reduce((acc, exp) => {
    return acc + (exp ? exp.amount : 0);
  }, 0);

  const accounts = useMemo(() => {
    if (form.watch('supplier') && form.watch('supplier').id) {
      const filteredSuppliers = suppliers?.find(
        (supplier) => supplier.id === form.watch('supplier')?.id,
      )?.accounts;
      return filteredSuppliers?.map((account) => ({
        id: account.id,
        cbu: account.cbu,
        name: `${account.currency} - ${account.cbu}`,
      }));
    }
  }, [form.watch('supplier')]);

  const handleChangeCurrency = (value: string) => {
    form.setValue('currency', value);
    if (value === 'ARS') {
      form.setValue('exchangeRate', 1);
    }
  };

  const handleChangeSupplier = (selectedSupplier: Supplier) => {
    form.setValue('supplier', selectedSupplier);
    form.setValue('supplierAccount', { id: '' }, { shouldValidate: true });

    const selectedSupplierLedgerAccount = ledgerAccountsChildren?.find(
      (account) => account.id === selectedSupplier?.ledgerAccount,
    );

    if (!selectedSupplierLedgerAccount) return;

    const currency = form.getValues('currency');

    const accountingEntries = form.getValues('accountingEntries');

    const supplierAccountingEntryIndex = accountingEntries.findIndex(
      (entry) => entry.isSupplierEntry,
    );

    const existingId =
      supplierAccountingEntryIndex >= 0 ? accountingEntries[supplierAccountingEntryIndex].id : '';

    const createSupplierAccountingEntry = () => ({
      id: existingId,
      ledgerAccount: {
        id: selectedSupplierLedgerAccount.id,
        name: selectedSupplierLedgerAccount.name,
      },
      amount: -totalExpensesAmount,
      currency,
      exchangeRateOfficial: 1,
      exchangeRateAlt: 1,
      details: '',
      isSupplierEntry: true,
    });

    const filteredEntries = accountingEntries.filter((entry) => !entry.isSupplierEntry);

    const updatedEntries = [...filteredEntries, createSupplierAccountingEntry()];

    form.setValue('accountingEntries', updatedEntries);
  };

  const handleCalendarSelect = (date: Date | undefined, field: FieldValues) => {
    if (date && field.value?.toDateString() !== date.toDateString()) {
      field.onChange(date);
      if (field.name === 'receiptDate') {
        form.setValue('accountingDate', date);
      }
    }
  };

  const calcNewPendingAmount = useMemo(() => {
    if (invoice?.pendingAmount && invoice?.total) {
      return invoice.pendingAmount + (totalExpensesAmount - invoice?.total);
    }
    if (invoice?.pendingAmount === 0) return invoice.pendingAmount;
    return totalExpensesAmount;
  }, [totalExpensesAmount, form.watch('accountingEntries')]);

  const handleChangeExchangeRate = (value: number) => {
    form.setValue('exchangeRate', value);
    const accountingEntries = form.getValues('accountingEntries');
    accountingEntries.forEach((_, index) => {
      form.setValue(`accountingEntries.${index}.exchangeRateOfficial`, value);
    });
  };

  return (
    <FormProvider {...form}>
      <form
        className="flex flex-col flex-1"
        onSubmit={submitHandler && form.handleSubmit(submitHandler)}
      >
        <div className={'flex flex-col flex-1 w-full max-w-screen-2xl mx-auto mb-[60px] px-14'}>
          <div className="flex flex-col gap-[50px]">
            <div className="flex gap-20">
              <div className="flex flex-col w-full gap-10">
                <div className="flex flex-row gap-2">
                  <div className="flex flex-row gap-6 w-4/6 border-r-slate-200 items-center  border-r-[1px] pr-6">
                    <FormField
                      control={form.control}
                      name="receiptNumber"
                      disabled={readonly}
                      render={({ field }) => (
                        <FormItem className="w-[140px]">
                          <FormLabel>Factura #</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              autoFocus
                              type="text"
                              placeholder="Ingrese N° de Factura"
                              className="truncate"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div className="w-[170px]">
                            <SelectFilter
                              form={form}
                              isLoading={isLoadingSuppliers}
                              disabled={readonly}
                              formName="supplier"
                              formLabel="Proveedor"
                              formPlaceholder="Seleccionar Proveedor"
                              formFilterPlaceholder="Buscar Proveedor..."
                              options={suppliers || []}
                              classnames={{
                                button: 'w-full',
                                item: 'w-full',
                                popover: 'min-w-[250px]',
                              }}
                              onChange={handleChangeSupplier}
                            />
                          </div>
                        </TooltipTrigger>
                        <TooltipContent
                          side="top"
                          className={cn(
                            'w-[200px]',
                            form.getValues('supplier')?.name ? '' : 'hidden',
                          )}
                        >
                          <p className="text-xs">{form.getValues('supplier')?.name}</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                    <SelectFilter
                      form={form}
                      isLoading={!form.watch('supplier')}
                      disabled={!accounts?.length}
                      formName="supplierAccount"
                      formLabel="Cuenta Bancaria"
                      formPlaceholder="Seleccionar Cuenta"
                      formFilterPlaceholder="Buscar Cuenta..."
                      options={accounts || []}
                      classnames={{ button: 'w-full', item: 'w-2/5' }}
                    />
                  </div>
                  <div className="flex flex-row gap-6 w-2/6 pl-4">
                    <FormField
                      control={form.control}
                      name="currency"
                      render={({ field }) => (
                        <FormItem className="w-full">
                          <FormLabel>Moneda</FormLabel>
                          <Select
                            onValueChange={(value) => handleChangeCurrency(value)}
                            value={field.value}
                            disabled={readonly || onEdit}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Moneda" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {CURRENCY_OPTIONS.map((currency) => (
                                <SelectItem key={currency.value} value={currency.value}>
                                  {currency.name}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="exchangeRate"
                      disabled={readonly}
                      render={({ field }) => (
                        <FormItem className="w-full">
                          <FormLabel>TC Oficial</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              type="number"
                              disabled={invoice && invoice?.invoiceState !== InvoiceState.DRAFT}
                              placeholder="Ingresar Conversión"
                              value={field.value || ''}
                              onChange={(e) => {
                                handleChangeExchangeRate(Number(e.target.value));
                              }}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="exchangeRateAlt"
                      disabled={readonly}
                      render={({ field }) => (
                        <FormItem className="w-full">
                          <FormLabel>TC Alt.</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              type="number"
                              disabled={invoice && invoice?.invoiceState !== InvoiceState.DRAFT}
                              placeholder="Ingresar Conversión"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
                <div className="flex flex-row ">
                  <div className="flex flex-col gap-2">
                    <FormLabel>Comprobante</FormLabel>
                    <FileUploader
                      form={form}
                      disabled={readonly}
                      fieldName="files"
                      label="Seleccionar Archivo"
                      fileLimit={1}
                      initialFiles={form.watch('files')}
                      buttonClassname="justify-start w-[245px]"
                    />
                  </div>
                </div>
                <FormField
                  control={form.control}
                  name="details"
                  disabled={readonly}
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel>Descripción</FormLabel>
                      <FormControl>
                        <Textarea
                          placeholder="Ingrese aquí la descripción del gasto..."
                          className="resize-none"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <Separator orientation="vertical" className="h-auto" />
              <div className="flex flex-col w-1/4 gap-6">
                <FormField
                  control={form.control}
                  name="receiptDate"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel>Fecha de Comprobante</FormLabel>
                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant={'outline'}
                              className={cn(
                                'w-full font-normal justify-start',
                                !field.value && 'text-muted-foreground',
                              )}
                              disabled={readonly}
                            >
                              <div
                                className={cn(
                                  'w-60 flex items-center justify-start',
                                  field.value ? 'text-sm font-normal' : 'text-slate-500',
                                )}
                              >
                                <CalendarIcon className="w-4 h-4 mr-2 opacity-50" />
                                {field.value ? (
                                  format(field.value, 'dd/MM/yyyy')
                                ) : (
                                  <span>Selecciona una fecha</span>
                                )}
                              </div>
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                          <Calendar
                            defaultMonth={form.getValues('receiptDate')}
                            mode="single"
                            showOutsideDays
                            fixedWeeks
                            captionLayout="dropdown"
                            fromYear={new Date().getFullYear() - 5}
                            toYear={new Date().getFullYear() + 10}
                            selected={field.value}
                            onSelect={(date) => handleCalendarSelect(date, field)}
                            locale={es}
                          />
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="dueDate"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel>Fecha de Pago</FormLabel>
                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant={'outline'}
                              disabled={readonly}
                              className={cn(
                                'w-full font-normal justify-start',
                                !field.value && 'text-muted-foreground',
                              )}
                            >
                              <div
                                className={cn(
                                  'w-60 flex items-center justify-start',
                                  field.value ? 'text-sm font-normal' : 'text-slate-500',
                                )}
                              >
                                <CalendarIcon className="w-4 h-4 mr-2 opacity-50" />
                                {field.value ? (
                                  format(field.value, 'dd/MM/yyyy')
                                ) : (
                                  <span>Selecciona una fecha</span>
                                )}
                              </div>
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                          <Calendar
                            defaultMonth={form.getValues('dueDate')}
                            mode="single"
                            showOutsideDays
                            fixedWeeks
                            captionLayout="dropdown"
                            fromYear={new Date().getFullYear() - 5}
                            toYear={new Date().getFullYear() + 10}
                            selected={field.value}
                            onSelect={(date) => handleCalendarSelect(date, field)}
                            locale={es}
                          />
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="accountingDate"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel>Fecha Contable</FormLabel>
                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant={'outline'}
                              disabled={readonly}
                              className={cn(
                                'w-full font-normal justify-start',
                                !field.value && 'text-muted-foreground',
                              )}
                            >
                              <div
                                className={cn(
                                  'w-60 flex items-center justify-start',
                                  field.value ? 'text-sm font-normal' : 'text-slate-500',
                                )}
                              >
                                <CalendarIcon className="w-4 h-4 mr-2 opacity-50" />
                                {field.value ? (
                                  format(field.value, 'dd/MM/yyyy')
                                ) : (
                                  <span>Selecciona una fecha</span>
                                )}
                              </div>
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                          <Calendar
                            defaultMonth={form.getValues('accountingDate')}
                            mode="single"
                            showOutsideDays
                            fixedWeeks
                            captionLayout="dropdown"
                            fromYear={new Date().getFullYear() - 5}
                            toYear={new Date().getFullYear() + 10}
                            selected={field.value}
                            onSelect={(date) => handleCalendarSelect(date, field)}
                            locale={es}
                          />
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <InvoiceTabs
              totalAmount={totalAmount}
              form={form}
              allowPayments={allowPayments}
              ledgerAccounts={ledgerAccountsChildren}
              isLoadingLedgerAccountsChildren={isLoadingLedgerAccountsChildren}
              suppliers={suppliers}
              invoicePaymentState={invoice?.paymentState}
              readonly={readonly}
              onEdit={onEdit}
            />
          </div>
        </div>
        <div
          className={cn('sticky bottom-0 bg-white shadow-2xl border border-t-1', {
            'bg-white/80': invoice,
          })}
        >
          <div className="flex flex-col gap-4 py-4 px-14">
            {invoice ? (
              <div className="flex justify-end">
                <TotalsCard
                  total={{
                    label: 'TOTAL',
                    amount: totalExpensesAmount || 0,
                    currency: invoice?.money.currency || CURRENCY_OPTIONS[0],
                  }}
                  pending={{
                    label: 'Importe adeudado',
                    amount: calcNewPendingAmount || 0,
                    currency: invoice?.money.currency || CURRENCY_OPTIONS[0],
                  }}
                  className="py-3"
                />
              </div>
            ) : (
              <div className="flex justify-end">
                <TotalsCard
                  total={{
                    label: 'TOTAL',
                    amount: totalExpensesAmount || 0,
                    currency: (form.watch('currency') as 'USD' | 'ARS') || CURRENCY_OPTIONS[0],
                  }}
                  className="py-3"
                />
              </div>
            )}
            <div className="flex flex-row justify-end gap-2">
              <Button type="button" variant="outline" disabled={isLoading} onClick={dismissHandler}>
                {cancelLabel}
              </Button>
              {!readonly && (
                <Button
                  type="submit"
                  disabled={isLoading || !form.formState.isValid || !form.formState.isDirty}
                  className="relative"
                  isLoading={isLoading}
                >
                  {submitLabel}
                </Button>
              )}
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export { InvoiceForm };
