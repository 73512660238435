import { Dispatch, SetStateAction, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { QuerySortParamOptions } from '@/api/types';
import { DEFAULT_PAGINATION_DIR, DEFAULT_PAGINATION_SORT } from '@/common/constants';

type Filters<T> = {
  [key: string]: T;
};

type SetStateFunction<T> = (newValue: T) => void;

const useFilterAndSortByBe = <T,>(
  refetch: () => void,
  setDir: SetStateFunction<QuerySortParamOptions>,
  setSort: SetStateFunction<keyof T>,
  setFilters?: Dispatch<SetStateAction<Filters<string>>>,
  filterObj?: any,
  sortDefault?: any,
  dirDefault?: QuerySortParamOptions,
) => {
  const location = useLocation();

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    const setParameters = () => {
      const params = new URLSearchParams(location.search);

      // Set filters
      const newFilters: Filters<string> = {};
      if (setFilters) {
        const filterKeys = Object.keys(filterObj);
        filterKeys.forEach((key) => {
          const paramValue = params.get(key);
          if (paramValue) {
            newFilters[key] = paramValue;
          }
        });
        setFilters(newFilters);
      }

      // Set sort and dir
      const dirValue = params.get('dir') as QuerySortParamOptions;
      const sortValue = params.get('sort');
      setDir(dirValue ? dirValue : dirDefault ? dirDefault : DEFAULT_PAGINATION_DIR);
      setSort(sortValue ? sortValue : sortDefault ? sortDefault : DEFAULT_PAGINATION_SORT);

      // Refetch if any parameters are set
      if (Object.keys(newFilters).length || (dirValue && sortValue)) {
        refetch();
      }
    };

    setParameters();
  }, [location.search]);
};

export default useFilterAndSortByBe;
