import { useLedgerAccountByType } from '@/api/ledgerAccount/ledgerAccount.api';
import { useTablePagination } from '@/hooks/useTablePagination';
import { getLedgerAccountTableColumnsByType } from '../utils/LedgerAccountColumnsUtils';
import { Outlet } from 'react-router-dom';
import { TableWrapper } from '@/components/shared/TableWrapper';
import { DEFAULT_PAGINATION_PAGE_COUNT } from '@/common/constants';
import { LedgerAccountApi, LedgerAccountTypeEnum } from '@/api/ledgerAccount/ledgerAccount.model';
import useFilterAndSortByBe from '@/hooks/useFilterAndSortByBe';

const PAGE_TITLE = 'Tipos';

const LedgerAccountType = () => {
  const { pageIndex, pageSize, paginationState, dir, sort, setPagination, setDir, setSort } =
    useTablePagination<LedgerAccountApi>();

  const LedgerAccountTypeColumns = getLedgerAccountTableColumnsByType('Subtipos Asociados');

  const { data, isLoading, isFetching, refetch } = useLedgerAccountByType({
    page: pageIndex,
    size: pageSize,
    dir: dir,
    sort: sort,
    type: LedgerAccountTypeEnum.TYPE,
  });

  useFilterAndSortByBe<LedgerAccountApi>(
    refetch,
    setDir,
    setSort,
    undefined,
    undefined,
    'code',
    'asc',
  );

  return (
    <>
      <TableWrapper
        columns={LedgerAccountTypeColumns}
        data={data}
        isLoading={isLoading}
        isFetching={isFetching}
        rowsLabel={PAGE_TITLE}
        pageCount={data ? data.totalPages : DEFAULT_PAGINATION_PAGE_COUNT}
        pagination={paginationState}
        onPaginationChange={setPagination}
      />
      <Outlet />
    </>
  );
};

export default LedgerAccountType;
