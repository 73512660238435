import { FC } from 'react';
import {
  DEFAULT_PAGINATION_PAGE_COUNT,
  SONNER_ERROR_OPTIONS,
  SONNER_SUCCESS_OPTIONS,
} from '@/common/constants';
import { useTablePagination } from '@/hooks/useTablePagination';
import useTableColumnsWithActions from '@/hooks/useTableColumnsWithActions';
import { Outlet, useNavigate } from 'react-router-dom';
import { AlertDialogWrapper } from '@/components/shared/AlertDialog';
import { useMutation } from '@tanstack/react-query';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { AxiosError } from 'axios';
import { toast } from 'sonner';
import { TableWrapper } from '@/components/shared/TableWrapper';
import * as Sentry from '@sentry/react';
import { deleteDonationMutationFn, useDonations } from '@/api/donation/donation.api';
import { DonationTableColumns } from './utils/DonationTableColumns';
import { DonationApi } from '@/api/donation/donation.model';
import { pathsBase } from '@/routes/paths';
import { SearchBar } from '@/components/shared/SearchBar';
import { useSearchBar } from '@/hooks/useSearchBar';
import { useAlertDialog } from '@/hooks/useAlertDialog';
import useFilterAndSortByBe from '@/hooks/useFilterAndSortByBe';
import { defaultDonationFormValues } from './utils/DonationSchema';
import { Trash2 } from 'lucide-react';
import { getParamsWithAnimation, handleClearQueryParams } from '@/lib/utils';

const PAGE_TITLE = 'Donaciones';

const DonationPage: FC = () => {
  const navigate = useNavigate();

  const {
    pageIndex,
    pageSize,
    paginationState,
    dir,
    sort,
    setPagination,
    setDir,
    setSort,
    setFilters,
    filters,
  } = useTablePagination<DonationApi>();

  const { searchValue, form: searchBarForm, handleSubmit: handleSearchSubmit } = useSearchBar();

  const { data, isLoading, isFetching, refetch } = useDonations({
    page: pageIndex,
    size: pageSize,
    dir: dir,
    sort: sort,
    search: searchValue,
    costCenters: filters.costCenters,
    state: filters.state,
  });

  const { alertDialog, setAlertDialog, handleDismissAlertDialog } = useAlertDialog();

  const { mutate: deleteDonation, isPending: isDeleteDonationPending } = useMutation({
    mutationFn: deleteDonationMutationFn,
    onSuccess: () => {
      refetch();
      toast(
        <SuccessToastText>Donación eliminada exitosamente</SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismissAlertDialog();
    },
    onError: (error: AxiosError) => {
      Sentry.captureException(error);
      toast.error('No es posible eliminar la Donación debido a que tiene gastos asociados.', {
        description: 'Por favor, eliminá los gastos asociados e intentalo nuevamente.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  useFilterAndSortByBe<DonationApi>(
    refetch,
    setDir,
    setSort,
    setFilters,
    defaultDonationFormValues,
  );

  const handleDeleteDonation = (donation: DonationApi) => {
    setAlertDialog({
      title: `¿Estás seguro que queres eliminar "${donation.name}"? `,
      description: `Esta acción no puede ser deshecha y se perderá toda la información asociada a esta Donación ("${donation.name}").`,
      open: true,
      onCancel: handleDismissAlertDialog,
      onConfirm: () => deleteDonation(donation.id),
    });
  };

  const handleOpenDonationDetail = (donation: DonationApi) => {
    navigate(`/${pathsBase.DONATIONS}/${donation.id}${getParamsWithAnimation(location.search)}`);
  };

  const columns = DonationTableColumns();

  const DonationsColumnsWithActions = useTableColumnsWithActions<DonationApi>(
    columns,
    () => {},
    handleDeleteDonation,
    true,
    handleOpenDonationDetail,
  );

  return (
    <>
      <SearchBar
        form={searchBarForm}
        searchTerm="donación"
        isLoading={isLoading || isFetching}
        submitHandler={handleSearchSubmit}
      />
      <TableWrapper
        columns={DonationsColumnsWithActions}
        data={data ? data[0] : data}
        isLoading={isLoading}
        isFetching={isFetching}
        rowsLabel={PAGE_TITLE}
        pageCount={data ? data[0].totalPages : DEFAULT_PAGINATION_PAGE_COUNT}
        pagination={paginationState}
        onPaginationChange={setPagination}
        emptyCta={
          location.search
            ? {
                label: 'Limpiar Filtros',
                onClick: () => handleClearQueryParams(navigate),
              }
            : undefined
        }
        emptyLogo={<Trash2 className="mr-2 h-4 w-4" />}
      />
      <AlertDialogWrapper
        {...alertDialog}
        isLoading={isDeleteDonationPending}
        onDismiss={handleDismissAlertDialog}
      />
      <Outlet />
    </>
  );
};

export default DonationPage;
