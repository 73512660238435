import { Skeleton } from '@/components/ui/skeleton';

const CostCenterDetailSkeleton = () => (
  <div className="px-2 max-w-[1000px]">
    <div className={'flex flex-col mt-2 mb-[20px]'}>
      <div className="flex flex-col gap-[20px]">
        <div className="flex flex-row gap-6 w-full">
          <Skeleton className="w-1/4 h-10" />
        </div>
        <div className="flex flex-col gap-2 w-full">
          <Skeleton className="w-full h-28 bg-transparent border">
            <div className="flex h-full items-center justify-between">
              <Skeleton className="w-1/4 h-10 ml-6" />
              <Skeleton className="w-1/6 h-10 mr-6" />
            </div>
          </Skeleton>
          <Skeleton className="w-full h-28 bg-transparent border">
            <div className="flex h-full items-center justify-between">
              <Skeleton className="w-1/4 h-10 ml-6" />
              <Skeleton className="w-1/6 h-10 mr-6" />
            </div>
          </Skeleton>
        </div>
      </div>
    </div>
  </div>
);

export { CostCenterDetailSkeleton };
