import { DonationApi, DonationState, donationStateMap } from '@/api/donation/donation.model';
import { ColumnDef } from '@tanstack/react-table';
import { CostCenterApi } from '@/api/costCenter/costCenter.model';
import {
  formatAmount,
  genericColumnFilterFn,
  getDataArray,
  getParamsWithAnimation,
} from '@/lib/utils';
import { SortColumnWithBe } from '@/components/shared/SortColumnWithBe';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { pathsBase } from '@/routes/paths';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCostCenterCellFiltered } from '@/pages/CostCenter/utils/CostCenterUtils';
import { FilterColumnWithBe } from '@/components/shared/FilterColumnWithBe';
import { useCostCentersList } from '@/api/costCenter/costCenter.api';

const DonationTableColumns = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onPayDonationClick = (id: string) => {
    navigate(
      `/${pathsBase.DONATIONS}/${id}/${pathsBase.PAY}${getParamsWithAnimation(location.search)}`,
    );
  };

  const donationsTableColumns: ColumnDef<DonationApi>[] = [
    {
      accessorKey: 'name',
      header: ({ column }) => <SortColumnWithBe title="Nombre" column={column} />,
      cell: ({ row }) => {
        const name = row.getValue('name') as string;
        return <div className="line-clamp-2">{name}</div>;
      },
    },
    {
      accessorKey: 'reportDate',
      header: ({ column }) => <SortColumnWithBe title="Vencimiento" column={column} />,
    },
    {
      accessorKey: 'costCenters',
      filterFn: genericColumnFilterFn<DonationApi>('costCenters', 'id'),
      header: ({ column }) => {
        return (
          <FilterColumnWithBe
            title="CC Asociados"
            column={column}
            filterOptions={getDataArray(useCostCentersList)}
            commandDropdownClassname="w-[13.5rem] max-w-[13.5rem]"
          />
        );
      },
      cell: ({ row }) => {
        const costCenters = row.getValue('costCenters') as Array<CostCenterApi>;

        if (costCenters && costCenters.length > 0) {
          const cell = getCostCenterCellFiltered(costCenters);

          return <span>{cell}</span>;
        }

        return '-';
      },
    },
    {
      accessorKey: 'amount',
      header: ({ column }) => <SortColumnWithBe title="Donación" column={column} />,
      cell: ({ row }) => {
        const amount = parseFloat(row.getValue('amount'));
        return formatAmount(amount);
      },
    },
    {
      accessorKey: 'totalExpenses',
      header: () => <p className="text-xs">Gastos Asociados</p>,
      cell: ({ row }) => {
        const amount = parseFloat(row.getValue('totalExpenses'));
        return formatAmount(amount);
      },
    },
    {
      accessorKey: 'balance',
      header: () => <p className="text-xs">Balance</p>,
      cell: ({ row }) => {
        const amount = parseFloat(row.getValue('balance'));
        return (
          <p className={amount < 0 ? 'text-rose-700' : 'text-green-700'}>{formatAmount(amount)}</p>
        );
      },
    },
    {
      accessorKey: 'id',
      header: 'Acciones',
      cell: ({ row }) => {
        const state = row.original.state as DonationApi['state'];
        return (
          <>
            {state === 'PENDING' && (
              <Button
                variant="link"
                className="h-fit p-0"
                onClick={() => {
                  onPayDonationClick(row.getValue('id'));
                }}
              >
                <span className="text-xs text-foreground font-medium underline transition-colors hover:text-blue-500">
                  Registrar Cobro
                </span>
              </Button>
            )}
            {state === DonationState.COMPLETED && (
              <span className="text-xs text-teal-700 text-muted-foreground font-medium">
                Cobrado
              </span>
            )}
          </>
        );
      },
    },
    {
      accessorKey: 'state',
      header: ({ column }) => {
        const resultArray = (Object.keys(donationStateMap) as Array<DonationState>).map((key) => ({
          label: donationStateMap[key],
          value: key,
        }));
        return <FilterColumnWithBe title="Estado" column={column} filterOptions={resultArray} />;
      },
      cell: ({ row }) => {
        const state = row.getValue('state') as DonationApi['state'];
        const stateLabel = donationStateMap[state as keyof typeof donationStateMap] || '-';
        return (
          <Badge
            variant={
              state
                ? state === DonationState.COMPLETED
                  ? 'outlineGreen'
                  : 'outlineYellow'
                : 'outlineGray'
            }
          >
            {stateLabel}
          </Badge>
        );
      },
      maxSize: 100,
    },
  ];

  return donationsTableColumns;
};

export { DonationTableColumns };
