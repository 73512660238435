import { ColumnDef } from '@tanstack/react-table';
import { BankAccount } from '@/api/bankAccount/bankAccount.model';
import { SortColumnWithBe } from '@/components/shared/SortColumnWithBe';
import { formatAmount } from '@/lib/utils.ts';

const bankAccountTableColumns: ColumnDef<BankAccount>[] = [
  {
    accessorKey: 'name',
    header: ({ column }) => <SortColumnWithBe title="Nombre" column={column} />,
  },
  {
    accessorKey: 'accountNumber',
    header: ({ column }) => <SortColumnWithBe title="Cuenta" column={column} />,
  },
  {
    accessorKey: 'ledgerAccount',
    header: ({ column }) => <SortColumnWithBe title="Cuenta Contable" column={column} />,
  },
  {
    accessorKey: 'cbu',
    header: ({ column }) => <SortColumnWithBe title="CBU" column={column} />,
  },
  {
    accessorKey: 'currency',
    header: ({ column }) => <SortColumnWithBe title="Moneda" column={column} />,
  },
  {
    accessorKey: 'balance',
    header: ({ column }) => <SortColumnWithBe title="Balance" column={column} />,
    cell: ({ row }) => {
      return (
        <p className={parseFloat(row.getValue('balance')) < 0 ? 'text-rose-700' : 'text-green-700'}>
          {formatAmount(row.getValue('balance'), row.getValue('currency'))}
        </p>
      );
    },
  },
];

export { bankAccountTableColumns };
