import { FC, useState } from 'react';
import DialogWrapper from '@/components/shared/DialogWrapper';
import { useNavigate } from 'react-router-dom';
import { paths } from '@/routes/paths';
import { Button } from '@/components/ui/button.tsx';
import { CalendarIcon } from 'lucide-react';
import useCustomLoader from '@/hooks/useCustomLoader.tsx';
import { useGetReport } from '@/api/user/user.api.ts';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover.tsx';
import { cn } from '@/lib/utils.ts';
import { format, subDays, addMonths, isAfter } from 'date-fns';
import { Calendar } from '@/components/ui/calendar.tsx';
import { es } from 'date-fns/locale';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { DATES_OPTIONS } from '@/common/constants';

const InvoicesReport: FC = () => {
  const navigate = useNavigate();

  const handleDismiss = () => navigate(paths.INVOICES);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [dateType, setDateType] = useState(DATES_OPTIONS[0].value);

  const { isLoading: isLoadingReport, response: downloadReportHandler } =
    useCustomLoader(useGetReport());

  const handleCalendarSelect = (date: Date | undefined, setDate: (date: Date | null) => void) => {
    setDate(date ?? subDays(new Date(), 30));
  };

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
    if (date && endDate && isAfter(date, endDate)) {
      setEndDate(null);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (startDate && date) {
      const maxEndDate = addMonths(startDate, 3);
      if (isAfter(date, maxEndDate)) {
        setEndDate(maxEndDate);
      } else {
        setEndDate(date);
      }
    } else {
      setEndDate(date);
    }
  };

  const handleDownloadReport = () => {
    downloadReportHandler({
      startDate: startDate || undefined,
      endDate: endDate || undefined,
      dateType: dateType,
    });
  };

  const handleFilterByChange = (value: string) => {
    setDateType(value);
  };

  return (
    <DialogWrapper
      title="Generar reporte de facturas"
      subtitle="El reporte posee un máximo de rango de fechas de exportación de 3 meses."
      className="w-full max-w-xs"
      titleClassName="text-xl"
      onDismiss={handleDismiss}
      onOverlayClick={handleDismiss}
    >
      <div>
        <div>
          <div className="w-full">
            <label className="block text-sm font-medium text-gray-700">Filtrar Por...</label>
            <Select onValueChange={handleFilterByChange}>
              <SelectTrigger className="text-muted-foreground hover:bg-accent hover:text-accent-foreground">
                <SelectValue placeholder="Seleccione el tipo de fecha" />
              </SelectTrigger>
              <SelectContent>
                {DATES_OPTIONS.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="w-full mt-4">
            <label className="block text-sm font-medium text-gray-700">Fecha de Inicio</label>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  className={cn(
                    'w-full font-normal justify-start',
                    !startDate && 'text-muted-foreground',
                  )}
                >
                  <div
                    className={cn(
                      'w-60 flex items-center justify-start',
                      startDate ? 'text-sm font-normal' : 'text-slate-500',
                    )}
                  >
                    <CalendarIcon className="w-4 h-4 mr-2 opacity-50" />
                    {startDate ? (
                      format(startDate, 'dd/MM/yyyy')
                    ) : (
                      <span>Selecciona una fecha</span>
                    )}
                  </div>
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  mode="single"
                  showOutsideDays
                  fixedWeeks
                  captionLayout="dropdown"
                  fromYear={new Date().getFullYear() - 10}
                  toDate={endDate || undefined}
                  toYear={(!endDate && new Date().getFullYear() + 10) || undefined}
                  selected={startDate || undefined}
                  onSelect={(date) => handleCalendarSelect(date, handleStartDateChange)}
                  locale={es}
                />
              </PopoverContent>
            </Popover>
          </div>

          <div className="w-full mt-4">
            <label className="block text-sm font-medium text-gray-700">Fecha de Fin</label>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  className={cn(
                    'w-full font-normal justify-start',
                    !endDate && 'text-muted-foreground',
                  )}
                >
                  <div
                    className={cn(
                      'w-60 flex items-center justify-start',
                      endDate ? 'text-sm font-normal' : 'text-slate-500',
                    )}
                  >
                    <CalendarIcon className="w-4 h-4 mr-2 opacity-50" />
                    {endDate ? format(endDate, 'dd/MM/yyyy') : <span>Selecciona una fecha</span>}
                  </div>
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  mode="single"
                  showOutsideDays
                  fixedWeeks
                  captionLayout="dropdown"
                  fromDate={startDate || undefined}
                  fromYear={(!startDate && new Date().getFullYear() - 5) || undefined}
                  toYear={new Date().getFullYear() + 10}
                  selected={endDate || undefined}
                  onSelect={(date) => handleCalendarSelect(date, handleEndDateChange)}
                  locale={es}
                />
              </PopoverContent>
            </Popover>
          </div>
        </div>
        <div className="text-right mt-4">
          <Button onClick={handleDownloadReport} isLoading={isLoadingReport}>
            Descargar
          </Button>
        </div>
      </div>
    </DialogWrapper>
  );
};

export default InvoicesReport;
