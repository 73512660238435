import { NavigateFunction } from 'react-router-dom';
import { ANIMATION_QUERY_PARAM, CURRENCY_OPTIONS, SONNER_ERROR_OPTIONS } from '@/common/constants';
import { CurrencyOption, FilterCell, FilterItem, HasCode, HasId } from '@/common/types';
import { RoutedTabsProps, Tab } from '@/components/shared/RoutedTabs';
import { FilterFn, Row } from '@tanstack/react-table';
import { type ClassValue, clsx } from 'clsx';
import { UUID } from 'crypto';
import { Children, isValidElement, ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';
import { ZodObject, ZodString, ZodTypeAny, z } from 'zod';
import { Location } from 'history';
import { toast } from 'sonner';
import { parseISO } from 'date-fns';

const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

const getParams = () => {
  const currentUrl = new URL(window.location.href);
  return currentUrl.searchParams;
};

const getValidChildren = (children: React.ReactNode) => {
  return Children.toArray(children).filter((child) => isValidElement(child)) as ReactElement[];
};

const getTab = (id: string, values: RoutedTabsProps<string>['tabs']): Tab<string> =>
  values.find((tab) => tab.id === id) || values[0];

const isValidZodLiteralUnion = <T extends z.ZodLiteral<unknown>>(
  literals: T[],
): literals is [T, T, ...T[]] => {
  return literals.length >= 2;
};

const constructZodLiteralUnionType = <T extends z.ZodLiteral<unknown>>(literals: T[]) => {
  if (!isValidZodLiteralUnion(literals)) {
    throw new Error(
      'Literals passed do not meet the criteria for constructing a union schema, the minimum length is 2',
    );
  }

  return z.union(literals);
};

const uniqueById = <T extends { id: UUID }>(arr: T[]): T[] => {
  const seen = new Map<string, boolean>();
  const result: T[] = [];

  for (const item of arr) {
    if (item && !seen.has(item.id)) {
      seen.set(item.id, true);
      result.push(item);
    }
  }

  return result;
};

const getFullName = (firstName: string, lastName: string) => `${firstName} ${lastName}`;

const getCurrencyCell = <T>(row: Row<T>, value: string, currency?: string): string => {
  const amount = parseFloat(row.getValue(value));
  return formatAmount(amount, currency);
};

const getConvertedAmount = (amount: number, exchangeRate: number) => {
  return amount / exchangeRate;
};

const genericColumnFilterFn = <T>(
  propertyKey: keyof T,
  subPropertyKey?: keyof HasId | keyof HasCode,
): FilterFn<T> => {
  return (row, _columnId, value) => {
    if (value === undefined || value.length === 0) {
      return false;
    }

    const propertyValue = row.original[propertyKey];

    if (Array.isArray(propertyValue)) {
      return propertyValue.some((item: any) => {
        const subPropertyValue = subPropertyKey ? item[subPropertyKey] : item;
        return subPropertyValue && subPropertyValue === value;
      });
    } else if (typeof propertyValue === 'object' && propertyValue !== null && subPropertyKey) {
      // @ts-ignore
      return propertyValue[subPropertyKey] === value;
    }

    return false;
  };
};

const getFilterOptionsFromMap = <T extends FilterItem>(
  options: Map<Array<T>, any>,
  labelKey: keyof T = 'name',
): Array<FilterCell> => {
  const uniqueItemsMap = new Map<string, FilterCell>();

  options.forEach((_, key) => {
    if (!Array.isArray(key)) {
      if (key[0] === null) return;
      // @ts-ignore
      uniqueItemsMap.set(key.id, { value: key.id, label: key.name });
    } else {
      key.forEach((item) => {
        const label = item[labelKey] || '';
        // @ts-ignore
        uniqueItemsMap.set(item.id, { value: item.id, label });
      });
    }
  });

  return Array.from(uniqueItemsMap.values());
};

const getFilterOptionsFromIterable = (
  options: IterableIterator<any>,
  categoryTypeMap: Record<string, string> = {},
): Array<FilterCell> => {
  const uniqueEntries = new Map<string, FilterCell>();

  Array.from(options).forEach((option) => {
    if (option) {
      uniqueEntries.set(option, {
        label: categoryTypeMap[option] || option,
        value: option,
      });
    }
  });

  return Array.from(uniqueEntries.values());
};

function getCurrencyOptionByValue(value: CurrencyOption['value']): CurrencyOption | undefined {
  return CURRENCY_OPTIONS.find((option) => option.value === value);
}

const getPercentage = (totalValue: string, remainingValue: string): string => {
  const total = parseFloat(totalValue.replace(/[^0-9,-]/g, '').replace(',', '.'));
  const remaining = parseFloat(remainingValue.replace(/[^0-9,-]/g, '').replace(',', '.'));
  return total === 0 && remaining === 0
    ? '-'
    : `${((remaining * 100) / total).toFixed(2).replace('.', ',')}%`;
};

const mapCurrencyToSymbol = (currency: string) => {
  switch (currency) {
    case 'USD':
      return 'U$';
    case 'ARS':
      return 'AR$';
    case 'EUR':
      return '€';
    default:
      return 'U$';
  }
};

const formatAmount = (amount: number = 0, currency?: string) => {
  const currencyCode = mapCurrencyToSymbol(currency || 'USD');

  // Handle large numbers with readable suffixes
  let formattedAmount: string;
  if (Math.abs(amount) >= 1e12) {
    formattedAmount = (amount / 1e12).toFixed(2) + 'B';
  } else if (Math.abs(amount) >= 1e9) {
    formattedAmount = (amount / 1e9).toFixed(2) + 'MM';
  } else {
    formattedAmount = new Intl.NumberFormat('es-ES', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      useGrouping: true,
    }).format(amount);
  }

  return `${currencyCode}${formattedAmount}`;
};

const isFieldRequired = (schema: ZodTypeAny, fieldName: string): boolean => {
  // Type guard to check if the schema is a ZodObject
  if (schema instanceof ZodObject) {
    const fieldSchema = schema.shape[fieldName];
    if (!fieldSchema) {
      console.warn(`Field "${fieldName}" does not exist in the schema.`);
      return false;
    }

    // Direct checks on ZodObject's fields to determine optionality
    if ('isOptional' in fieldSchema && fieldSchema.isOptional()) {
      return false;
    }

    if (fieldSchema instanceof ZodString) {
      return fieldSchema._def.checks.some((check) => check.kind === 'min' && check.value >= 0);
    }

    // Similar checks can be implemented for ZodNumber, ZodBoolean, etc., if needed
    // If it's not explicitly optional, we consider it required
    return true;
  }

  // If the schema is not a ZodObject, indicating the field does not exist or the check is not applicable
  return false;
};

const getRequiredLabel = (schema: ZodTypeAny, fieldName: string, label: string): string => {
  return isFieldRequired(schema, fieldName) ? `${label}*` : label;
};

const getCurrentDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  const hours = currentDate.getHours().toString().padStart(2, '0');
  const minutes = currentDate.getMinutes().toString().padStart(2, '0');
  const seconds = currentDate.getSeconds().toString().padStart(2, '0');
  return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
};

const extractKeyFromUrl = (url: string) => {
  const parsedUrl = new URL(url);
  return parsedUrl.pathname.substring(1);
};

const getQueryParamByName = (name: string, location: Location<any>) => {
  const queryParams = new URLSearchParams(location.search);
  return queryParams.get(name);
};

const fixAmount = (amount: number, digits: number) => {
  return Number(amount.toFixed(digits));
};

const handleClearQueryParams = (navigate: NavigateFunction, clearDate?: boolean) => {
  const currentUrl = new URL(window.location.href);
  const searchParams = currentUrl.searchParams;
  const date = searchParams.get('date');
  currentUrl.search = date ? `date=${date}` : '';
  window.history.pushState({}, '', currentUrl);
  navigate(date && !clearDate ? currentUrl.search : currentUrl);
};

const getDataArray = (method: any, useParams?: boolean) => {
  const currentUrl = new URL(window.location.href);
  const searchParams = currentUrl.searchParams;
  const { data } = method(useParams ? searchParams : undefined);
  const optionsArray = data?.map((option: any) => ({
    label: option.name,
    value: option.id,
  }));
  return optionsArray || [];
};

const getParamsWithAnimation = (path: string, cancelAnimation?: boolean) => {
  let finalUrl = path || `?${ANIMATION_QUERY_PARAM}=true`;

  if (cancelAnimation) {
    finalUrl = finalUrl.replace(/&?useAnimation=true/g, '');
  } else if (!finalUrl.includes(`${ANIMATION_QUERY_PARAM}=true`)) {
    finalUrl += `&${ANIMATION_QUERY_PARAM}=true`;
  }

  return finalUrl;
};

const normalizeTerm = (str: string) => {
  const strToLowerCase = str.toLowerCase();
  return strToLowerCase.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

const getFormattedDate = (date?: Date) => {
  if (date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  } else {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();

    return `${year}-${month}-${day}`;
  }
};

function getLongDateFormat(dateString: string): string {
  if (isNaN(Date.parse(dateString))) {
    toast.error("La fecha debe estar en el formato 'yyyy-mm-dd'", {
      ...SONNER_ERROR_OPTIONS,
    });
  }

  const date = parseISO(dateString);

  const day = new Intl.DateTimeFormat('es-ES', { day: 'numeric' });
  const month = new Intl.DateTimeFormat('es-ES', { month: 'long' });
  const year = new Intl.DateTimeFormat('es-ES', { year: 'numeric' });

  return `${day.format(date)} de ${month.format(date)}, ${year.format(date)}`;
}

const createRandomID = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

const getErrorKeyandValue = (error: string) => {
  const match = error.match(/Detail: Key \((.*?)\)=\((.*?)\)/);

  if (match) {
    const key = match[1];
    const value = match[2];
    return { key, value };
  }
  return { key: '', value: '' };
};

export {
  cn,
  getValidChildren,
  getTab,
  constructZodLiteralUnionType,
  uniqueById,
  getFullName,
  getCurrencyCell,
  getConvertedAmount,
  genericColumnFilterFn,
  getFilterOptionsFromMap,
  getFilterOptionsFromIterable,
  getCurrencyOptionByValue,
  getPercentage,
  formatAmount,
  getRequiredLabel,
  getCurrentDate,
  extractKeyFromUrl,
  getQueryParamByName,
  getDataArray,
  fixAmount,
  handleClearQueryParams,
  getParamsWithAnimation,
  normalizeTerm,
  getFormattedDate,
  getLongDateFormat,
  createRandomID,
  getErrorKeyandValue,
  getParams,
};
