import { TableWrapper } from '@/components/shared/TableWrapper';
import useTableColumnsWithActions from '@/hooks/useTableColumnsWithActions';
import { useNavigate, useParams } from 'react-router-dom';
import { useInvoice } from '@/api/invoice/invoice.api';
import { InvoiceEditParams } from '../InvoiceDetails.tsx';
import { useTablePagination } from '@/hooks/useTablePagination.tsx';
import {
  ANIMATION_QUERY_PARAM,
  DEFAULT_PAGINATION_PAGE_COUNT,
  SONNER_ERROR_OPTIONS,
  SONNER_SUCCESS_OPTIONS,
} from '@/common/constants.ts';
import { AlertDialogWrapper } from '@/components/shared/AlertDialog.tsx';
import { useMutation } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import SuccessToastText from '@/components/shared/SuccessToastText.tsx';
import { toast } from 'sonner';
import { pathsBase } from '@/routes/paths.ts';
import { paymentsTableColumns } from '@/pages/Payments/utils/PaymentsTableColumns.tsx';
import { PaymentApi } from '@/api/payment/payment.model.ts';
import { deletePaymentMutationFn, usePayments } from '@/api/payment/payment.api.ts';
import useUserStore from '@/store/useUserStore.ts';
import { UserRole } from '@/api/user/user.model.ts';
import { useAlertDialog } from '@/hooks/useAlertDialog.tsx';

const PAGE_TITLE = 'Pagos';

interface PaymentsTabProps {
  showCTA: boolean;
}

const PaymentsTab = ({ showCTA }: PaymentsTabProps) => {
  const navigate = useNavigate();

  const { user } = useUserStore();

  const { id } = useParams() as InvoiceEditParams;

  const { pageIndex, pageSize, paginationState, setPagination } = useTablePagination();

  const { alertDialog, setAlertDialog, handleDismissAlertDialog } = useAlertDialog();

  const { refetch: refetchInvoice } = useInvoice(id);

  const {
    data: payments,
    isLoading: isLoadingPayments,
    refetch: refetchPayments,
  } = usePayments(id, {
    page: pageIndex,
    size: pageSize,
  });

  const { mutate: deleteCreditNote, isPending: isDeletePaymentPending } = useMutation({
    mutationFn: deletePaymentMutationFn,
    onSuccess: () => {
      refetchInvoice();
      refetchPayments();
      toast(
        <SuccessToastText>Pago eliminada exitosamente</SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismissAlertDialog();
    },
    onError: (error: AxiosError) => {
      Sentry.captureException(error);
      toast.error('Ha ocurrido un error durante la eliminación del pago.', {
        description: 'Por favor, intentalo nuevamente.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  const handlePaymentDelete = (payment: PaymentApi) => {
    setAlertDialog({
      title: `¿Estás seguro que queres eliminar el pago "#${payment.paymentNumber}"? `,
      description: `Esta acción no puede ser deshecha y se perderá toda la información asociada a este pago ("#${payment.paymentNumber})".`,
      open: true,
      onCancel: handleDismissAlertDialog,
      onConfirm: () => deleteCreditNote(payment.id),
    });
  };

  const handleCreatePayment = () => {
    if (id) {
      navigate(
        `/${pathsBase.EXPENSES}/${pathsBase.INVOICES}/${id}/${pathsBase.PAY}?${ANIMATION_QUERY_PARAM}=true`,
      );
    }
  };

  const columnsWithActions = useTableColumnsWithActions<PaymentApi>(
    paymentsTableColumns,
    () => {},
    handlePaymentDelete,
    true,
    undefined,
    user?.role !== UserRole.ADMIN,
  );

  return (
    <>
      <TableWrapper
        data={payments}
        columns={columnsWithActions}
        rowsLabel={PAGE_TITLE}
        isLoading={isLoadingPayments}
        pageCount={payments ? payments.totalPages : DEFAULT_PAGINATION_PAGE_COUNT}
        pagination={paginationState}
        onPaginationChange={setPagination}
        emptyCta={
          showCTA
            ? {
                label: 'Registrar Pago',
                onClick: handleCreatePayment,
              }
            : undefined
        }
        emptyImgProps={{ width: 210, height: 150 }}
      />
      <AlertDialogWrapper
        {...alertDialog}
        isLoading={isDeletePaymentPending}
        onDismiss={handleDismissAlertDialog}
      />
    </>
  );
};

export { PaymentsTab };
