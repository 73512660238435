import { SearchBarFormType, SearchBarSchema } from '@/common/schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

const useSearchBar = () => {
  const [searchValue, setSearchValue] = useState('');

  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.set('search', searchValue);
  window.history.pushState({}, '', currentUrl);

  const form = useForm<SearchBarFormType>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: zodResolver(SearchBarSchema),
  });

  const searchWatchValue = form.watch('value');

  const handleSubmit: SubmitHandler<SearchBarFormType> = (formData) => {
    setSearchValue(formData.value);
  };

  useEffect(() => {
    if (searchWatchValue === '') {
      setSearchValue('');
    }
  }, [searchWatchValue]);

  return { searchValue, form, handleSubmit };
};

export { useSearchBar };
