import { FC } from 'react';
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { LedgerAccountFormType, LedgerAccountSchema } from '../utils/LedgerAccountSchema';
import { LEDGER_ACCOUNT_TYPE_OPTIONS } from '@/common/constants';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { LedgerAccountType } from '@/api/ledgerAccount/ledgerAccount.model';
import { useLedgerAccountByTypeList } from '@/api/ledgerAccount/ledgerAccount.api';
import { SelectFilter } from '@/components/shared/SelectFilter';
import { getLedgerAccountLabel, getPreviousLevel } from '../utils/LedgerAccountUtils';
import { getRequiredLabel } from '@/lib/utils';

interface LedgerAccountFormProps {
  form: UseFormReturn<LedgerAccountFormType, unknown>;
  submitLabel: string;
  cancelLabel: string;
  type: LedgerAccountType;
  editMode?: boolean;
  isLoading?: boolean;
  submitHandler: SubmitHandler<LedgerAccountFormType>;
  dismissHandler: () => void;
}

const LedgerAccountForm: FC<LedgerAccountFormProps> = ({
  form,
  submitLabel,
  cancelLabel,
  type,
  editMode = false,
  isLoading = false,
  submitHandler,
  dismissHandler,
}: LedgerAccountFormProps) => {
  const { data: parentLedgerAccounts, isLoading: isLoadingParentLedgerAccounts } =
    useLedgerAccountByTypeList(getPreviousLevel(type));

  const parentLabel = getLedgerAccountLabel(getPreviousLevel(type));
  const label = getLedgerAccountLabel(type);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(submitHandler)} className="flex flex-col gap-8">
        <div className="flex flex-col gap-4">
          <FormField
            control={form.control}
            name="type"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>{getRequiredLabel(LedgerAccountSchema, 'type', 'Nivel')}</FormLabel>
                <Select disabled onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Seleccione el nivel" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {LEDGER_ACCOUNT_TYPE_OPTIONS.map((ledgerAccountType) => (
                      <SelectItem key={ledgerAccountType.value} value={ledgerAccountType.value}>
                        {ledgerAccountType.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          {editMode ? (
            <>
              <FormLabel>{getLedgerAccountLabel(getPreviousLevel(type))}</FormLabel>
              <Input type="text" value={form.getValues('parent.name')} disabled />
            </>
          ) : (
            <SelectFilter
              form={form}
              isLoading={isLoadingParentLedgerAccounts}
              formName="parent"
              formLabel={getRequiredLabel(LedgerAccountSchema, 'parent', parentLabel)}
              formPlaceholder={`Seleccionar ${getLedgerAccountLabel(getPreviousLevel(type))}`}
              formFilterPlaceholder={`Buscar ${getLedgerAccountLabel(getPreviousLevel(type))}...`}
              options={parentLedgerAccounts || []}
              classnames={{
                button: 'w-full',
              }}
            />
          )}
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{getRequiredLabel(LedgerAccountSchema, 'name', label)}</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="text"
                    placeholder={`Ingresar ${getLedgerAccountLabel(type)}`}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="code"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{getRequiredLabel(LedgerAccountSchema, 'code', 'Código')}</FormLabel>
                <FormControl>
                  <Input {...field} type="text" placeholder="Ingresar código" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex flex-row justify-end gap-2">
          <Button type="button" variant="outline" disabled={isLoading} onClick={dismissHandler}>
            {cancelLabel}
          </Button>
          <Button
            type="submit"
            disabled={isLoading || !form.formState.isValid}
            className="relative"
            isLoading={isLoading}
          >
            {submitLabel}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export { LedgerAccountForm };
