import {
  useAccountingEntriesByLedgerAccount,
  useGetAccountingReportByLedgerAccount,
} from '@/api/accountingEntry/accountingEntry.api';
import { ANIMATION_QUERY_PARAM, DEFAULT_PAGINATION_PAGE_COUNT } from '@/common/constants';
import { Heading } from '@/components/shared/Heading';
import { PageContent } from '@/components/shared/PageContent';
import { TableWrapper } from '@/components/shared/TableWrapper';
import { Button } from '@/components/ui/button';
import { useTablePagination } from '@/hooks/useTablePagination';
import { pathsBase } from '@/routes/paths';
import { FileDownIcon, FilePlusIcon } from 'lucide-react';
import { Helmet } from 'react-helmet';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { AccountingEntry } from '@/api/accountingEntry/accountingEntry.model';
import { useLedgerAccount } from '@/api/ledgerAccount/ledgerAccount.api';
import { AccountMayorDetailTableColumns } from '@/pages/AccountsMayor/utils/AccountMayorDetailTableColumns.tsx';
import AccountingEntryDetailBreadcrumb from './components/AccountingEntryDetailBreadcrumb';
import useLedgerAccountStore from '@/store/useLedgerAccountStore';
import { useEffect } from 'react';
import useFilterAndSortByBe from '@/hooks/useFilterAndSortByBe';
import useCustomLoader from '@/hooks/useCustomLoader.tsx';
import { DateFilter } from '@/components/shared/DateFilter';
import { getParams } from '@/lib/utils';

const PAGE_TITLE = 'Detalle de Asientos';

type AccountMayorDetailParams = {
  id: AccountingEntry['id'];
};

const AccountMayorDetail = () => {
  const { id } = useParams() as AccountMayorDetailParams;
  const columns = AccountMayorDetailTableColumns();
  const navigate = useNavigate();
  const { setLedgerAccount } = useLedgerAccountStore();

  const {
    pageIndex,
    pageSize,
    paginationState,
    setPagination,
    dir,
    setDir,
    sort,
    setSort,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  } = useTablePagination('paymentDate');

  const { response: handleDownloadAccountingReport, isLoading: isLoadingAccountingReport } =
    useCustomLoader(useGetAccountingReportByLedgerAccount(id, getParams()));

  const { data, isLoading, isFetching, refetch } = useAccountingEntriesByLedgerAccount({
    page: pageIndex,
    size: pageSize,
    dir: dir,
    sort: sort,
    ledgerAccountId: id,
    startDate: startDate,
    endDate: endDate,
  });

  useFilterAndSortByBe(refetch, setDir, setSort, undefined, undefined, 'paymentDate');

  const { data: ledgerAccount, isLoading: isLoadingLedgerAccount } = useLedgerAccount(id);

  const handleCreateAccountingEntry = () =>
    navigate(
      `/${pathsBase.ACCOUNTS_MAYOR}/${id}/${pathsBase.CREATE}?${ANIMATION_QUERY_PARAM}=true`,
    );

  useEffect(() => {
    if (ledgerAccount) {
      setLedgerAccount(ledgerAccount);
    }
  }, [ledgerAccount]);

  return (
    <>
      <Helmet>
        <title>PEM - {PAGE_TITLE}</title>
      </Helmet>
      <Heading
        title={ledgerAccount ? `${ledgerAccount.code} - ${ledgerAccount.name}` : ''}
        subtitle={PAGE_TITLE}
        isLoading={isLoading || isLoadingLedgerAccount}
        classNames={{
          title: 'text-4xl font-normal',
          section: 'py-4 min-h-28',
          subtitle: 'font-extralight tracking-normal',
        }}
        rightComponent={
          <div className="flex gap-4">
            <DateFilter
              mode="range"
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
            <Button
              variant={'secondary'}
              onClick={() => handleDownloadAccountingReport()}
              isLoading={isLoadingAccountingReport}
              disabled={isLoadingAccountingReport}
            >
              <FileDownIcon className="w-4 h-4 mr-2" />
              Descargar Reporte
            </Button>
            <Button onClick={handleCreateAccountingEntry}>
              <FilePlusIcon className="w-4 h-4 mr-2" />
              Crear Asientos
            </Button>
          </div>
        }
        useBreadcrumb
        breadcrumbComponent={<AccountingEntryDetailBreadcrumb />}
      />
      <PageContent>
        <TableWrapper
          columns={columns}
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
          rowsLabel={PAGE_TITLE}
          pageCount={data ? data.totalPages : DEFAULT_PAGINATION_PAGE_COUNT}
          pagination={paginationState}
          onPaginationChange={setPagination}
        />
      </PageContent>
      <Outlet />
    </>
  );
};

export default AccountMayorDetail;
