import { FC, useEffect } from 'react';
import { BankAccountBox } from './BankAccountBox';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { SupplierFormType } from '../utils/SupplierSchema';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Button } from '@/components/ui/button';
import { PlusCircle } from 'lucide-react';
import { createRandomID } from '@/lib/utils';

interface BankAccountFormParams {
  form: UseFormReturn<SupplierFormType, unknown>;
}

const BankAccountForm: FC<BankAccountFormParams> = ({ form }) => {
  const accounts = form.watch('accounts');

  const { append: appendAccount } = useFieldArray<SupplierFormType>({
    control: form.control,
    name: 'accounts',
  });

  const addAccountHandler = () => {
    appendAccount({ id: createRandomID(), cbu: '', currency: '' });
  };

  useEffect(() => {
    if (!accounts?.length) {
      addAccountHandler();
    }
  }, [accounts]);

  return (
    <div className="flex flex-col w-full gap-3">
      <ScrollArea className={`overflow-auto h-32`}>
        {accounts?.map((account, index) => (
          <BankAccountBox key={account.id} id={account.id} index={index} form={form} />
        ))}
      </ScrollArea>
      <Button
        type="button"
        className="text-xs w-fit"
        variant="outlinePrimary"
        onClick={addAccountHandler}
      >
        <PlusCircle className="w-4 h-4 mr-2" />
        Cuenta Bancaria
      </Button>
    </div>
  );
};

export { BankAccountForm };
