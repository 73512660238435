import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@/components/ui/breadcrumb';
import { getParamsWithAnimation } from '@/lib/utils';
import { paths } from '@/routes/paths';
import useLedgerAccountStore from '@/store/useLedgerAccountStore';
import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface AccountingEntryDetailBreadcrumbProps {
  entry?: number;
  group?: boolean;
}

const modifyQueryParams = (search: string) => {
  const params = new URLSearchParams(search);
  if (params.has('amp')) {
    const ampValue = params.get('amp');
    params.delete('amp');
    params.set('pageIndex', ampValue as string);
  } else {
    params.delete('pageIndex');
  }
  params.delete('startDate');
  params.delete('endDate');

  return params.toString();
};

const AccountingEntryDetailBreadcrumb: FC<AccountingEntryDetailBreadcrumbProps> = ({
  entry,
  group = false,
}) => {
  const location = useLocation();
  const { ledgerAccount } = useLedgerAccountStore();
  const modifiedParams = `?${modifyQueryParams(location.search)}`;
  return (
    <Breadcrumb>
      <BreadcrumbItem>
        <BreadcrumbLink
          as={Link}
          to={`${paths.ACCOUNTS_MAYOR}${getParamsWithAnimation(modifiedParams, true)}`}
        >
          Mayor de Cuentas
        </BreadcrumbLink>
      </BreadcrumbItem>
      <BreadcrumbItem isCurrentPage={!group}>
        <BreadcrumbLink
          as={Link}
          to={`${paths.ACCOUNTS_MAYOR}/${ledgerAccount.id}${getParamsWithAnimation(location.search, true).replace('amdp', 'pageIndex')}`}
        >
          {ledgerAccount.name}
        </BreadcrumbLink>
      </BreadcrumbItem>
      {group && (
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink as={Link} to={`${paths.ACCOUNTS_MAYOR}`}>
            {`Asiento #${entry || '-'}`}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
    </Breadcrumb>
  );
};

export default AccountingEntryDetailBreadcrumb;
