import { FC } from 'react';
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { CategoryFormType, CategorySchema } from '../utils/CategorySchema';
import { getRequiredLabel } from '@/lib/utils';

interface CategoriesFormProps {
  form: UseFormReturn<CategoryFormType, any>;
  submitLabel: string;
  cancelLabel: string;
  isLoading?: boolean;
  submitHandler: SubmitHandler<CategoryFormType>;
  dismissHandler: () => void;
}

const CategoriesForm: FC<CategoriesFormProps> = ({
  form,
  submitLabel,
  cancelLabel,
  isLoading = false,
  submitHandler,
  dismissHandler,
}: CategoriesFormProps) => {
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(submitHandler)} className="flex flex-col gap-8">
        <div className="flex flex-col gap-4">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{getRequiredLabel(CategorySchema, 'name', 'Nombre')}</FormLabel>
                <FormControl>
                  <Input {...field} type="name" placeholder="Ingresar nombre" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex flex-row justify-end gap-2">
          <Button type="button" variant="outline" disabled={isLoading} onClick={dismissHandler}>
            {cancelLabel}
          </Button>
          <Button
            type="submit"
            disabled={isLoading || !form.formState.isValid}
            className="relative"
            isLoading={isLoading}
          >
            {submitLabel}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export { CategoriesForm };
