import { LedgerAccountApi } from '@/api/ledgerAccount/ledgerAccount.model';
import { Supplier } from '@/api/supplier/supplier.model';
import {
  FISCAL_PERSON_TYPE_OPTIONS,
  AFIP_CONDITION_TYPE_OPTIONS,
  DEFAULT_OPTIONAL_EMAIL_SCHEMA,
} from '@/common/constants';
import { constructZodLiteralUnionType, createRandomID } from '@/lib/utils';
import { z } from 'zod';

const SupplierTypeSchema = constructZodLiteralUnionType(
  FISCAL_PERSON_TYPE_OPTIONS.map((literal) => z.literal(literal.value)),
);

const SupplierAfipConditionTypeSchema = constructZodLiteralUnionType(
  AFIP_CONDITION_TYPE_OPTIONS.map((literal) => z.literal(literal.value)),
);

const REQUIRED_OPTION_LABEL = 'Seleccione una opción';

const SelectSchema = z
  .object({
    id: z.string().min(1, REQUIRED_OPTION_LABEL),
    name: z.string(),
  })
  .required();

const SupplierCUITSchema = z.coerce.string().min(1, 'El CUIL/CUIT es obligatorio');

const Accounts = z
  .object({
    id: z.string().optional(),
    cbu: z.string().optional(),
    currency: z.string().optional(),
    createdAt: z.string().optional(),
    updatedAt: z.string().optional(),
    expenses: z.array(z.object({ id: z.string() })).optional(),
  })
  .array()
  .optional();

const SupplierSchema = z
  .object({
    nickName: z.string().optional(),
    type: SupplierTypeSchema,
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    businessName: z.string().optional(),
    cuit: SupplierCUITSchema,
    email: DEFAULT_OPTIONAL_EMAIL_SCHEMA.nullable(),
    afipCondition: SupplierAfipConditionTypeSchema,
    address: z.string().optional(),
    activity: z.string().optional(),
    ledgerAccount: SelectSchema,
    code: z.coerce.number().optional(),
    accounts: Accounts.optional(),
  })
  .refine(
    (data) => {
      if (data.type === 'LEGAL_ENTITY') {
        return !!data.nickName;
      } else if (data.type === 'INDIVIDUAL') {
        return !!data.firstName && !!data.lastName;
      }
      return true;
    },
    {
      message: 'Faltan campos obligatorios.',
      path: [],
    },
  );

type SupplierFormType = z.infer<typeof SupplierSchema>;

const defaultSupplierFormValues: SupplierFormType = {
  ledgerAccount: { id: '', name: '' },
  nickName: '',
  type: FISCAL_PERSON_TYPE_OPTIONS[0].value,
  cuit: '',
  firstName: '',
  lastName: '',
  businessName: '',
  afipCondition: AFIP_CONDITION_TYPE_OPTIONS[0].value,
  address: '',
  activity: '',
  code: undefined,
  accounts: [{ id: createRandomID(), cbu: '', currency: '' }],
};

const generateDefaultValuesFromSupplier = (supplier: Supplier) => {
  return {
    nickName:
      supplier.type === 'LEGAL_ENTITY'
        ? supplier.nickName
        : supplier.nickName || `${supplier.firstName} ${supplier.lastName}`,
    firstName: supplier.firstName || '',
    lastName: supplier.lastName || '',
    cuit: supplier.cuit,
    email: supplier.email,
    type: supplier.type,
    businessName: supplier.businessName || '',
    afipCondition: supplier.afipCondition,
    activity: supplier.activity,
    address: supplier.address,
    ledgerAccount: supplier.ledgerAccount as LedgerAccountApi,
    code: supplier.code || undefined,
    accounts: supplier.accounts,
  };
};

export type { SupplierFormType };
export { SupplierSchema, defaultSupplierFormValues, generateDefaultValuesFromSupplier };
