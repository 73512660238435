import { LedgerAccountApi } from '@/api/ledgerAccount/ledgerAccount.model';
import { SortColumnWithBe } from '@/components/shared/SortColumnWithBe';
import { ColumnDef } from '@tanstack/react-table';

const getLedgerAccountTableColumnsByType = (conceptTitle?: string) => {
  const columns: ColumnDef<LedgerAccountApi>[] = [
    {
      accessorKey: 'code',
      header: ({ column }) => <SortColumnWithBe title="Código" column={column} />,
    },
    {
      accessorKey: 'name',
      header: ({ column }) => <SortColumnWithBe title="Nombre" column={column} />,
    },
  ];
  if (conceptTitle) {
    columns.push({
      accessorKey: 'concepts',
      header: () => <p className="text-xs">{conceptTitle}</p>,
      cell: ({ row }) => {
        const concepts = row.getValue('concepts') as Array<LedgerAccountApi>;
        const visibleConcepts = concepts.map((account) => account.name).join(', ');
        return <div className="line-clamp-2">{visibleConcepts}</div>;
      },
    });
  }
  return columns;
};

export { getLedgerAccountTableColumnsByType };
