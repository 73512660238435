import { Helmet } from 'react-helmet';
import { Button } from '@/components/ui/button';
import { bankAccountTableColumns } from './utils/BankAccountTableColumns';
import { Heading } from '@/components/shared/Heading';
import { PageContent } from '@/components/shared/PageContent';
import { Outlet, useNavigate } from 'react-router-dom';
import { FC } from 'react';
import { FilePlusIcon } from 'lucide-react';
import { deleteBankAccountMutationFn, useBankAccounts } from '@/api/bankAccount/bankAccount.api';
import { paths, pathsBase } from '@/routes/paths';
import {
  DEFAULT_PAGINATION_PAGE_COUNT,
  SONNER_ERROR_OPTIONS,
  SONNER_SUCCESS_OPTIONS,
} from '@/common/constants';
import { useTablePagination } from '@/hooks/useTablePagination';
import { AlertDialogWrapper } from '@/components/shared/AlertDialog';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'sonner';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { BankAccount, BankAccountApi } from '@/api/bankAccount/bankAccount.model';
import useTableColumnsWithActions from '@/hooks/useTableColumnsWithActions';
import { TableWrapper } from '@/components/shared/TableWrapper';
import * as Sentry from '@sentry/react';
import { useAlertDialog } from '@/hooks/useAlertDialog';
import useFilterAndSortByBe from '@/hooks/useFilterAndSortByBe';

const PAGE_TITLE = 'Cuentas Bancarias';

const BankAccounts: FC = () => {
  const navigate = useNavigate();

  const { pageIndex, pageSize, paginationState, dir, sort, setPagination, setDir, setSort } =
    useTablePagination<BankAccountApi>();

  const { data, isLoading, isFetching, refetch } = useBankAccounts({
    page: pageIndex,
    size: pageSize,
    dir: dir,
    sort: sort,
  });

  const { alertDialog, setAlertDialog, handleDismissAlertDialog } = useAlertDialog();

  const { mutate: deleteBankAccount, isPending: isDeleteBankAccountPending } = useMutation({
    mutationFn: deleteBankAccountMutationFn,
    onSuccess: () => {
      refetch();
      toast(
        <SuccessToastText>Cuenta Bancaria eliminada exitosamente</SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismissAlertDialog();
    },
    onError: (error: AxiosError) => {
      Sentry.captureException(error);
      toast.error('Ha ocurrido un error durante la eliminación de la Cuenta Bancaria.', {
        description: 'Por favor, intentalo nuevamente.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  useFilterAndSortByBe<BankAccountApi>(refetch, setDir, setSort);

  const handleCreateBankAccount = () => navigate(`/${pathsBase.BANK_ACCOUNTS}/${pathsBase.CREATE}`);

  const handleEditBankAccount = (bankAccount: BankAccount) =>
    navigate(`${paths.BANK_ACCOUNTS}/${pathsBase.EDIT}/${bankAccount.id}`);

  const handleDeleteBankAccount = (bankAccount: BankAccount) => {
    setAlertDialog({
      title: `¿Estás seguro que queres eliminar "${bankAccount.name}"? `,
      description: `Esta acción no puede ser deshecha y se perderá toda la información asociada a esta Cuenta Bancaria: "${bankAccount.name}". `,
      open: true,
      onCancel: handleDismissAlertDialog,
      onConfirm: () => deleteBankAccount(bankAccount.id),
    });
  };

  const bankAccountsColumnsWithActions = useTableColumnsWithActions<BankAccount>(
    bankAccountTableColumns,
    handleEditBankAccount,
    handleDeleteBankAccount,
  );

  return (
    <>
      <Helmet>
        <title>PEM - {PAGE_TITLE}</title>
      </Helmet>
      <Heading
        title={PAGE_TITLE}
        rightComponent={
          <Button onClick={handleCreateBankAccount}>
            <FilePlusIcon className="w-4 h-4 mr-2" />
            Crear Cuenta Bancaria
          </Button>
        }
      />
      <PageContent>
        <TableWrapper
          columns={bankAccountsColumnsWithActions}
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
          rowsLabel={PAGE_TITLE}
          pageCount={data ? data.totalPages : DEFAULT_PAGINATION_PAGE_COUNT}
          pagination={paginationState}
          onPaginationChange={setPagination}
        />
      </PageContent>
      <AlertDialogWrapper
        {...alertDialog}
        isLoading={isDeleteBankAccountPending}
        onDismiss={handleDismissAlertDialog}
      />
      <Outlet />
    </>
  );
};

export default BankAccounts;
