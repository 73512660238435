import { TableCell } from '@/components/ui/table';
import { formatAmount } from '@/lib/utils';
import { ExpenseRow } from '@/pages/Expenses/components/ExpenseRow';
import { FC, useEffect } from 'react';
import { CreditNoteFormType } from '../utils/CreditNoteSchema';
import { InvoiceFormType } from '@/pages/Invoices/utils/InvoiceSchema';
import { UseFormReturn } from 'react-hook-form';
import { LedgerAccountList } from '@/api/ledgerAccount/ledgerAccount.model';
import { DonationList } from '@/api/donation/donation.model';
import { CostCenterApi } from '@/api/costCenter/costCenter.model';
import { CategoryListItem } from '@/api/category/category.model';
import { ExpenseInInvoiceDTO } from '@/api/invoice/invoice.model';

interface CreditNoteExpenseRowProps {
  form: UseFormReturn<CreditNoteFormType, unknown>;
  invoiceForm: UseFormReturn<InvoiceFormType, unknown>;
  expense: ExpenseInInvoiceDTO;
  creditNoteIndex: number;
  ledgerAccounts: LedgerAccountList[] | undefined;
  donations: DonationList[] | undefined;
  globalCostCenter: CostCenterApi | undefined;
  categories: CategoryListItem[] | undefined;
  isLoadingLedgerAccounts: boolean;
}

const CreditNoteExpenseRow: FC<CreditNoteExpenseRowProps> = ({
  form,
  invoiceForm,
  expense,
  creditNoteIndex,
  ledgerAccounts,
  donations,
  globalCostCenter,
  categories,
  isLoadingLedgerAccounts,
}) => {
  useEffect(() => {
    const value = invoiceForm.watch(`expenses.${creditNoteIndex}.amount`);
    form.setValue(`creditNoteDetails.${creditNoteIndex}.amount`, value || 0);
  }, [invoiceForm.watch(`expenses.${creditNoteIndex}.amount`)]);
  return (
    <>
      <ExpenseRow
        form={invoiceForm}
        expenseIndex={creditNoteIndex}
        ledgerAccounts={ledgerAccounts}
        donations={donations}
        globalCostCenter={globalCostCenter}
        categories={categories}
        isLoadingLedgerAccounts={isLoadingLedgerAccounts}
        onDeleteExpense={() => {}}
        readonly
        onEdit
      />
      <TableCell colSpan={6} className="relative">
        <span className="text-xs leading-[14px] absolute right-[10px] top-[-50px]">
          Importe original: {formatAmount(expense.money?.amount, expense.money?.currency)}
        </span>
      </TableCell>
    </>
  );
};

export { CreditNoteExpenseRow };
