import { UUID } from 'crypto';
import { AccountingEntryPayload } from '../accountingEntry/accountingEntry.model';

enum ExpenseState {
  PENDING = 'PENDING',
  PAYED = 'PAYED',
}

interface Money {
  id?: UUID;
  currency: string;
  amount: number;
  exchangeRate: number;
}

interface Transaction {
  id?: UUID;
  amount: number;
  destination: {
    category?: { id: string; name?: string };
    costCenter?: {
      id: string;
      code?: string;
      global?: boolean;
    };
  };
  money: Money;
  createdAt?: string;
  updatedAt?: string;
  fileURL?: string;
  description?: string;
}

interface Expense extends Transaction {
  id?: UUID;
  type: string;
  state: ExpenseState;
  dueDate: string;
  receiptNumber: string;
  currency?: string;
  supplier: { id: string; name?: string };
  donation?: { id: string; name?: string };
  employee?: { id: string; name?: string };
  ledgerAccount?: { id: string; name?: string };
  global: boolean;
  receiptDate: string;
  accountingDate: string;
  accountingEntries?: Array<AccountingEntryPayload>;
  supplierAccount?: { id: string | undefined };
}

const expenseStateMap: { [key in ExpenseState]: string } = {
  PENDING: 'Pendiente',
  PAYED: 'Pago',
};

type PayExpensesPayload = Array<{
  id: string;
  payDate: string;
}>;

export type { Transaction, Expense, PayExpensesPayload, Money };
export { ExpenseState, expenseStateMap };
