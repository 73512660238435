import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  CategoryFormType,
  CategorySchema,
  defaultCategoryFormValues,
} from './utils/CategorySchema';
import { zodResolver } from '@hookform/resolvers/zod';
import DialogWrapper from '@/components/shared/DialogWrapper';
import { useMutation } from '@tanstack/react-query';
import { createCategoryMutationFn, useCategories } from '@/api/category/category.api';
import { AxiosError } from 'axios';
import { toast } from 'sonner';
import { paths } from '@/routes/paths';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { SONNER_SUCCESS_OPTIONS, SONNER_ERROR_OPTIONS } from '@/common/constants';
import { CategoriesForm } from './components/CategoriesForm';
import * as Sentry from '@sentry/react';
import { CategoryApi } from '@/api/category/category.model';
import { ErrorResponse } from '@/api/types';

const CategoriesCreate: FC = () => {
  const navigate = useNavigate();

  const form = useForm<CategoryFormType>({
    defaultValues: defaultCategoryFormValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(CategorySchema),
  });

  const { refetch } = useCategories();

  const { mutate: createCategory, isPending } = useMutation({
    mutationFn: createCategoryMutationFn,
    onSuccess: (data: CategoryApi) => {
      refetch();
      toast(
        <SuccessToastText>
          ¡Categoria <span className="text-blue-700">{data.name}</span> fue creada exitosamente!
        </SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismiss();
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      Sentry.captureException(error);

      if (error.response && error.response.data.type === 'DUPLICATED_ERROR') {
        form.setError('name', {
          type: 'validate',
          message:
            'El nombre ingresado ya fue utilizado previamente. Intentá nuevamente con un nombre nuevo.',
        });
      }
      toast.error('Ha ocurrido un error durante la creación de la nueva Categoría.', {
        description: 'Por favor, revisa los datos ingresados e intenta de nuevo.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  const handleCreateCategory: SubmitHandler<CategoryFormType> = (formData) =>
    createCategory(formData);

  const handleDismiss = () => navigate(paths.CATEGORIES);

  return (
    <DialogWrapper
      title="Crear Categoría"
      subtitle="Las categorías podrán ser asociadas a multiples Centros de Costos, asignando un monto a cada uno de ellos."
      className="w-[425px]"
      onDismiss={handleDismiss}
      onOverlayClick={handleDismiss}
    >
      <CategoriesForm
        form={form}
        submitLabel="Crear Categoría"
        cancelLabel="Cancelar"
        isLoading={isPending}
        submitHandler={handleCreateCategory}
        dismissHandler={handleDismiss}
      />
    </DialogWrapper>
  );
};

export default CategoriesCreate;
