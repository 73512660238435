import { MutationFunction, keepPreviousData, useQuery } from '@tanstack/react-query';
import { QueryRequestParams, ResponsePaginated } from '../types';
import {
  DEFAULT_PAGINATION_DIR,
  DEFAULT_PAGINATION_PAGE_INDEX,
  DEFAULT_PAGINATION_PAGE_SEARCH,
  DEFAULT_PAGINATION_PAGE_SIZE,
  DEFAULT_PAGINATION_SORT,
} from '@/common/constants';
import { CRUDService } from '../CRUDService.api';
import { Supplier, SupplierList } from './supplier.model';
import { SupplierFormType } from '@/pages/Suppliers/utils/SupplierSchema';
import { UUID } from 'crypto';
import httpClient from '../httpClient';
import * as Sentry from '@sentry/react';

const SUPPLIERS_BASE_PATH = '/supplier';
const SUPPLIERS_KEY = 'suppliers';
const SUPPLIERS_STALE_TIME = 5000;

const transformSupplierList = (response: Array<Supplier>): Array<SupplierList> => {
  return response.map((supplier) => {
    return {
      id: supplier.id,
      name: supplier.name,
      value: supplier.name,
      ledgerAccount: (supplier.ledgerAccount as string) ?? null,
      accounts: supplier.accounts,
    };
  });
};

class SupplierService extends CRUDService<Supplier, SupplierFormType> {
  constructor() {
    super(SUPPLIERS_BASE_PATH);
  }

  async getList() {
    try {
      const response = await httpClient.get(`${SUPPLIERS_BASE_PATH}/list`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }
}

const supplierService = new SupplierService();

const useSuppliers = ({
  page = DEFAULT_PAGINATION_PAGE_INDEX,
  size = DEFAULT_PAGINATION_PAGE_SIZE,
  sort = DEFAULT_PAGINATION_SORT,
  dir = DEFAULT_PAGINATION_DIR,
  search = DEFAULT_PAGINATION_PAGE_SEARCH,
}: QueryRequestParams<Supplier> = {}) => {
  const queryParams = `page=${page}&size=${size}&sort=${sort}&dir=${dir}&query=${search}`;

  return useQuery<
    [ResponsePaginated<Supplier>, { totalExpenses: number }],
    Error,
    [ResponsePaginated<Supplier>, { totalExpenses: number }]
  >({
    queryKey: [SUPPLIERS_KEY, { page, size, search }],
    queryFn: async () => {
      const response = (await supplierService.getAll(queryParams)) as {
        page: ResponsePaginated<Supplier>;
        data: { totalExpenses: number };
      };
      return [response.page, response.data];
    },
    placeholderData: keepPreviousData,
    staleTime: SUPPLIERS_STALE_TIME,
  });
};

const useSupplier = (id: UUID) => {
  return useQuery<Supplier, Error, Supplier>({
    queryKey: [`${SUPPLIERS_KEY}-edit`, { id }],
    queryFn: async () => {
      const response = (await supplierService.get(id)) as Supplier;
      return response as Supplier;
    },
    placeholderData: keepPreviousData,
    staleTime: SUPPLIERS_STALE_TIME,
    retry: 1,
  });
};

const useSupplierList = () => {
  return useQuery<Array<Supplier>, Error, Array<SupplierList>>({
    queryKey: [`${SUPPLIERS_KEY}-list`],
    queryFn: async () => {
      const response = await supplierService.getList();
      return response as Array<Supplier>;
    },
    select: transformSupplierList,
    placeholderData: keepPreviousData,
    staleTime: SUPPLIERS_STALE_TIME,
  });
};

const createSupplierMutationFn = async (data: SupplierFormType) => {
  data.accounts = data.accounts?.map((account) => ({
    cbu: account.cbu,
    currency: account.currency,
  }));
  return await supplierService.create(data);
};

const editSupplierMutationFn: MutationFunction<
  Supplier,
  [Supplier['id'], SupplierFormType]
> = async (params) => {
  const [id, data] = params;
  data.accounts = data.accounts?.map((account) => {
    if (account.createdAt) {
      return {
        cbu: account.cbu,
        currency: account.currency,
        createdAt: account.createdAt,
        updatedAt: account.updatedAt,
        id: account.id,
        expenses: [],
      };
    } else {
      return {
        cbu: account.cbu,
        currency: account.currency,
      };
    }
  });
  return (await supplierService.edit(id, data)) as Supplier;
};

const deleteSupplierMutationFn = async (id: Supplier['id']) => {
  return await supplierService.delete(id);
};

export {
  SUPPLIERS_KEY,
  useSuppliers,
  useSupplier,
  useSupplierList,
  createSupplierMutationFn,
  editSupplierMutationFn,
  deleteSupplierMutationFn,
};
