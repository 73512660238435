import { FC, ReactNode } from 'react';

interface SuccessToastTextProps {
  children: ReactNode;
}

const SuccessToastText: FC<SuccessToastTextProps> = ({ children }) => {
  return <p className="text-sm font-medium text-foreground">{children}</p>;
};

export default SuccessToastText;
