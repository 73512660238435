import { useLedgerAccountChildrens } from '@/api/ledgerAccount/ledgerAccount.api';
import { CURRENCY_OPTIONS } from '@/common/constants';
import { CurrencyOption } from '@/common/types';
import { SelectFilter } from '@/components/shared/SelectFilter';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { CurrencyInput } from '@/components/ui/currency-input';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import { fixAmount, getRequiredLabel } from '@/lib/utils';
import { Trash } from 'lucide-react';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { AccountingEntryItemSchema } from './AccountingEntryItemSchema';
import { AccountingEntryFormType } from '../AccountingEntrySchema';

interface AccountingEntryItemProps {
  form: UseFormReturn<AccountingEntryFormType, unknown>;
  index: number;
  usePrepopulatedAccount?: boolean;
  onDelete: (index: number) => void;
}

const getDebitInArs = (
  amount: number,
  exchangeRateOfficial: number,
  currency: CurrencyOption['value'],
) => {
  if (amount >= 0) {
    if (currency === 'ARS') {
      return amount;
    }

    return fixAmount(amount * exchangeRateOfficial, 2);
  }

  return undefined;
};

const getCreditInArs = (
  amount: number,
  exchangeRateOfficial: number,
  currency: CurrencyOption['value'],
) => {
  if (amount < 0) {
    if (currency === 'ARS') {
      return -amount;
    }

    return fixAmount(-amount * exchangeRateOfficial, 2);
  }

  return 0;
};

const AccountingEntryItem: FC<AccountingEntryItemProps> = ({
  form,
  index,
  usePrepopulatedAccount,
  onDelete,
}) => {
  const { data: ledgerAccounts, isLoading: isLoadingLedgerAccounts } = useLedgerAccountChildrens();
  const amount = form.watch(`accountingEntries.${index}.amount`);
  const exchangeRateOfficial = form.watch(`accountingEntries.${index}.exchangeRateOfficial`);

  const handleChangeCurrency = (value: string) => {
    form.setValue(`accountingEntries.${index}.currency`, value);
    if (value === 'USD' || value === 'ARS') {
      form.setValue(`accountingEntries.${index}.exchangeRateOfficial`, 1);
      form.setValue(`accountingEntries.${index}.exchangeRateAlt`, 1);
    }
  };

  return (
    <Card className="relative p-6 mt-4">
      <Button
        type="button"
        onClick={() => onDelete(index)}
        variant="outlineDestructive"
        className="absolute right-4"
      >
        <Trash className="w-4 h-4" />
      </Button>
      <div className="flex flex-col gap-6">
        <SelectFilter
          form={form}
          formLabel={getRequiredLabel(
            AccountingEntryItemSchema,
            'ledgerAccount',
            'Cuenta Contable',
          )}
          formName={`accountingEntries.${index}.ledgerAccount`}
          options={ledgerAccounts || []}
          formPlaceholder="Selecciona una cuenta"
          formFilterPlaceholder="Buscar cuenta"
          isLoading={isLoadingLedgerAccounts}
          classnames={{ button: 'w-full', item: 'w-1/2' }}
          disabled={usePrepopulatedAccount}
        />
        <div className="flex flex-row gap-4">
          <FormField
            control={form.control}
            name={`accountingEntries.${index}.currency`}
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>
                  {getRequiredLabel(AccountingEntryItemSchema, 'currency', 'Moneda')}
                </FormLabel>
                <Select onValueChange={(value) => handleChangeCurrency(value)} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Seleccione la Moneda" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {CURRENCY_OPTIONS.map((currency) => (
                      <SelectItem key={currency.value} value={currency.value}>
                        {currency.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name={`accountingEntries.${index}.amount`}
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>
                  {getRequiredLabel(AccountingEntryItemSchema, 'amount', 'Monto')}
                </FormLabel>
                <FormControl>
                  <CurrencyInput
                    {...field}
                    type="number"
                    currency={
                      form.watch(`accountingEntries.${index}.currency`) as CurrencyOption['value']
                    }
                    placeholder="Ingresar monto"
                    allowNegative={true}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {form.watch(`accountingEntries.${index}.currency`) !== 'ARS' && (
            <>
              <FormField
                control={form.control}
                name={`accountingEntries.${index}.exchangeRateOfficial`}
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>
                      {getRequiredLabel(
                        AccountingEntryItemSchema,
                        'exchangeRateOfficial',
                        'Tipo de Cambio Oficial',
                      )}
                    </FormLabel>
                    <FormControl>
                      <Input {...field} type="number" placeholder="Ingresar Conversión" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={`accountingEntries.${index}.exchangeRateAlt`}
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>
                      {getRequiredLabel(
                        AccountingEntryItemSchema,
                        'exchangeRateAlt',
                        'Tipo de Cambio Alt.',
                      )}
                    </FormLabel>
                    <FormControl>
                      <Input {...field} type="number" placeholder="Ingresar Conversión" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </>
          )}
        </div>
        <div className="flex flex-row gap-4">
          <FormItem className="w-1/2">
            <FormLabel>Debe en ARS</FormLabel>
            <FormControl>
              <CurrencyInput
                type="number"
                currency="ARS"
                placeholder="Ingresar monto"
                disabled
                value={getDebitInArs(
                  amount,
                  exchangeRateOfficial,
                  form.watch(`accountingEntries.${index}.currency`) as CurrencyOption['value'],
                )}
                onChange={() => {}}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
          <FormItem className="w-1/2">
            <FormLabel>Haber en ARS</FormLabel>
            <FormControl>
              <CurrencyInput
                type="number"
                currency="ARS"
                placeholder="Ingresar monto"
                disabled
                value={getCreditInArs(
                  amount,
                  exchangeRateOfficial,
                  form.watch(`accountingEntries.${index}.currency`) as CurrencyOption['value'],
                )}
                onChange={() => {}}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </div>
        <div className="flex flex-row gap-4">
          <FormField
            control={form.control}
            name={`accountingEntries.${index}.details`}
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>
                  {getRequiredLabel(AccountingEntryItemSchema, 'details', 'Detalle')}
                </FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="Escriba aquí el detalle..."
                    className="resize-none"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>
    </Card>
  );
};

export default AccountingEntryItem;
