interface DashboardData {
  nextReport: string;
  weeklyExpenses: string;
}

export enum UserRole {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export type { DashboardData };
