import { CreditNoteFormType } from '@/pages/CreditNotes/utils/CreditNoteSchema';
import {
  CreateCreditNotePayload,
  CreditNoteApi,
  CreditNotesTransformResponse,
} from './creditNote.model';
import { UUID } from 'crypto';
import * as Sentry from '@sentry/react';
import httpClient from '../httpClient';
import {
  DEFAULT_PAGINATION_DIR,
  DEFAULT_PAGINATION_PAGE_INDEX,
  DEFAULT_PAGINATION_PAGE_SIZE,
  DEFAULT_PAGINATION_SORT,
} from '@/common/constants';
import { QueryRequestParams, ResponsePaginated } from '../types';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { InvoiceApi } from '../invoice/invoice.model';
import { CRUDService } from '../CRUDService.api';
import { Money } from '@/common/types';

const CREDIT_NOTE_BASE_PATH = '/creditNote';
const CREDIT_NOTE_KEY = 'creditNote';
const CREDIT_NOTE_STALE_TIME = 5000;

interface CreateCrediNoteMutationParams {
  data: CreditNoteFormType;
  invoiceId: InvoiceApi['id'];
  invoiceCurrency: Money['currency'];
}

const transformCreditNoteFormToPayload = ({
  data,
  invoiceId,
  invoiceCurrency,
}: CreateCrediNoteMutationParams): CreateCreditNotePayload => {
  const amount = data.creditNoteDetails.reduce((accumulator, item) => {
    return accumulator + item.amount;
  }, 0);

  return {
    number: data.number,
    invoice: {
      id: invoiceId,
    },
    ledgerAccount: {
      id: data.ledgerAccount.id as UUID,
    },
    receiptDate: data.receiptDate.toISOString().split('T')[0],
    reversionDate: data.reversionDate.toISOString().split('T')[0],
    fileURL: data && data.files ? data.files[0] : '',
    details: data.details || '',
    amount: amount,
    money: {
      amount: amount,
      currency: invoiceCurrency,
      exchangeRate: data.exchangeRate,
    },
    creditNoteDetails: data.creditNoteDetails,
  };
};

class CreditNoteService extends CRUDService<CreditNoteApi, CreditNoteFormType> {
  constructor() {
    super(CREDIT_NOTE_BASE_PATH);
  }

  async getInvoiceCreditNotes(id: InvoiceApi['id'], params?: string) {
    try {
      const response = await httpClient.get(
        params
          ? `${CREDIT_NOTE_BASE_PATH}/invoice/${id}?${params}`
          : `${CREDIT_NOTE_BASE_PATH}/invoice/${id}`,
      );
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async createCreditNote(data: CreateCreditNotePayload) {
    try {
      const response = await httpClient.post(`${CREDIT_NOTE_BASE_PATH}`, {
        data,
      });
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }
}

const creditNoteService = new CreditNoteService();

const useCreditNotes = ({
  page = DEFAULT_PAGINATION_PAGE_INDEX,
  size = DEFAULT_PAGINATION_PAGE_SIZE,
  sort = DEFAULT_PAGINATION_SORT,
  dir = DEFAULT_PAGINATION_DIR,
}: QueryRequestParams<CreditNoteApi> = {}) => {
  const queryParams = `page=${page}&size=${size}&sort=${sort}&dir=${dir}`;

  return useQuery<ResponsePaginated<CreditNoteApi>, Error, CreditNotesTransformResponse>({
    queryKey: [CREDIT_NOTE_KEY, { page, size }],
    queryFn: async () => {
      const response = await creditNoteService.getAll(queryParams);
      return response as ResponsePaginated<CreditNoteApi>;
    },
    placeholderData: keepPreviousData,
    staleTime: CREDIT_NOTE_STALE_TIME,
  });
};

const useInvoiceCreditNotes = (
  id: InvoiceApi['id'],
  {
    page = DEFAULT_PAGINATION_PAGE_INDEX,
    size = DEFAULT_PAGINATION_PAGE_SIZE,
    sort = DEFAULT_PAGINATION_SORT,
    dir = DEFAULT_PAGINATION_DIR,
    staleTime = CREDIT_NOTE_STALE_TIME,
  }: QueryRequestParams<CreditNoteApi> = {},
) => {
  const queryParams = `page=${page}&size=${size}&sort=${sort}&dir=${dir}`;

  return useQuery<ResponsePaginated<CreditNoteApi>, Error, CreditNotesTransformResponse>({
    queryKey: [CREDIT_NOTE_KEY, { id, page, size }],
    queryFn: async () => {
      const response = await creditNoteService.getInvoiceCreditNotes(id, queryParams);
      return response as ResponsePaginated<CreditNoteApi>;
    },
    placeholderData: keepPreviousData,
    staleTime,
  });
};

const createCreditNoteMutationFn = async ({
  data,
  invoiceId,
  invoiceCurrency,
}: CreateCrediNoteMutationParams): Promise<any> => {
  const payload = transformCreditNoteFormToPayload({ data, invoiceId, invoiceCurrency });
  return await creditNoteService.createCreditNote(payload);
};

const deleteCreditNoteMutationFn = async (id: CreditNoteApi['id']) => {
  return await creditNoteService.delete(id);
};

export {
  useCreditNotes,
  useInvoiceCreditNotes,
  createCreditNoteMutationFn,
  deleteCreditNoteMutationFn,
};
