import { ColumnDef } from '@tanstack/react-table';
import { SortColumnWithBe } from '@/components/shared/SortColumnWithBe';
import { Donor } from '@/api/donor/donor.model';
import { formatAmount } from '@/lib/utils';

const donorsTableColumns: ColumnDef<Donor>[] = [
  {
    accessorKey: 'name',
    header: ({ column }) => <SortColumnWithBe title="Nombre" column={column} />,
  },
  {
    accessorKey: 'email',
    header: ({ column }) => <SortColumnWithBe title="Email" column={column} />,
    cell: ({ row }) => {
      const email = row.getValue('email');
      return email || 'N/A';
    },
  },
  {
    accessorKey: 'amount',
    header: () => <p className="text-xs">Total Donaciones</p>,
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue('amount'));
      return formatAmount(amount);
    },
  },
  {
    accessorKey: 'donationsQuantity',
    header: () => <p className="text-xs">Donaciones</p>,
  },
];

export { donorsTableColumns };
