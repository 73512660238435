import {
  LayoutGrid,
  DollarSign,
  Heart,
  MapPin,
  UserRound,
  Briefcase,
  Menu,
  Folders,
  Archive,
  Globe,
  Scale,
} from 'lucide-react';
import { paths } from '@/routes/paths';
import { DEFAULT_LEDGERACCOUNT_TAB } from '@/common/constants';

const SidebarElements = [
  {
    name: 'Dashboard',
    icon: <LayoutGrid className="w-4 h-4 mr-2" />,
    path: paths.DASHBOARD,
  },
  {
    name: 'Gastos',
    icon: <DollarSign className="w-4 h-4 mr-2" />,
    path: paths.EXPENSES,
    submenu: [
      {
        name: 'Facturas',
        path: paths.INVOICES,
      },
      {
        name: 'Proveedores',
        path: paths.SUPPLIERS,
      },
      {
        name: 'Expense Reports',
        path: paths.EXPENSE_REPORTS,
      },
    ],
  },
  {
    name: 'Proyectos',
    icon: <Folders className="w-4 h-4 mr-2" />,
    path: paths.PROJECTS,
  },
  {
    name: 'Centros de Costos',
    icon: <Briefcase className="w-4 h-4 mr-2" />,
    path: paths.COST_CENTERS,
  },
  {
    name: 'Donaciones',
    icon: <Heart className="w-4 h-4 mr-2" />,
    path: paths.DONATIONS,
    submenu: [
      {
        name: 'Donaciones',
        path: paths.DONATIONS,
      },
      {
        name: 'Donantes',
        path: paths.DONORS,
      },
    ],
  },
  {
    name: 'Patrocinador Fiscal',
    icon: <UserRound className="w-4 h-4 mr-2" />,
    path: paths.FISCAL_SPONSORS,
  },
  {
    name: 'Categorias',
    icon: <Menu className="w-4 h-4 mr-2" />,
    path: paths.CATEGORIES,
  },
  {
    name: 'Territorios',
    icon: <MapPin className="w-4 h-4 mr-2" />,
    path: paths.TERRITORIES,
  },
  {
    name: 'Plan de Cuentas',
    icon: <Archive className="w-4 h-4 mr-2" />,
    path: DEFAULT_LEDGERACCOUNT_TAB.path,
  },
  {
    name: 'Cuentas Bancarias',
    icon: <Globe className="w-4 h-4 mr-2" />,
    path: paths.BANK_ACCOUNTS,
  },
  {
    name: 'Mayor de Cuentas',
    icon: <Scale className="w-4 h-4 mr-2" />,
    path: paths.ACCOUNTS_MAYOR,
  },
];

export default SidebarElements;
