import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import DialogWrapper from '@/components/shared/DialogWrapper';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'sonner';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { SONNER_ERROR_OPTIONS, SONNER_SUCCESS_OPTIONS } from '@/common/constants';
import * as Sentry from '@sentry/react';
import { FiscalSponsorApi } from '@/api/fiscalSponsor/fiscalSponsor.model';
import {
  FiscalSponsorFormType,
  FiscalSponsorSchema,
  defaultFiscalSponsorFormValues,
} from './utils/FiscalSponsorSchema';
import {
  editFiscalSponsorMutationFn,
  useFiscalSponsor,
  useFiscalSponsors,
} from '@/api/fiscalSponsor/fiscalSponsor.api';
import FiscalSponsorForm from './components/FiscalSponsorForm';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ErrorResponse } from '@/api/types';
import { UUID } from 'crypto';
import { paths } from '@/routes/paths';
import { EditFormSkeleton } from '@/components/shared/EditFormSkeleton';

type FiscalSponsorsEditParams = {
  id: FiscalSponsorApi['id'];
};

const FiscalSponsorEdit: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams() as FiscalSponsorsEditParams;
  const {
    data: fiscalSponsor,
    isLoading: isFiscalSponsorLoading,
    refetch: refetchFiscalSponsor,
    error,
  } = useFiscalSponsor(id);
  const { refetch: refetchFiscalSponsors } = useFiscalSponsors();

  const form = useForm<FiscalSponsorFormType>({
    defaultValues: fiscalSponsor
      ? {
          name: fiscalSponsor.name,
          email: fiscalSponsor.email,
        }
      : defaultFiscalSponsorFormValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(FiscalSponsorSchema),
  });

  const { mutate: editFiscalSponsor, isPending } = useMutation<
    FiscalSponsorApi,
    AxiosError<ErrorResponse>,
    [UUID, FiscalSponsorFormType]
  >({
    mutationFn: editFiscalSponsorMutationFn,
    onSuccess: (data: FiscalSponsorApi) => {
      refetchFiscalSponsors();
      toast(
        <SuccessToastText>
          ¡Patrocinador fiscal <span className="text-blue-700">{data.name}</span> fue editado
          exitosamente!
        </SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismiss();
      refetchFiscalSponsor();
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      Sentry.captureException(error);

      if (error.response && error.response.data.type === 'DUPLICATED_ERROR') {
        form.setError('email', {
          type: 'validate',
          message:
            'El email ingresado ya fue utilizado previamente para otro Patrocinador Fiscal. Intentá nuevamente ingresado un nuevo email.',
        });
      } else {
        toast.error('Ha ocurrido un error durante la edición del Patrocinador Fiscal.', {
          description: 'Por favor, revisa los datos ingresados e intentá de nuevo.',
          ...SONNER_ERROR_OPTIONS,
        });
      }
    },
  });

  useEffect(() => {
    if (fiscalSponsor) {
      form.reset({
        name: fiscalSponsor.name,
        email: fiscalSponsor.email,
      });
    }
  }, [fiscalSponsor]);

  if (error) {
    return <Navigate to="*" replace={true} />;
  }

  const handleDismiss = () => navigate(paths.FISCAL_SPONSORS);

  const handleEditFiscalSponsor: SubmitHandler<FiscalSponsorFormType> = (formData) => {
    if (fiscalSponsor) {
      editFiscalSponsor([fiscalSponsor.id, formData]);
    } else {
      toast.error('Lo sentimos, no tenés ningún Patrocinador Fiscal seleccionado.', {
        description: 'Por favor, seleccioná un Patrocinador Fiscal e intentá de nuevo.',
        ...SONNER_ERROR_OPTIONS,
      });
      handleDismiss();
    }
  };

  return (
    <DialogWrapper
      title="Editar Patrocinador Fiscal"
      className="w-[425px]"
      onDismiss={handleDismiss}
      onOverlayClick={handleDismiss}
    >
      {isFiscalSponsorLoading || !fiscalSponsor ? (
        <EditFormSkeleton inputRows={2} />
      ) : (
        <FiscalSponsorForm
          form={form}
          submitLabel="Editar Patrocinador Fiscal"
          cancelLabel="Cancelar"
          isLoading={isPending}
          submitHandler={handleEditFiscalSponsor}
          dismissHandler={handleDismiss}
        />
      )}
    </DialogWrapper>
  );
};

export default FiscalSponsorEdit;
