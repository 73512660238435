import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import DialogWrapper from '@/components/shared/DialogWrapper';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'sonner';
import { paths } from '@/routes/paths';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { SONNER_SUCCESS_OPTIONS, SONNER_ERROR_OPTIONS } from '@/common/constants';
import * as Sentry from '@sentry/react';
import {
  SupplierFormType,
  SupplierSchema,
  defaultSupplierFormValues,
} from './utils/SupplierSchema';
import { createSupplierMutationFn, useSuppliers } from '@/api/supplier/supplier.api';
import { Supplier } from '@/api/supplier/supplier.model';
import { SupplierForm } from './components/SupplierForm';
import { ErrorResponse } from '@/api/types';

const SuppliersCreate: FC = () => {
  const navigate = useNavigate();

  const form = useForm<SupplierFormType>({
    defaultValues: defaultSupplierFormValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(SupplierSchema),
  });

  const { refetch } = useSuppliers();

  const { mutate: createSupplier, isPending } = useMutation({
    mutationFn: createSupplierMutationFn,
    onSuccess: (data: Supplier) => {
      refetch();
      toast(
        <SuccessToastText>
          ¡Proveedor <span className="text-blue-700">{data.name}</span> fue creado exitosamente!
        </SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismiss();
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      Sentry.captureException(error);
      if (error.response && error.response.data.type === 'DUPLICATED_ERROR') {
        if (error.response.data.errors?.some((error) => error.includes('(email)'))) {
          form.setError('email', {
            type: 'validate',
            message:
              'El email ingresado ya fue utilizado previamente. Intentá nuevamente con un email nuevo.',
          });
        } else if (error.response.data.errors?.some((error) => error.includes('(cuit)'))) {
          form.setError('cuit', {
            type: 'validate',
            message:
              'El CUIT/CUIL ingresado ya fue utilizado previamente. Intentá nuevamente con un CUIT/CUIL nuevo.',
          });
        } else if (error.response.data.errors?.some((error) => error.includes('(code)'))) {
          form.setError('code', {
            type: 'validate',
            message:
              'El código ingresado ya fue utilizado previamente. Intentá nuevamente con uno distinto.',
          });
        }
      }
      toast.error('Ha ocurrido un error durante la creación del nuevo Proveedor.', {
        description: 'Por favor, revisa los datos ingresados e intenta de nuevo.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  const handleCreateSupplier: SubmitHandler<SupplierFormType> = (formData) => {
    if (
      formData.accounts?.length === 1 &&
      formData.accounts[0].cbu === '' &&
      formData.accounts[0].currency === ''
    ) {
      formData.accounts = [];
    }
    createSupplier(formData);
  };

  const handleDismiss = () => navigate(paths.SUPPLIERS);

  return (
    <DialogWrapper
      title="Registrar nuevo Proveedor"
      onDismiss={handleDismiss}
      onOverlayClick={handleDismiss}
      className="w-full max-w-3xl"
      titleClassName="text-xl"
    >
      <SupplierForm
        form={form}
        submitLabel="Registrar Proveedor"
        cancelLabel="Cancelar"
        isLoading={isPending}
        submitHandler={handleCreateSupplier}
        dismissHandler={handleDismiss}
      />
    </DialogWrapper>
  );
};

export default SuppliersCreate;
