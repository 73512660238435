import {
  DEFAULT_NAME_SCHEMA,
  DEFAULT_CBU_SCHEMA,
  CURRENCY_OPTIONS,
  DEFAULT_ACCOUNT_NUMBER_SCHEMA,
} from '@/common/constants';
import { z } from 'zod';

const REQUIRED_OPTION_LABEL = 'Seleccione una opción';

const SelectSchema = z
  .object({
    id: z.string().min(1, REQUIRED_OPTION_LABEL),
    name: z.string(),
  })
  .required();

export const BankAccountSchema = z
  .object({
    name: DEFAULT_NAME_SCHEMA,
    accountNumber: DEFAULT_ACCOUNT_NUMBER_SCHEMA,
    ledgerAccount: SelectSchema,
    cbu: DEFAULT_CBU_SCHEMA,
    currency: z.string().min(1, REQUIRED_OPTION_LABEL),
  })
  .required();

export type BankAccountFormType = z.infer<typeof BankAccountSchema>;

export const defaultBankAccountFormValues: BankAccountFormType = {
  name: '',
  accountNumber: '',
  ledgerAccount: { id: '', name: '' },
  cbu: null,
  currency: CURRENCY_OPTIONS[0].value,
};
