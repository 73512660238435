import { FC, useMemo, useState } from 'react';
import { Button } from '../ui/button';
import { CalendarDays, Check } from 'lucide-react';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { Calendar } from '../ui/calendar';
import { DateRange, isDateRange } from 'react-day-picker';
import { cn, getFormattedDate, getLongDateFormat } from '@/lib/utils';
import { useNavigate } from 'react-router-dom';

type SetStateFunction = (newValue: string) => void;

interface DateFilterParams {
  setSelectedDate?: SetStateFunction;
  selectedDate?: string | undefined;
  setStartDate?: SetStateFunction;
  startDate?: string | undefined;
  setEndDate?: SetStateFunction;
  endDate?: string | undefined;
  mode?: 'single' | 'range';
  setFilters?: SetStateFunction;
  hidePopup?: boolean;
  className?: string;
}

const DateFilter: FC<DateFilterParams> = ({
  setSelectedDate,
  selectedDate,
  mode = 'single',
  setStartDate,
  startDate,
  setEndDate,
  endDate,
  hidePopup,
  className,
}) => {
  const navigate = useNavigate();
  const currentUrl = new URL(window.location.href);
  const params = new URLSearchParams(currentUrl.search);

  const today = new Date();
  const [month, setMonth] = useState(today);

  const updateUrlAndNavigate = () => {
    currentUrl.search = params.toString();
    window.history.pushState({}, '', currentUrl);
    navigate(currentUrl.pathname + currentUrl.search);
  };

  const dateChangeHandler = (date: Date | DateRange | undefined) => {
    if (date) {
      if (!isDateRange(date)) {
        setSelectedDate?.(getFormattedDate(date));
        params.set('date', getFormattedDate(date));
      } else {
        setStartDate?.(getFormattedDate(date.from));
        setEndDate?.(getFormattedDate(date.to));
        params.set('startDate', getFormattedDate(date.from));
        params.set('endDate', getFormattedDate(date.to));
      }
    } else {
      if (!isDateRange(date)) {
        setSelectedDate?.('');
        params.delete('date');
      } else {
        setStartDate?.('');
        setEndDate?.('');
        params.delete('startDate');
        params.delete('endDate');
      }
    }
    updateUrlAndNavigate();
  };

  const handlerClearDates = () => {
    if (setSelectedDate) {
      setSelectedDate('');
      params.delete('date');
      params.set('pageIndex', '0');
    } else if (setStartDate && setEndDate) {
      setStartDate('');
      setEndDate('');
      params.delete('startDate');
      params.delete('endDate');
      params.set('pageIndex', '0');
    }
    setMonth(today);
    updateUrlAndNavigate();
  };

  const fecha = useMemo(() => {
    const range: DateRange = { from: undefined, to: undefined };
    if (selectedDate) {
      const [year, month, day] = selectedDate.split('-').map(Number);
      return new Date(year, month - 1, day);
    }
    if (startDate) {
      const [year, month, day] = startDate.split('-').map(Number);
      range.from = new Date(year, month - 1, day);
    }
    if (endDate) {
      const [year, month, day] = endDate.split('-').map(Number);
      range.to = new Date(year, month - 1, day);
    }
    return range;
  }, [startDate, endDate, selectedDate]);

  const displayText = selectedDate ? (
    <div>
      <span className="text-teal-600 font-normal">Hasta: </span>
      <span className="text-teal-600 font-bold">{getLongDateFormat(selectedDate)}</span>
    </div>
  ) : startDate && endDate ? (
    <div>
      <span className="text-teal-600 font-bold">{getLongDateFormat(startDate)} - </span>
      <span className="text-teal-600 font-bold">{getLongDateFormat(endDate)}</span>
    </div>
  ) : (
    'Filtrar Período'
  );
  return (
    <>
      <Popover>
        <PopoverTrigger asChild>
          <div className="flex flex-col items-center">
            <Button
              variant={'secondary'}
              onClick={() => {}}
              className={cn(
                className,
                selectedDate || (startDate && endDate) ? 'bg-green-100 hover:bg-green-100' : '',
              )}
            >
              {selectedDate || (startDate && endDate) ? (
                <Check
                  color="#ffffff"
                  size={18}
                  className=" bg-teal-600 rounded mr-3"
                  onClick={handlerClearDates}
                />
              ) : (
                <CalendarDays className="w-4 h-4 mr-2" />
              )}
              {displayText}
            </Button>
            {!hidePopup && (selectedDate || (startDate && endDate)) ? (
              <span className="bg-teal-600 py-2 px-5 rounded-b-[12px] text-white text-[11px]">
                Período Seleccionado
              </span>
            ) : null}
          </div>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="end">
          <Calendar
            defaultMonth={month}
            mode={mode as any}
            showOutsideDays
            fixedWeeks
            fromYear={new Date().getFullYear() - 5}
            toYear={new Date().getFullYear() + 10}
            selected={fecha}
            onSelect={dateChangeHandler}
            month={month}
            onMonthChange={setMonth}
            classNames={{
              caption_label: '',
              nav_button:
                'h-7 w-7 p-0 opacity-50 hover:opacity-100 border border-input bg-transparent hover:bg-accent hover:text-accent-foreground inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-500 focus-visible:ring-offset-1',
            }}
          />
        </PopoverContent>
      </Popover>
    </>
  );
};

export { DateFilter };
