import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import DialogWrapper from '@/components/shared/DialogWrapper';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'sonner';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { SONNER_ERROR_OPTIONS, SONNER_SUCCESS_OPTIONS } from '@/common/constants';
import * as Sentry from '@sentry/react';
import {
  ExpenseReportFormType,
  ExpenseReportSchema,
  defaultExpenseReportFormValues,
} from './utils/ExpenseReportSchema';
import { ExpenseReportsForm } from './components/ExpenseReportsForm';
import { ExpenseReportApi } from '@/api/expenseReport/expenseReport.model';
import {
  editExpenseReportMutationFn,
  useExpenseReport,
  useExpenseReports,
} from '@/api/expenseReport/expenseReport.api';
import { getFullName } from '@/lib/utils';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Supplier } from '@/api/supplier/supplier.model';
import { ErrorResponse } from '@/api/types';
import { UUID } from 'crypto';
import { paths } from '@/routes/paths';
import { EditFormSkeleton } from '@/components/shared/EditFormSkeleton';

type SuppliersEditParams = {
  id: Supplier['id'];
};

const ExpenseReportsEdit: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams() as SuppliersEditParams;
  const {
    data: expenseReport,
    isLoading: isExpenseReportLoading,
    refetch: refetchExpenseReport,
    error,
  } = useExpenseReport(id);
  const { refetch: refetchExpenseReports } = useExpenseReports();

  const form = useForm<ExpenseReportFormType>({
    defaultValues: expenseReport
      ? {
          firstName: expenseReport.firstName,
          lastName: expenseReport.lastName,
          email: expenseReport.email,
        }
      : defaultExpenseReportFormValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(ExpenseReportSchema),
  });

  const { mutate: editExpenseReport, isPending } = useMutation<
    ExpenseReportApi,
    AxiosError<ErrorResponse>,
    [UUID, ExpenseReportFormType]
  >({
    mutationFn: editExpenseReportMutationFn,
    onSuccess: (data: ExpenseReportApi) => {
      refetchExpenseReports();
      toast(
        <SuccessToastText>
          ¡Expense Report{' '}
          <span className="text-blue-700">{getFullName(data.firstName, data.lastName)}</span> fue
          editado exitosamente!
        </SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismiss();
      refetchExpenseReport();
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      Sentry.captureException(error);
      toast.error('Ha ocurrido un error durante la edición del Expense Report.', {
        description: 'Por favor, revisa los datos ingresados e intentá de nuevo.',
        ...SONNER_ERROR_OPTIONS,
      });

      if (error.response && error.response.data.type === 'DUPLICATED_ERROR') {
        form.setError('email', {
          type: 'validate',
          message:
            'El email ingresado ya fue utilizado previamente para otro Expense Report. Intentá nuevamente ingresado un nuevo email.',
        });
      } else {
        toast.error('Ha ocurrido un error durante la edición del Expense Report.', {
          description: 'Por favor, revisa los datos ingresados e intentá de nuevo.',
          ...SONNER_ERROR_OPTIONS,
        });
      }
    },
  });

  useEffect(() => {
    if (expenseReport) {
      form.reset({
        firstName: expenseReport.firstName,
        lastName: expenseReport.lastName,
        email: expenseReport.email,
      });
    }
  }, [expenseReport]);

  if (error) {
    return <Navigate to="*" replace={true} />;
  }

  const handleDismiss = () => navigate(paths.EXPENSE_REPORTS);

  const handleEditExpenseReport: SubmitHandler<ExpenseReportFormType> = (formData) => {
    if (expenseReport) {
      editExpenseReport([expenseReport.id, formData]);
    } else {
      toast.error('Lo sentimos, no tenés ningún Expense Report seleccionado.', {
        description: 'Por favor, seleccioná un Expense Report e intentá de nuevo.',
        ...SONNER_ERROR_OPTIONS,
      });
      handleDismiss();
    }
  };

  return (
    <DialogWrapper
      title="Editar Expense Report"
      onDismiss={handleDismiss}
      onOverlayClick={handleDismiss}
    >
      {isExpenseReportLoading || !expenseReport ? (
        <EditFormSkeleton inputRows={3} />
      ) : (
        <ExpenseReportsForm
          form={form}
          submitLabel="Editar Expense Report"
          cancelLabel="Cancelar"
          isLoading={isPending}
          submitHandler={handleEditExpenseReport}
          dismissHandler={handleDismiss}
        />
      )}
    </DialogWrapper>
  );
};

export { ExpenseReportsEdit };
