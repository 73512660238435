import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { pathsBase } from '@/routes/paths';
import { Expense } from '@/api/transaction/transaction.model';
import { format } from 'date-fns';
import { useInvoice } from '@/api/invoice/invoice.api';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { SONNER_SUCCESS_OPTIONS, SONNER_ERROR_OPTIONS } from '@/common/constants';
import { CurrencyOption } from '@/common/types';
import { PaymentForm } from './components/PaymentsForm';
import { PaymentFormType, PaymentSchema, defaultPaymentFormValues } from './utils/PaymentSchema';
import { generatePaymentForm } from './utils/PaymentUtils';
import { createPaymentMutationFn, usePayments } from '@/api/payment/payment.api';
import { AxiosError } from 'axios';
import { ErrorResponse } from '@/api/types';
import { SheetLayout } from '@/components/layout/SheetLayout.tsx';
import { Helmet } from 'react-helmet';
import { EditFormSkeleton } from '@/components/shared/EditFormSkeleton.tsx';
import TableSkeleton from '@/components/shared/TableSkeleton.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import { getParamsWithAnimation } from '@/lib/utils';

const PAGE_TITLE = 'Registrar Pago';

type InvoiceEditParams = {
  id: Expense['id'];
};

const PaymentCreate: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams() as InvoiceEditParams;

  const { data: invoice, refetch: refetchInvoice, isLoading: isLoadingInvoice } = useInvoice(id);

  const { refetch: refetchInvoicePayments } = usePayments(id);

  const form = useForm<PaymentFormType>({
    defaultValues: invoice ? generatePaymentForm(invoice) : defaultPaymentFormValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(PaymentSchema),
  });

  const { mutate: createPayment, isPending } = useMutation({
    mutationFn: createPaymentMutationFn,
    onSuccess: () => {
      refetchInvoice();
      refetchInvoicePayments();
      toast(
        <SuccessToastText>
          ¡Pago <span className="text-blue-700">#{form.watch('paymentNumber')}</span> realizado
          exitosamente!
        </SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismiss();
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      if (error.response && error.response.data.type === 'DUPLICATED_ERROR') {
        form.setError('paymentNumber', {
          type: 'validate',
          message: 'El número ingresado ya fue utilizado previamente.',
        });
      }
      toast.error('Ha ocurrido un error durante la edición del pago.', {
        description: 'Por favor, revisa los datos ingresados e intenta de nuevo.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  useEffect(() => {
    form.setFocus('paymentNumber');
  }, []);

  const renderFormatedAmount = () => {
    const currencyInvoice = invoice?.money.currency;
    const currencyPayment = form.watch('currency');
    const exchangeRate = form.watch('exchangeRate');
    const amount = form.watch('amount');

    if (currencyInvoice === 'USD' && currencyPayment === 'ARS') {
      return amount / exchangeRate;
    }
    if (currencyInvoice === 'USD' && currencyPayment === 'USD') {
      return amount;
    }
    if (currencyInvoice === 'ARS' && currencyPayment === 'USD') {
      return amount * exchangeRate;
    }
    if (currencyInvoice === 'ARS' && currencyPayment === 'ARS') {
      return amount;
    }
    return amount;
  };

  const handleDismiss = () =>
    navigate(
      `/${pathsBase.EXPENSES}/${pathsBase.INVOICES}/${invoice?.id}${getParamsWithAnimation(location.search, true)}`,
    );

  const createPaymentHandler: SubmitHandler<PaymentFormType> = (formData) => {
    createPayment({
      paymentNumber: formData.paymentNumber,
      invoice: { id: `${invoice?.id}` },
      ledgerAccount: { id: formData.ledgerAccount.id },
      money: {
        currency: formData.currency as CurrencyOption['value'],
        amount: formData.amount,
        exchangeRate: formData.exchangeRate,
      },
      amount: renderFormatedAmount(),
      paymentDate: format(formData.paymentDate, 'yyyy-MM-dd'),
      fileURL: formData.files ? formData.files[0] : undefined,
    });
  };

  return (
    <>
      <Helmet>
        <title>PEM - {PAGE_TITLE}</title>
      </Helmet>
      <SheetLayout onClose={handleDismiss}>
        {isLoadingInvoice || !invoice ? (
          <>
            <EditFormSkeleton inputRows={3} />
            <TableSkeleton />
          </>
        ) : (
          <div className="mx-auto max-w-screen-lg">
            <div className="flex flex-col gap-4">
              <h1 className="text-3xl text-[40px] font-light tracking-tighter color-foreground">
                {PAGE_TITLE}
              </h1>
              <h2 className="text-2xl font-light tracking-tighter color-foreground">
                Factura: <span className="font-medium">#{invoice.receiptNumber}</span>
              </h2>
            </div>
            <Separator className="mt-8" />
            <div className="mt-12">
              <PaymentForm
                form={form}
                submitLabel="Registrar Pago"
                cancelLabel="Cancelar"
                submitHandler={createPaymentHandler}
                dismissHandler={handleDismiss}
                isLoading={isPending}
                invoice={invoice ? invoice : null}
              />
            </div>
          </div>
        )}
      </SheetLayout>
    </>
  );
};

export { PaymentCreate };
