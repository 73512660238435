import httpClient from '../httpClient';
import {
  DEFAULT_PAGINATION_DIR,
  DEFAULT_PAGINATION_PAGE_INDEX,
  DEFAULT_PAGINATION_PAGE_SEARCH,
  DEFAULT_PAGINATION_PAGE_SIZE,
  DEFAULT_PAGINATION_SORT,
} from '@/common/constants';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { Expense, PayExpensesPayload } from './transaction.model';
import { QueryRequestParams, ResponsePaginated } from '../types';
import { ExpenseReportApi } from '../expenseReport/expenseReport.model';
import { Supplier } from '../supplier/supplier.model';

const TRANSACTION_BASE_PATH = '/transaction';
const TRANSACTION_KEY = 'transactions';
const TRANSACTION_STALE_TIME = 5000;

const TransactionApi = {
  getExpenses: async (params?: string) => {
    try {
      const response = await httpClient.get(
        params
          ? `${TRANSACTION_BASE_PATH}/expenses?${params}`
          : `${TRANSACTION_BASE_PATH}/expenses`,
      );
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  deleteTransaction: async (id: string) => {
    try {
      const response = await httpClient.delete(`${TRANSACTION_BASE_PATH}/${id}`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  postTransaction: async (data: any) => {
    try {
      const response = await httpClient.post(`${TRANSACTION_BASE_PATH}`, {
        data,
      });
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  getEmployeeTransactions: async (employeeId: ExpenseReportApi['id'], params?: string) => {
    try {
      const response = await httpClient.get(
        params
          ? `${TRANSACTION_BASE_PATH}/employee/${employeeId}?${params}`
          : `${TRANSACTION_BASE_PATH}/employee/${employeeId}`,
      );
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  getSupplierTransactions: async (supplierId: Supplier['id'], params?: string) => {
    try {
      const response = await httpClient.get(
        params
          ? `${TRANSACTION_BASE_PATH}/supplier/${supplierId}?${params}`
          : `${TRANSACTION_BASE_PATH}/supplier/${supplierId}`,
      );
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  getSupplierTransactionsByState: async (supplierId: Supplier['id'], state: Expense['state']) => {
    try {
      const response = await httpClient.get(
        `${TRANSACTION_BASE_PATH}/supplier/${supplierId}/${state}`,
      );
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  payExpenses: async (data: PayExpensesPayload) => {
    try {
      const response = await httpClient.post(`${TRANSACTION_BASE_PATH}/paid`, {
        data,
      });
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
};

const useExpenses = ({
  page = DEFAULT_PAGINATION_PAGE_INDEX,
  size = DEFAULT_PAGINATION_PAGE_SIZE,
  sort = DEFAULT_PAGINATION_SORT,
  dir = DEFAULT_PAGINATION_DIR,
  search = DEFAULT_PAGINATION_PAGE_SEARCH,
}: QueryRequestParams<Expense> = {}) => {
  const queryParams = `page=${page}&size=${size}&sort=${sort}&dir=${dir}&search=${search}`;

  return useQuery<
    [
      ResponsePaginated<Expense>,
      {
        totalExpenses: number;
        dueExpenses: number;
      },
    ],
    Error,
    [
      ResponsePaginated<Expense>,
      {
        totalExpenses: number;
        dueExpenses: number;
      },
    ]
  >({
    queryKey: [TRANSACTION_KEY, { page, size }],
    queryFn: async () => {
      const response = (await TransactionApi.getExpenses(queryParams)) as {
        page: ResponsePaginated<Expense>;
        data: {
          totalExpenses: number;
          dueExpenses: number;
        };
      };
      return [response.page, response.data];
    },
    placeholderData: keepPreviousData,
    staleTime: TRANSACTION_STALE_TIME,
  });
};

const useEmployeeExpenses = ({
  page = DEFAULT_PAGINATION_PAGE_INDEX,
  size = DEFAULT_PAGINATION_PAGE_SIZE,
  sort = DEFAULT_PAGINATION_SORT,
  dir = DEFAULT_PAGINATION_DIR,
  employeeId,
}: QueryRequestParams<Expense> & { employeeId: ExpenseReportApi['id'] }) => {
  const queryParams = `page=${page}&size=${size}&sort=${sort}&dir=${dir}`;

  return useQuery<
    [ResponsePaginated<Expense>, { totalExpense: number }],
    Error,
    [ResponsePaginated<Expense>, { totalExpense: number }]
  >({
    queryKey: [`${TRANSACTION_KEY}-employee`, { employeeId, page, size }],
    queryFn: async () => {
      const response = (await TransactionApi.getEmployeeTransactions(employeeId, queryParams)) as {
        page: ResponsePaginated<Expense>;
        data: {
          totalExpense: number;
        };
      };
      return [response.page, response.data];
    },
    placeholderData: keepPreviousData,
    staleTime: TRANSACTION_STALE_TIME,
    enabled: !!employeeId,
  });
};

const deleteTransactionMutationFn = async (id: string) => {
  return await TransactionApi.deleteTransaction(id);
};

export { TRANSACTION_KEY, useExpenses, useEmployeeExpenses, deleteTransactionMutationFn };
