import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import DialogWrapper from '@/components/shared/DialogWrapper';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'sonner';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { SONNER_ERROR_OPTIONS, SONNER_SUCCESS_OPTIONS } from '@/common/constants';
import { LedgerAccount, LedgerAccountApi } from '@/api/ledgerAccount/ledgerAccount.model';
import {
  LedgerAccountFormType,
  LedgerAccountSchema,
  defaultLedgerAccountFormValues,
} from './utils/LedgerAccountSchema';
import {
  editLedgerAccountMutationFn,
  useLedgerAccount,
  useLedgerAccountByType,
} from '@/api/ledgerAccount/ledgerAccount.api';
import { LedgerAccountForm } from './components/LedgerAccountForm';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ErrorResponse } from '@/api/types';
import { paths } from '@/routes/paths';
import { EditFormSkeleton } from '@/components/shared/EditFormSkeleton';
import * as Sentry from '@sentry/react';
import { getLedgerAccountLabel, getLedgerAccountType } from './utils/LedgerAccountUtils';
import { getParamsWithAnimation } from '@/lib/utils';

type LedgerAccountEditParams = {
  id: LedgerAccountApi['id'];
};

const LedgerAccountEdit: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const type = getLedgerAccountType(pathname);
  const { id } = useParams() as LedgerAccountEditParams;

  const {
    data: ledgerAccount,
    isLoading: isLedgerAccountLoading,
    refetch: refetchledgerAccount,
    error,
  } = useLedgerAccount(id);

  const { refetch: refetchLedgerAccount } = useLedgerAccountByType({ type: type });

  const form = useForm<LedgerAccountFormType>({
    defaultValues: ledgerAccount
      ? {
          name: ledgerAccount.name,
          code: ledgerAccount.code,
          type: ledgerAccount.type,
          parent: { ...ledgerAccount.parent, children: [] },
        }
      : defaultLedgerAccountFormValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(LedgerAccountSchema),
  });

  const { mutate: editledgerAccount, isPending } = useMutation<
    LedgerAccountApi,
    AxiosError<ErrorResponse>,
    [LedgerAccount, LedgerAccountFormType]
  >({
    mutationFn: editLedgerAccountMutationFn,
    onSuccess: (data: LedgerAccountApi) => {
      refetchLedgerAccount();
      toast(
        <SuccessToastText>
          ¡Cuenta Contable <span className="text-blue-700">{data.name}</span> editada exitosamente!
        </SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismiss();
      refetchledgerAccount();
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      Sentry.captureException(error);

      if (error.response && error.response.data.type === 'DUPLICATED_ERROR') {
        form.setError('code', {
          type: 'validate',
          message:
            'El código ingresado ya fue utilizado previamente. Intentá nuevamente con un nuevo.',
        });
      } else {
        toast.error('Ha ocurrido un error durante la edición de la Cuenta Contable.', {
          description: 'Por favor, revisa los datos ingresados e intentá de nuevo.',
          ...SONNER_ERROR_OPTIONS,
        });
      }
    },
  });

  useEffect(() => {
    if (ledgerAccount) {
      form.reset({
        name: ledgerAccount.name,
        code: ledgerAccount.code,
        type: ledgerAccount.type,
        parent: { ...ledgerAccount.parent, children: [] },
      });
    }
  }, [ledgerAccount]);

  if (error) {
    return <Navigate to="*" replace={true} />;
  }

  const handleDismiss = () => {
    if (pathname.includes(paths.LEDGER_ACCOUNTS_SUBTYPE)) {
      navigate(`${paths.LEDGER_ACCOUNTS_SUBTYPE}${getParamsWithAnimation(location.search, true)}`);
    } else if (pathname.includes(paths.LEDGER_ACCOUNTS_ACCOUNT)) {
      navigate(`${paths.LEDGER_ACCOUNTS_ACCOUNT}${getParamsWithAnimation(location.search, true)}`);
    } else if (pathname.includes(paths.LEDGER_ACCOUNTS_SUBACCOUNT)) {
      navigate(
        `${paths.LEDGER_ACCOUNTS_SUBACCOUNT}${getParamsWithAnimation(location.search, true)}`,
      );
    } else {
      navigate(paths.LEDGER_ACCOUNTS_TYPE);
    }
  };

  const handleEditledgerAccount: SubmitHandler<LedgerAccountFormType> = (formData) => {
    if (ledgerAccount) {
      editledgerAccount([ledgerAccount, formData]);
    } else {
      toast.error('Lo sentimos, no tenés ningúna Cuenta Contable seleccionado.', {
        description: 'Por favor, seleccioná una Cuenta Contable e intentá de nuevo.',
        ...SONNER_ERROR_OPTIONS,
      });
      handleDismiss();
    }
  };

  return (
    <DialogWrapper
      title={`Editar ${getLedgerAccountLabel(type)}`}
      className="w-full max-w-xl"
      onDismiss={handleDismiss}
      onOverlayClick={handleDismiss}
    >
      {isLedgerAccountLoading || !ledgerAccount ? (
        <EditFormSkeleton inputRows={3} />
      ) : (
        <LedgerAccountForm
          form={form}
          type={ledgerAccount.type}
          submitLabel={`Editar ${getLedgerAccountLabel(type)}`}
          cancelLabel="Cancelar"
          isLoading={isPending}
          submitHandler={handleEditledgerAccount}
          dismissHandler={handleDismiss}
          editMode
        />
      )}
    </DialogWrapper>
  );
};

export default LedgerAccountEdit;
