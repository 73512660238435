import { FC } from 'react';
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { DonationFormType } from '../utils/DonationSchema';
import { CURRENCY_OPTIONS, DETAIL_DISABLED_BUTTON_CLASS, YEAR_OPTIONS } from '@/common/constants';
import { useFiscalSponsorList } from '@/api/fiscalSponsor/fiscalSponsor.api';
import { useDonorsList } from '@/api/donor/donor.api';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn, getConvertedAmount } from '@/lib/utils';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { CalendarIcon } from 'lucide-react';
import { Calendar } from '@/components/ui/calendar';
import { SelectFilter } from '@/components/shared/SelectFilter';
import { DonationFormSection } from './DonationFormSection';
import { Separator } from '@/components/ui/separator';
import { CurrencyInput } from '@/components/ui/currency-input';
import { CurrencyOption } from '@/common/types';
import { FileUploader } from '@/components/shared/FileUploader.tsx';
import { CostCenterForm } from './CostCentersForm';
import { Textarea } from '@/components/ui/textarea';

interface DonationFormProps {
  form: UseFormReturn<DonationFormType, unknown>;
  submitLabel: string;
  cancelLabel: string;
  isLoading?: boolean;
  submitHandler: SubmitHandler<DonationFormType>;
  dismissHandler: () => void;
  disabled?: boolean;
}

const DonationForm: FC<DonationFormProps> = ({
  form,
  submitLabel,
  cancelLabel,
  isLoading = false,
  submitHandler,
  dismissHandler,
  disabled,
}: DonationFormProps) => {
  const { data: fiscalSponsors, isLoading: isLoadingFiscalSponsors } = useFiscalSponsorList();
  const { data: donors, isLoading: isLoadingDonors } = useDonorsList();

  const handleChangeCurrency = (value: string) => {
    form.setValue('currency', value);
    if (value === 'USD') {
      form.setValue('exchangeRate', 1);
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(submitHandler)} className="flex flex-col">
        <DonationFormSection title="Datos Administrativos">
          <div className="flex flex-col gap-6">
            <div className="flex flex-row gap-4">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Nombre</FormLabel>
                    <FormControl>
                      <Input {...field} type="text" placeholder="Ingresar Nombre" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <SelectFilter
                form={form}
                isLoading={isLoadingDonors}
                formName="donor"
                formLabel="Donante"
                formPlaceholder="Donante"
                formFilterPlaceholder="Buscar Donante..."
                options={donors || []}
                classnames={{
                  button: disabled ? `${DETAIL_DISABLED_BUTTON_CLASS} w-full` : 'w-full',
                }}
                disabled={disabled}
              />
              <SelectFilter
                form={form}
                isLoading={isLoadingFiscalSponsors}
                formName="fiscalSponsor"
                formLabel="Patrocinador Fiscal"
                formPlaceholder="Patrocinador Fiscal"
                formFilterPlaceholder="Buscar Patrocinador Fiscal..."
                options={fiscalSponsors || []}
                classnames={{
                  button: disabled ? `${DETAIL_DISABLED_BUTTON_CLASS} w-full` : 'w-full',
                }}
                disabled={disabled}
              />
            </div>
            <div className="flex flex-row gap-4">
              <FormField
                control={form.control}
                name="entryDate"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Fecha de Ingreso</FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            disabled={disabled}
                            variant={'outline'}
                            className={cn(
                              disabled
                                ? `${DETAIL_DISABLED_BUTTON_CLASS} w-full pl-3`
                                : 'w-full pl-3 hover:bg-transparent',
                            )}
                            childrenClassName={cn(
                              'justify-between w-full text-left text-foreground font-normal',
                              !field.value
                                ? 'text-muted-foreground hover:text-muted-foreground'
                                : null,
                            )}
                          >
                            {field.value ? (
                              format(field.value, 'dd/MM/yyyy')
                            ) : (
                              <span className="font-light text-muted-foreground">
                                Selecciona una fecha
                              </span>
                            )}
                            <CalendarIcon className="w-4 h-4 ml-auto opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="center">
                        <Calendar
                          defaultMonth={form.getValues('entryDate')}
                          mode="single"
                          showOutsideDays
                          fixedWeeks
                          captionLayout="dropdown"
                          fromYear={2020}
                          toYear={new Date().getFullYear() + 10}
                          selected={field.value}
                          onSelect={field.onChange}
                          // disabled={(date) =>
                          //   date < new Date(new Date().setDate(new Date().getDate() - 1))
                          // }
                          locale={es}
                        />
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="reportDate"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Fecha de Reporte</FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            disabled={disabled}
                            variant={'outline'}
                            className={cn(
                              disabled
                                ? `${DETAIL_DISABLED_BUTTON_CLASS} w-full pl-3`
                                : 'w-full pl-3 hover:bg-transparent',
                            )}
                            childrenClassName={cn(
                              'justify-between w-full text-left text-foreground font-normal',
                              !field.value
                                ? 'text-muted-foreground hover:text-muted-foreground'
                                : null,
                            )}
                          >
                            {field.value ? (
                              format(field.value, 'dd/MM/yyyy')
                            ) : (
                              <span>Selecciona una fecha</span>
                            )}
                            <CalendarIcon className="w-4 h-4 ml-auto opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="center">
                        <Calendar
                          defaultMonth={form.getValues('reportDate')}
                          mode="single"
                          showOutsideDays
                          fixedWeeks
                          captionLayout="dropdown"
                          fromYear={new Date().getFullYear()}
                          toYear={new Date().getFullYear() + 10}
                          selected={field.value}
                          onSelect={field.onChange}
                          disabled={(date) =>
                            date < new Date(new Date().setDate(new Date().getDate() - 1))
                          }
                          locale={es}
                        />
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="year"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Año</FormLabel>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <FormControl>
                        <SelectTrigger
                          className={cn(disabled ? DETAIL_DISABLED_BUTTON_CLASS : null)}
                          disabled={disabled}
                        >
                          <SelectValue placeholder="Año" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {YEAR_OPTIONS.map((year) => (
                          <SelectItem key={year.value} value={year.value}>
                            {year.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex flex-row gap-4">
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Descripción</FormLabel>
                    <FormControl>
                      <Textarea
                        disabled={disabled}
                        placeholder="Escriba aquí la descripción de la donación..."
                        className={cn(
                          disabled
                            ? `${DETAIL_DISABLED_BUTTON_CLASS} w-full resize-none`
                            : 'resize-none',
                        )}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </DonationFormSection>
        <Separator className="my-10" />
        <DonationFormSection title="Donación">
          <div className="flex flex-row gap-4">
            <FormField
              control={form.control}
              name="currency"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Moneda</FormLabel>
                  <Select
                    onValueChange={(value) => handleChangeCurrency(value)}
                    value={field.value}
                  >
                    <FormControl>
                      <SelectTrigger
                        className={cn(disabled ? DETAIL_DISABLED_BUTTON_CLASS : null)}
                        disabled={disabled}
                      >
                        <SelectValue placeholder="Seleccione la Moneda" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {CURRENCY_OPTIONS.map((currency) => (
                        <SelectItem key={currency.value} value={currency.value}>
                          {currency.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="amount"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Monto en {form.watch('currency')}</FormLabel>
                  <FormControl>
                    <CurrencyInput
                      {...field}
                      type="number"
                      disabled={disabled}
                      currency={form.watch('currency') as CurrencyOption['value']}
                      placeholder="Ingresar monto"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="exchangeRate"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Tipo de Cambio {`(${form.watch('currency')}/USD)`}</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="number"
                      placeholder="Ingresar Conversión"
                      disabled={disabled || form.watch('currency') === 'USD'}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="amountConverted"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Total Donación (US$)</FormLabel>
                  <FormControl>
                    <CurrencyInput
                      {...field}
                      type="number"
                      disabled
                      value={getConvertedAmount(
                        form.watch('amount') || 0,
                        form.watch('exchangeRate'),
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </DonationFormSection>
        <Separator className="my-10" />
        <DonationFormSection title="Documentos">
          <div className="grid grid-cols-2">
            <FileUploader
              form={form}
              fieldName="files"
              initialFiles={form.watch('files')}
              buttonClassname={cn(disabled ? DETAIL_DISABLED_BUTTON_CLASS : null)}
              fileLimit={10}
            />
          </div>
        </DonationFormSection>
        <Separator className="my-10" />
        <DonationFormSection title="Gastos Administrativos">
          <div className="flex flex-col gap-3">
            <FormField
              control={form.control}
              name="globalCostCenter.amount"
              render={({ field }) => (
                <>
                  <FormItem className="w-[212px]">
                    <FormLabel>Monto en {form.watch('currency')}</FormLabel>
                    <FormControl>
                      <CurrencyInput
                        {...field}
                        type="number"
                        currency={form.watch('currency') as CurrencyOption['value']}
                        placeholder="Ingresar monto"
                        defaultValue={
                          disabled ? field.value : Number((form.watch('amount') * 0.1).toFixed(2))
                        }
                        disabled={disabled}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                  <p className="max-w-xl text-xs font-normal text-muted-foreground">
                    El monto ingresado sera transferido al Centro de Costos destinado a gastos
                    administrativos al momento de crear la donación.
                  </p>
                </>
              )}
            />
          </div>
        </DonationFormSection>
        <Separator className="my-10" />
        <DonationFormSection title="Centros de Costos Administrativos">
          <CostCenterForm form={form} disabled={disabled} />
        </DonationFormSection>
        {disabled ? (
          <div className="flex flex-row justify-end gap-2 mt-10">
            <Button type="button" variant="outline" onClick={dismissHandler}>
              {cancelLabel}
            </Button>
            <Button
              type="button"
              disabled={
                isLoading ||
                (!form.getFieldState('name').isDirty && !form.getFieldState('files').isDirty)
              }
              isLoading={isLoading}
              className="relative"
              onClick={() => submitHandler(form.getValues())}
            >
              {submitLabel}
            </Button>
          </div>
        ) : (
          <div className="flex flex-row justify-end gap-2 mt-10">
            <Button type="button" variant="outline" disabled={isLoading} onClick={dismissHandler}>
              {cancelLabel}
            </Button>
            <Button
              type="submit"
              disabled={isLoading || !form.formState.isValid}
              className="relative"
            >
              {submitLabel}
            </Button>
          </div>
        )}
      </form>
    </FormProvider>
  );
};

export { DonationForm };
