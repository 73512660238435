import { AxiosResponse } from 'axios';
import httpClient from '../httpClient';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { DashboardData } from './user.model';
import { getCurrentDate } from '@/lib/utils';

type UserRoles = 'ADMIN';

interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: UserRoles;
}

const UserService = () => {
  return {
    getUser: async () => {
      return httpClient
        .get('/user/me')
        .then((response: AxiosResponse) => {
          return response;
        })
        .catch((error) => {
          throw error;
        });
    },
    getDashboard: async () => {
      return httpClient
        .get<DashboardData>('/user/dashboard')
        .then((response: AxiosResponse<DashboardData>) => {
          return response;
        })
        .catch((error) => {
          throw error;
        });
    },
  };
};

const useGetDashboard = () => {
  return useQuery({
    queryKey: ['dashboard'],
    queryFn: async () => {
      const response = await UserService().getDashboard();
      return response.data;
    },
    placeholderData: keepPreviousData,
  });
};

const useGetReport = () => {
  return async (params?: { startDate?: Date; endDate?: Date; dateType: string }) => {
    const queryParams: any = {};
    if (params?.startDate) {
      queryParams.startDate = params.startDate.toISOString().split('T')[0];
    }
    if (params?.endDate) {
      queryParams.endDate = params.endDate.toISOString().split('T')[0];
    }
    queryParams.dateType = params?.dateType;

    const response = await httpClient.get('invoice/all/xls', {
      params: queryParams,
      responseType: 'blob',
      timeout: 600000,
    });

    const blob = new Blob([response.data as BlobPart], {
      type: response.headers['content-type'],
    });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', `Reporte-PEM_${getCurrentDate()}.xlsx`);
    link.click();
  };
};

export type { User };
export { useGetDashboard, useGetReport };
export default UserService();
