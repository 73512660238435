import { useState } from 'react';
import { DEFAULT_DENY_ALERT_DIALOG } from '@/common/constants';
import { AlertDialogWrapperProps } from '@/components/shared/AlertDialog';

const useAlertDialog = () => {
  const [alertDialog, setAlertDialog] =
    useState<Omit<AlertDialogWrapperProps, 'onDismiss'>>(DEFAULT_DENY_ALERT_DIALOG);

  const handleDismissAlertDialog = () => setAlertDialog({ ...alertDialog, open: false });

  return { alertDialog, setAlertDialog, handleDismissAlertDialog };
};

export { useAlertDialog };
