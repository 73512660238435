const pathsBase = {
  HOME: '/',
  LOGIN: 'login',
  DASHBOARD: 'dashboard',
  EXPENSES: 'gastos',
  SUPPLIERS: 'proveedores',
  EXPENSE_REPORTS: 'expense-reports',
  EXPENSE_REPORT_DETAIL_PAYMENTS: 'rendicion',
  EXPENSE_REPORT_DETAIL_FUNDING: 'funding',
  PROJECTS: 'proyectos',
  COST_CENTERS: 'centros-de-costos',
  DONATIONS: 'donaciones',
  DONORS: 'donantes',
  FISCAL_SPONSORS: 'patrocinadores-fiscales',
  CATEGORIES: 'categorias',
  TERRITORIES: 'territorios',
  LEDGER_ACCOUNTS: 'plan-de-cuentas',
  LEDGER_ACCOUNTS_TYPE: 'tipos',
  LEDGER_ACCOUNTS_SUBTYPE: 'subtipos',
  LEDGER_ACCOUNTS_ACCOUNT: 'cuentas',
  LEDGER_ACCOUNTS_SUBACCOUNT: 'subcuentas',
  BANK_ACCOUNTS: 'cuentas-bancarias',
  ACCOUNTS_MAYOR: 'mayor-de-cuentas',
  CREATE: 'crear',
  EDIT: 'editar',
  PAY: 'pagar',
  DETAIL: 'detalle',
  INVOICES: 'facturas',
  CREDIT_NOTE: 'nota-de-credito',
  REPORT: 'reporte',
};

type PathKeys = keyof typeof pathsBase;

type Paths = {
  [K in PathKeys]: string;
};

const paths: Paths = {
  HOME: '/',
  LOGIN: `/${pathsBase.LOGIN}`,
  DASHBOARD: `/${pathsBase.DASHBOARD}`,
  EXPENSES: `/${pathsBase.EXPENSES}`,
  SUPPLIERS: `/${pathsBase.EXPENSES}/${pathsBase.SUPPLIERS}`,
  EXPENSE_REPORTS: `/${pathsBase.EXPENSES}/${pathsBase.EXPENSE_REPORTS}`,
  EXPENSE_REPORT_DETAIL_PAYMENTS: `/${pathsBase.EXPENSES}/${pathsBase.EXPENSE_REPORTS}/:id`,
  EXPENSE_REPORT_DETAIL_FUNDING: `/${pathsBase.EXPENSES}/${pathsBase.EXPENSE_REPORTS}/:id/${pathsBase.EXPENSE_REPORT_DETAIL_FUNDING}`,
  PROJECTS: `/${pathsBase.PROJECTS}`,
  COST_CENTERS: `/${pathsBase.COST_CENTERS}`,
  DONATIONS: `/${pathsBase.DONATIONS}`,
  DONORS: `/${pathsBase.DONATIONS}/${pathsBase.DONORS}`,
  FISCAL_SPONSORS: `/${pathsBase.FISCAL_SPONSORS}`,
  CATEGORIES: `/${pathsBase.CATEGORIES}`,
  TERRITORIES: `/${pathsBase.TERRITORIES}`,
  LEDGER_ACCOUNTS: `/${pathsBase.LEDGER_ACCOUNTS}`,
  LEDGER_ACCOUNTS_TYPE: `/${pathsBase.LEDGER_ACCOUNTS}/${pathsBase.LEDGER_ACCOUNTS_TYPE}`,
  LEDGER_ACCOUNTS_SUBTYPE: `/${pathsBase.LEDGER_ACCOUNTS}/${pathsBase.LEDGER_ACCOUNTS_SUBTYPE}`,
  LEDGER_ACCOUNTS_ACCOUNT: `/${pathsBase.LEDGER_ACCOUNTS}/${pathsBase.LEDGER_ACCOUNTS_ACCOUNT}`,
  LEDGER_ACCOUNTS_SUBACCOUNT: `/${pathsBase.LEDGER_ACCOUNTS}/${pathsBase.LEDGER_ACCOUNTS_SUBACCOUNT}`,
  ACCOUNTS_MAYOR: `/${pathsBase.ACCOUNTS_MAYOR}`,
  BANK_ACCOUNTS: `/${pathsBase.BANK_ACCOUNTS}`,
  CREATE: pathsBase.CREATE,
  EDIT: pathsBase.EDIT,
  PAY: pathsBase.PAY,
  DETAIL: pathsBase.DETAIL,
  INVOICES: `/${pathsBase.EXPENSES}/${pathsBase.INVOICES}`,
  CREDIT_NOTE: `/${pathsBase.EXPENSES}/${pathsBase.INVOICES}/${pathsBase.CREDIT_NOTE}`,
  REPORT: `/${pathsBase.EXPENSES}/${pathsBase.INVOICES}/${pathsBase.REPORT}`,
};

export { pathsBase, paths };
