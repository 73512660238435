import { MutationFunction, keepPreviousData, useQuery } from '@tanstack/react-query';
import { FiscalSponsorQueryParams, ResponsePaginated } from '../types';
import {
  DEFAULT_PAGINATION_DIR,
  DEFAULT_PAGINATION_PAGE_INDEX,
  DEFAULT_PAGINATION_PAGE_SIZE,
  DEFAULT_PAGINATION_SORT,
  DEFAULT_PROJECT_OPTION,
} from '@/common/constants';
import { CRUDService } from '../CRUDService.api';
import {
  FiscalSponsorApi,
  FiscalSponsorList,
  FiscalSponsorsTransformResponse,
} from './fiscalSponsor.model';
import { FiscalSponsorFormType } from '@/pages/FiscalSponsor/utils/FiscalSponsorSchema';
import httpClient from '../httpClient';
import * as Sentry from '@sentry/react';
import { UUID } from 'crypto';

const FISCAL_SPONSOR_BASE_PATH = '/fiscal-sponsor';
const FISCAL_SPONSOR_KEY = 'fiscalSponsors';
const FISCAL_SPONSOR_STALE_TIME = 5000;

const transformFiscalSponsorList = (response: FiscalSponsorApi[]): FiscalSponsorList[] => {
  return response.map((fiscalSponsor) => {
    return {
      id: fiscalSponsor.id,
      name: fiscalSponsor.name,
      value: fiscalSponsor.name,
    };
  });
};

class FiscalSponsorService extends CRUDService<FiscalSponsorApi, FiscalSponsorFormType> {
  constructor() {
    super(FISCAL_SPONSOR_BASE_PATH);
  }

  // Add here the methods that are specific to this service
  async getList() {
    try {
      const response = await httpClient.get(`${FISCAL_SPONSOR_BASE_PATH}/list`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }
}

const fiscalSponsorService = new FiscalSponsorService();

const useFiscalSponsors = ({
  page = DEFAULT_PAGINATION_PAGE_INDEX,
  size = DEFAULT_PAGINATION_PAGE_SIZE,
  sort = DEFAULT_PAGINATION_SORT,
  dir = DEFAULT_PAGINATION_DIR,
  projects = DEFAULT_PROJECT_OPTION,
}: FiscalSponsorQueryParams<FiscalSponsorApi> = {}) => {
  const queryParams =
    `page=${page}&size=${size}&sort=${sort}&dir=${dir}` + (projects ? `&projects=${projects}` : '');

  return useQuery<ResponsePaginated<FiscalSponsorApi>, Error, FiscalSponsorsTransformResponse>({
    queryKey: [FISCAL_SPONSOR_KEY, { page, size, dir, sort, projects }],
    queryFn: async () => {
      const response = await fiscalSponsorService.getAll(queryParams);
      return response as ResponsePaginated<FiscalSponsorApi>;
    },
    placeholderData: keepPreviousData,
    staleTime: FISCAL_SPONSOR_STALE_TIME,
  });
};

const useFiscalSponsor = (id: UUID) => {
  return useQuery<FiscalSponsorApi, Error, FiscalSponsorApi>({
    queryKey: [`${FISCAL_SPONSOR_KEY}-edit`, { id }],
    queryFn: async () => {
      const response = await fiscalSponsorService.get(id);
      return response as FiscalSponsorApi;
    },
    placeholderData: keepPreviousData,
    staleTime: FISCAL_SPONSOR_STALE_TIME,
    retry: 1,
  });
};

const useFiscalSponsorList = () => {
  return useQuery<FiscalSponsorApi[], Error, FiscalSponsorList[]>({
    queryKey: [FISCAL_SPONSOR_KEY],
    queryFn: async () => {
      const response = await fiscalSponsorService.getList();
      return response as FiscalSponsorApi[];
    },
    select: transformFiscalSponsorList,
    placeholderData: keepPreviousData,
    staleTime: FISCAL_SPONSOR_STALE_TIME,
  });
};

const createFiscalSponsorMutationFn = async (data: FiscalSponsorFormType) => {
  return await fiscalSponsorService.create(data);
};

const editFiscalSponsorMutationFn: MutationFunction<
  FiscalSponsorApi,
  [FiscalSponsorApi['id'], FiscalSponsorFormType]
> = async (params) => {
  const [id, data] = params;
  return (await fiscalSponsorService.edit(id, data)) as FiscalSponsorApi;
};

const deleteFiscalSponsorMutationFn = async (id: FiscalSponsorApi['id']) => {
  return await fiscalSponsorService.delete(id);
};

export {
  FISCAL_SPONSOR_KEY,
  useFiscalSponsors,
  useFiscalSponsor,
  useFiscalSponsorList,
  createFiscalSponsorMutationFn,
  editFiscalSponsorMutationFn,
  deleteFiscalSponsorMutationFn,
};
