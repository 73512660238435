import {
  DEFAULT_PAGINATION_DIR,
  DEFAULT_PAGINATION_PAGE_INDEX,
  DEFAULT_PAGINATION_PAGE_SIZE,
  DEFAULT_PAGINATION_SORT,
} from '@/common/constants';
import { CRUDService } from '../CRUDService.api';
import httpClient from '../httpClient';
import { QueryRequestParams, ResponsePaginated } from '../types';
import { EmployeeFund, EmployeeFundPayload } from './employeeFund.model';
import * as Sentry from '@sentry/react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ExpenseReportFundFormType } from '@/pages/ExpenseReportDetail/utils/ExpenseReportFundSchema';
import { getConvertedAmount } from '@/lib/utils';
import { ExpenseReportApi } from '../expenseReport/expenseReport.model';

const EMPLOYEE_FUND_BASE_PATH = '/employeeFund';
const EMPLOYEE_FUND_KEY = 'employeeFund';
const EMPLOYEE_FUND_STALE_TIME = 5000;

const transformEmployeeFundPayload = (data: ExpenseReportFundFormType): EmployeeFundPayload => {
  return {
    amount: getConvertedAmount(data.amount, data.exchangeRate),
    description: data.description,
    employee: { id: data.employee.id },
    donation: { id: data.donation.id },
    costCenter: { id: data.costCenter.id },
    category: { id: data.category.id },
    money: {
      currency: data.currency,
      amount: data.amount,
      exchangeRate: data.exchangeRate,
    },
    ledgerAccount: { id: data.ledgerAccount.id },
    file: data.files && data.files[0],
  };
};

class EmployeeFundService extends CRUDService<EmployeeFund, EmployeeFundPayload> {
  constructor() {
    super(EMPLOYEE_FUND_BASE_PATH);
  }
  async getEmployeeFunds(employeeId: ExpenseReportApi['id'], params?: string) {
    try {
      const response = await httpClient.get(
        params
          ? `${EMPLOYEE_FUND_BASE_PATH}/employee/${employeeId}?${params}`
          : `${EMPLOYEE_FUND_BASE_PATH}/employee/${employeeId}`,
      );
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }
}

const employeeFundService = new EmployeeFundService();

type EmployeeFundParams = QueryRequestParams<EmployeeFund> & { employeeId: ExpenseReportApi['id'] };

const useEmployeeFunds = ({
  employeeId,
  page = DEFAULT_PAGINATION_PAGE_INDEX,
  size = DEFAULT_PAGINATION_PAGE_SIZE,
  sort = DEFAULT_PAGINATION_SORT,
  dir = DEFAULT_PAGINATION_DIR,
}: EmployeeFundParams) => {
  const queryParams = `page=${page}&size=${size}&sort=${sort}&dir=${dir}`;

  return useQuery<
    [ResponsePaginated<EmployeeFund>, { totalFund: number }],
    Error,
    [ResponsePaginated<EmployeeFund>, { totalFund: number }]
  >({
    queryKey: [`${EMPLOYEE_FUND_KEY}-employee`, { employeeId, page, size }],
    queryFn: async () => {
      const response = (await employeeFundService.getEmployeeFunds(employeeId, queryParams)) as {
        page: ResponsePaginated<EmployeeFund>;
        data: {
          totalFund: number;
        };
      };
      return [response.page, response.data];
    },
    placeholderData: keepPreviousData,
    staleTime: EMPLOYEE_FUND_STALE_TIME,
    enabled: !!employeeId,
  });
};

const deleteEmployeeFundMutationFn = async (id: string) => {
  return await employeeFundService.delete(id);
};

const createEmployeeFundMutationFn = async (data: ExpenseReportFundFormType) => {
  const payload = transformEmployeeFundPayload(data);
  return await employeeFundService.create(payload);
};

export { useEmployeeFunds, deleteEmployeeFundMutationFn, createEmployeeFundMutationFn };
