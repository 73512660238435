import { AccountingEntryDetail } from '@/api/accountingEntry/accountingEntry.model';
import { Money } from '@/api/transaction/transaction.model';
import { cn, formatAmount, getParamsWithAnimation } from '@/lib/utils';
import { ColumnDef } from '@tanstack/table-core';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx';
import { Button } from '@/components/ui/button.tsx';
import { MoreHorizontal } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { pathsBase } from '@/routes/paths.ts';
import { SortColumnWithBe } from '@/components/shared/SortColumnWithBe';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

const AccountMayorDetailTableColumns = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onDetailClick = (id: string) => {
    navigate(
      `/${pathsBase.ACCOUNTS_MAYOR}/group/${id}${getParamsWithAnimation(location.search, true).replace('pageIndex', 'amdp')}`,
    );
  };

  const accountMayorDetailTableColumns: ColumnDef<AccountingEntryDetail>[] = [
    {
      accessorKey: 'code',
      header: ({ column }) => <SortColumnWithBe title="Código" column={column} />,
    },
    {
      accessorKey: 'paymentDate',
      header: ({ column }) => <SortColumnWithBe title="Fecha" column={column} />,
      cell: ({ row }) => {
        return row.getValue('paymentDate');
      },
    },
    {
      accessorKey: 'moneyOfficial.amount',
      header: () => <p className="text-xs">Monto Original</p>,
      cell: ({ row }) => {
        const money = row.original['moneyOfficial'] as Money;
        const amount = row.original.amount;
        const originalAmount = money ? money.amount : amount;
        const originalAmountCreditOrDebit = row.original.credit ? -originalAmount : originalAmount;
        return formatAmount(originalAmountCreditOrDebit, money?.currency || 'ARS');
      },
    },
    {
      accessorKey: 'amount',
      header: () => <p className="text-xs">Debe</p>,
      cell: ({ row }) => {
        const credit = row.original.credit;
        const amount = row.getValue('amount') as number;
        return credit ? formatAmount(0, 'ARS') : formatAmount(amount, 'ARS');
      },
    },
    {
      accessorKey: 'credit',
      header: () => <p className="text-xs">Haber</p>,
      cell: ({ row }) => {
        const credit = row.getValue('credit') as boolean;
        const amount = row.original.amount;
        return credit ? formatAmount(amount, 'ARS') : formatAmount(0, 'ARS');
      },
    },
    {
      accessorKey: 'balance',
      header: () => <p className="text-xs">Balance ARS</p>,
      cell: ({ row }) => {
        const balance = row.getValue('balance') as number;
        const currencyCell = formatAmount(balance, 'ARS');
        return (
          <p
            className={
              balance === 0 ? 'text-foreground' : balance < 0 ? 'text-rose-700' : 'text-green-700'
            }
          >
            {balance === 0 ? 0 : balance ? currencyCell : '-'}
          </p>
        );
      },
    },
    {
      accessorKey: 'balanceAlt',
      header: () => <p className="text-xs">Balance USD</p>,
      cell: ({ row }) => {
        const balance = row.getValue('balanceAlt') as number;
        const currencyCell = formatAmount(balance, 'USD');
        return (
          <p
            className={
              balance === 0 ? 'text-foreground' : balance < 0 ? 'text-rose-700' : 'text-green-700'
            }
          >
            {balance === 0 ? 0 : balance ? currencyCell : '-'}
          </p>
        );
      },
    },
    {
      accessorKey: 'details',
      header: () => <p className="text-xs">Detalles</p>,
      cell: ({ row }) => {
        const details = row.getValue('details') as string;
        return (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="mt-[2px]" formNoValidate disabled>
                <p className="py-2 text-sm truncate max-w-40 text-foreground">{details || '-'}</p>
              </TooltipTrigger>
              <TooltipContent side="top" className={cn('w-[200px]', details ? '' : 'hidden')}>
                <p className="text-xs">{details || '-'}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        );
      },
    },
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }) => {
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={() => onDetailClick(row.original.group)}>
                Abrir
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];

  return accountMayorDetailTableColumns;
};

export { AccountMayorDetailTableColumns };
