import { useAccountingEntriesByGroup } from '@/api/accountingEntry/accountingEntry.api';
import { DEFAULT_PAGINATION_PAGE_COUNT } from '@/common/constants';
import { Heading } from '@/components/shared/Heading';
import { PageContent } from '@/components/shared/PageContent';
import { TableWrapper } from '@/components/shared/TableWrapper';
import { useTablePagination } from '@/hooks/useTablePagination';
import { Helmet } from 'react-helmet';
import { Outlet, useParams } from 'react-router-dom';
import { AccountingEntry } from '@/api/accountingEntry/accountingEntry.model';
import { AccountingEntryDetailTableColumns } from '@/pages/AccountsMayor/utils/AccountingEntryDetailTableColumns.tsx';
import AccountingEntryDetailBreadcrumb from '../../components/AccountingEntryDetailBreadcrumb';
import useFilterAndSortByBe from '@/hooks/useFilterAndSortByBe';

const PAGE_TITLE = 'Detalle de Asiento';

type AccountMayorDetailParams = {
  id: AccountingEntry['id'];
};

const AccountMayorDetail = () => {
  const { id } = useParams() as AccountMayorDetailParams;
  const columns = AccountingEntryDetailTableColumns();

  const { pageIndex, pageSize, paginationState, setPagination, sort, dir, setDir, setSort } =
    useTablePagination();

  const { data, isLoading, refetch } = useAccountingEntriesByGroup({
    page: pageIndex,
    size: pageSize,
    groupId: id,
    sort: sort,
    dir: dir,
  });

  useFilterAndSortByBe(refetch, setDir, setSort);

  return (
    <>
      <Helmet>
        <title>PEM - {PAGE_TITLE}</title>
      </Helmet>
      <Heading
        title={data ? `Asiento: #${data.content[data.content.length - 1].code}` : ''}
        subtitle={PAGE_TITLE}
        isLoading={isLoading}
        classNames={{
          title: 'text-4xl font-normal',
          section: 'py-4 min-h-28',
          subtitle: 'font-extralight tracking-normal',
        }}
        useBreadcrumb
        breadcrumbComponent={
          <AccountingEntryDetailBreadcrumb
            group
            entry={data && data.content[data.content.length - 1].code}
          />
        }
      />
      <PageContent>
        <TableWrapper
          columns={columns}
          data={data}
          isLoading={isLoading}
          rowsLabel={PAGE_TITLE}
          pageCount={data ? data.totalPages : DEFAULT_PAGINATION_PAGE_COUNT}
          pagination={paginationState}
          onPaginationChange={setPagination}
        />
      </PageContent>
      <Outlet />
    </>
  );
};

export default AccountMayorDetail;
