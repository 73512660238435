import { FC } from 'react';
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { BankAccountFormType, BankAccountSchema } from '../utils/BankAccountSchema';
import { getRequiredLabel } from '@/lib/utils';
import { useLedgerAccountChildrens } from '@/api/ledgerAccount/ledgerAccount.api';
import { SelectFilter } from '@/components/shared/SelectFilter';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { CURRENCY_OPTIONS } from '@/common/constants';

interface BankAccountsFormProps {
  form: UseFormReturn<BankAccountFormType, any>;
  submitLabel: string;
  cancelLabel: string;
  isLoading?: boolean;
  submitHandler: SubmitHandler<BankAccountFormType>;
  dismissHandler: () => void;
}

const BankAccountsForm: FC<BankAccountsFormProps> = ({
  form,
  submitLabel,
  cancelLabel,
  isLoading = false,
  submitHandler,
  dismissHandler,
}: BankAccountsFormProps) => {
  const { data: ledgerAccountsChildren, isLoading: isLoadingLedgerAccounts } =
    useLedgerAccountChildrens();
  const handleChangeCurrency = (value: string) => {
    form.setValue('currency', value);
  };
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(submitHandler)} className="flex flex-col gap-8">
        <div className="flex flex-col gap-4">
          <SelectFilter
            form={form}
            isLoading={isLoadingLedgerAccounts}
            formName="ledgerAccount"
            formLabel="Cuenta Contable"
            formPlaceholder="Cuenta Contable"
            formFilterPlaceholder="Buscar Cuenta Contable..."
            options={ledgerAccountsChildren || []}
            classnames={{ button: 'w-full', item: 'w-96' }}
          />
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>{getRequiredLabel(BankAccountSchema, 'name', 'Nombre')}</FormLabel>
                <FormControl>
                  <Input {...field} type="name" placeholder="Ingresar nombre" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="accountNumber"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>
                  {getRequiredLabel(BankAccountSchema, 'accountNumber', 'Número de Cuenta')}
                </FormLabel>
                <FormControl>
                  <Input {...field} type="text" placeholder="Ingresar Número de Cuenta" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="cbu"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>{getRequiredLabel(BankAccountSchema, 'cbu', 'CBU')}</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="cbu"
                    placeholder="Ingresar cbu"
                    value={field.value ?? ''}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="currency"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Moneda</FormLabel>
                <Select onValueChange={(value) => handleChangeCurrency(value)} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Seleccione la Moneda" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {CURRENCY_OPTIONS.map((currency) => (
                      <SelectItem key={currency.value} value={currency.value}>
                        {currency.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex flex-row justify-end gap-2">
          <Button type="button" variant="outline" disabled={isLoading} onClick={dismissHandler}>
            {cancelLabel}
          </Button>
          <Button
            type="submit"
            disabled={isLoading || !form.formState.isValid}
            className="relative"
            isLoading={isLoading}
          >
            {submitLabel}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export { BankAccountsForm };
