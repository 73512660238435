import { FC, ReactElement, useEffect, useState } from 'react';
import { Sheet, SheetClose, SheetContent } from '@/components/ui/sheet';
import { X } from 'lucide-react';
import { ANIMATION_QUERY_PARAM, DEFAULT_ENTRANCE_ANIMATION_DELAY } from '@/common/constants';
import { cn } from '@/lib/utils';

interface SheetLayoutProps {
  children: ReactElement;
  defaultOpen?: boolean;
  contentClassname?: string;
  childrenClassname?: string;
  closeContentClassname?: string;
  onClose?: () => void;
}

const SheetLayout: FC<SheetLayoutProps> = ({
  children,
  defaultOpen = true,
  contentClassname,
  childrenClassname,
  closeContentClassname,
  onClose,
}: SheetLayoutProps) => {
  const [open, setOpen] = useState(defaultOpen);
  const [ready, setReady] = useState(false);
  const [useAnimationQueryParam, setUseAnimationQueryParam] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const result = query.get(ANIMATION_QUERY_PARAM) === 'true';
    setUseAnimationQueryParam(result);
    setReady(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      if (onClose) onClose();
    }, DEFAULT_ENTRANCE_ANIMATION_DELAY);
  };

  if (!ready) {
    return null;
  }

  return (
    <Sheet open={open}>
      <SheetContent
        side="bottom"
        className={cn(
          'w-screen h-screen overflow-y-auto',
          !useAnimationQueryParam ? '!animate-none' : null,
          contentClassname,
        )}
      >
        <div className="flex flex-col min-h-full">
          <div className={cn('flex justify-end', closeContentClassname)}>
            <SheetClose
              className="flex items-center justify-center w-8 h-8 p-0 bg-[#EEEEEE] rounded-full transition-all hover:scale-90"
              onClick={handleClose}
            >
              <X className="h-4 w-4 color-foreground" />
              <span className="sr-only">Close</span>
            </SheetClose>
          </div>
          <div className={cn('h-full p-8', childrenClassname)}>{children}</div>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export { SheetLayout };
export { ANIMATION_QUERY_PARAM };
