import {
  deleteLedgerAccountMutationFn,
  useLedgerAccountByType,
} from '@/api/ledgerAccount/ledgerAccount.api';
import { useTablePagination } from '@/hooks/useTablePagination';
import { getLedgerAccountTableColumnsByType } from '../utils/LedgerAccountColumnsUtils';
import { Outlet, useNavigate } from 'react-router-dom';
import { TableWrapper } from '@/components/shared/TableWrapper';
import {
  DEFAULT_PAGINATION_PAGE_COUNT,
  SONNER_ERROR_OPTIONS,
  SONNER_SUCCESS_OPTIONS,
} from '@/common/constants';
import { LedgerAccountApi, LedgerAccountTypeEnum } from '@/api/ledgerAccount/ledgerAccount.model';
import { AlertDialogWrapper } from '@/components/shared/AlertDialog';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { toast } from 'sonner';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import useTableColumnsWithActions from '@/hooks/useTableColumnsWithActions';
import { useMutation } from '@tanstack/react-query';
import { paths, pathsBase } from '@/routes/paths';
import { SearchBar } from '@/components/shared/SearchBar';
import { useSearchBar } from '@/hooks/useSearchBar';
import { useAlertDialog } from '@/hooks/useAlertDialog';
import { ErrorResponse } from '@/api/types';
import useFilterAndSortByBe from '@/hooks/useFilterAndSortByBe';
import { getParamsWithAnimation } from '@/lib/utils';

const PAGE_TITLE = 'Subtipos';

const LedgerAccountSubType = () => {
  const navigate = useNavigate();

  const { pageIndex, pageSize, paginationState, dir, sort, setPagination, setDir, setSort } =
    useTablePagination<LedgerAccountApi>();

  const { searchValue, form: searchBarForm, handleSubmit: handleSearchSubmit } = useSearchBar();

  const { data, isLoading, isFetching, refetch } = useLedgerAccountByType({
    page: pageIndex,
    size: pageSize,
    dir: dir,
    sort: sort,
    type: LedgerAccountTypeEnum.SUB_TYPE,
    search: searchValue,
  });

  const { alertDialog, setAlertDialog, handleDismissAlertDialog } = useAlertDialog();

  const { mutate: deleteSubtype, isPending: isDeleteSubtypePending } = useMutation({
    mutationFn: deleteLedgerAccountMutationFn,
    onSuccess: () => {
      refetch();
      toast(
        <SuccessToastText>Subtipo eliminado exitosamente</SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismissAlertDialog();
    },
    onError: (error: AxiosError) => {
      const errorResponse: ErrorResponse = error.response?.data as ErrorResponse;
      Sentry.captureException(error);
      if (errorResponse && errorResponse.type === 'ENTITY_RELATION') {
        toast.error('No es posible eliminar el Subtipo debido a que tiene cuentas asociadas.', {
          description: 'Por favor, eliminá las cuentas asociadas e intentalo nuevamente.',
          ...SONNER_ERROR_OPTIONS,
        });
      } else {
        toast.error('Ha ocurrido un error durante la eliminación del Subtipo.', {
          description: 'Por favor, intentalo nuevamente.',
          ...SONNER_ERROR_OPTIONS,
        });
      }
    },
  });

  useFilterAndSortByBe<LedgerAccountApi>(
    refetch,
    setDir,
    setSort,
    undefined,
    undefined,
    'code',
    'asc',
  );

  const handleDeleteSubtype = (ledgerAccount: LedgerAccountApi) => {
    setAlertDialog({
      title: `¿Estás seguro que queres eliminar "${ledgerAccount.code}. ${ledgerAccount.name}"? `,
      description: `Esta acción no puede ser deshecha y se perderá toda la información asociada a este Subtipo: "${ledgerAccount.code}. ${ledgerAccount.name}". `,
      open: true,
      onCancel: handleDismissAlertDialog,
      onConfirm: () => deleteSubtype(ledgerAccount.id),
    });
  };

  const handleEditSubtype = (ledgerAccount: LedgerAccountApi) =>
    navigate(
      `${paths.LEDGER_ACCOUNTS_SUBTYPE}/${pathsBase.EDIT}/${ledgerAccount.id}${getParamsWithAnimation(location.search)}`,
    );

  const LedgerAccountTypeColumns = getLedgerAccountTableColumnsByType('Cuentas Asociadas');

  const LedgerAccountColumnsWithActions = useTableColumnsWithActions<LedgerAccountApi>(
    LedgerAccountTypeColumns,
    handleEditSubtype,
    handleDeleteSubtype,
  );

  return (
    <>
      <SearchBar
        form={searchBarForm}
        searchTerm="subtipo"
        isLoading={isLoading || isFetching}
        useAbsolutePosition={false}
        submitHandler={handleSearchSubmit}
      />
      <TableWrapper
        columns={LedgerAccountColumnsWithActions}
        data={data}
        isLoading={isLoading}
        isFetching={isFetching}
        rowsLabel={PAGE_TITLE}
        pageCount={data ? data.totalPages : DEFAULT_PAGINATION_PAGE_COUNT}
        pagination={paginationState}
        onPaginationChange={setPagination}
      />
      <AlertDialogWrapper
        {...alertDialog}
        isLoading={isDeleteSubtypePending}
        onDismiss={handleDismissAlertDialog}
      />
      <Outlet />
    </>
  );
};

export default LedgerAccountSubType;
