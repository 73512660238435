import { paths } from '@/routes/paths';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { SONNER_SUCCESS_OPTIONS, SONNER_ERROR_OPTIONS } from '@/common/constants';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { AxiosError } from 'axios';
import { toast } from 'sonner';
import * as Sentry from '@sentry/react';
import { DonationForm } from './components/DonationForm';
import {
  DonationFormType,
  DonationSchema,
  defaultDonationFormValues,
} from './utils/DonationSchema';
import { createDonationMutationFn, useDonations } from '@/api/donation/donation.api';
import { Helmet } from 'react-helmet';
import { SheetLayout } from '@/components/layout/SheetLayout';
import DonationRemainAmount from './components/DonationRemainAmount';
import DonationSummary from './components/DonationSummary';
import DialogWrapper from '@/components/shared/DialogWrapper';
import { ErrorResponse } from '@/api/types';

const PAGE_TITLE = 'Registrar Donación';

const DonationsCreate: FC = () => {
  const navigate = useNavigate();

  const { refetch } = useDonations();

  const [showSummaryDialog, setShowSummaryDialog] = useState(false);

  const form = useForm<DonationFormType>({
    defaultValues: defaultDonationFormValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: zodResolver(DonationSchema),
  });

  const { mutate: createDonation, isPending: isCreateDonationPending } = useMutation({
    mutationFn: createDonationMutationFn,
    onSuccess: () => {
      refetch();
      toast(
        <SuccessToastText>
          ¡Donación <span className="text-blue-700">{form.watch('name')}</span> creada exitosamente!
        </SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleGoBack();
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      Sentry.captureException(error);
      if (error.response && error.response.data.type === 'DUPLICATED_ERROR') {
        form.setError('name', {
          type: 'validate',
          message:
            'El nombre ingresado ya fue utilizado previamente. Intentá nuevamente con un nombre nuevo.',
        });
      }
      toast.error('Ha ocurrido un error durante la creación de la Donación.', {
        description: 'Por favor, revisa los datos ingresados e intenta de nuevo.',
        ...SONNER_ERROR_OPTIONS,
      });
      handleDismissSummaryDialog();
    },
  });

  const handleGoBack = () => navigate(paths.DONATIONS);

  const handleOpenSummaryDialog = () => setShowSummaryDialog(true);

  const handleDismissSummaryDialog = () => setShowSummaryDialog(false);

  const handleCreateDonation = () => createDonation(form.getValues());

  const assignedAmount =
    form.watch('costCenters').reduce((acc, costCenter) => {
      return (
        Number(acc) +
        costCenter.categories.reduce((acc, category) => Number(acc) + Number(category.amount), 0)
      );
    }, 0) + Number(form.watch('globalCostCenter.amount'));

  return (
    <>
      <Helmet>
        <title>PEM - {PAGE_TITLE}</title>
      </Helmet>
      <SheetLayout onClose={handleGoBack}>
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-light tracking-tighter color-foreground">{PAGE_TITLE}</h1>
          <div className="fixed top-16 right-40 max-h-48 min-w-40 max-w-56 z-50">
            <DonationRemainAmount
              totalAmount={form.watch('amount')}
              assignedAmount={assignedAmount}
              currency={form.watch('currency')}
            />
          </div>
          <div className="mt-12">
            <DonationForm
              form={form}
              cancelLabel="Cancelar"
              submitLabel={PAGE_TITLE}
              isLoading={isCreateDonationPending}
              dismissHandler={handleGoBack}
              submitHandler={handleOpenSummaryDialog}
            />
          </div>
        </div>
      </SheetLayout>
      <DialogWrapper
        open={showSummaryDialog}
        title={'Resumen Alta Donación'}
        subtitle="Este resumen presenta los detalles de la nueva donación. Verifique todos los datos administrativos y las asignaciones de los Centros de Costos antes de confirmar la entrada. Una vez creada, no se podrá volver a modificar los datos de la Donación."
        onDismiss={handleDismissSummaryDialog}
        onOverlayClick={handleDismissSummaryDialog}
        className="min-w-[50rem]"
        titleClassName="text-2xl"
        subtitleClassName="text-sm"
      >
        <DonationSummary
          donation={form.getValues()}
          cancelLabel="Editar Donación"
          submitLabel="Confirmar Donación"
          isLoading={isCreateDonationPending}
          onDismiss={handleDismissSummaryDialog}
          onSubmit={handleCreateDonation}
        />
      </DialogWrapper>
    </>
  );
};

export default DonationsCreate;
