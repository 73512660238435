import { LedgerAccountType } from '@/api/ledgerAccount/ledgerAccount.model';
import { DEFAULT_NAME_SCHEMA, LEDGER_ACCOUNT_TYPE_OPTIONS } from '@/common/constants';
import { constructZodLiteralUnionType } from '@/lib/utils';
import { z } from 'zod';

const REQUIRED_OPTION_LABEL = 'Seleccione una opción';

const LedgerAccountLevelTypeSchema = constructZodLiteralUnionType(
  LEDGER_ACCOUNT_TYPE_OPTIONS.map((literal) => z.literal(literal.value)),
);

const SelectParentSchema = z
  .object({
    id: z.string().min(1, REQUIRED_OPTION_LABEL),
    name: z.string(),
    code: z.string(),
    children: z.array(z.any()),
  })
  .required();

const LedgerAccountSchema = z
  .object({
    type: LedgerAccountLevelTypeSchema,
    parent: SelectParentSchema,
    name: DEFAULT_NAME_SCHEMA,
    code: z.string().min(1, 'El código es obligatorio'),
  })
  .required();

type LedgerAccountFormType = z.infer<typeof LedgerAccountSchema>;

const defaultLedgerAccountFormValues: LedgerAccountFormType = {
  type: '',
  parent: { id: '', name: '', code: '', children: [] },
  name: '',
  code: '',
};

const getLedgerAccountFormInitialValues = (type: LedgerAccountType): LedgerAccountFormType => {
  return {
    ...defaultLedgerAccountFormValues,
    type,
  };
};

export type { LedgerAccountFormType };
export { LedgerAccountSchema, defaultLedgerAccountFormValues, getLedgerAccountFormInitialValues };
