import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { FC } from 'react';
import { pathsBase } from '@/routes/paths';
import {
  DEFAULT_PAGINATION_PAGE_COUNT,
  SONNER_ERROR_OPTIONS,
  SONNER_SUCCESS_OPTIONS,
} from '@/common/constants';
import { useTablePagination } from '@/hooks/useTablePagination';
import { AlertDialogWrapper } from '@/components/shared/AlertDialog';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'sonner';
import SuccessToastText from '@/components/shared/SuccessToastText';
import useTableColumnsWithActions from '@/hooks/useTableColumnsWithActions';
import { TableWrapper } from '@/components/shared/TableWrapper';
import * as Sentry from '@sentry/react';
import { Invoice, InvoiceApi } from '@/api/invoice/invoice.model';
import { deleteInvoiceMutationFn, useInvoices } from '@/api/invoice/invoice.api';
import { invoiceTableColumns } from './utils/InvoicesTableColumns';
import { UserRole } from '@/api/user/user.model.ts';
import useUserStore from '@/store/useUserStore.ts';
import { SearchBar } from '@/components/shared/SearchBar';
import { useSearchBar } from '@/hooks/useSearchBar';
import { useAlertDialog } from '@/hooks/useAlertDialog';
import { defaultInvoiceFormValues } from './utils/InvoiceSchema';
import { Trash2 } from 'lucide-react';
import { handleClearQueryParams, getParamsWithAnimation } from '@/lib/utils';
import useFilterAndSortByBe from '@/hooks/useFilterAndSortByBe';
import { InfoCard } from '@/components/shared/InfoCard';
import { DateFilter } from '@/components/shared/DateFilter';
import { Card } from '@/components/ui/card';

const PAGE_TITLE = 'Facturas';

const Invoices: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useUserStore();

  const {
    pageIndex,
    pageSize,
    paginationState,
    dir,
    sort,
    filters,
    setPagination,
    setDir,
    setSort,
    setFilters,
    selectedDate,
    setSelectedDate,
  } = useTablePagination<InvoiceApi>();

  const { searchValue, form: searchBarForm, handleSubmit: handleSearchSubmit } = useSearchBar();

  const { data, isLoading, isFetching, refetch } = useInvoices({
    page: pageIndex,
    size: pageSize,
    dir: dir,
    sort: sort,
    search: searchValue,
    invoiceState: filters?.invoiceState,
    paymentState: filters?.paymentState,
    supplier: filters?.supplier,
    details: filters?.details,
    dueDate: selectedDate,
  });

  const { alertDialog, setAlertDialog, handleDismissAlertDialog } = useAlertDialog();

  const { mutate: deleteInvoice, isPending: isDeleteInvoicePending } = useMutation({
    mutationFn: deleteInvoiceMutationFn,
    onSuccess: () => {
      refetch();
      toast(
        <SuccessToastText>¡Factura eliminada exitosamente!</SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismissAlertDialog();
    },
    onError: (error: AxiosError) => {
      Sentry.captureException(error);
      toast.error('Ha ocurrido un error durante la eliminación de la Factura.', {
        description: 'Por favor, intentalo nuevamente.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  useFilterAndSortByBe<InvoiceApi>(refetch, setDir, setSort, setFilters, defaultInvoiceFormValues);

  const handleViewInvoice = (invoice: Invoice) => {
    navigate(
      `/${pathsBase.EXPENSES}/${pathsBase.INVOICES}/${invoice.id}${getParamsWithAnimation(location.search).replace('pageIndex', 'invoicesPageIndex')}`,
    );
  };

  const handleDeleteInvoice = (invoice: Invoice) => {
    setAlertDialog({
      title: `¿Estás seguro que queres eliminar la factura "#${invoice.receiptNumber}"? `,
      description: `Esta acción no puede ser deshecha y se perderá toda la información asociada a esta Factura: "#${invoice.receiptNumber}". `,
      open: true,
      onCancel: handleDismissAlertDialog,
      onConfirm: () => deleteInvoice(invoice.id),
    });
  };

  const invoicesColumnsWithActions = useTableColumnsWithActions<Invoice>(
    invoiceTableColumns,
    undefined,
    handleDeleteInvoice,
    user?.role !== UserRole.ADMIN,
    handleViewInvoice,
    user?.role !== UserRole.ADMIN,
  );

  return (
    <>
      <Card className="absolute top-[-170px] right-0 flex flex-col absolute items-center">
        <InfoCard
          items={[
            {
              title: 'Total Pendiente',
              values: data
                ? [
                    { value: data[1].dueExpensesAlt },
                    { value: data[1].dueExpenses, currency: 'ARS' },
                  ]
                : [],
            },
          ]}
          className={{
            card: 'w-[350px] flex items-center justify-center border-none shadow-none',
            header: 'w-full flex justify-center',
            content: 'w-full gap-4 flex justify-around',
          }}
        />
        <DateFilter
          hidePopup
          setSelectedDate={setSelectedDate}
          selectedDate={selectedDate}
          className="w-[350px]"
        />
      </Card>
      <SearchBar
        form={searchBarForm}
        searchTerm="factura"
        isLoading={isLoading || isFetching}
        className="right-[290px]"
        submitHandler={handleSearchSubmit}
      />
      <TableWrapper
        columns={invoicesColumnsWithActions}
        data={data ? data[0] : data}
        isLoading={isLoading}
        isFetching={isFetching}
        rowsLabel={PAGE_TITLE}
        pageCount={data ? data[0].totalPages : DEFAULT_PAGINATION_PAGE_COUNT}
        pagination={paginationState}
        switchEmptyEntityGender
        onPaginationChange={setPagination}
        emptyCta={
          location.search
            ? {
                label: 'Limpiar Filtros',
                onClick: () => {
                  handleClearQueryParams(navigate, true);
                  setSelectedDate('');
                },
              }
            : undefined
        }
        emptyLogo={<Trash2 className="mr-2 h-4 w-4" />}
      />
      <AlertDialogWrapper
        {...alertDialog}
        isLoading={isDeleteInvoicePending}
        onDismiss={handleDismissAlertDialog}
      />
      <Outlet />
    </>
  );
};

export default Invoices;
