import { FC } from 'react';
import {
  DEFAULT_PAGINATION_PAGE_COUNT,
  SONNER_ERROR_OPTIONS,
  SONNER_SUCCESS_OPTIONS,
} from '@/common/constants';
import { useTablePagination } from '@/hooks/useTablePagination';
import { donorsTableColumns } from './utils/DonorsTableColumns';
import { deleteDonorMutationFn, useDonors } from '@/api/donor/donor.api';
import useTableColumnsWithActions from '@/hooks/useTableColumnsWithActions';
import { Donor, DonorApi } from '@/api/donor/donor.model';
import { Outlet, useNavigate } from 'react-router-dom';
import { AlertDialogWrapper } from '@/components/shared/AlertDialog';
import { useMutation } from '@tanstack/react-query';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { AxiosError } from 'axios';
import { toast } from 'sonner';
import { TableWrapper } from '@/components/shared/TableWrapper';
import * as Sentry from '@sentry/react';
import { paths } from '@/routes/paths';
import { useAlertDialog } from '@/hooks/useAlertDialog';
import useFilterAndSortByBe from '@/hooks/useFilterAndSortByBe';

const PAGE_TITLE = 'Donantes';

const Donors: FC = () => {
  const navigate = useNavigate();

  const { pageIndex, pageSize, paginationState, dir, sort, setPagination, setDir, setSort } =
    useTablePagination<DonorApi>();

  const { data, isLoading, isFetching, refetch } = useDonors({
    page: pageIndex,
    size: pageSize,
    dir: dir,
    sort: sort,
  });

  const { alertDialog, setAlertDialog, handleDismissAlertDialog } = useAlertDialog();

  const { mutate: deleteDonor, isPending: isDeleteDonorPending } = useMutation({
    mutationFn: deleteDonorMutationFn,
    onSuccess: () => {
      refetch();
      toast(
        <SuccessToastText>Donante eliminado exitosamente</SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismissAlertDialog();
    },
    onError: (error: AxiosError) => {
      Sentry.captureException(error);
      toast.error('Ha ocurrido un error durante la eliminación del Donante.', {
        description: 'Por favor, intentalo nuevamente.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  useFilterAndSortByBe<DonorApi>(refetch, setDir, setSort);

  const handleEditDonor = (donor: Donor) => navigate(`${paths.DONORS}/${paths.EDIT}/${donor.id}`);

  const handleDeleteDonor = (donor: Donor) => {
    setAlertDialog({
      title: `¿Estás seguro que queres eliminar "${donor.name}"? `,
      description: `Esta acción no puede ser deshecha y se perderá toda la información asociada a este Donante: "${donor.name}". `,
      open: true,
      onCancel: handleDismissAlertDialog,
      onConfirm: () => deleteDonor(donor.id),
    });
  };

  const donorsColumnsWithActions = useTableColumnsWithActions<Donor>(
    donorsTableColumns,
    handleEditDonor,
    handleDeleteDonor,
  );

  return (
    <>
      <TableWrapper
        columns={donorsColumnsWithActions}
        data={data ? data[0] : data}
        isLoading={isLoading}
        isFetching={isFetching}
        rowsLabel={PAGE_TITLE}
        pageCount={data ? data[0].totalPages : DEFAULT_PAGINATION_PAGE_COUNT}
        pagination={paginationState}
        onPaginationChange={setPagination}
      />
      <AlertDialogWrapper
        {...alertDialog}
        isLoading={isDeleteDonorPending}
        onDismiss={handleDismissAlertDialog}
      />
      <Outlet />
    </>
  );
};

export default Donors;
