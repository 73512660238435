import { CostCenter, CostCenterApi } from '@/api/costCenter/costCenter.model';
import { Column } from '@tanstack/react-table';

const getCostCenterHeaderOptionsFiltered = <T>(column: Column<T, unknown>) => {
  const filteredOptions: Map<CostCenterApi[], number> = new Map();

  for (const [key, value] of column.getFacetedUniqueValues()) {
    const filteredKeys = key.filter((obj: CostCenter) => !obj.global);
    filteredOptions.set(filteredKeys, value);
  }

  return filteredOptions;
};

const getCostCenterCellFiltered = (costCenters: Array<CostCenterApi>) => {
  return [
    ...new Set(
      costCenters
        .filter(
          (costCenter: CostCenterApi | null | undefined) =>
            costCenter !== null && costCenter !== undefined && !costCenter.global,
        )
        .map((costCenter: CostCenterApi) => costCenter.code),
    ),
  ]
    .sort()
    .join(', ');
};

export { getCostCenterHeaderOptionsFiltered, getCostCenterCellFiltered };
