import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import DialogWrapper from '@/components/shared/DialogWrapper';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'sonner';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { SONNER_ERROR_OPTIONS, SONNER_SUCCESS_OPTIONS } from '@/common/constants';
import { TerritoryApi } from '@/api/territory/territory.model';
import {
  TerritoryFormType,
  TerritorySchema,
  defaultTerritoryFormValues,
} from './utils/TerritorySchema';
import {
  editTerritoryMutationFn,
  useTerritories,
  useTerritory,
} from '@/api/territory/territory.api';
import TerritoryForm from './components/TerritoryForm';
import { UUID } from 'crypto';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ErrorResponse } from '@/api/types';
import { paths } from '@/routes/paths';
import { EditFormSkeleton } from '@/components/shared/EditFormSkeleton';
import * as Sentry from '@sentry/react';

type TerritoriesEditParams = {
  id: TerritoryApi['id'];
};

const TerritoriesEdit: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams() as TerritoriesEditParams;
  const {
    data: territory,
    isLoading: isTerritoryLoading,
    refetch: refetchTerritory,
    error,
  } = useTerritory(id);
  const { refetch: refetchTerritories } = useTerritories();

  const form = useForm<TerritoryFormType>({
    defaultValues: territory
      ? {
          name: territory.name,
          code: territory.code,
        }
      : defaultTerritoryFormValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(TerritorySchema),
  });

  const { mutate: editTerritory, isPending } = useMutation<
    TerritoryApi,
    AxiosError<ErrorResponse>,
    [UUID, TerritoryFormType]
  >({
    mutationFn: editTerritoryMutationFn,
    onSuccess: (data: TerritoryApi) => {
      refetchTerritories();
      toast(
        <SuccessToastText>
          ¡Territorio <span className="text-blue-700">{data.name}</span> fue editado exitosamente!
        </SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismiss();
      refetchTerritory();
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      Sentry.captureException(error);

      if (error.response && error.response.data.type === 'DUPLICATED_ERROR') {
        form.setError('code', {
          type: 'validate',
          message:
            'El código ingresado ya fue utilizado previamente. Intentá nuevamente con un código personalizado.',
        });
      } else {
        toast.error('Ha ocurrido un error durante la edición del Proyecto.', {
          description: 'Por favor, revisa los datos ingresados e intentá de nuevo.',
          ...SONNER_ERROR_OPTIONS,
        });
      }
    },
  });

  useEffect(() => {
    if (territory) {
      form.reset({
        name: territory.name,
        code: territory.code,
      });
    }
  }, [territory]);

  if (error) {
    return <Navigate to="*" replace={true} />;
  }

  const handleDismiss = () => navigate(paths.TERRITORIES);

  const handleEditTerritory: SubmitHandler<TerritoryFormType> = (formData) => {
    if (territory) {
      editTerritory([territory.id, formData]);
    } else {
      toast.error('Lo sentimos, no tenés ningún Territorio seleccionado.', {
        description: 'Por favor, seleccioná un Territorio e intentá de nuevo.',
        ...SONNER_ERROR_OPTIONS,
      });
      handleDismiss();
    }
  };

  return (
    <DialogWrapper
      title="Editar Territorio"
      className="w-[425px]"
      onDismiss={handleDismiss}
      onOverlayClick={handleDismiss}
    >
      {isTerritoryLoading || !territory ? (
        <EditFormSkeleton inputRows={2} />
      ) : (
        <TerritoryForm
          form={form}
          submitLabel="Editar Territorio"
          cancelLabel="Cancelar"
          isLoading={isPending}
          submitHandler={handleEditTerritory}
          dismissHandler={handleDismiss}
        />
      )}
    </DialogWrapper>
  );
};

export default TerritoriesEdit;
