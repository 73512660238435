import useUserStore from '@/store/useUserStore';
import authApi from '@/api/auth/auth.api';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { paths } from '@/routes/paths';
import { HttpCode } from '@/common/constants';
import userApi from '@/api/user/user.api';
import { useState } from 'react';

export const useAuth = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setUser, setToken } = useUserStore();

  const login = async (userData: { email: string; password: string }) => {
    setLoading(true);
    return await authApi.login(userData);
  };

  const { mutate } = useMutation({
    mutationFn: login,
    onSuccess: (response) => {
      setToken(response.data.accessToken);
      fetchUserData();
      navigate(paths.DASHBOARD, { replace: true });
    },
    onError: (error: AxiosError) => {
      if (error.response?.status === HttpCode.FORBIDDEN) {
        toast.error('Credenciales incorrectas. Por favor, revisa tus datos e intenta de nuevo.', {
          closeButton: true,
        });
      } else {
        toast.error('Ocurrió un error inesperado. Por favor, intenta de nuevo.', {
          closeButton: true,
        });
      }
      setLoading(false);
    },
  });

  const fetchUserData = () => {
    const fetchUser = async () => {
      try {
        const user = (await userApi.getUser()).data;
        user.role = user.authorities[0].authority.split('_')[1];
        setUser(user);
      } catch (error) {
        toast.error(
          'Ocurrió un error al recuperar los datos del usuario. Por favor, intenta de nuevo.',
          {
            closeButton: true,
          },
        );
        setToken('');
      } finally {
        setLoading(false);
      }
    };
    fetchUser();
  };

  return { mutate, loading };
};
