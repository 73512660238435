import { Column } from '@tanstack/react-table';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { ArrowDownIcon, ArrowUpIcon, ChevronsUpDown, X as XIcon } from 'lucide-react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface SortColumnProps<TData, TValue> extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>;
  title: string;
  initialSortDirection?: 'asc' | 'desc';
}

export function SortColumnWithBe<TData, TValue>({
  column,
  title,
  className,
  initialSortDirection,
}: SortColumnProps<TData, TValue>) {
  useEffect(() => {
    if (initialSortDirection) {
      column.toggleSorting(initialSortDirection !== 'desc');
    }
  }, []);

  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const currentDir = params.get('dir');
  const currentSort = params.get('sort');

  const handleClearSorting = () => {
    const currentUrl = new URL(window.location.href);
    const params = new URLSearchParams(currentUrl.search);
    params.delete('dir');
    params.delete('sort');
    currentUrl.search = params.toString();
    window.history.pushState({}, '', currentUrl);
    navigate(currentUrl.search);
  };

  const handleToggleSorting = (sortBy: string) => {
    const currentUrl = new URL(window.location.href);
    const params = new URLSearchParams(currentUrl.search);
    const currentDir = params.get('dir');
    const newDir = currentDir === 'desc' || currentDir === null ? 'asc' : 'desc';
    params.set('dir', newDir);
    params.set('sort', sortBy);
    currentUrl.search = params.toString();
    window.history.pushState({}, '', currentUrl);
    navigate(currentUrl.search);
  };

  if (!column.getCanSort()) {
    return <div className={cn(className)}>{title}</div>;
  }

  return (
    <div className={cn('flex items-center space-x-2', className)}>
      <div className="relative w-fit">
        <Button
          variant="ghost"
          className={cn('p-0 hover:bg-transparent', { 'text-foreground': column.getIsSorted() })}
          onClick={() => handleToggleSorting(column.id)}
        >
          <span className="text-xs">{title}</span>
          {currentDir === 'desc' && currentSort === column.id ? (
            <ArrowDownIcon className="w-3.5 h-3.5 ml-2" />
          ) : currentDir === 'asc' && currentSort === column.id ? (
            <ArrowUpIcon className="w-3.5 h-3.5 ml-2" />
          ) : (
            <ChevronsUpDown className="w-3.5 h-3.5 ml-2" />
          )}
        </Button>
        {location.search && currentSort === column.id ? (
          <Button
            type="button"
            variant="link"
            className="absolute right-[-20px] top-2/4 translate-y-[-50%] z-50 w-fit h-fit p-0 text-destructive"
            onClick={handleClearSorting}
          >
            <XIcon className="w-3 h-3 color-destructive" />
          </Button>
        ) : null}
      </div>
    </div>
  );
}
