import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Button } from '@/components/ui/button';
import { LogOut } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { paths } from '@/routes/paths';
import useUserStore from '@/store/useUserStore';
import { FC } from 'react';
import { Skeleton } from '../ui/skeleton';

interface NavbarProps {
  isLoading?: boolean;
}

const Navbar: FC<NavbarProps> = ({ isLoading }) => {
  const navigate = useNavigate();
  const { user, setToken } = useUserStore();

  const logout = () => {
    setToken('');
    navigate(paths.LOGIN, { replace: true });
  };

  if (isLoading) {
    return (
      <header className="flex items-center justify-end px-8 py-4">
        <div className="flex items-center gap-4">
          <Skeleton className="w-40 h-8 bg-slate-200" />
          <Skeleton className="rounded-full w-9 h-9 bg-slate-200" />
        </div>
      </header>
    );
  }

  return (
    <header className="flex items-center justify-end px-8 py-4">
      <div className="flex items-center gap-4">
        <p className="text-sm text-foreground">{`${user?.firstName} ${user?.lastName}`}</p>
        <Popover>
          <PopoverTrigger asChild>
            <Avatar className="cursor-pointer">
              <AvatarFallback>
                {(user?.firstName ?? '').charAt(0).toUpperCase() +
                  (user?.lastName ?? '').charAt(0).toUpperCase()}
              </AvatarFallback>
            </Avatar>
          </PopoverTrigger>
          <PopoverContent className="px-4 py-2 mt-2 mr-8 w-fit">
            <Button
              variant="link"
              onClick={logout}
              className="justify-center w-full h-auto p-2 text-sm font-light border-none text-accent-foreground"
            >
              <LogOut className="w-4 h-4 mr-2" />
              Cerrar Sesión
            </Button>
          </PopoverContent>
        </Popover>
      </div>
    </header>
  );
};

export default Navbar;
