import { InvoiceApi } from '@/api/invoice/invoice.model';
import { CreditNoteFormType } from './CreditNoteSchema';
import { LedgerAccountApi } from '@/api/ledgerAccount/ledgerAccount.model';
import { CreditNote } from '@/api/creditNote/creditNote.model';
import moment from 'moment';
import { CURRENCY_OPTIONS } from '@/common/constants';

const generateCreditNoteExpenses = (invoice: InvoiceApi): CreditNote['creditNoteDetails'] => {
  return (
    invoice.expenses?.map((expense) => {
      return {
        expense: {
          ...expense,
          type: 'Expense',
          id: expense.id ?? '',
        },
        amount: expense.money?.amount || 0,
      };
    }) || []
  );
};

const generateCreditNoteForm = (invoice: InvoiceApi): CreditNoteFormType => {
  const ledgerAccount =
    invoice.accountingEntries && invoice.accountingEntries.length > 0
      ? (invoice.accountingEntries[0].ledgerAccount as LedgerAccountApi)
      : null;

  return {
    number: '',
    reversionDate: moment().toDate(),
    receiptDate: moment().toDate(),
    ledgerAccount: ledgerAccount
      ? { id: ledgerAccount.id, name: ledgerAccount.name }
      : { id: '', name: '' },
    currency: CURRENCY_OPTIONS[0].value,
    exchangeRate: 1,
    files: [],
    creditNoteDetails: generateCreditNoteExpenses(invoice),
    invoicePendingAmount: invoice.pendingAmount || 0,
    revertedAmount: 0,
  };
};

export { generateCreditNoteForm };
