import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from 'react-router-dom';
import Router from './routes/Router.tsx';
import { Toaster } from '@/components/ui/sonner';
import { toast } from 'sonner';
import { GLOBAL_ERROR_MESSAGE } from './common/constants.ts';
import * as Sentry from '@sentry/react';
import { S3Client } from '@aws-sdk/client-s3';
import { S3ClientProvider } from './providers/s3ClientContext.tsx';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) =>
      toast.error(GLOBAL_ERROR_MESSAGE, {
        description: `Error: ${error.message}`,
        closeButton: true,
      }),
  }),
});

const s3Client = new S3Client({
  region: import.meta.env.VITE_S3_REGION,
  credentials: {
    accessKeyId: import.meta.env.VITE_S3_ACCESS_KEY_ID,
    secretAccessKey: import.meta.env.VITE_S3_SECRET_ACCESS_KEY,
  },
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <S3ClientProvider s3Client={s3Client}>
        <>
          <RouterProvider router={Router} />
          <Toaster />
        </>
      </S3ClientProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
