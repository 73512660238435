import { AxiosResponse } from 'axios';
import httpClient from '../httpClient';

interface LoginResponse {
  accessToken: string;
  userId: string;
}

const AuthService = () => {
  return {
    login: async (data: { email: string; password: string }) => {
      return httpClient
        .login<LoginResponse>({ data })
        .then((response: AxiosResponse<LoginResponse>) => {
          httpClient.setAuthToken(response.data.accessToken);
          return response;
        })
        .catch((error) => {
          throw error;
        });
    },
  };
};

export default AuthService();
