import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  CostCenterFormType,
  CostCenterSchema,
  defaultCostCenterFormValues,
} from './utils/CostCenterSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import DialogWrapper from '@/components/shared/DialogWrapper';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'sonner';
import { paths } from '@/routes/paths';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { SONNER_SUCCESS_OPTIONS, SONNER_ERROR_OPTIONS } from '@/common/constants';
import { ErrorResponse } from '@/api/types';
import { createCostCenterMutationFn, useCostCenters } from '@/api/costCenter/costCenter.api';
import { CostCenterApi } from '@/api/costCenter/costCenter.model';
import { CostCentersForm } from './components/CostCentersForm';
import * as Sentry from '@sentry/react';

const CostCentersCreate: FC = () => {
  const navigate = useNavigate();

  const form = useForm<CostCenterFormType>({
    defaultValues: defaultCostCenterFormValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(CostCenterSchema),
  });

  const { refetch } = useCostCenters();

  const { mutate: createCostCenter, isPending } = useMutation({
    mutationFn: createCostCenterMutationFn,
    onSuccess: (data: CostCenterApi) => {
      refetch();
      toast(
        <SuccessToastText>
          ¡Centro de Costos <span className="text-blue-700">{data.code}</span> fue creado
          exitosamente!
        </SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismiss();
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      Sentry.captureException(error);

      if (error.response && error.response.data.type === 'DUPLICATED_ERROR') {
        form.setError('code', {
          type: 'validate',
          message:
            'El código ingresado ya fue utilizado previamente. Intentá nuevamente con un código personalizado.',
        });
      }
      toast.error('Ha ocurrido un error durante la creación del nuevo Centro de Costos.', {
        description: 'Por favor, revisa los datos ingresados e intenta de nuevo.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  const handleCreateCostCenter: SubmitHandler<CostCenterFormType> = (formData) => {
    if (formData.project && formData.code) {
      createCostCenter({
        code: formData.code,
        project: formData.project,
        territory: formData.territory
          ? {
              id: formData.territory.id ? formData.territory.id : undefined,
              name: formData.territory.name ? formData.territory.name : undefined,
            }
          : undefined,
      });
    } else {
      toast.error('Lo sentimos, no tenés ningún proyecto o código seleccionado.', {
        description:
          'Por favor, seleccioná un proyecto e ingresa un código para intentar nuevamente.',
        ...SONNER_ERROR_OPTIONS,
      });
    }
  };

  const handleDismiss = () => navigate(paths.COST_CENTERS);

  return (
    <DialogWrapper
      title="Crear Centro de Costos"
      subtitle="Un Centro de Costos es una combinación entre un Proyecto y un Territorio. Una vez creado, podrá ser asociado a una donación."
      className="w-[425px]"
      onDismiss={handleDismiss}
      onOverlayClick={handleDismiss}
    >
      <CostCentersForm
        form={form}
        submitLabel="Crear Centro de Costos"
        cancelLabel="Cancelar"
        isLoading={isPending}
        submitHandler={handleCreateCostCenter}
        dismissHandler={handleDismiss}
      />
    </DialogWrapper>
  );
};

export { CostCentersCreate };
