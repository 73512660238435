import { useCostCenter } from '@/api/costCenter/costCenter.api';
import { CostCenterApi } from '@/api/costCenter/costCenter.model';
import DialogWrapper from '@/components/shared/DialogWrapper';
import { getParamsWithAnimation } from '@/lib/utils';
import { paths } from '@/routes/paths';
import { FC } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CostCenterDetailForm } from './components/CostCenterDetailForm';
import { CostCenterDetailSkeleton } from './components/CostCenterDetailSkeleton';

type CostCentersDetailParams = {
  id: CostCenterApi['id'];
};

const CostCentersDetail: FC = () => {
  const { id } = useParams() as CostCentersDetailParams;

  const navigate = useNavigate();
  const location = useLocation();

  const { data: costCenter, isLoading: isLoadingCostCenter } = useCostCenter(id);

  const handleDismiss = () => {
    navigate(`${paths.COST_CENTERS}${getParamsWithAnimation(location.search, true)}`);
  };

  return (
    <DialogWrapper onDismiss={handleDismiss} className="w-full max-w-5xl">
      {isLoadingCostCenter ? (
        <CostCenterDetailSkeleton />
      ) : (
        <CostCenterDetailForm costCenter={costCenter!} />
      )}
    </DialogWrapper>
  );
};

export { CostCentersDetail };
