import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { CreatePaymentPayload, PaymentApi, PaymentsTransformResponse } from './payment.model';
import { QueryRequestParams, ResponsePaginated } from '../types';
import { InvoiceApi } from '../invoice/invoice.model';
import {
  DEFAULT_PAGINATION_DIR,
  DEFAULT_PAGINATION_PAGE_INDEX,
  DEFAULT_PAGINATION_PAGE_SIZE,
  DEFAULT_PAGINATION_SORT,
} from '@/common/constants';
import httpClient from '../httpClient';
import * as Sentry from '@sentry/react';

const PAYMENT_BASE_PATH = '/payment';
const PAYMENT_KEY = 'payment';
const INVOICE_BASE_PATH = '/invoice';
const INVOICES_PAYMENTS_STALE_TIME = 5000;

const paymentApi = {
  getPayments: async (id: InvoiceApi['id'], params?: string) => {
    try {
      const response = await httpClient.get(
        params
          ? `${PAYMENT_BASE_PATH}${INVOICE_BASE_PATH}/${id}?${params}`
          : `${PAYMENT_BASE_PATH}${INVOICE_BASE_PATH}/${id}`,
      );
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },

  deletePayment: async (id: string) => {
    try {
      const response = await httpClient.delete(`${PAYMENT_BASE_PATH}/${id}`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },

  createPayment: async (data: CreatePaymentPayload) => {
    try {
      const response = await httpClient.post(`${PAYMENT_BASE_PATH}`, {
        data,
      });
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
};

const usePayments = (
  id: InvoiceApi['id'],
  {
    page = DEFAULT_PAGINATION_PAGE_INDEX,
    size = DEFAULT_PAGINATION_PAGE_SIZE,
    sort = DEFAULT_PAGINATION_SORT,
    dir = DEFAULT_PAGINATION_DIR,
    staleTime = INVOICES_PAYMENTS_STALE_TIME,
  }: QueryRequestParams<PaymentApi> = {},
) => {
  const queryParams = `page=${page}&size=${size}&sort=${sort}&dir=${dir}`;

  return useQuery<ResponsePaginated<PaymentApi>, Error, PaymentsTransformResponse>({
    queryKey: [PAYMENT_KEY, { id, page, size }],
    queryFn: async () => {
      const response = await paymentApi.getPayments(id, queryParams);
      return response as ResponsePaginated<PaymentApi>;
    },
    placeholderData: keepPreviousData,
    staleTime,
  });
};

const createPaymentMutationFn = async (data: CreatePaymentPayload) => {
  return await paymentApi.createPayment(data);
};

const deletePaymentMutationFn = async (id: PaymentApi['id']) => {
  return await paymentApi.deletePayment(id);
};

export { usePayments, createPaymentMutationFn, deletePaymentMutationFn };
