import { InvoiceApi } from '@/api/invoice/invoice.model';
import { PaymentFormType } from './PaymentSchema';
import moment from 'moment';

const generatePaymentForm = (invoice: InvoiceApi): PaymentFormType => {
  return {
    paymentNumber: `P-${invoice.receiptNumber}-`,
    ledgerAccount: { id: '', name: '' },
    files: [],
    currency: invoice.money.currency,
    exchangeRate: 1,
    amount: 0,
    paymentDate: moment().toDate(),
  };
};

const renderPendingAmount = (
  currencyInvoice: string,
  currencyPayment: string,
  exchangeRate: number,
  amount: number = 0,
  pendingAmount: number,
) => {
  if (pendingAmount) {
    if (currencyInvoice === 'USD' && currencyPayment === 'ARS') {
      return pendingAmount - amount / exchangeRate;
    }
    if (currencyInvoice === 'USD' && currencyPayment === 'USD') {
      return pendingAmount - amount;
    }
    if (currencyInvoice === 'ARS' && currencyPayment === 'USD') {
      return pendingAmount - amount * exchangeRate;
    }
    if (currencyInvoice === 'ARS' && currencyPayment === 'ARS') {
      return pendingAmount - amount;
    }
  }
  return amount;
};

export { generatePaymentForm, renderPendingAmount };
