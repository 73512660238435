import { DEFAULT_EMAIL_SCHEMA, DEFAULT_NAME_SCHEMA } from '@/common/constants';
import { z } from 'zod';

export const FiscalSponsorSchema = z
  .object({
    name: DEFAULT_NAME_SCHEMA,
    email: DEFAULT_EMAIL_SCHEMA,
  })
  .required();

export type FiscalSponsorFormType = {
  name: string;
  email: string;
  projects: string;
};

export const defaultFiscalSponsorFormValues: FiscalSponsorFormType = {
  name: '',
  email: '',
  projects: '',
};
