import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import DialogWrapper from '@/components/shared/DialogWrapper';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'sonner';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { SONNER_ERROR_OPTIONS, SONNER_SUCCESS_OPTIONS } from '@/common/constants';
import * as Sentry from '@sentry/react';
import { Supplier } from '@/api/supplier/supplier.model';
import {
  SupplierFormType,
  SupplierSchema,
  defaultSupplierFormValues,
  generateDefaultValuesFromSupplier,
} from './utils/SupplierSchema';
import { editSupplierMutationFn, useSupplier, useSuppliers } from '@/api/supplier/supplier.api';
import { SupplierForm } from './components/SupplierForm';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ErrorResponse } from '@/api/types';
import { UUID } from 'crypto';
import { paths } from '@/routes/paths';
import { EditFormSkeleton } from '@/components/shared/EditFormSkeleton';
import { getParamsWithAnimation } from '@/lib/utils';

type SuppliersEditParams = {
  id: Supplier['id'];
};

const SuppliersEdit: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams() as SuppliersEditParams;
  const {
    data: supplier,
    isLoading: isSupplierLoading,
    refetch: refetchSupplier,
    error,
  } = useSupplier(id);
  const { refetch: refetchSuppliers } = useSuppliers();

  const form = useForm<SupplierFormType>({
    defaultValues: supplier
      ? generateDefaultValuesFromSupplier(supplier)
      : defaultSupplierFormValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(SupplierSchema),
  });

  const { mutate: editSupplier, isPending } = useMutation<
    Supplier,
    AxiosError<ErrorResponse>,
    [UUID, SupplierFormType]
  >({
    mutationFn: editSupplierMutationFn,
    onSuccess: (data: Supplier) => {
      refetchSuppliers();
      toast(
        <SuccessToastText>
          ¡Proveedor <span className="text-blue-700">{data.name}</span> fue editado exitosamente!
        </SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismiss();
      refetchSupplier();
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      Sentry.captureException(error);
      toast.error('Ha ocurrido un error durante la edición del Proveedor.', {
        description: 'Por favor, revisa los datos ingresados e intentá de nuevo.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  useEffect(() => {
    if (supplier) {
      form.reset(generateDefaultValuesFromSupplier(supplier));
    }
  }, [supplier]);

  if (error) {
    return <Navigate to="*" replace={true} />;
  }

  const handleDismiss = () =>
    navigate(`${paths.SUPPLIERS}${getParamsWithAnimation(location.search, true)}`);

  const handleEditSupplier: SubmitHandler<SupplierFormType> = (formData) => {
    if (
      formData.accounts?.length === 1 &&
      formData.accounts[0].cbu === '' &&
      formData.accounts[0].currency === ''
    ) {
      formData.accounts = [];
    }
    if (supplier) {
      if (formData.code === 0) formData.code = undefined;
      editSupplier([supplier.id, formData]);
    } else {
      toast.error('Lo sentimos, no tenés ningún Proveedor seleccionado.', {
        description: 'Por favor, seleccioná un Proveedor e intentá de nuevo.',
        ...SONNER_ERROR_OPTIONS,
      });
      handleDismiss();
    }
  };

  return (
    <DialogWrapper
      title="Editar Proveedor"
      className="w-full max-w-3xl"
      titleClassName="text-xl"
      onDismiss={handleDismiss}
      onOverlayClick={handleDismiss}
    >
      {isSupplierLoading || !supplier ? (
        <EditFormSkeleton inputRows={4} />
      ) : (
        <SupplierForm
          form={form}
          submitLabel="Editar Proveedor"
          cancelLabel="Cancelar"
          isLoading={isPending}
          submitHandler={handleEditSupplier}
          dismissHandler={handleDismiss}
        />
      )}
    </DialogWrapper>
  );
};

export default SuppliersEdit;
