import { FC, ReactElement } from 'react';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Outlet } from 'react-router-dom';

interface Tab<T> {
  id: T;
  label: string;
  path: string;
}

interface RoutedTabsProps<T> {
  tabs: Array<Tab<T>>;
  value: string;
  rightComponent?: ReactElement;
  onValueChange: (value: string) => void;
}

const RoutedTabs: FC<RoutedTabsProps<any>> = ({ tabs, value, rightComponent, onValueChange }) => {
  return (
    <Tabs value={value} className="w-full" onValueChange={onValueChange}>
      <div className="relative flex flex-col gap-4">
        <div className="flex justify-between items-center w-full">
          <TabsList>
            {tabs.map((tab) => (
              <TabsTrigger key={tab.id} value={tab.id} className="w-[195px]">
                {tab.label}
              </TabsTrigger>
            ))}
          </TabsList>
          {rightComponent}
        </div>
        <Outlet />
      </div>
    </Tabs>
  );
};

export type { Tab, RoutedTabsProps };
export { RoutedTabs };
