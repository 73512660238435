import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { Button } from '../ui/button';
import { GetObjectCommand } from '@aws-sdk/client-s3';
import { extractKeyFromUrl } from '@/lib/utils';
import { useState } from 'react';
import { useS3Client } from '@/providers/s3ClientContext';

interface DownloadFileCellProps {
  fileUrl: string;
  linkLabel?: string;
}

const DownloadFileCell = ({ fileUrl, linkLabel = 'Descargar' }: DownloadFileCellProps) => {
  const [fileSignedUrl, setFileSignedUrl] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const s3Client = useS3Client();

  const handleCreateSignedFileUrl = async () => {
    setIsLoading(true);

    try {
      const key = extractKeyFromUrl(fileUrl);

      const command = new GetObjectCommand({
        Bucket: import.meta.env.VITE_S3_BUCKET_NAME,
        Key: key,
      });

      const newSignedUrl = await getSignedUrl(s3Client, command, { expiresIn: 86400 });

      setFileSignedUrl(newSignedUrl);

      setIsLoading(false);

      window.open(newSignedUrl, '_blank');
    } catch (error) {
      console.log('File download error: ', error);
      setIsLoading(false);
    }
  };

  const handleDownloadFile = () => {
    if (fileSignedUrl) {
      window.open(fileSignedUrl, '_blank');
    } else {
      handleCreateSignedFileUrl();
    }
  };

  return (
    <Button
      variant="link"
      className="px-0 text-foreground font-medium underline transition-colors hover:text-blue-500"
      isLoading={isLoading}
      onClick={handleDownloadFile}
    >
      {linkLabel}
    </Button>
  );
};

export { DownloadFileCell };
