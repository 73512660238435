import { Separator } from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';

const DonationPayDetailSkeleton = () => (
  <div className="mx-auto px-14 max-w-[1000px]">
    <div className="flex flex-col">
      <div className="flex justify-start">
        <Skeleton className="w-2/5 h-10" />
      </div>
    </div>
    <div className={'flex flex-col mt-14 mb-[60px]'}>
      <div className="flex flex-col gap-[50px]">
        <div className="flex flex-col w-full gap-10">
          <Skeleton className="w-full h-36" />
          <Separator />
        </div>
        <div className="flex flex-col w-full gap-10">
          <Skeleton className="w-full h-36" />
          <Separator />
        </div>
        <div className="flex flex-col w-full gap-10">
          <Skeleton className="w-full h-36" />
          <Separator />
        </div>
        <div className="flex flex-col gap-5">
          <div className="flex flex-row gap-6 w-full">
            <Skeleton className="w-1/4 h-10" />
          </div>
          <Separator />
          <div className="flex flex-row gap-6 w-full">
            <Skeleton className="w-full h-10" />
            <Skeleton className="w-full h-10" />
            <Skeleton className="w-full h-10" />
            <Skeleton className="w-full h-10" />
          </div>
          <Separator />
          <div className="flex flex-row gap-6 w-full">
            <Skeleton className="w-full h-10" />
            <Skeleton className="w-full h-10" />
            <Skeleton className="w-full h-10" />
            <Skeleton className="w-full h-10" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export { DonationPayDetailSkeleton };
