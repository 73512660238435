import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { UserRole } from '@/api/user/user.model.ts';

type User = {
  email: string;
  firstName: string;
  lastName: string;
  role: UserRole;
};

type UserStore = {
  token: string;
  user: User | null;
  setUser: (user: User) => void;
  setToken: (token: string) => void;
};

const useUserStore = create<UserStore>()(
  persist(
    (set) => ({
      token: '',
      user: null,
      setUser: (user) => set({ user }),
      setToken: (token) => set({ token }),
    }),
    {
      name: 'user-storage',
    },
  ),
);

export default useUserStore;
