import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import DialogWrapper from '@/components/shared/DialogWrapper';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'sonner';
import { paths } from '@/routes/paths';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { SONNER_SUCCESS_OPTIONS, SONNER_ERROR_OPTIONS } from '@/common/constants';
import { ExpenseReportsForm } from './components/ExpenseReportsForm';
import * as Sentry from '@sentry/react';
import {
  createExpenseReportMutationFn,
  useExpenseReports,
} from '@/api/expenseReport/expenseReport.api';
import { ExpenseReportApi } from '@/api/expenseReport/expenseReport.model';
import { getFullName } from '@/lib/utils';
import {
  ExpenseReportFormType,
  ExpenseReportSchema,
  defaultExpenseReportFormValues,
} from './utils/ExpenseReportSchema';
import { ErrorResponse } from '@/api/types';

const ExpenseReportsCreate: FC = () => {
  const navigate = useNavigate();

  const form = useForm<ExpenseReportFormType>({
    defaultValues: defaultExpenseReportFormValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(ExpenseReportSchema),
  });

  const { refetch } = useExpenseReports();

  const { mutate: createExpenseReport, isPending } = useMutation({
    mutationFn: createExpenseReportMutationFn,
    onSuccess: (data: ExpenseReportApi) => {
      refetch();
      toast(
        <SuccessToastText>
          ¡Expense Report{' '}
          <span className="text-blue-700">{getFullName(data.firstName, data.lastName)}</span> fue
          creado exitosamente!
        </SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismiss();
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      Sentry.captureException(error);
      if (error.response && error.response.data.type === 'DUPLICATED_ERROR') {
        form.setError('email', {
          type: 'validate',
          message:
            'El email ingresado ya fue utilizado previamente. Intentá nuevamente con un email nuevo.',
        });
      }
      toast.error('Ha ocurrido un error durante la creación del nuevo Expense Report.', {
        description: 'Por favor, revisa los datos ingresados e intenta de nuevo.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  const handleCreateExpenseReport: SubmitHandler<ExpenseReportFormType> = (formData) =>
    createExpenseReport(formData);

  const handleDismiss = () => navigate(paths.EXPENSE_REPORTS);

  return (
    <DialogWrapper
      title="Crear Expense Report"
      onDismiss={handleDismiss}
      onOverlayClick={handleDismiss}
    >
      <ExpenseReportsForm
        form={form}
        submitLabel="Crear Expense Report"
        cancelLabel="Cancelar"
        isLoading={isPending}
        submitHandler={handleCreateExpenseReport}
        dismissHandler={handleDismiss}
      />
    </DialogWrapper>
  );
};

export { ExpenseReportsCreate };
