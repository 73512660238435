import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { CostCenterFormType, CostCenterSchema } from '../utils/CostCenterSchema';
import { Info } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { Checkbox } from '@/components/ui/checkbox';
import { ProjectListItem } from '@/api/project/project.model';
import { TerritoryListItem } from '@/api/territory/territory.model';
import { SelectFilter } from '@/components/shared/SelectFilter';
import { useProjectsList } from '@/api/project/project.api';
import { useTerritoriesList } from '@/api/territory/territory.api';
import { getRequiredLabel } from '@/lib/utils';

interface CostCentersFormProps {
  form: UseFormReturn<CostCenterFormType, any>;
  submitLabel: string;
  cancelLabel: string;
  isLoading?: boolean;
  isModify?: boolean;
  submitHandler: SubmitHandler<CostCenterFormType>;
  dismissHandler: () => void;
}

const generateAutoCode = (
  form: CostCentersFormProps['form'],
  projects?: Array<ProjectListItem>,
  territories?: Array<TerritoryListItem>,
) => {
  let result = 'CC';
  const project = form.getValues().project?.id;
  const territory = form.getValues().territory?.id;

  if (!project && !territory) {
    return undefined;
  }

  if (project && projects) {
    const projectCode = projects.find((p) => p.id === project)?.code;
    result += `-${projectCode}`;
  }

  if (territory && territories) {
    const territoryCode = territories.find((t) => t.id === territory)?.code;
    result += `-${territoryCode}`;
  }

  return result;
};

const CostCentersForm: FC<CostCentersFormProps> = ({
  form,
  submitLabel,
  cancelLabel,
  isLoading = false,
  isModify = false,
  submitHandler,
  dismissHandler,
}: CostCentersFormProps) => {
  const { data: projects, isLoading: isProjectsListLoading } = useProjectsList();
  const { data: territories, isLoading: isTerritoriesListLoading } = useTerritoriesList();
  const [useCustomCode, setUseCustomCode] = useState(false);
  const [defaultCode, setDefaultCode] = useState<string>(form.getValues('code') ?? '');

  const generatedAutoCode = useMemo(() => {
    if (!useCustomCode) {
      return generateAutoCode(form, projects, territories);
    }
  }, [form.watch('project'), form.watch('territory')]);

  const handleCustomCodeChange = useCallback((checked: boolean) => setUseCustomCode(checked), []);

  const handleGeneratedAutoCode = () => {
    if (useCustomCode) {
      form.setValue('code', defaultCode ?? '');
      form.trigger('code');
    } else {
      if (!isModify) {
        form.setValue('code', generatedAutoCode ?? '');

        if (generatedAutoCode) {
          form.trigger('code');
        }
      } else {
        // TODO Make autogenerate code on edit.
        // form.setValue('code', generatedAutoCode);
      }
    }
  };

  useEffect(
    () => handleGeneratedAutoCode(),
    [form.watch('project'), form.watch('territory'), useCustomCode],
  );

  useEffect(() => {
    const defaultCode = form.getValues('code');
    if (defaultCode) {
      setDefaultCode(defaultCode);
    }
  }, []);

  useEffect(() => {
    if (useCustomCode === false) {
      form.setFocus('code');
    }
  }, [useCustomCode]);
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(submitHandler)} className="flex flex-col gap-8">
        <div className="flex flex-col gap-4">
          <SelectFilter
            form={form}
            isLoading={isProjectsListLoading}
            formName="project"
            formLabel={getRequiredLabel(CostCenterSchema, 'project', 'Proyecto')}
            formPlaceholder="Seleccionar Proyecto"
            formFilterPlaceholder="Buscar Proyecto..."
            options={projects || []}
            classnames={{ button: 'w-full' }}
          />
          <SelectFilter
            form={form}
            isLoading={isTerritoriesListLoading}
            formName="territory"
            formLabel={getRequiredLabel(CostCenterSchema, 'territory', 'Territorio')}
            formPlaceholder="Seleccionar Territorio"
            formFilterPlaceholder="Buscar Territorio..."
            options={territories || []}
            classnames={{ button: 'w-full' }}
          />
          <FormField
            control={form.control}
            name="code"
            render={({ field }) => (
              <div className="space-y-2">
                <FormItem>
                  <div className="flex items-center gap-1">
                    <FormLabel className="leading-5">
                      {getRequiredLabel(CostCenterSchema, 'code', 'Código')}
                    </FormLabel>
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger className="mt-[2px]">
                          <Info className="w-3 h-3 text-foregorund" />
                        </TooltipTrigger>
                        <TooltipContent side="right" className="w-[308px] bg-muted">
                          <p className="text-xs">
                            El código es una combinación entre el proyecto y territorio elegido. Es
                            posible modificarlo.
                          </p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                  <FormControl>
                    <Input
                      {...field}
                      type="text"
                      placeholder={!useCustomCode ? '-' : undefined}
                      disabled={!useCustomCode}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
                <FormItem className="flex flex-row items-start p-3 space-x-2 space-y-0 border rounded-md border-input">
                  <FormControl>
                    <Checkbox
                      checked={useCustomCode}
                      className="mt-[2px]"
                      onCheckedChange={handleCustomCodeChange}
                    />
                  </FormControl>
                  <div className="space-y-[0.25rem] leading-none">
                    <FormLabel className="text-sm text-foreground">Código personalizado</FormLabel>
                    <FormDescription className="text-xs text-muted-foreground">
                      Habilitando esta opción podrás ingresar un código personalizado para el nuevo
                      centro de costos.
                    </FormDescription>
                  </div>
                </FormItem>
              </div>
            )}
          />
        </div>
        <div className="flex flex-row justify-end gap-2">
          <Button type="button" variant="outline" disabled={isLoading} onClick={dismissHandler}>
            {cancelLabel}
          </Button>
          <Button
            type="submit"
            disabled={!form.formState.isValid}
            className="relative"
            isLoading={isLoading}
          >
            {submitLabel}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export { CostCentersForm };
