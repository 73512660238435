import { InvoiceApi, InvoiceState, invoiceStateMap } from '@/api/invoice/invoice.model.ts';
import { Badge } from '@/components/ui/badge.tsx';

const InvoiceBadge = ({ invoice }: { invoice: InvoiceApi }) => {
  if (invoice?.invoiceState === InvoiceState.DRAFT) {
    return (
      <Badge variant="outlineGray" className="px-4 text-base font-normal rounded-xl">
        {invoiceStateMap.DRAFT}
      </Badge>
    );
  }

  switch (invoice?.paymentState) {
    case 'PAYED':
      return (
        <Badge variant="outlineGreen" className="px-4 text-base font-normal rounded-xl">
          Pago Completo
        </Badge>
      );

    case 'CANCELLED':
      return (
        <Badge variant="outlineRed" className="px-4 text-base font-normal rounded-xl">
          Pago Cancelado
        </Badge>
      );

    default:
      return (
        <Badge variant="outlineYellow" className="px-4 text-base font-normal rounded-xl">
          Pago Pendiente
        </Badge>
      );
  }
};

export { InvoiceBadge };
