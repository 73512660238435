import { FC } from 'react';
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { ExpenseReportFormType } from '../utils/ExpenseReportSchema';

interface ExpenseReportsFormProps {
  form: UseFormReturn<ExpenseReportFormType, any>;
  submitLabel: string;
  cancelLabel: string;
  isLoading?: boolean;
  submitHandler: SubmitHandler<ExpenseReportFormType>;
  dismissHandler: () => void;
}

const ExpenseReportsForm: FC<ExpenseReportsFormProps> = ({
  form,
  submitLabel,
  cancelLabel,
  isLoading = false,
  submitHandler,
  dismissHandler,
}: ExpenseReportsFormProps) => {
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(submitHandler)} className="flex flex-col gap-8">
        <div className="flex flex-col gap-4">
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Nombre</FormLabel>
                <FormControl>
                  <Input {...field} type="text" placeholder="Ingresar nombre" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Apellido</FormLabel>
                <FormControl>
                  <Input {...field} type="text" placeholder="Ingresar apellido" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input {...field} type="email" placeholder="Ingresar email" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex flex-row justify-end gap-2">
          <Button type="button" variant="outline" disabled={isLoading} onClick={dismissHandler}>
            {cancelLabel}
          </Button>
          <Button
            type="submit"
            disabled={isLoading || !form.formState.isValid}
            className="relative"
            isLoading={isLoading}
          >
            {submitLabel}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export { ExpenseReportsForm };
