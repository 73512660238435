import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import DialogWrapper from '@/components/shared/DialogWrapper';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'sonner';
import { paths } from '@/routes/paths';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { SONNER_SUCCESS_OPTIONS, SONNER_ERROR_OPTIONS } from '@/common/constants';
import { DonorsForm } from './components/DonorsForm';
import * as Sentry from '@sentry/react';
import { createDonorMutationFn, useDonors } from '@/api/donor/donor.api';
import { DonorFormType, DonorSchema, defaultDonorFormValues } from './utils/DonorSchema';
import { DonorApi } from '@/api/donor/donor.model';
import { ErrorResponse } from '@/api/types';

const DonorsCreate: FC = () => {
  const navigate = useNavigate();

  const form = useForm<DonorFormType>({
    defaultValues: defaultDonorFormValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(DonorSchema),
  });

  const { refetch } = useDonors();

  const { mutate: createDonor, isPending } = useMutation({
    mutationFn: createDonorMutationFn,
    onSuccess: (data: DonorApi) => {
      refetch();
      toast(
        <SuccessToastText>
          ¡Donante <span className="text-blue-700">{data.name}</span> fue creado exitosamente!
        </SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismiss();
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      Sentry.captureException(error);
      if (error.response && error.response.data.type === 'DUPLICATED_ERROR') {
        form.setError('email', {
          type: 'validate',
          message:
            'El email ingresado ya fue utilizado previamente. Intentá nuevamente con un email nuevo.',
        });
      }
      toast.error('Ha ocurrido un error durante la creación del nuevo Donante.', {
        description: 'Por favor, revisa los datos ingresados e intenta de nuevo.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  const handleCreateDonor: SubmitHandler<DonorFormType> = (formData) => createDonor(formData);

  const handleDismiss = () => navigate(paths.DONORS);

  return (
    <DialogWrapper title="Crear Donante" onDismiss={handleDismiss} onOverlayClick={handleDismiss}>
      <DonorsForm
        form={form}
        submitLabel="Crear Donante"
        cancelLabel="Cancelar"
        isLoading={isPending}
        submitHandler={handleCreateDonor}
        dismissHandler={handleDismiss}
      />
    </DialogWrapper>
  );
};

export default DonorsCreate;
