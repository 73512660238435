import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { createBrowserRouter, RouteObject } from 'react-router-dom';
import { Login } from '@/pages/Login/Login';
import { NotFound } from '@/pages/NotFound/NotFound';
import { paths, pathsBase } from './paths';
import { ExpenseReportsCreate } from '@/pages/ExpenseReports/ExpenseReportsCreate';
import { CostCentersCreate } from '@/pages/CostCenter/CostCentersCreate';
import { CostCentersEdit } from '@/pages/CostCenter/CostCentersEdit';
import { ExpenseReportsEdit } from '@/pages/ExpenseReports/ExpenseReportsEdit';
import ProtectedRoute from './ProtectedRoute';
import Donors from '@/pages/Donors/Donors';
import ProjectsCreate from '@/pages/Projects/ProjectsCreate';
import Suppliers from '@/pages/Suppliers/Suppliers';
import SuppliersCreate from '@/pages/Suppliers/SuppliersCreate';
import ExpenseReports from '@/pages/ExpenseReports/ExpenseReports';
import DonationPage from '@/pages/Donations/DonationPage';
import DonationsCreate from '@/pages/Donations/DonationsCreate';
import SuppliersEdit from '@/pages/Suppliers/SuppliersEdit';
import ProjectsEdit from '@/pages/Projects/ProjectsEdit';
import TerritoriesEdit from '@/pages/Territories/TerritoriesEdit';
import FiscalSponsorEdit from '@/pages/FiscalSponsor/FiscalSponsorEdit';
import DonorsEdit from '@/pages/Donors/DonorsEdit';
import DonorsCreate from '@/pages/Donors/DonorsCreate';
import LedgerAccountEdit from '@/pages/LedgerAccounts/LedgerAccountEdit';
import Categories from '@/pages/Categories/Categories';
import CategoriesCreate from '@/pages/Categories/CategoriesCreate';
import CategoriesEdit from '@/pages/Categories/CategoriesEdit';
import BankAccounts from '@/pages/BankAccounts/BankAccounts';
import BankAccountsCreate from '@/pages/BankAccounts/BankAccountsCreate';
import BankAccountEdit from '@/pages/BankAccounts/BankAccountsEdit';
import ExpenseReportExpenses from '@/pages/ExpenseReportDetail/ExpenseReportExpenses/ExpenseReportExpenses';
import ExpenseReportFund from '@/pages/ExpenseReportDetail/ExpenseReportFund/ExpenseReportFund';
import { ExpenseReportExpensesCreate } from '@/pages/ExpenseReportDetail/ExpenseReportExpenses/ExpenseReportExpensesCreate';
import ExpenseReportFundCreate from '@/pages/ExpenseReportDetail/ExpenseReportFund/ExpenseReportFundCreate';
import LedgerAccountType from '@/pages/LedgerAccounts/LedgerAccountType/LedgerAccountType';
import LedgerAccountSubType from '@/pages/LedgerAccounts/LedgerAccountSubType/LedgerAccountSubType';
import LedgerAccountAccounts from '@/pages/LedgerAccounts/LedgerAccountAccounts/LedgerAccountAccounts';
import LedgerAccountSubAccount from '@/pages/LedgerAccounts/LedgerAccountSubAccount/LedgerAccountSubAccount';
import AccountsMayor from '@/pages/AccountsMayor/AccountsMayor';
import AccountingEntryCreate from '@/pages/AccountsMayor/AccountingEntry/AccountingEntryCreate';
import AccountMayorDetail from '@/pages/AccountsMayor/AccountsMayorDetail';
import DonationPay from '@/pages/Donations/DonationPay';
import Invoices from '@/pages/Invoices/Invoices';
import { InvoiceCreate } from '@/pages/Invoices/InvoiceCreate';
import InvoiceDetails from '@/pages/Invoices/InvoiceDetails.tsx';
import { CreditNoteCreate } from '@/pages/CreditNotes/CreditNoteCreate/CreditNoteCreate';
import AccountingEntryDetail from '@/pages/AccountsMayor/AccountingEntry/AccountingEntryDetail/AccountingEntryDetail.tsx';
import { PaymentCreate } from '@/pages/Payments/PaymentsCreate';
import DonationsDetail from '@/pages/Donations/DonationDetail';
import DonationPayDetail from '@/pages/Donations/DonationPayDetail';
import InvoicesReport from '@/pages/Invoices/InvoicesReport.tsx';
import { CostCentersDetail } from '@/pages/CostCenter/CostCentersDetail';

// Lazy-loaded components
const Dashboard = lazy(() => import('@/pages/Dashboard/Dashboard'));
const Projects = lazy(() => import('@/pages/Projects/Projects'));
const Expenses = lazy(() => import('@/pages/Expenses/Expenses'));
const CostCenter = lazy(() => import('@/pages/CostCenter/CostCenters'));
const Donations = lazy(() => import('@/pages/Donations/Donations'));
const FiscalSponsor = lazy(() => import('@/pages/FiscalSponsor/FiscalSponsor'));
const FiscalSponsorCreate = lazy(() => import('@/pages/FiscalSponsor/FiscalSponsorCreate'));
const LedgerAccounts = lazy(() => import('@/pages/LedgerAccounts/LedgerAccounts'));
const LedgerAccountCreate = lazy(() => import('@/pages/LedgerAccounts/LedgerAccountCreate'));
const Territories = lazy(() => import('@/pages/Territories/Territories'));
const TerritoriesCreate = lazy(() => import('@/pages/Territories/TerritoriesCreate'));
const ExpenseReportDetail = lazy(() => import('@/pages/ExpenseReportDetail/ExpenseReportDetail'));

// Define routes, using getRouteElement for protected routes
const routes: RouteObject[] = [
  {
    path: paths.LOGIN,
    element: <Login />,
  },
  {
    path: paths.DASHBOARD,
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: pathsBase.EXPENSES,
    element: (
      <ProtectedRoute>
        <Expenses />
      </ProtectedRoute>
    ),
    children: [
      {
        path: pathsBase.INVOICES,
        element: <Invoices />,
        children: [
          {
            path: paths.CREATE,
            element: <InvoiceCreate />,
          },
          {
            path: `:id`,
            children: [
              {
                path: '',
                element: <InvoiceDetails />,
              },
              {
                path: `${pathsBase.PAY}`,
                element: <PaymentCreate />,
              },
              {
                path: `${pathsBase.CREDIT_NOTE}`,
                element: <CreditNoteCreate />,
              },
            ],
          },
          {
            path: `${paths.REPORT}`,
            element: <InvoicesReport />,
          },
        ],
      },
      {
        path: pathsBase.SUPPLIERS,
        element: <Suppliers />,
        children: [
          {
            path: paths.CREATE,
            element: (
              <ProtectedRoute useLayout={false}>
                <SuppliersCreate />
              </ProtectedRoute>
            ),
          },
          {
            path: `${paths.EDIT}/:id`,
            element: <SuppliersEdit />,
          },
        ],
      },
      {
        path: pathsBase.EXPENSE_REPORTS,
        element: <ExpenseReports />,
        children: [
          {
            path: paths.CREATE,
            element: <ExpenseReportsCreate />,
          },
          {
            path: `${paths.EDIT}/:id`,
            element: <ExpenseReportsEdit />,
          },
        ],
      },
    ],
  },
  {
    path: paths.EXPENSE_REPORT_DETAIL_PAYMENTS,
    element: (
      <ProtectedRoute>
        <ExpenseReportDetail />
      </ProtectedRoute>
    ),
    children: [
      {
        path: paths.EXPENSE_REPORT_DETAIL_PAYMENTS,
        element: <ExpenseReportExpenses />,
        children: [
          {
            path: paths.CREATE,
            element: <ExpenseReportExpensesCreate />,
          },
        ],
      },
      {
        path: paths.EXPENSE_REPORT_DETAIL_FUNDING,
        element: <ExpenseReportFund />,
        children: [
          {
            path: paths.CREATE,
            element: <ExpenseReportFundCreate />,
          },
        ],
      },
    ],
  },
  {
    path: paths.PROJECTS,
    element: (
      <ProtectedRoute>
        <Projects />
      </ProtectedRoute>
    ),
    children: [
      {
        path: paths.CREATE,
        element: (
          <ProtectedRoute useLayout={false}>
            <ProjectsCreate />
          </ProtectedRoute>
        ),
      },
      {
        path: `${paths.EDIT}/:id`,
        element: <ProjectsEdit />,
      },
    ],
  },
  {
    path: paths.COST_CENTERS,
    element: (
      <ProtectedRoute>
        <CostCenter />
      </ProtectedRoute>
    ),
    children: [
      {
        path: paths.CREATE,
        element: <CostCentersCreate />,
      },
      {
        path: `${paths.EDIT}/:id`,
        element: <CostCentersEdit />,
      },
      {
        path: `:id`,
        element: <CostCentersDetail />,
      },
    ],
  },
  {
    path: paths.DONATIONS,
    element: (
      <ProtectedRoute>
        <Donations />
      </ProtectedRoute>
    ),
    children: [
      {
        path: paths.DONATIONS,
        element: <DonationPage />,
        children: [
          {
            path: paths.CREATE,
            element: <DonationsCreate />,
          },
          {
            path: `:id`,
            children: [
              {
                path: '',
                element: <DonationsDetail />,
              },
              {
                path: `${pathsBase.PAY}`,
                element: <DonationPay />,
              },
              {
                path: `${pathsBase.PAY}/${paths.DETAIL}`,
                element: <DonationPayDetail />,
              },
            ],
          },
        ],
      },
      {
        path: paths.DONORS,
        element: <Donors />,
        children: [
          {
            path: paths.CREATE,
            element: <DonorsCreate />,
          },
          {
            path: `${paths.EDIT}/:id`,
            element: <DonorsEdit />,
          },
        ],
      },
    ],
  },
  // {
  //   path: paths.DONATIONS,
  //   element: (
  //     <ProtectedRoute>
  //       <Donations />
  //     </ProtectedRoute>
  //   ),
  //   children: [
  //     {
  //       path: paths.DONATIONS,
  //       element: <DonationPage />,
  //       children: [
  //         {
  //           path: paths.CREATE,
  //           element: <DonationsCreate />,
  //         },
  //         {
  //           path: `${paths.PAY}/:id`,
  //           element: <DonationPay />,
  //         },
  //         {
  //           path: `${paths.DETAIL}/:id`,
  //           element: <DonationsDetail />,
  //         },
  //         {
  //           path: `${paths.PAY}/${paths.DETAIL}/:id`,
  //           element: <DonationPayDetail />,
  //         },
  //       ],
  //     },
  //     {
  //       path: paths.DONORS,
  //       element: <Donors />,
  //       children: [
  //         {
  //           path: paths.CREATE,
  //           element: <DonorsCreate />,
  //         },
  //         {
  //           path: `${paths.EDIT}/:id`,
  //           element: <DonorsEdit />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    path: paths.FISCAL_SPONSORS,
    element: (
      <ProtectedRoute>
        <FiscalSponsor />
      </ProtectedRoute>
    ),
    children: [
      {
        path: paths.CREATE,
        element: (
          <ProtectedRoute useLayout={false}>
            <FiscalSponsorCreate />
          </ProtectedRoute>
        ),
      },
      {
        path: `${paths.EDIT}/:id`,
        element: (
          <ProtectedRoute useLayout={false}>
            <FiscalSponsorEdit />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: pathsBase.LEDGER_ACCOUNTS,
    element: (
      <ProtectedRoute>
        <LedgerAccounts />
      </ProtectedRoute>
    ),
    children: [
      {
        path: paths.LEDGER_ACCOUNTS_TYPE,
        element: <LedgerAccountType />,
      },
      {
        path: paths.LEDGER_ACCOUNTS_SUBTYPE,
        element: <LedgerAccountSubType />,
        children: [
          {
            path: paths.CREATE,
            element: (
              <ProtectedRoute useLayout={false}>
                <LedgerAccountCreate />
              </ProtectedRoute>
            ),
          },
          {
            path: `${paths.EDIT}/:id`,
            element: (
              <ProtectedRoute useLayout={false}>
                <LedgerAccountEdit />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: paths.LEDGER_ACCOUNTS_ACCOUNT,
        element: <LedgerAccountAccounts />,
        children: [
          {
            path: paths.CREATE,
            element: (
              <ProtectedRoute useLayout={false}>
                <LedgerAccountCreate />
              </ProtectedRoute>
            ),
          },
          {
            path: `${paths.EDIT}/:id`,
            element: (
              <ProtectedRoute useLayout={false}>
                <LedgerAccountEdit />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: paths.LEDGER_ACCOUNTS_SUBACCOUNT,
        element: <LedgerAccountSubAccount />,
        children: [
          {
            path: paths.CREATE,
            element: (
              <ProtectedRoute useLayout={false}>
                <LedgerAccountCreate />
              </ProtectedRoute>
            ),
          },
          {
            path: `${paths.EDIT}/:id`,
            element: (
              <ProtectedRoute useLayout={false}>
                <LedgerAccountEdit />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },
  {
    path: paths.TERRITORIES,
    element: (
      <ProtectedRoute>
        <Territories />
      </ProtectedRoute>
    ),
    children: [
      {
        path: paths.CREATE,
        element: (
          <ProtectedRoute useLayout={false}>
            <TerritoriesCreate />
          </ProtectedRoute>
        ),
      },
      {
        path: `${paths.EDIT}/:id`,
        element: (
          <ProtectedRoute useLayout={false}>
            <TerritoriesEdit />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: pathsBase.CATEGORIES,
    element: (
      <ProtectedRoute>
        <Categories />
      </ProtectedRoute>
    ),
    children: [
      {
        path: paths.CREATE,
        element: (
          <ProtectedRoute useLayout={false}>
            <CategoriesCreate />
          </ProtectedRoute>
        ),
      },
      {
        path: `${paths.EDIT}/:id`,
        element: (
          <ProtectedRoute useLayout={false}>
            <CategoriesEdit />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: pathsBase.BANK_ACCOUNTS,
    element: (
      <ProtectedRoute>
        <BankAccounts />
      </ProtectedRoute>
    ),
    children: [
      {
        path: paths.CREATE,
        element: (
          <ProtectedRoute useLayout={false}>
            <BankAccountsCreate />
          </ProtectedRoute>
        ),
      },
      {
        path: `${paths.EDIT}/:id`,
        element: (
          <ProtectedRoute useLayout={false}>
            <BankAccountEdit />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: paths.ACCOUNTS_MAYOR,
    element: (
      <ProtectedRoute>
        <AccountsMayor />
      </ProtectedRoute>
    ),
    children: [
      {
        path: paths.CREATE,
        element: (
          <ProtectedRoute useLayout={false}>
            <AccountingEntryCreate />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: `${paths.ACCOUNTS_MAYOR}/:id`,
    element: (
      <ProtectedRoute>
        <AccountMayorDetail />
      </ProtectedRoute>
    ),
    children: [
      {
        path: paths.CREATE,
        element: (
          <ProtectedRoute useLayout={false}>
            <AccountingEntryCreate />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: `${paths.ACCOUNTS_MAYOR}/group/:id`,
    element: (
      <ProtectedRoute>
        <AccountingEntryDetail />
      </ProtectedRoute>
    ),
    children: [],
  },
  {
    path: paths.HOME,
    element: <Navigate to={paths.DASHBOARD} replace />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

const router = createBrowserRouter(routes);

export default router;
