import { paths, pathsBase } from '@/routes/paths';
import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { SONNER_SUCCESS_OPTIONS, SONNER_ERROR_OPTIONS } from '@/common/constants';
import SuccessToastText from '@/components/shared/SuccessToastText';
import * as Sentry from '@sentry/react';
import { DonationForm } from './components/DonationForm';
import {
  DonationFormType,
  DonationSchema,
  defaultDonationFormValues,
} from './utils/DonationSchema';
import { Helmet } from 'react-helmet';
import { SheetLayout } from '@/components/layout/SheetLayout';
import { AlertDialogWrapper } from '@/components/shared/AlertDialog';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { DonationApi } from '@/api/donation/donation.model';
import {
  deleteDonationMutationFn,
  editDonationMutationFn,
  useDonation,
  useDonations,
} from '@/api/donation/donation.api';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';
import { AxiosError } from 'axios';
import { setDonationDetailDefaultValue } from './utils/DonationPaySchema';
import { DonationFormSkeleton } from './components/DonationFormSkeleton';
import { useAlertDialog } from '@/hooks/useAlertDialog';
import { getParamsWithAnimation } from '@/lib/utils';

const PAGE_TITLE = 'Detalle de Donación';

type DonationDetailParams = {
  id: DonationApi['id'];
};

const DonationsDetail: FC = () => {
  const { id } = useParams() as DonationDetailParams;

  const navigate = useNavigate();

  const { data: donation, isLoading } = useDonation(id);
  const { refetch: refetchDonations } = useDonations();

  const { alertDialog, setAlertDialog, handleDismissAlertDialog } = useAlertDialog();

  const { mutate: deleteDonation, isPending: isDeleteDonationPending } = useMutation({
    mutationFn: deleteDonationMutationFn,
    onSuccess: () => {
      toast(
        <SuccessToastText>¡Donación eliminada exitosamente!</SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleGoDonations();
    },
    onError: (error: AxiosError) => {
      Sentry.captureException(error);
      toast.error('Ha ocurrido un error durante la eliminación de la Donación.', {
        description: 'Por favor, intentalo nuevamente.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  const { mutate: editDonation, isPending: isEditDonationPending } = useMutation<
    DonationApi,
    AxiosError,
    [string, DonationFormType]
  >({
    mutationFn: editDonationMutationFn,
    onSuccess: () => {
      refetchDonations();
      toast(
        <SuccessToastText>¡Donación editada exitosamente!</SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleGoDonations();
    },
    onError: (error: AxiosError) => {
      Sentry.captureException(error);
      toast.error('Ha ocurrido un error durante la edición de la Donación.', {
        description: 'Por favor, intentalo nuevamente.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  const form = useForm<DonationFormType>({
    defaultValues:
      setDonationDetailDefaultValue(donation as DonationApi) || defaultDonationFormValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(DonationSchema),
  });

  const handleDeleteDonation = (donation: DonationApi) => {
    setAlertDialog({
      title: `¿Estás seguro que queres eliminar "${donation.name}"? `,
      description: `Esta acción no puede ser deshecha y se perderá toda la información asociada a esta Donación ("${donation.name}").`,
      open: true,
      onCancel: handleDismissAlertDialog,
      onConfirm: () => deleteDonation(donation.id),
    });
  };

  const handleEditDonation: SubmitHandler<DonationFormType> = (formData) => {
    if (donation) {
      editDonation([donation.id, formData]);
    }
  };

  const handleGoDonations = () => navigate(paths.DONATIONS);

  const handlePayDonation = () =>
    navigate(
      `/${pathsBase.DONATIONS}/${id}/${pathsBase.PAY}${getParamsWithAnimation(location.search)}&detail=true`,
    );

  const handleOpenPaymentDetail = () =>
    navigate(
      `/${pathsBase.DONATIONS}/${id}/${pathsBase.PAY}/${paths.DETAIL}${getParamsWithAnimation(location.search)}`,
    );

  const handleDismiss = () =>
    navigate(`${paths.DONATIONS}${getParamsWithAnimation(location.search, true)}`);

  useEffect(() => {
    if (donation) {
      form.reset(setDonationDetailDefaultValue(donation as DonationApi));
    }
  }, [donation, form]);

  return (
    <>
      <Helmet>
        <title>PEM - {PAGE_TITLE}</title>
      </Helmet>
      <SheetLayout onClose={handleDismiss}>
        {isLoading || !donation ? (
          <DonationFormSkeleton />
        ) : (
          <div className="max-w-4xl mx-auto">
            <div className="grid grid-cols-2">
              <div className="flex gap-6 justify-start items-center">
                <h1 className="text-4xl font-normal tracking-tighter text-foreground">
                  {form.getValues('name') || donation?.name}
                </h1>
                {donation?.state !== 'COMPLETED' ? (
                  <Badge variant="outlineYellow" className="px-4 text-base font-normal rounded-xl">
                    Pendiente
                  </Badge>
                ) : (
                  <Badge variant="outlineGreen" className="px-4 text-base font-normal rounded-xl">
                    Completo
                  </Badge>
                )}
              </div>
              <div className="flex justify-end items-center">
                {donation?.state !== 'COMPLETED' ? (
                  <>
                    <Button
                      variant="outlineDestructive"
                      className="mr-4"
                      onClick={() => {
                        if (donation) {
                          handleDeleteDonation(donation);
                        }
                      }}
                      isLoading={isDeleteDonationPending}
                    >
                      Eliminar Donación
                    </Button>
                    <Button variant="default" onClick={handlePayDonation}>
                      Registrar Cobro
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="outlineDestructive"
                      className="mr-4"
                      onClick={() => {
                        if (donation) {
                          handleDeleteDonation(donation);
                        }
                      }}
                      isLoading={isDeleteDonationPending}
                    >
                      Eliminar Donación
                    </Button>
                    <Button variant="default" onClick={handleOpenPaymentDetail}>
                      Ver Cobro
                    </Button>
                  </>
                )}
              </div>
            </div>
            <Separator className="mt-8" />
            <div className="mt-12">
              <DonationForm
                form={form}
                cancelLabel="Cerrar Donación"
                submitLabel="Guardar Cambios"
                dismissHandler={handleDismiss}
                submitHandler={handleEditDonation}
                isLoading={isEditDonationPending}
                disabled
              />
            </div>
          </div>
        )}
      </SheetLayout>
      <AlertDialogWrapper
        {...alertDialog}
        isLoading={isDeleteDonationPending}
        onDismiss={handleDismissAlertDialog}
      />
    </>
  );
};

export default DonationsDetail;
