import { Button, buttonVariants } from '@/components/ui/button';
import logoImage from '@/assets/images/logo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import SidebarElements from './SidebarElements';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { cn } from '@/lib/utils';
import { useEffect, useState } from 'react';
import { paths } from '@/routes/paths';

const BASE_CLASS =
  'flex items-center justify-between w-full p-2 text-sm font-normal text-accent-foreground rounded-md border border-transparent hover:bg-gray-100 hover:border-gray-100 hover:no-underline data-[state=open]:bg-[#EFF6FF] data-[state=open]:text-indigo-700';

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openedItem, setOpenedItem] = useState<string>('');

  const checkActive = (path: string) => {
    return (
      location.pathname.startsWith(path) ||
      (location.pathname.includes(paths.LEDGER_ACCOUNTS) && path.includes(paths.LEDGER_ACCOUNTS))
    );
  };

  useEffect(() => {
    const item = SidebarElements.find((element) => checkActive(element.path));
    setOpenedItem(item?.name ?? '');
  }, [location.pathname]);

  return (
    <aside className="min-h-screen w-[215px] p-4 border-r-[1px] border-gray-200">
      <img src={logoImage} alt="logo" width={64} height={56} className="mt-4 mb-12" />
      <nav className="space-y-2">
        {SidebarElements.map((item) =>
          item.submenu ? (
            <Accordion
              type="single"
              collapsible
              key={item.name}
              className="space-y-1"
              value={openedItem}
              onValueChange={setOpenedItem}
            >
              <AccordionItem value={item.name} className="border-none ">
                <AccordionTrigger
                  onClick={() => navigate(item.submenu[0].path)}
                  className={cn(buttonVariants({ variant: 'ghost' }), BASE_CLASS, {
                    'hover:text-indigo-700 bg-[#EFF6FF] border-[#E2E8F0] hover:bg-[#EFF6FF] hover:border-[#E2E8F0]':
                      location.pathname.startsWith(item.path),
                  })}
                >
                  <div className="flex items-center justify-start">
                    {item.icon}
                    <div className={cn('text-sm duration-200 ')}>{item.name}</div>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="mt-2 p-2 pl-8 space-y-2 bg-slate-50 rounded-md border border-[#E2E8F0]">
                  {item.submenu?.map((subitem) => (
                    <Button
                      key={subitem.name}
                      variant={location.pathname === subitem.path ? 'secondary' : 'ghost'}
                      onClick={() => navigate(subitem.path)}
                      className={cn(
                        BASE_CLASS,
                        'h-auto p-0 font-sm font-normal text-muted-foreground border-0 hover:bg-transparent hover:font-normal hover:text-muted-foreground',
                        {
                          'bg-transparent text-indigo-700 hover:text-indigo-700':
                            location.pathname === subitem.path,
                        },
                      )}
                    >
                      {subitem.name}
                    </Button>
                  ))}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          ) : (
            <Button
              key={item.name}
              variant={checkActive(item.path) ? 'secondary' : 'ghost'}
              onClick={() => navigate(item.path)}
              className={cn(
                'flex items-center font-normal justify-start w-full p-2 text-sm text-accent-foreground rounded-md border border-transparent hover:bg-gray-100 hover:border-gray-100',
                {
                  'text-indigo-700 bg-[#EFF6FF] border-[#E2E8F0] hover:bg-[#EFF6FF] hover:border-[#E2E8F0]':
                    checkActive(item.path),
                },
              )}
            >
              {item.icon}
              {item.name}
            </Button>
          ),
        )}
      </nav>
    </aside>
  );
};

export default Sidebar;
