import { TableWrapper } from '@/components/shared/TableWrapper';
import { creditNotesTableColumns } from '../../CreditNotes/utils/CreditNotesTableColumns';
import { CreditNoteApi } from '@/api/creditNote/creditNote.model';
import {
  ANIMATION_QUERY_PARAM,
  DEFAULT_PAGINATION_PAGE_COUNT,
  SONNER_ERROR_OPTIONS,
  SONNER_SUCCESS_OPTIONS,
} from '@/common/constants';
import useTableColumnsWithActions from '@/hooks/useTableColumnsWithActions';
import { AlertDialogWrapper } from '@/components/shared/AlertDialog';
import { useTablePagination } from '@/hooks/useTablePagination';
import { deleteCreditNoteMutationFn, useInvoiceCreditNotes } from '@/api/creditNote/creditNote.api';
import { InvoiceEditParams } from '../InvoiceDetails.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { toast } from 'sonner';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import { paths, pathsBase } from '@/routes/paths';
import { useInvoice } from '@/api/invoice/invoice.api';
import { UserRole } from '@/api/user/user.model.ts';
import useUserStore from '@/store/useUserStore.ts';
import { useAlertDialog } from '@/hooks/useAlertDialog.tsx';
import useFilterAndSortByBe from '@/hooks/useFilterAndSortByBe.tsx';

const PAGE_TITLE = 'Notas de Crédito';

interface CreditNotesTabProps {
  showCTA: boolean;
}

const CreditNotesTab = ({ showCTA }: CreditNotesTabProps) => {
  const navigate = useNavigate();

  const { user } = useUserStore();

  const { id } = useParams() as InvoiceEditParams;

  const { pageIndex, pageSize, paginationState, setPagination, dir, sort, setDir, setSort } =
    useTablePagination();

  const { alertDialog, setAlertDialog, handleDismissAlertDialog } = useAlertDialog();

  const { refetch: refetchInvoice } = useInvoice(id);

  const {
    data: creditNotes,
    isLoading: isLoadingCreditNotes,
    refetch: refetchCreditNotes,
  } = useInvoiceCreditNotes(id, {
    page: pageIndex,
    size: pageSize,
    dir: dir,
    sort: sort,
  });

  useFilterAndSortByBe(refetchCreditNotes, setDir, setSort);

  const { mutate: deleteCreditNote, isPending: isDeleteCreditNotePending } = useMutation({
    mutationFn: deleteCreditNoteMutationFn,
    onSuccess: () => {
      refetchInvoice();
      refetchCreditNotes();
      toast(
        <SuccessToastText>¡Nota de Crédito eliminada exitosamente!</SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismissAlertDialog();
    },
    onError: (error: AxiosError) => {
      Sentry.captureException(error);
      toast.error('Ha ocurrido un error durante la eliminación de la Nota de Crédito.', {
        description: 'Por favor, intentalo nuevamente.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  const handleCreditNoteDelete = (creditNote: CreditNoteApi) => {
    setAlertDialog({
      title: `¿Estás seguro que queres eliminar la Nota de Crédito "#${creditNote.number}"? `,
      description: `Esta acción no puede ser deshecha y se perderá toda la información asociada a esta Nota de Crédito ("#${creditNote.number})".`,
      open: true,
      onCancel: handleDismissAlertDialog,
      onConfirm: () => deleteCreditNote(creditNote.id),
    });
  };

  const handleCreateCreditNote = () => {
    if (id) {
      navigate(`${paths.INVOICES}/${id}/${pathsBase.CREDIT_NOTE}?${ANIMATION_QUERY_PARAM}=true`);
    }
  };

  const columnsWithActions = useTableColumnsWithActions<CreditNoteApi>(
    creditNotesTableColumns,
    () => {},
    handleCreditNoteDelete,
    true,
    undefined,
    user?.role !== UserRole.ADMIN,
  );

  return (
    <>
      <TableWrapper
        columns={columnsWithActions}
        data={creditNotes}
        rowsLabel={PAGE_TITLE}
        isLoading={isLoadingCreditNotes || isDeleteCreditNotePending}
        pageCount={creditNotes ? creditNotes.totalPages : DEFAULT_PAGINATION_PAGE_COUNT}
        pagination={paginationState}
        onPaginationChange={setPagination}
        switchEmptyEntityGender
        emptyCta={
          showCTA
            ? {
                label: 'Registrar Nota de Crédito',
                onClick: handleCreateCreditNote,
              }
            : undefined
        }
        emptyImgProps={{ width: 210, height: 150 }}
      />
      <AlertDialogWrapper
        {...alertDialog}
        isLoading={isDeleteCreditNotePending}
        onDismiss={handleDismissAlertDialog}
      />
    </>
  );
};

export { CreditNotesTab };
