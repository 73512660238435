import { Table, TableBody, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { FC } from 'react';
import { CreditNoteFormType } from '../utils/CreditNoteSchema';
import { UseFormReturn } from 'react-hook-form';
import { InvoiceApi } from '@/api/invoice/invoice.model';
import { InvoiceFormType } from '@/pages/Invoices/utils/InvoiceSchema';
import { useDonationsList } from '@/api/donation/donation.api';
import { useGetGlobalCostCenter } from '@/api/costCenter/costCenter.api';
import { useCategoriesList } from '@/api/category/category.api';
import { LedgerAccountList } from '@/api/ledgerAccount/ledgerAccount.model';
import { CreditNoteExpenseRow } from './CreditNoteExpenseRow';

interface CreditNoteExpensesTableProps {
  form: UseFormReturn<CreditNoteFormType, unknown>;
  invoiceForm: UseFormReturn<InvoiceFormType, unknown>;
  expenses: InvoiceApi['expenses'];
  ledgerAccounts: LedgerAccountList[] | undefined;
  isLoadingLedgerAccounts: boolean;
}

const CreditNoteExpensesTable: FC<CreditNoteExpensesTableProps> = ({
  form,
  invoiceForm,
  expenses,
  ledgerAccounts,
  isLoadingLedgerAccounts,
}) => {
  const { data: donations } = useDonationsList();

  const { data: globalCostCenter } = useGetGlobalCostCenter();

  const { data: categories } = useCategoriesList();

  return (
    <Table className="w-full">
      <TableHeader>
        <TableRow className="hover:bg-inherit">
          <TableHead>Descripción</TableHead>
          <TableHead>Cuenta Contable</TableHead>
          <TableHead>Donación</TableHead>
          <TableHead>Centro de Costos</TableHead>
          <TableHead>Categoría</TableHead>
          <TableHead className="w-48">Importe a revertir</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {expenses?.map((_expense, index) => (
          <CreditNoteExpenseRow
            key={_expense.id}
            form={form}
            invoiceForm={invoiceForm}
            expense={_expense}
            creditNoteIndex={index}
            ledgerAccounts={ledgerAccounts}
            donations={donations}
            globalCostCenter={globalCostCenter}
            categories={categories}
            isLoadingLedgerAccounts={isLoadingLedgerAccounts}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export { CreditNoteExpensesTable };
