import { useState } from 'react';

interface HookFunction<P> {
  (params?: P): Promise<void>;
}

const useCustomLoader = <P = void,>(fn: HookFunction<P>) => {
  const [isLoading, setIsLoading] = useState(false);

  const response = async (params?: P) => {
    setIsLoading(true);
    await fn(params);
    setIsLoading(false);
  };

  return { isLoading, response };
};

export default useCustomLoader;
