import { ColumnDef } from '@tanstack/react-table';
import { SortColumnWithBe } from '@/components/shared/SortColumnWithBe';
import {
  Invoice,
  InvoiceState,
  PaymentState,
  invoicePaymentBadgeStylesMap,
  invoicePaymentStateMap,
  invoiceStateMap,
} from '@/api/invoice/invoice.model';
import { Badge } from '@/components/ui/badge';
import { formatAmount, genericColumnFilterFn, getDataArray } from '@/lib/utils';
import { Supplier } from '@/api/supplier/supplier.model';
import { Money } from '@/api/transaction/transaction.model';
import { FilterColumnWithBe } from '@/components/shared/FilterColumnWithBe';
import { useSupplierList } from '@/api/supplier/supplier.api';
import { useDetails } from '@/api/invoice/invoice.api';

const invoiceTableColumns: ColumnDef<Invoice>[] = [
  {
    accessorKey: 'receiptNumber',
    header: ({ column }) => <SortColumnWithBe title="Factura #" column={column} />,
  },
  {
    accessorKey: 'supplier',
    filterFn: genericColumnFilterFn<Invoice>('supplier', 'id'),
    header: ({ column }) => {
      return (
        <FilterColumnWithBe
          title="Proveedor"
          column={column}
          filterOptions={getDataArray(useSupplierList, true)}
          commandDropdownClassname="max-w-[300px]"
        />
      );
    },
    cell: ({ row }) => {
      const supplier = row.getValue('supplier') as Supplier;
      return supplier?.name || '-';
    },
    size: 200,
  },
  {
    accessorKey: 'details',
    header: ({ column }) => {
      return (
        <FilterColumnWithBe
          title="Descripción"
          column={column}
          filterOptions={getDataArray(useDetails, true)}
          commandDropdownClassname="max-w-[300px]"
        />
      );
    },
  },
  {
    accessorKey: 'receiptDate',
    header: ({ column }) => <SortColumnWithBe title="Fecha" column={column} />,
  },
  {
    accessorKey: 'dueDate',
    header: ({ column }) => <SortColumnWithBe title="Fecha de Pago" column={column} />,
  },
  {
    accessorKey: 'money',
    header: () => <p className="text-xs">Total</p>,
    cell: ({ row }) => {
      const money = row.getValue('money') as Money;
      return formatAmount(money?.amount || 0, money?.currency);
    },
  },
  {
    accessorKey: 'pendingAmount',
    header: () => <p className="text-xs">Pendiente</p>,
    cell: ({ row }) => {
      const money = row.getValue('money') as Money;
      return formatAmount(row.getValue('pendingAmount') || 0, money?.currency);
    },
  },
  {
    accessorKey: 'invoiceState',
    header: ({ column }) => {
      const resultArray = (Object.keys(invoiceStateMap) as Array<InvoiceState>).map((key) => ({
        label: invoiceStateMap[key],
        value: key,
      }));
      return (
        <FilterColumnWithBe title="Estado Factura" column={column} filterOptions={resultArray} />
      );
    },
    cell: ({ row }) => {
      const state = row.getValue('invoiceState');
      const stateLabel = invoiceStateMap[state as keyof typeof invoiceStateMap] || '-';
      return (
        <Badge
          variant={
            state
              ? state === InvoiceState.PUBLISHED
                ? 'outlineBlue'
                : 'outlineGray'
              : 'outlineGray'
          }
        >
          {stateLabel}
        </Badge>
      );
    },
  },
  {
    accessorKey: 'paymentState',
    header: ({ column }) => {
      const resultArray = (Object.keys(invoicePaymentStateMap) as Array<PaymentState>).map(
        (key) => ({
          label: invoicePaymentStateMap[key],
          value: key,
        }),
      );
      return <FilterColumnWithBe title="Estado Pago" column={column} filterOptions={resultArray} />;
    },
    cell: ({ row }) => {
      const state = row.getValue('paymentState');
      const stateLabel =
        invoicePaymentStateMap[state as keyof typeof invoicePaymentStateMap] || '-';
      return state ? (
        <Badge
          variant={
            state
              ? invoicePaymentBadgeStylesMap[state as keyof typeof invoicePaymentBadgeStylesMap]
              : 'outlineGray'
          }
        >
          {stateLabel}
        </Badge>
      ) : (
        stateLabel
      );
    },
  },
];

export { invoiceTableColumns };
