import { z } from 'zod';

const REQUIRED_OPTION_LABEL = 'Seleccione una opción';
const REQUIRED_AMOUNT_LABEL = 'Ingrese un monto válido';

const SelectSchema = z
  .object({
    id: z.string().min(1, REQUIRED_OPTION_LABEL),
    name: z.string(),
  })
  .required();

const OptionalSelectSchema = z
  .object({
    id: z.string(),
    name: z.string(),
  })
  .nullable();

const ExpenseSchema = z
  .object({
    supplierAccount: z.object({ id: z.string().min(1, 'El id es obligatorio') }).optional(),
    id: z.string().optional(),
    description: z.string().optional(),
    global: z.boolean(),
    ledgerAccount: SelectSchema,
    donation: OptionalSelectSchema,
    costCenter: OptionalSelectSchema,
    category: OptionalSelectSchema,
    amount: z.coerce.number().min(0, REQUIRED_AMOUNT_LABEL),
    money: z
      .object({
        amount: z.number(),
        currency: z.string(),
      })
      .optional(),
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  .superRefine((data: { [key: string]: any }, ctx) => {
    if (!data.global) {
      const requiredFields = ['ledgerAccount', 'donation', 'costCenter', 'category'];
      requiredFields.forEach((field) => {
        if (!data[field] || data[field].id === '') {
          ctx.addIssue({
            path: [field],
            message: 'Este campo es obligatorio',
            code: 'custom',
          });
        }
      });
    }
  });

type ExpenseFormType = z.infer<typeof ExpenseSchema>;

const defaultExpenseFormValues: ExpenseFormType = {
  description: '',
  global: false,
  ledgerAccount: { id: '', name: '' },
  donation: { id: '', name: '' },
  costCenter: { id: '', name: '' },
  category: { id: '', name: '' },
  amount: 0,
};

export { ExpenseSchema, defaultExpenseFormValues };
export type { ExpenseFormType };
