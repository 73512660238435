import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { ExpenseReportFundFormType } from '../utils/ExpenseReportFundSchema';
import { ExpenseReportApi } from '@/api/expenseReport/expenseReport.model';
import { useCategoriesByDonationCostCenter } from '@/api/category/category.api';
import { useCostCenterByDonation } from '@/api/costCenter/costCenter.api';
import { useDonationsList } from '@/api/donation/donation.api';
import { FC, useEffect } from 'react';
import { getConvertedAmount, getFullName } from '@/lib/utils';
import { SelectFilter } from '@/components/shared/SelectFilter';
import { Button } from '@/components/ui/button';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Textarea } from '@/components/ui/textarea';
import { CurrencyInput } from '@/components/ui/currency-input';
import { Input } from '@/components/ui/input';
import { CurrencyOption } from '@/common/types';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { CURRENCY_OPTIONS } from '@/common/constants';
import { FileUploader } from '@/components/shared/FileUploader';
import { useLedgerAccountChildrens } from '@/api/ledgerAccount/ledgerAccount.api';

interface ExpenseReportFundFormProps {
  form: UseFormReturn<ExpenseReportFundFormType, unknown>;
  submitLabel: string;
  cancelLabel: string;
  isLoading?: boolean;
  expenseReport?: ExpenseReportApi;
  submitHandler: SubmitHandler<ExpenseReportFundFormType>;
  dismissHandler: () => void;
}

const costCenterIdForm = 'costCenter.id';
const donationIdForm = 'donation.id';

const ExpenseReportFundForm: FC<ExpenseReportFundFormProps> = ({
  form,
  submitLabel,
  cancelLabel,
  isLoading,
  expenseReport,
  submitHandler,
  dismissHandler,
}) => {
  const donationId = form.watch(donationIdForm);
  const costCenterId = form.watch(costCenterIdForm);
  const { data: ledgerAccountsChildren, isLoading: isLoadingLedgerAccounts } =
    useLedgerAccountChildrens();
  const { data: donations, isLoading: isLoadingDonations } = useDonationsList();
  const { data: costCenters, isLoading: isLoadingCostCenters } =
    useCostCenterByDonation(donationId);
  const { data: categories, isLoading: isLoadingCategories } = useCategoriesByDonationCostCenter(
    donationId,
    costCenterId,
  );
  const costCentersWithoutGlobal = costCenters?.filter((costCenter) => costCenter.global === false);

  // Reset the cost center and category when the donation changes
  useEffect(() => {
    if (donationId) {
      form.setValue('costCenter', { id: '', name: '' });
      form.setValue('category', { id: '', name: '' });
    }
  }, [form.watch('donation'), donationId]);

  // Reset the category when the cost center changes
  useEffect(() => {
    if (costCenterId) {
      form.setValue('category', { id: '', name: '' });
    }
  }, [form.watch('costCenter'), costCenterId]);

  // Set employee when expense report is passed as prop
  useEffect(() => {
    if (expenseReport) {
      form.setValue('employee.id', expenseReport.id);
      form.setValue('employee.name', getFullName(expenseReport.firstName, expenseReport.lastName));
    }
  }, [expenseReport]);

  const handleChangeCurrency = (value: string) => {
    form.setValue('currency', value);
    if (value === 'USD') {
      form.setValue('exchangeRate', 1);
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(submitHandler)} className="flex flex-col gap-6 py-8">
        <div className="flex flex-col gap-4">
          <div className="flex flex-row gap-4">
            <SelectFilter
              form={form}
              isLoading={isLoadingLedgerAccounts}
              formName="ledgerAccount"
              formLabel="Cuenta Contable"
              formPlaceholder="Cuenta Contable"
              formFilterPlaceholder="Buscar Cuenta Contable..."
              options={ledgerAccountsChildren || []}
              classnames={{ button: 'w-[200px]', item: 'w-1/4' }}
            />

            <SelectFilter
              form={form}
              isLoading={isLoadingDonations}
              formName="donation"
              formLabel="Donación"
              formPlaceholder="Seleccionar Donación"
              formFilterPlaceholder="Buscar Donación..."
              options={donations || []}
              classnames={{ button: 'w-full', item: 'w-1/4' }}
            />

            <SelectFilter
              form={form}
              isLoading={
                isLoadingCostCenters ||
                costCenters === undefined ||
                costCenters.length === 0 ||
                !form.watch('donation.id')
              }
              formName="costCenter"
              formLabel="Centro de Costo"
              formPlaceholder="Seleccionar Centro de Costo"
              formFilterPlaceholder="Buscar Centro de Costo..."
              options={costCentersWithoutGlobal || []}
              classnames={{ button: 'w-full', item: 'w-1/4' }}
            />

            <SelectFilter
              form={form}
              isLoading={
                isLoadingCategories ||
                categories === undefined ||
                categories.length === 0 ||
                !form.watch('costCenter.id')
              }
              formName="category"
              formLabel="Categoria"
              formPlaceholder="Seleccionar Categoria"
              formFilterPlaceholder="Buscar Categoria..."
              options={categories || []}
              classnames={{ button: 'w-full', item: 'w-1/4' }}
            />
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex flex-row gap-4">
            <FormField
              control={form.control}
              name="currency"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Tipo de Moneda</FormLabel>
                  <Select
                    onValueChange={(value) => handleChangeCurrency(value)}
                    value={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Seleccione la Moneda" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {CURRENCY_OPTIONS.map((currency) => (
                        <SelectItem key={currency.value} value={currency.value}>
                          {currency.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="amount"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Monto en {form.watch('currency')}</FormLabel>
                  <FormControl>
                    <CurrencyInput
                      {...field}
                      type="number"
                      currency={form.watch('currency') as CurrencyOption['value']}
                      placeholder="Ingresar monto"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="exchangeRate"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Tipo de Cambio {`(${form.watch('currency')}/USD)`}</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="number"
                      placeholder="Ingresar Conversión"
                      disabled={form.watch('currency') === 'USD'}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="amountConverted"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Monto total en USD</FormLabel>
                  <FormControl>
                    <CurrencyInput
                      {...field}
                      type="number"
                      disabled
                      value={getConvertedAmount(
                        Number(form.watch('amount')) || 0,
                        Number(form.watch('exchangeRate')),
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>

        <div className="flex flex-row w-full gap-4">
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem className="w-1/2">
                <FormLabel>Descripción</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="Ingrese aquí la descripción del Anticipo..."
                    className="resize-none"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="flex flex-col w-1/3 gap-2">
            <FormLabel>Solicitud de Anticipo</FormLabel>
            <FileUploader
              form={form}
              fieldName="files"
              label="Cargar Solicitud de Anticipo"
              fileLimit={1}
            />
          </div>
        </div>

        <div className="flex flex-row justify-end gap-2">
          <Button type="button" variant="outline" disabled={isLoading} onClick={dismissHandler}>
            {cancelLabel}
          </Button>
          <Button
            type="submit"
            disabled={isLoading || !form.formState.isValid}
            className="relative"
            isLoading={isLoading}
          >
            {submitLabel}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default ExpenseReportFundForm;
