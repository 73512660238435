import { FC, ImgHTMLAttributes, ReactElement } from 'react';
import notFoundImage from '@/assets/images/illustrations/not-found.png';
import { Button } from '../ui/button';
import { FilePlusIcon } from 'lucide-react';

interface TableEmptyProps {
  title: string;
  description?: string;
  cta?: {
    label: string;
    onClick?: () => void;
  };
  imageProps?: ImgHTMLAttributes<HTMLImageElement>;
  logo?: ReactElement;
}

const TableEmpty: FC<TableEmptyProps> = ({
  title,
  description,
  cta,
  imageProps,
  logo,
}: TableEmptyProps) => {
  return (
    <div className="flex flex-col justify-center p-4">
      <img
        src={notFoundImage}
        alt="logo"
        width={250}
        height={200}
        className="mx-auto"
        {...imageProps}
      />
      <div className="flex flex-col gap-8 mt-8">
        <div className="text-center">
          <h2 className="text-2xl font-medium text-foreground">{title}</h2>
          <p className="text-base font-light text-muted-foreground mt-2">{description}</p>
        </div>
        {cta ? (
          <Button type="button" className="w-fit mx-auto" onClick={cta.onClick}>
            {logo ? logo : <FilePlusIcon className="mr-2 h-4 w-4" />}
            {cta.label}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export type { TableEmptyProps };
export { TableEmpty };
