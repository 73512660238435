import {
  DEFAULT_EMAIL_SCHEMA,
  DEFAULT_NAME_SCHEMA,
  FISCAL_PERSON_TYPE_OPTIONS,
} from '@/common/constants';
import { constructZodLiteralUnionType } from '@/lib/utils';
import { z } from 'zod';

const DonorTypeSchema = constructZodLiteralUnionType(
  FISCAL_PERSON_TYPE_OPTIONS.map((literal) => z.literal(literal.value)),
);

const DonorSchema = z
  .object({
    name: DEFAULT_NAME_SCHEMA,
    email: DEFAULT_EMAIL_SCHEMA,
    type: DonorTypeSchema,
  })
  .required();

type DonorFormType = z.infer<typeof DonorSchema>;

const defaultDonorFormValues: DonorFormType = {
  name: '',
  email: '',
  type: FISCAL_PERSON_TYPE_OPTIONS[0].value,
};

export type { DonorFormType };
export { DonorSchema, defaultDonorFormValues };
