import { FC, ReactNode } from 'react';

interface DonationFormSectionProps {
  title: string;
  children: ReactNode;
}

const DonationFormSection: FC<DonationFormSectionProps> = ({
  title,
  children,
}: DonationFormSectionProps) => {
  return (
    <div className="flex flex-col gap-6">
      <h2 className="text-xl font-medium text-foreground leading-none">{title}</h2>
      <div>{children}</div>
    </div>
  );
};

export { DonationFormSection };
