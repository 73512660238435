import { ExpenseReportApi } from '@/api/expenseReport/expenseReport.model';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type ExpenseReportStore = {
  expenseReport: ExpenseReportApi;
  setExpenseReport: (expenseReport: ExpenseReportApi) => void;
};

const useExpenseReportStore = create<ExpenseReportStore>()(
  persist(
    (set) => ({
      expenseReport: {} as ExpenseReportApi,
      setExpenseReport: (expenseReport) => set({ expenseReport }),
    }),
    {
      name: 'expense-report-storage',
    },
  ),
);

export default useExpenseReportStore;
