/* eslint-disable sonarjs/cognitive-complexity */
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { cn } from '@/lib/utils';
import { Check, ChevronsUpDown } from 'lucide-react';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@/components/ui/command';
import { FilterEmpty } from '@/components/shared/FilterEmpty';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Button } from '../ui/button';
import { FilterItem } from '@/common/types';
import { ScrollArea } from '../ui/scroll-area';

interface SelectFilterClassNames {
  button?: string;
  item?: string;
  popover?: string;
}

interface SelectFilterProps {
  form: UseFormReturn<any, unknown, any>;
  isLoading: boolean;
  formName: string;
  formLabel?: string;
  formPlaceholder: string;
  formFilterPlaceholder: string;
  options: Array<FilterItem>;
  selectedOption?: string;
  onChange?: (value: any) => void;
  classnames?: SelectFilterClassNames;
  disabled?: boolean;
  shouldValidate?: boolean;
}

const SelectFilter: FC<SelectFilterProps> = ({
  form,
  isLoading,
  formName,
  formLabel,
  formPlaceholder,
  formFilterPlaceholder,
  options,
  selectedOption,
  onChange,
  shouldValidate = true,
  disabled,
  classnames: { item: itemClassname, button: buttonClassname, popover: popoverClassname } = {},
}) => {
  const handleOptionSelect = (option: FilterItem) => {
    form.setValue(
      formName,
      {
        id: option.id,
        name: option.name,
        code: option.code,
        children: option.children || [],
      },
      { shouldValidate: shouldValidate },
    );
  };
  return (
    <FormField
      control={form.control}
      name={formName}
      disabled={isLoading}
      render={({ field }) => (
        <FormItem className={cn('flex flex-col w-full', itemClassname)}>
          {formLabel && <FormLabel>{formLabel}</FormLabel>}
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  type="button"
                  variant="outline"
                  role="combobox"
                  className={cn(
                    'w-[200px] hover:bg-transparent hover:text-foreground disabled:bg-slate-100 disabled:opacity-100',
                    buttonClassname,
                  )}
                  childrenClassName={cn(
                    'justify-between text-sm font-normal text-foreground truncate',
                    !field.value || field.value.name === ''
                      ? 'font-light text-muted-foreground hover:text-muted-foreground truncate'
                      : null,
                  )}
                  fullWidth
                  disabled={isLoading || disabled}
                >
                  <div className="flex justify-between w-full items-center">
                    <span className="w-5/6 truncate text-start">
                      {selectedOption || (field.value && field.value.name) || formPlaceholder}
                    </span>
                    <ChevronsUpDown className="w-4 h-4 ml-2 opacity-50 text-foreground" />
                  </div>
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent
              className={cn('p-0 popover-content-width-same-as-its-trigger', popoverClassname)}
            >
              <Command>
                <CommandInput
                  placeholder={formFilterPlaceholder}
                  className="placeholder:font-light"
                />
                <ScrollArea className={`overflow-auto ${options.length > 6 ? 'h-52' : ''}`}>
                  <CommandEmpty>
                    <FilterEmpty
                      title={`No se encontraron resultados que coincidan con la busqueda ingresada`}
                    />
                  </CommandEmpty>
                  <CommandGroup className="w-full">
                    {options &&
                      options.map((option) => (
                        <CommandItem
                          onWheel={(e) => {
                            e.stopPropagation();
                          }}
                          value={option.name}
                          key={option.id}
                          onSelect={() => {
                            if (onChange) {
                              onChange(option);
                            } else {
                              handleOptionSelect(option);
                            }
                          }}
                        >
                          <Check
                            className={cn(
                              'mr-2 h-4 w-4',
                              field.value && option.id === field.value.id
                                ? 'opacity-100'
                                : 'opacity-0',
                            )}
                          />
                          {option.name}
                        </CommandItem>
                      ))}
                  </CommandGroup>
                </ScrollArea>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export { SelectFilter };
