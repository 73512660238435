import { DETAIL_DISABLED_BUTTON_CLASS } from '@/common/constants';
import { AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Card } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { cn, formatAmount } from '@/lib/utils';
import { FC } from 'react';

interface CategoriesBoxParams {
  field: any;
  index: number;
  value: string;
  isOpen: boolean;
  onChevronClick: (value: string) => void;
}

const CategoriesBox: FC<CategoriesBoxParams> = ({
  field,
  value,
  isOpen,
  onChevronClick,
}: CategoriesBoxParams) => {
  return (
    <Card className="w-full px-5 py-[22px] shadow-sm">
      <AccordionItem value={value} className="border-none">
        <AccordionTrigger
          className="flex justify-between items-center py-0 pr-6 hover:no-underline"
          onChevronClick={() => onChevronClick(value)}
          chevronClassname={cn('w-9 h-9', isOpen ? 'rotate-180' : null)}
        >
          <div className="flex flex-col gap-2 items-start">
            <Label>Categoría</Label>
            <Select defaultValue={field.category} value={field.category}>
              <SelectTrigger disabled className={DETAIL_DISABLED_BUTTON_CLASS}>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={field.category}>{field.category}</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="flex gap-10">
            <div className="flex items-center  gap-1.5">
              <p className="text-sm font-medium uppercase text-muted-foreground">TOTAL:</p>
              <p className="text-l font-normal text-foreground">{formatAmount(field.amount)}</p>
            </div>
            <div className="flex items-center  gap-1.5">
              <p className="text-sm font-medium uppercase text-muted-foreground">GASTOS:</p>
              <p className="text-l font-normal text-foreground">{formatAmount(field.expenses)}</p>
            </div>
            <div className={cn('flex items-center gap-1.5')}>
              <p className="text-sm font-medium uppercase text-muted-foreground">DISPONIBLE:</p>
              <p
                className={cn(
                  'text-l font-normal text-foreground',
                  field.balance >= 0 ? 'text-green-600' : 'text-red-600',
                )}
              >
                {formatAmount(field.balance)}
              </p>
            </div>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <div className="flex flex-col gap-2.5 mt-6">
            <Label>Donaciones</Label>
            <div className="flex flex-col gap-4">
              {field.donations.map(
                (value: { name: string; amount: number; balance: number; expenses: number }) => (
                  <div
                    key={value.name}
                    className="flex items-end justify-between gap-5 p-5 rounded-lg bg-slate-50"
                  >
                    <div className="flex flex-col gap-2.5">
                      <Label>Donación</Label>
                      <Select defaultValue={value.name} value={value.name}>
                        <SelectTrigger disabled className={DETAIL_DISABLED_BUTTON_CLASS}>
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value={value.name}>{value.name}</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                    <div className="flex gap-10">
                      <div className="flex items-center  gap-1.5">
                        <p className="text-sm font-medium text-muted-foreground">Total:</p>
                        <p className="text-l font-normal text-foreground">
                          {formatAmount(value.amount)}
                        </p>
                      </div>
                      <div className="flex items-center  gap-1.5">
                        <p className="text-sm font-medium text-muted-foreground">Gastos:</p>
                        <p className="text-l font-normal text-foreground">
                          {formatAmount(value.expenses)}
                        </p>
                      </div>
                      <div className={cn('flex items-center gap-1.5')}>
                        <p className="text-sm font-medium text-muted-foreground">Disponible:</p>
                        <p
                          className={cn(
                            'text-l font-normal text-foreground',
                            value.balance >= 0 ? 'text-green-600' : 'text-red-600',
                          )}
                        >
                          {formatAmount(value.balance)}
                        </p>
                      </div>
                    </div>
                  </div>
                ),
              )}
            </div>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Card>
  );
};

export { CategoriesBox };
