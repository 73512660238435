import { FC } from 'react';
import {
  DEFAULT_PAGINATION_PAGE_COUNT,
  SONNER_ERROR_OPTIONS,
  SONNER_SUCCESS_OPTIONS,
} from '@/common/constants';
import { useTablePagination } from '@/hooks/useTablePagination';
import { expenseReportsTableColumns } from './utils/ExpenseReportsTableColumns';
import useTableColumnsWithActions from '@/hooks/useTableColumnsWithActions';
import { TableWrapper } from '@/components/shared/TableWrapper';
import {
  deleteExpenseReportMutationFn,
  useExpenseReports,
} from '@/api/expenseReport/expenseReport.api';
import { ExpenseReport, ExpenseReportApi } from '@/api/expenseReport/expenseReport.model';
import { Outlet, useNavigate } from 'react-router-dom';
import { AlertDialogWrapper } from '@/components/shared/AlertDialog';
import { getFullName } from '@/lib/utils';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';
import { paths } from '@/routes/paths';
import { useAlertDialog } from '@/hooks/useAlertDialog';
import useFilterAndSortByBe from '@/hooks/useFilterAndSortByBe';

const PAGE_TITLE = 'Expense Reports';

const ExpenseReports: FC = () => {
  const navigate = useNavigate();

  const { pageIndex, pageSize, paginationState, dir, sort, setPagination, setDir, setSort } =
    useTablePagination<ExpenseReportApi>();

  const { data, isLoading, isFetching, refetch } = useExpenseReports({
    page: pageIndex,
    size: pageSize,
    dir: dir,
    sort: sort,
  });

  const { alertDialog, setAlertDialog, handleDismissAlertDialog } = useAlertDialog();

  const { mutate: deleteExpenseReport, isPending: isDeleteExpenseReportPending } = useMutation({
    mutationFn: deleteExpenseReportMutationFn,
    onSuccess: () => {
      refetch();
      toast(
        <SuccessToastText>Expense Report eliminado exitosamente</SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismissAlertDialog();
    },
    onError: (error: AxiosError) => {
      Sentry.captureException(error);
      toast.error('Ha ocurrido un error durante la eliminación del Expense Report.', {
        description: 'Por favor, intentalo nuevamente.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  useFilterAndSortByBe<ExpenseReportApi>(refetch, setDir, setSort);

  const handleEditExpenseReport = (expenseReport: ExpenseReport) =>
    navigate(`${paths.EXPENSE_REPORTS}/${paths.EDIT}/${expenseReport.id}`);

  const handleDeleteExpenseReport = (expenseReport: ExpenseReport) => {
    setAlertDialog({
      title: `¿Estás seguro que queres eliminar "${getFullName(expenseReport.firstName, expenseReport.lastName)}"? `,
      description: `Esta acción no puede ser deshecha y se perderá toda la información asociada a este Expense Report: "${getFullName(expenseReport.firstName, expenseReport.lastName)}". `,
      open: true,
      onCancel: handleDismissAlertDialog,
      onConfirm: () => deleteExpenseReport(expenseReport.id),
    });
  };

  const expenseReportsColumnsWithActions = useTableColumnsWithActions<ExpenseReport>(
    expenseReportsTableColumns,
    handleEditExpenseReport,
    handleDeleteExpenseReport,
  );

  return (
    <>
      <TableWrapper
        columns={expenseReportsColumnsWithActions}
        data={data ? data[0] : data}
        isLoading={isLoading}
        isFetching={isFetching}
        rowsLabel={PAGE_TITLE}
        pageCount={data ? data[0].totalPages : DEFAULT_PAGINATION_PAGE_COUNT}
        pagination={paginationState}
        onPaginationChange={setPagination}
      />
      <AlertDialogWrapper
        {...alertDialog}
        isLoading={isDeleteExpenseReportPending}
        onDismiss={handleDismissAlertDialog}
      />
      <Outlet />
    </>
  );
};

export default ExpenseReports;
