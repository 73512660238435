import { FC, useEffect } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { paths } from '@/routes/paths';
import { SheetLayout } from '@/components/layout/SheetLayout';
import { Helmet } from 'react-helmet';
import { Expense } from '@/api/transaction/transaction.model';
import { useInvoice } from '@/api/invoice/invoice.api';
import { EditFormSkeleton } from '@/components/shared/EditFormSkeleton';
import TableSkeleton from '@/components/shared/TableSkeleton';
import { Separator } from '@/components/ui/separator';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  CreditNoteFormType,
  CreditNoteSchema,
  defaultCreditNoteFormValues,
} from '../utils/CreditNoteSchema';
import { generateCreditNoteForm } from '../utils/creditNoteUtils';
import CreditNoteForm from '../components/CreditNoteForm';
import { useMutation } from '@tanstack/react-query';
import { createCreditNoteMutationFn, useInvoiceCreditNotes } from '@/api/creditNote/creditNote.api';
import { toast } from 'sonner';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { SONNER_ERROR_OPTIONS, SONNER_SUCCESS_OPTIONS } from '@/common/constants';
import { AxiosError } from 'axios';
import { ErrorResponse } from '@/api/types';
import * as Sentry from '@sentry/react';
import { getParamsWithAnimation } from '@/lib/utils';

const PAGE_TITLE = 'Registrar Nota de Crédito';

type CreditNoteCreateParams = {
  id: Expense['id'];
};

const CreditNoteCreate: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams() as CreditNoteCreateParams;

  const {
    data: invoice,
    isLoading: isLoadingInvoice,
    error: invoiceError,
    refetch: refetchInvoice,
  } = useInvoice(id);

  const { refetch: refetchCreditNotes } = useInvoiceCreditNotes(id);

  const form = useForm<CreditNoteFormType>({
    defaultValues: defaultCreditNoteFormValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(CreditNoteSchema),
  });

  const { mutate: createCreditNote, isPending: isPendingCreateCreditNote } = useMutation({
    mutationFn: createCreditNoteMutationFn,
    onSuccess: () => {
      refetchInvoice();
      refetchCreditNotes();
      toast(
        <SuccessToastText>
          ¡Nota de Crédito <span className="text-blue-700">#{form.watch('number')}</span> creada
          exitosamente!
        </SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismiss();
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      Sentry.captureException(error);
      if (error.response && error.response.data.type === 'DUPLICATED_ERROR') {
        form.setError('number', {
          type: 'validate',
          message:
            'El número ingresado ya fue utilizado previamente. Intentá nuevamente con un número distinto.',
        });
      }

      toast.error('Ha ocurrido un error durante la creación de la Nota de Crédito.', {
        description: 'Por favor, revisa los datos ingresados e intenta de nuevo.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  const handleCreateCreditNoteSubmit = (formData: CreditNoteFormType) => {
    if (invoice) {
      createCreditNote({
        data: formData,
        invoiceId: invoice.id,
        invoiceCurrency: invoice.money.currency,
      });
    }
  };

  useEffect(() => {
    if (invoice) {
      form.reset(generateCreditNoteForm(invoice));
    }
  }, [invoice]);

  if (!id || invoiceError) {
    return <Navigate to="*" replace={true} />;
  }

  const handleDismiss = () =>
    navigate(`${paths.INVOICES}/${id}${getParamsWithAnimation(location.search, true)}`);

  return (
    <>
      <Helmet>
        <title>PEM - {PAGE_TITLE}</title>
      </Helmet>
      <SheetLayout onClose={handleDismiss}>
        {isLoadingInvoice || !invoice ? (
          <>
            <EditFormSkeleton inputRows={3} />
            <TableSkeleton />
          </>
        ) : (
          <div className="mx-auto max-w-screen-2xl">
            <div className="flex flex-col gap-4">
              <h1 className="text-3xl text-[40px] font-light tracking-tighter color-foreground">
                {PAGE_TITLE}
              </h1>
              <h2 className="text-2xl font-light tracking-tighter color-foreground">
                Factura: <span className="font-medium">#{invoice.receiptNumber}</span>
              </h2>
            </div>
            <Separator className="mt-8" />
            <div className="mt-14">
              <CreditNoteForm
                form={form}
                invoice={invoice}
                isLoading={isLoadingInvoice || isPendingCreateCreditNote}
                submitLabel="Registrar Nota de Crédito"
                cancelLabel="Cancelar"
                submitHandler={handleCreateCreditNoteSubmit}
                dismissHandler={handleDismiss}
              />
            </div>
          </div>
        )}
      </SheetLayout>
    </>
  );
};

export { CreditNoteCreate };
